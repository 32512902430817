<template>
	<div>
    <b-button
      variant="info"
      @click="showModalPDF">
      Generar PDF
    </b-button>

    <b-modal ref="bv-modal-pdf" hide-footer title="Emprendimiento">
      <label>Seleccione la información que quiere descargar:</label>
      <b-col class="d-block p-3">
        
        <b-form-checkbox v-model="datos_emprendimiento" :disabled="true">Emprendimiento</b-form-checkbox>
        <b-form-checkbox v-if="this.has_redes_sociales" v-model="datos_redes_sociales">Redes sociales</b-form-checkbox>

        <b-form-checkbox v-model="datos_administrativos">Datos Administrativos</b-form-checkbox>

        <b-form-checkbox v-if="this.has_emprendedores" v-model="datos_integrantes">Integrantes</b-form-checkbox>

        <b-form-checkbox v-if="this.has_estadios" v-model="datos_estadios">Histórico Estadios</b-form-checkbox>
        <b-form-checkbox v-if="this.has_mdn" v-model="datos_registros_mdn">Histórico Modelo de Negocio</b-form-checkbox>
        <b-form-checkbox v-if="this.has_pdn" v-model="datos_registros_pdn">Histórico Plan de Negocio</b-form-checkbox>
        
        <b-form-checkbox v-if="this.has_proteccion_tecnologica" v-model="datos_proteccion_tecnologica">Protección Tecnológica</b-form-checkbox>
        <b-form-checkbox v-if="this.has_formalización_juridica" v-model="datos_formalización_juridica">Formalización Juridica</b-form-checkbox>
        
        <b-form-checkbox v-if="this.has_financiamiento" v-model="datos_financiamiento">Financiamiento Obtenido</b-form-checkbox>
        <b-form-checkbox v-if="this.has_concursos" v-model="datos_concursos_emprendimiento">Concursos y Eventos</b-form-checkbox>
        <b-form-checkbox v-if="this.has_capacitaciones" v-model="datos_capacitaciones_institucionales">Capacitaciones Institucionales</b-form-checkbox>
        <b-form-checkbox v-if="this.has_asesorias" v-model="datos_asesorias">Asesorias</b-form-checkbox>
        <b-form-checkbox v-if="this.has_mentorias" v-model="datos_mentorias">Mentorias</b-form-checkbox>
        <b-form-checkbox v-if="this.has_observaciones" v-model="datos_observaciones">Observaciones</b-form-checkbox>
        <b-form-checkbox v-if="this.has_estudios_mercados" v-model="datos_estudios_mercados">Estudios de Mercado</b-form-checkbox>
        <b-form-checkbox v-if="this.has_cooperaciones_internacionales" v-model="datos_cooperaciones_internacionales">Cooperaciones Internacionales</b-form-checkbox>
        <b-form-checkbox v-if="this.has_redes_internacionales" v-model="datos_redes_internacionales">Redes Internacionales</b-form-checkbox>

        <b-form-checkbox v-if="this.has_necesidad_capital" v-model="datos_necesidad_capital">Necesidad de Capítal</b-form-checkbox>
        <b-form-checkbox v-if="this.has_necesidad_socio" v-model="datos_necesidad_socio">Necesidad de Socio</b-form-checkbox>
        <b-form-checkbox v-if="this.has_necesidad_asesoria" v-model="datos_necesidad_asesoria">Necesidad de Asesoria</b-form-checkbox>
        <b-form-checkbox v-if="this.has_necesidad_internacionalizacion" v-model="datos_necesidad_internacionalizacion">Necesidad de Internacionalización</b-form-checkbox>
        <b-form-checkbox v-if="this.has_necesidad_otra" v-model="datos_necesidad_otra">Otras Necesidades</b-form-checkbox>

      </b-col>
      <b-button 
        class="mt-3 pull-left" 
        variant="danger"  
        @click="hideModalPDF">Cerrar
      </b-button>

      <b-button 
        class="mt-3 pull-right" 
        variant="primary"  
        @click="printPDF">
        <b-spinner small v-if="this.downloading"></b-spinner>
        Descargar PDF
      </b-button>
    </b-modal>

	</div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    props: ['id_emprendimiento','emprendimiento'],
    data() {
      return {
        downloading:false,

        has_redes_sociales: false,
        
        has_emprendedores: false,

        has_estadios: false,
        has_mdn: false,
        has_pdn: false,
        has_proteccion_tecnologica:false,
        has_formalización_juridica:false,
        has_financiamiento: false,
        has_concursos: false,
        has_capacitaciones: false,
        has_asesorias: false,
        has_mentorias: false,
        has_observaciones: false,
        has_estudios_mercados: false,
        has_cooperaciones_internacionales: false,
        has_redes_internacionales: false,
        
        has_necesidad_capital: false,
        has_necesidad_socio: false,
        has_necesidad_asesoria: false,
        has_necesidad_internacionalizacion: false,
        has_necesidad_otra: false,

        datos_emprendimiento: true,
        datos_redes_sociales: false,
        
        datos_administrativos: false,
        
        datos_integrantes: false,

        datos_estadios: false,
        datos_registros_mdn: false,
        datos_registros_pdn: false,
        datos_financiamiento: false,
        datos_concursos_emprendimiento: false,
        datos_capacitaciones_institucionales: false,
        datos_asesorias: false,
        datos_mentorias: false,
        datos_observaciones: false,
        datos_estudios_mercados: false,
        datos_cooperaciones_internacionales: false,
        datos_redes_internacionales: false,

        datos_necesidad_capital: false,
        datos_necesidad_socio: false,
        datos_necesidad_asesoria: false,
        datos_necesidad_internacionalizacion: false,
        datos_necesidad_otra: false      
      }
    },
    methods:{
      checkComponentsHidden(){
        if( this.emprendimiento.emprendimiento.web!=null || 
            this.emprendimiento.emprendimiento.facebook!=null ||
            this.emprendimiento.emprendimiento.instagram!=null ||
            this.emprendimiento.emprendimiento.linkedin!=null){
          this.has_redes_sociales = true;
        }

        if(this.emprendimiento.emprendedores.length != 0){
          this.has_emprendedores=true;
        }
        if(this.emprendimiento.estadios.length != 0){
          this.has_estadios=true;
        }
        if(this.emprendimiento.registros_mdn.length != 0){
          this.has_mdn=true;
        }
        if(this.emprendimiento.registros_pdn.length != 0){
          this.has_pdn=true;
        }
        if(this.emprendimiento.emprendimiento.proteccion_tecnologica == 'si'){
          this.has_proteccion_tecnologica=true;
        }
        if(this.emprendimiento.emprendimiento.formalizacion_juridica == 'si'){
          this.has_formalización_juridica=true;
        }
        if(this.emprendimiento.financiamiento_obtenido_emprendimiento.length != 0){
          this.has_financiamiento=true;
        }
        if(this.emprendimiento.premios_concursos_emprendimiento.length != 0){
          this.has_concursos=true;
        }
        if(this.emprendimiento.capacitaciones_institucionales_emprendimiento.length != 0){
          this.has_capacitaciones=true;
        }
        if(this.emprendimiento.asesorias_emprendimiento.length != 0){
          this.has_asesorias=true;
        }
        if(this.emprendimiento.mentorias_emprendimiento.length != 0){
          this.has_mentorias=true;
        }
        if(this.emprendimiento.observaciones_emprendimiento.length != 0){
          this.has_observaciones=true;
        }
        if(this.emprendimiento.estudios_mercados_emprendimiento.length != 0){
          this.has_estudios_mercados=true;
        }
        if(this.emprendimiento.cooperaciones_internacionales_emprendimiento.length != 0){
          this.has_cooperaciones_internacionales=true;
        }
        if(this.emprendimiento.redes_internacionales_emprendimiento.length != 0){
          this.has_redes_internacionales=true;
        }
        if(this.emprendimiento.necesidad_capital_emprendimiento.length != 0){
          this.has_necesidad_capital=true;
        }
        if(this.emprendimiento.necesidad_socio_emprendimiento.length != 0){
          this.has_necesidad_socio=true;
        }
        if(this.emprendimiento.necesidad_asesoria_emprendimiento.length != 0){
          this.has_necesidad_asesoria=true;
        }
        if(this.emprendimiento.necesidad_internacionalizacion_emprendimiento.length != 0){
          this.has_necesidad_internacionalizacion=true;
        }
        if(this.emprendimiento.necesidad_otra_emprendimiento.length != 0){
          this.has_necesidad_otra=true;
        }
      },

      showModalPDF() {
        this.datos_redes_sociales =  false
        
        this.datos_administrativos =  false
        
        this.datos_integrantes =  false

        this.datos_estadios =  false
        this.datos_registros_mdn =  false
        this.datos_registros_pdn =  false
        this.datos_proteccion_tecnologica = false
        this.datos_formalización_juridica = false
        this.datos_financiamiento =  false
        this.datos_concursos_emprendimiento =  false
        this.datos_capacitaciones_institucionales =  false
        this.datos_asesorias =  false
        this.datos_mentorias =  false
        this.datos_observaciones =  false
        this.datos_estudios_mercados =  false
        this.datos_cooperaciones_internacionales =  false
        this.datos_redes_internacionales =  false

        this.datos_necesidad_capital =  false
        this.datos_necesidad_socio =  false
        this.datos_necesidad_asesoria =  false
        this.datos_necesidad_internacionalizacion =  false
        datos_necesidad_otra: false 
        this.$refs['bv-modal-pdf'].show()
      },

      hideModalPDF(){
        this.$refs['bv-modal-pdf'].hide()
      },

      printPDF(){ 
        this.downloading = true;

        axios({
          url: URL_API+'api/download-pdf',
          method: 'POST',
          responseType: 'blob', // important
          data: {
            id_emprendimiento: this.id_emprendimiento,
            datos_emprendimiento: this.datos_emprendimiento,
            datos_redes_sociales: this.datos_redes_sociales,
            datos_administrativos: this.datos_administrativos,
            datos_integrantes: this.datos_integrantes,
            datos_estadios: this.datos_estadios,
            datos_registros_mdn: this.datos_registros_mdn,
            datos_registros_pdn: this.datos_registros_pdn,
            datos_proteccion_tecnologica: this.datos_proteccion_tecnologica,
            datos_formalización_juridica: this.datos_formalización_juridica,
            datos_financiamiento: this.datos_financiamiento,
            datos_concursos_emprendimiento: this.datos_concursos_emprendimiento,
            datos_capacitaciones_institucionales: this.datos_capacitaciones_institucionales,
            datos_asesorias: this.datos_asesorias,
            datos_mentorias: this.datos_mentorias,
            datos_observaciones: this.datos_observaciones,
            datos_estudios_mercados: this.datos_estudios_mercados,
            datos_cooperaciones_internacionales: this.datos_cooperaciones_internacionales,
            datos_redes_internacionales: this.datos_redes_internacionales,
            datos_necesidad_capital: this.datos_necesidad_capital,
            datos_necesidad_socio: this.datos_necesidad_socio,
            datos_necesidad_asesoria: this.datos_necesidad_asesoria,
            datos_necesidad_internacionalizacion: this.datos_necesidad_internacionalizacion,
            datos_necesidad_otra: this.datos_necesidad_otra
          },
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.id_emprendimiento +' - Emprendimiento.pdf');
          document.body.appendChild(link);
          link.click();
          this.downloading = false;
        })
      }
    },
    mounted(){
      this.checkComponentsHidden();
    }
  };
</script>