<template>
	<div>
		<h4 class="pt-3">Capacitaciones</h4>   
    <b-card>
      <b-table
          class="table-capacitacionesemprendimiento"
          hover
          stacked="md"
          head-variant="dark"
          :items="this.capacitaciones_emprendimiento"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
        >
        </b-table>
    </b-card>
	</div>
</template>

<script>
  export default {
    props: ['capacitaciones_emprendimiento'],
    data() {
      return {
        fields: [
          { key: 'capacitacion',    label: 'Capacitación',    sortable: true, thStyle: {width:'50%'} },
          { key: 'institucion',     label: 'Institución', sortable: true, thStyle: {width:'30%'} },
          { key: 'anio',            label: 'Año',         sortable: true}
        ],
        sortBy: 'anio',
        sortDesc: true,
        sortDirection: 'desc',
      }
    }
  };
</script>
<style>
.table-capacitacionesemprendimiento thead tr th{
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.table-capacitacionesemprendimiento thead tr th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 400px) {
  .table-capacitacionesemprendimiento tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-capacitacionesemprendimiento tbody tr td:nth-child(2) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {
  .table-capacitacionesemprendimiento thead tr th:nth-child(3) {
    justify-content: center;
  }

  .table-capacitacionesemprendimiento tbody tr td:nth-child(3) {
    justify-content: center;
  }
}
</style>