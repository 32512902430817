<template>
  <div class="content pt-4"> 
    <b-container fluid>
      <div class="card">
        <div class="col-md-12">
          <!-- User Interface controls -->
          <b-row align="center">
            <b-col md="3" class="p-4">
              <router-link 
                :to="{ name: 'Rubros' }" 
                class="btn btn-info botones">
                  <div class="fa fa-puzzle-piece fa-3x pb-2"></div>
                  <br> Rubros Emprendimientos
              </router-link>
            </b-col>

            <b-col md="3" class="p-4">
              <router-link  
                :to="{ name: 'Perfiles' }" 
                class="btn btn-info botones">
                  <div class="fa fa-thumb-tack fa-3x pb-2"></div>
                  <br>Perfiles Emprendimientos
              </router-link>
            </b-col>

            <b-col md="3" class="p-4">
              <router-link  
                :to="{ name: 'Carreras' }" 
                class="btn btn-info botones">
                  <div class="fa fa-graduation-cap fa-3x pb-2"></div>
                  <br>Carreras UNL
              </router-link>
            </b-col>

            <b-col md="3" class="p-4">
              <router-link 
                :to="{ name: 'TiposFinanciamiento' }" 
                class="btn btn-info botones">
                  <div class="fa fa-money fa-3x pb-2"></div>
                  <br> Tipos de Financiamiento
              </router-link>
            </b-col>

            <b-col md="3" class="p-4">
              <router-link 
                :to="{ name: 'FinCapital' }" 
                class="btn btn-info botones">
                  <div class="fa fa-recycle fa-3x pb-2"></div>
                  <br> Destinos Inversiones
              </router-link>
            </b-col>

            <b-col md="3" class="p-4">
              <router-link 
                :to="{ name: 'PerfilesSocio' }"
                class="btn btn-info botones">
                  <div class="fa fa-handshake-o fa-3x pb-2"></div>
                  <br>Perfiles de Socios
              </router-link>
            </b-col>

            <b-col md="3" class="p-4">
              <router-link 
                :to="{ name: 'AsesoriaEspecial' }" 
                class="btn btn-info botones">
                  <div class="fa fa-certificate fa-3x pb-2"></div>
                  <br> Asesoria Especifica
              </router-link>
            </b-col>

            <b-col md="3" class="p-4">
              <router-link 
                :to="{ name: 'ServicioInternazionalizacion' }"
                class="btn btn-info botones">
                  <div class="fa fa-globe fa-3x pb-2"></div>
                  <br> Servicios de Internazionalización
              </router-link>
            </b-col>

            <b-col md="3" class="p-4">
              <router-link 
                :to="{ name: 'EstadosMDNyPDN' }" 
                class="btn btn-info botones">
                  <div class="fa fa-check-circle fa-3x pb-2"></div>
                  <br> Estados MDN y PDN
              </router-link>
            </b-col>

            <b-col md="3" class="p-4">
              <router-link 
                :to="{ name: 'SolidezEquipo' }"
                class="btn btn-info botones">
                  <div class="fa fa-shield fa-3x pb-2"></div>
                  <br> Solidez Equipo <small>(perfiles existentes)</small>
              </router-link>
            </b-col>

            <b-col md="3" class="p-4">
              <router-link 
                :to="{ name: 'CapacitacionesInstitucionales' }"
                class="btn btn-info botones">
                  <div class="fa fa-bank fa-3x pb-2"></div>
                  <br> Capacitaciones Institucionales
              </router-link>
            </b-col>

            <b-col md="3" class="p-4">
              <router-link 
                :to="{ name: 'AsesoriasUNL' }"
                class="btn btn-info botones">
                  <div class="fa fa-certificate fa-3x pb-2"></div>
                  <br> Asesorias/Asistencias Técnica
              </router-link>
            </b-col>
            
            <b-col md="3" class="p-4">
              <router-link 
                :to="{ name: 'ConcursosYEventos' }"
                class="btn btn-info botones">
                  <div class="fa fa-trophy fa-3x pb-2"></div>
                  <br> Concursos y Eventos
              </router-link>
            </b-col>

            <b-col md="3" class="p-4">
              <router-link 
                :to="{ name: 'Mentorias' }"
                class="btn btn-info botones">
                  <div class="fa fa-certificate fa-3x pb-2"></div>
                  <br> Mentorias
              </router-link>
            </b-col>

            <b-col md="3" class="p-4">
              <router-link 
                :to="{ name: 'GabineteIncubadorasUNL' }"
                class="btn btn-info botones">
                  <div class="fa fa-cube fa-3x pb-2"></div>
                  <br> Gabinetes e Incubadoras UNL
              </router-link>
            </b-col>

          </b-row>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        roles: [],
        rol_gestion_usuarios: false,
      }
    },
    computed: {
      perPage:{
        get(){
          return this.$store.state.perPage
        },
        set(value){
          this.$store.commit('setPerPage', {perPage: value})
        }
      },

      currentPage:{
        get(){
          return this.$store.state.currentPage
        },
        set(value){
          this.$store.commit('setCurrentPage', {currentPage: value})
        }
      }
    },

    mounted() {
      this.$store.commit('setTitulo', {setTitulo: 'Configuración'});
      this.$store.commit('setSubTitulo', {setSubTitulo: ''});
    },

    methods: {
      getRoles(){
        try {
          let me = localStorage.getItem('roles');
          this.roles = JSON.parse(me);
        } catch (err) {
          console.log(err);
        }
      },

      rolGestionUsuario(){
        if(this.roles.find(element => element.rol == 'gestion_usuarios' )){
          this.rol_gestion_usuarios=true;
        }else{
          this.rol_gestion_usuarios=false;
        }
      },

      resetPagination(){
        this.perPage=50;
        this.currentPage=1;
      }
    },

    created(){
      this.$Progress.start(),
      window.scrollTo(0, -1); 
      this.getRoles();
      this.rolGestionUsuario();
      this.resetPagination();
      this.$Progress.finish()
    }
  }
</script>

<style scoped>

  .botones{
    width: 100%;
    height: 150px;
    padding: .5rem;
    padding-top: 2rem;
    vertical-align: middle;
    text-align: center;
    size: 12px;

    transition: transform .2s; /* Animation */
  }

  .botones:hover {
    transform: scale(1.15); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  .fa{
    width: 24px;
  }

@media screen and (min-width: 400px) {
  .botones{
    width: 80%;
    height: 120px;
    padding-top: 1.2rem;
    vertical-align: middle;
    text-align: center;
    size: 8px;
  }

  .fa{
    width: 38px;
  }
}

@media screen and (min-width: 800px) {
  .botones{
    width: 100%;
    height: 150px;
    padding: 0.4rem;
    padding-top: 1rem;
    vertical-align: middle;
    text-align: center;
  }

  .fa{
    width: 32px;
  }
}

@media screen and (min-width: 1200px) {
  .botones{
    width: 175px;
    height: 175px;
    padding: .5rem;
    padding-top: 2.5rem;
    vertical-align: middle;
    text-align: center;
  }

  .fa{
    width: 48px;
  }
}
</style>