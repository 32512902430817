<template>
	<div>
		<h4 class="pt-3">Programas Vinculados</h4>   
    <b-card v-for="p in this.programas_vinculados">
      <b-container>
        <b-row>
          <b-col md="6" xs="12" class="pt-2">
            <b-row sm>
              <b-col class="titulo" md="6" xs="12">
                <label>Programa:</label>
              </b-col>
              <b-col md="6" xs="12">
                {{ p.otro_programas_SVyTT }}
              </b-col>
            </b-row>
          </b-col>

          <b-col md="6" xs="12" class="pt-2">
            <b-row sm>
              <b-col class="titulo" md="6" xs="12">
                <label>Gabinete / Incubadora:</label>
              </b-col>
              <b-col md="6" xs="12">
                {{ p.otro_gabinete }}
              </b-col>
            </b-row>
          </b-col>

          <b-col md="6" xs="12" class="pt-2">
            <b-row sm>
              <b-col class="titulo" md="6" xs="12">
                <label>Fecha Vinculación:</label>
              </b-col>
              <b-col md="6" xs="12">
                {{ p.fecha.split("-").reverse().join("/") }}
              </b-col>
            </b-row>
          </b-col>

          <b-col md="12" xs="12" class="pt-2" v-show="p.observacion!=''">
            <b-row sm>
              <b-col class="titulo" md="3" xs="12">
                <label>Motivo:</label>
              </b-col>
              <b-col md="9" xs="12">
                {{ p.observacion }}
              </b-col>
            </b-row>
          </b-col>

        </b-row>
      </b-container>
    </b-card>
	</div>
</template>

<script>
  export default {
    props: ['programas_vinculados']
  };
</script>
<style>
@media (min-width: 768.98px) { 
  .titulo{
    text-align: right;
  }
}
</style>