<template>
  <div class="content"> 
    <b-container fluid class="pt-4">
      <b-card style="min-height: 40rem;">
        <div class="col-md-12">
          <!-- User Interface controls -->
          <b-row>
            <b-col lg="12" class="my-4">
              <b-form-group
                label-cols-sm="8"
                label-align-sm="right"
                label-size="md"
                label-for="filterInput"  
              >
                <b-form-input
                  v-model="filter"
                  type="search"
                  id="filterInput"
                  autocomplete="off"
                  size="md"
                  placeholder="Buscár..."
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <!-- Main table element -->
        <div class="col-md-12 pt-1">
          <b-table
            class="table-emprendedores-unl"
            hover
            outlined
            stacked="md"
            head-variant="dark"
            :items="emprendedores"
            :fields="fields"
            :current-page="currentPageEmprendedoresUNL"
            :per-page="perPageEmprendedoresUNL"
            :filter="filter"
            :filterIncludedFields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered"
          >
            <template v-slot:cell(emprendedor)="row">
              {{ row.item.apellido.toUpperCase() +', '+ row.item.nombre }}
            </template>

            <template v-slot:cell(actions)="row">
              <b-button size="sm" @click="show(row.item, row.index, $event.target)" class="btn btn-info btn-fill mr-1">
                <span class="fa fa-eye"></span>
              </b-button>
            </template>
          </b-table>
        </div>
        <b-row>
          <b-col sm="12" md="5">
            <b-col>
              <b-form-group
                label="Por página"
                label-cols-sm="4"
                label-cols-md="7"
                label-cols-lg="8"
                label-align-sm="right"
                label-size="sm"
                label-for="perPageSelect"
                class="mb-0"
              >
                <b-form-select
                  v-model="perPageEmprendedoresUNL"
                  id="perPageSelect"
                  size="sm"
                  :options="pageOptions"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-col>
          
          <b-col sm="12" md="5" class="my-1">
            <b-pagination
              v-model="currentPageEmprendedoresUNL"
              :total-rows="totalRows"
              :per-page="perPageEmprendedoresUNL"
              align="center"
              size="sm"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-card>
    </b-container>
  </div>
</template>

<script>
  import auth from "@/logic/auth";
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;
  
  export default {
    data() {
      return {
        isLoading:true,
        ruta: null,
        emprendedores: [],
        editindex: null,
        deleteIndex: null,
        fields: [
          { key: 'dni', label: 'DNI', sortDirection: 'desc', sortable: true, thStyle: {width:'15%'}},
          { key: 'apellido', label: 'Apellido', sortable: true, thStyle: {width:'20%'}},
          { key: 'nombre', label: 'Nombre', sortable: true, thStyle: {width:'20%'}},
          { key: 'correo_electronico', label: 'Correo Electrónico', sortable: true, thStyle: {width:'30%'}},
          { key: 'actions', label: 'Acciones'}
        ],
        totalRows: 99999,
        pageOptions: [10, 25, 50, 100, 200],
        sortBy: 'dni',
        sortDesc: true,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
      }
    },

    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },

      perPageEmprendedoresUNL:{
        get(){
          return this.$store.state.perPage
        },
        set(value){
          this.$store.commit('setPerPage', {perPage: value})
        }
      },

      currentPageEmprendedoresUNL:{
        get(){
          return this.$store.state.currentPage
        },
        set(value){
          this.$store.commit('setCurrentPage', {currentPage: value})
        }
      }
    },

    methods: {  
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },

      currentRoute(){
          this.$store.commit('setTitulo', {setTitulo: 'Emprendedores UNL'})
          this.$store.commit('setSubTitulo', {setSubTitulo: ''})
          window.scrollTo(0, -1); 
      },

      async getEmprendedores(){
        this.$Progress.start()
        await axios.get(URL_API+'api/emprendedores').then(response =>{
          this.emprendedores = response.data.emprendedores;
          this.totalRows = this.emprendedores.length;
          this.isLoading = false;
          this.$Progress.finish()
        }).catch(error => {
            this.$Progress.finish();
            alert(error.response.data.message);
            console.log(error);
          })
      },

      show(item, index, button){
        this.$router.push({ path: 'EmprendedoresUNL/ver/'+item.id})
      },
    },

    created(){
      this.getEmprendedores()
      this.currentRoute()
    }
  }
</script>

<style>

.btn-close {
  background-color: red;
}

.table-emprendedores-unl {
  table-layout: fixed;
}

.table-emprendedores-unl thead th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;

}

.table-emprendedores-unl thead tr th{
  padding-top: .5rem;
  padding-bottom: .5rem;
}

@media screen and (min-width: 400px) {
  .table-emprendedores-unl tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-emprendedores-unl tbody tr td:nth-child(5) {
    text-transform: capitalize;
    text-align: left;
  }
}

@media screen and (min-width: 800px) {
  .table-emprendedores-unl tbody tr td:nth-child(1) {
    text-align: center;
  }

  .table-emprendedores-unl thead tr th:nth-child(5) {
    justify-content: center!important;
  }

  .table-emprendedores-unl tbody tr td:nth-child(5) {
    justify-content: center;
  }
}

input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
</style>