<template>
  <div>
    <h4>Protección Tecnológica</h4>
    <div class="card">
      <div class="col-md-12 pt-4 pb-4">
        <div class="row">
          <div class="col-md-12">
            
	          <!-- User Interface controls -->
	          <b-row>
	            <b-col sm="4" class="my-3">
                <label for="pitch">La tecnología es protegible:</label>
                <b-form-select
                    v-model="ProteccionTecnologica" 
                    :options="options" 
                    autocomplete="off">
                  </b-form-select>
	            </b-col>

              <b-col sm="4" class="my-3">
                <label for="pitch">¿Esta protegida?:</label>
                <b-form-select
                    v-model="EstaProtegida" 
                    :options="options" 
                    :disabled="ProteccionTecnologica=='no'">
                  </b-form-select>
	            </b-col>

              <b-col sm="4" class="my-3">
                <label for="pitch">Estado protección:</label>
                <b-form-select
                    v-model="EstadoProteccion" 
                    :options="estados_proteccion" 
                    :disabled="ProteccionTecnologica=='no'||EstaProtegida=='no'">
                  </b-form-select>
	            </b-col>
	          </b-row>
          </div>

        </div>
      </div>
    </div>

</div>
</template>

<script>

  export default {
    name: 'ProteccionTecnologica',
    data() {
      return {

        options: [
          {'value': 'si', 'text': 'SI' },
          {'value': 'no', 'text': 'NO' }
        ],

        estados_proteccion:[
          {'value': 'en_proceso', 'text': 'En proceso' },
          {'value': 'otorgada', 'text': 'Otorgada' }
        ]
      }
    },
    computed: {
      ProteccionTecnologica:{
        get(){
          return this.$store.state.emprendimiento.proteccion_tecnologica
        },
        set(value){
          this.$store.commit('setProteccionTecnologica', {ProteccionTecnologica: value})
        }
      },

      EstaProtegida:{
        get(){
          return this.$store.state.emprendimiento.esta_protegida
        },
        set(value){
          this.$store.commit('setEstaProtegida', {EstaProtegida: value})
        }
      },

      EstadoProteccion:{
        get(){
          return this.$store.state.emprendimiento.estado_proteccion
        },
        set(value){
          this.$store.commit('setEstadoProteccion', {EstadoProteccion: value})
        }
      },


      
    },
  };
</script>
