<template>
  <div>
    <h4>Concursos <small> premios/menciones/reconocimientos/eventos</small></h4>
    <div class="card">
      <div class="col-md-12 pt-4 pb-4">
        <div class="row">
          <div class="col-md-12">
          <!-- User Interface controls -->
          <b-row>
            <b-col sm="12" class="my-3">
              <b-button 
                class="btn btn-info btn-fill" 
                @click="nuevo()"><i class="fa fa-plus"></i> 
                  Agregar
              </b-button>
            </b-col>
          </b-row>
          </div>
          <!-- Main table element -->
          <div class="col-md-12 pb-4">
            <b-table
              class="table-capacitaciones-emprendimiento"
              hover
              stacked="md"
              head-variant="dark"
              :items="premios_concursos_emprendimiento"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
            >
              <template v-slot:cell(actions)="row">
                <b-button size="sm" @click="editarEventoConcurso(row.item, row.index, $event.target)" class="btn btn-warning btn-fill mr-1">
                <span class="fa fa-pencil"></span>
                </b-button>

                <b-button class="btn-danger btn-fill" size="sm" @click="deleteCapacitacion(row.item, row.index, $event.target)">
                <span class="fa fa-trash"></span>
                </b-button>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

  <b-modal size="lg" id="createConcursoEvento" hide-footer>
    <div class="row"> 
      <div class="col-md-10">
        <label>Concurso ó Evento:</label>
        <b-form-select 
          v-model="id_concurso_evento" 
          :options="concursos_eventos">
        </b-form-select>
      </div>  
      <div class="col-md-2">
        <label>Año:</label>
          <b-form-select 
            v-model="anio"
            :options="anios">
          </b-form-select>
      </div>   

      <div class="col-md-12 pt-3">
        <label>Observción:</label>
          <b-form-textarea 
            v-model="observacion">
          </b-form-textarea>
      </div>  
    </div>

    <div class="pt-4">
        <b-button 
          class="mt-3 pull-left btn-danger btn-fill" 
          @click="resetConcursoEvento">Cancelar
        </b-button>
        
        <b-button 
          class="mt-3 pull-right btn-info btn-fill" 
          @click="addConcurso">Agregar
        </b-button>
    </div>
  </b-modal>

  <b-modal size="lg" title="Editar" id="editEventoConcurso" hide-footer>
    <div class="row"> 
      <div class="col-md-10 pt-3">
        <label>Concurso ó Evento:</label>
        <b-form-select 
          v-model="id_concurso_evento" 
          :options="concursos_eventos">
        </b-form-select>
      </div>  
      <div class="col-md-2 pt-3">
        <label>Año:</label>
          <b-form-select 
            v-model="anio"
            :options="anios">
          </b-form-select>
      </div>   

      <div class="col-md-12 pt-3">
        <label>Observción:</label>
          <b-form-textarea 
            v-model="observacion">
          </b-form-textarea>
      </div>  
    </div>
    <div class="pt-4">
        <b-button class="mt-3 pull-left btn-danger btn-fill" @click="resetConcursoEvento">Cacelar</b-button>
        <b-button class="mt-3 pull-right btn-info btn-fill" @click="updateConcursoEvento(editindex)">Actualizar</b-button>
    </div>
  </b-modal>
</div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    data () {
      return {
        first_concurso_evento: null,
        id_concurso_evento: null,
        concursos_eventos: [],

        premios_concursos_emprendimiento: [],

        observacion:'',
        observacion_edit:'',

        anio: null,
        anios: [],

        id_concurso_evento_edit:null,
        anio_edit: null,

        editindex: null,
        deleteIndex: null,
        fields: [
          { key: 'concurso_evento',      label: 'Concurso ó Evento',  sortable: true, thStyle: {width:'60%'}  },
          { key: 'anio', label: 'Año',           sortable: true, thStyle: {width:'20%'}  },
          { key: 'actions',           label: 'Acciones',      sortable: false},
        ],
        sortBy: 'anio',
        sortDesc: true,
        sortDirection: 'desc',
      }
    },

    methods:{
      async getConcursosEventos(){
        let r = await axios.get(URL_API+'api/eventos-concursos');

        var arr = [];
        var first = true;
        var aux;

        r.data.forEach(function(element, index){
          if(first==true){
            aux = element.id;
            first = false;
          }
          var ins ='';
          if(element.institucion=='unl'){
            ins = ' - UNL'
          }else{
            if(element.otra_institucion!=null){
              ins = ' - '+element.otra_institucion;
            }
          }
          arr.push({'value': element.id, 'text': element.nombre+ins });
        });
        
        this.first_concurso_evento = aux;
        this.id_concurso_evento = aux;
        this.concursos_eventos = arr;
      },

      getPremiosConcursosEmprendimientos(){
        this.premios_concursos_emprendimiento = this.$store.state.emprendimiento.premios_concursos_emprendimiento;
      },

      nuevo(){
        this.resetConcursoEvento();
        this.getConcursosEventos();
        this.$bvModal.show('createConcursoEvento');
      },

      addConcurso(){
        var id = this.id_concurso_evento;
        var concurso = this.concursos_eventos.find(x => x.value == id).text;

        var nuevo_evento = {
          'id':                   this.$store.state.emprendimiento.id_premios_concursos_emprendimiento,
          'id_concurso_evento':   this.id_concurso_evento,
          'concurso_evento':      concurso,
          'anio':                 this.anio,
          'observacion':          this.observacion,
        };

        this.$store.commit('addPremioMencionEmprendimiento', nuevo_evento);
        this.resetConcursoEvento();
      },

      editarEventoConcurso(item, index, event){
        this.editindex = item.id; 
        this.id_concurso_evento = item.id_concurso_evento;
        this.anio = item.anio;
        this.observacion = item.observacion;
        this.$bvModal.show('editEventoConcurso');
      },

      updateConcursoEvento(index){
        var id = this.id_concurso_evento;
        var concurso = this.concursos_eventos.find(x => x.value == id).text;
         
        for(var i=0; i<this.$store.state.emprendimiento.premios_concursos_emprendimiento.length; i++){
          if(this.$store.state.emprendimiento.premios_concursos_emprendimiento[i].id === index){

            this.$store.state.emprendimiento.premios_concursos_emprendimiento[i].id_concurso_evento = this.id_concurso_evento;
            this.$store.state.emprendimiento.premios_concursos_emprendimiento[i].concurso_evento = concurso;
            this.$store.state.emprendimiento.premios_concursos_emprendimiento[i].anio = this.anio;
            this.$store.state.emprendimiento.premios_concursos_emprendimiento[i].observacion = this.observacion;
          }
        }
        this.resetConcursoEvento();
      },

      deleteCapacitacion(item, index, event){
        this.deleteIndex = item.id; 
        if (confirm('¿Seguro que desea eliminarlo?')) {
          this.$store.commit('deletePremioMencionEmprendimiento', this.deleteIndex);
        }
        this.resetConcursoEvento();
      },

      resetConcursoEvento() {
        this.id_concurso_evento = this.first_concurso_evento;
        var today = new Date();
        this.anio = today.getFullYear();
        this.observacion = '';

        this.getPremiosConcursosEmprendimientos();
        this.getConcursosEventos();
        
        this.$root.$emit('bv::hide::modal','createConcursoEvento');
        this.$root.$emit('bv::hide::modal','editEventoConcurso');
      },

      getAnios(){
        var today = new Date();
        this.anio = today.getFullYear();

        var arr = [];
        for (var i = this.anio; i >= 2000; i--) {
          arr.push({'value': i, 'text': i });
        }
        this.anios = arr;
      },
    },

    created(){
      this.getConcursosEventos();
      this.getPremiosConcursosEmprendimientos();
      this.getAnios();
    }
  };
</script>


<style>
.table-capacitaciones-emprendimiento {
  table-layout: fixed;
}

.table-capacitaciones-emprendimiento thead th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}


.table-capacitaciones-emprendimiento thead tr th{
  padding-top: .3rem;
  padding-bottom: .3rem;
}

@media screen and (min-width: 400px) {
  .table-capacitaciones-emprendimiento tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-capacitaciones-emprendimiento tbody tr td:nth-child(3) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {
  .table-capacitaciones-emprendimiento tbody tr td:nth-child(2) {
    text-align: center;
  }

  .table-capacitaciones-emprendimiento tbody tr td:nth-child(2) {
    text-align: center;
  }

  .table-capacitaciones-emprendimiento thead tr th:nth-child(3) {
    justify-content: center;
  }

  .table-capacitaciones-emprendimiento tbody tr td:nth-child(3) {
    justify-content: center;
  }
}
</style>