<template>
  <div class="contact-us full-screen">
    <nav class="navbar navbar-ct-default" role="navigation-demo"  style="background-color: #357CA5;">
      <div class="container">
        <!-- Brand and toggle get grouped for better mobile display -->
        <div class="navbar-header">
          <h4 class="title" style="color: white;">Secretaría de Vinculación y Transferencia Tecnológica</h4>
        </div>

        <!-- Collect the nav links, forms, and other content for toggling -->
        <div class="collapse navbar-collapse" id="navigation-example-2">
          <ul class="nav navbar-nav navbar-right">
            <li>
              <router-link :to="{path:'/'}">Home</router-link>
            </li>
          </ul>
        </div>
        <!-- /.navbar-collapse -->
      </div>
      <!-- /.container-->
    </nav>
    <div class="wrapper wrapper-full-page section content" style="background-color: #D2D6DE;">
      <div class="">
        <div class="container">
          <div class="row">
            <div class="col-md-12 text-center">
              <h2 class="title text-danger">Bienvenidos</h2>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {}

</script>
