<template>
	<div>
		<h4 class="pt-3">Solidez del equipo Emprendedor</h4>   
    <b-card>
      <EstadoPerfilEquipoEmprendedor 
        :hidden="this.estado_perfil_equipo_emprendedor.length==0"
        :estado_perfil_equipo_emprendedor="this.estado_perfil_equipo_emprendedor"
      />
      
      <PerfilesExistentesEquipoEmprendedor 
        :hidden="this.perfiles_existentes_equipo_emprendedor.length==0"
        :perfiles_existentes_equipo_emprendedor="this.perfiles_existentes_equipo_emprendedor"
      />

      <EstadoRolesEquipoEmprendedor 
        :hidden="this.roles_miembros_equipo_emprendedor.length==0"
        :roles_miembros_equipo_emprendedor="this.roles_miembros_equipo_emprendedor"
      />
      
    </b-card>
	</div>


  
</template>

<script>
  
  import EstadoPerfilEquipoEmprendedor from 'src/components/Show/EstadoPerfilEquipoEmprendedor.vue'
  import PerfilesExistentesEquipoEmprendedor from 'src/components/Show/PerfilesExistentesEquipoEmprendedor.vue'
  import EstadoRolesEquipoEmprendedor from 'src/components/Show/EstadoRolesEquipoEmprendedor.vue'

  export default {
    components: {
      EstadoPerfilEquipoEmprendedor, 
      PerfilesExistentesEquipoEmprendedor,
      EstadoRolesEquipoEmprendedor
    },
    props: [  'estado_perfil_equipo_emprendedor',
              'perfiles_existentes_equipo_emprendedor',
              'roles_miembros_equipo_emprendedor'
            ]
  };
</script>
