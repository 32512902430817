<template>
  <div>
    <h4>Programas Vinculados</h4>
    <div class="card" v-for="p in programas_vinculados">
      <div class="col-md-12 pt-4 pb-4">
        <div class="row">

          <b-col cols="12">
            <b-row sm>
              <b-col class="titulo" md="4" xs="12">
                <label>Fecha de vinculación:</label>
              </b-col>
              <b-col md="8" xs="12">
                {{ p.fecha.toString().split("-").reverse().join("/") }}
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12" v-show="p.observacion!=''">
            <b-row sm>
              <b-col class="titulo" md="4" xs="12">
                <label>Motivo:</label>
              </b-col>
              <b-col md="8" xs="12">
                {{ p.observacion }}
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12">
            <hr>
          </b-col>

          <div class="col-md-6 col-sm-12 pt-3">
            <label>Programa SVyTT:</label>
            <b-form-select
              v-model="p.id_otro_programas_SVyTT"
              :options="programas_SVyTT"
              disabled>
            </b-form-select>
          </div>

          <div class="col-md-6 col-sm-12 pt-3">
            <label>Incubado en:</label>
            <b-form-select
              v-model="p.id_otro_gabinete" 
              :options="gabinetes_emprendedores" 
              :disabled="p.id_otro_programas_SVyTT!=id_programa_actual"
              autocomplete="off">
            </b-form-select>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    name: 'InfoEmprendimiento',
    data () {
      return {
        first_gabinete: null,
        id_gabinete: null,
        gabinetes_emprendedores: [],

        first_plataforma_principal: null,
        id_plataforma_principal: null,
        plataformas:[],

        first_id_programas_SVyTT: null,
        programas_SVyTT: [],
      }
    },
    computed: {
      id_programa_actual:{
        get(){
          return this.$store.state.id_programa
        }
      },

      programas_vinculados:{
        get(){
          return this.$store.state.emprendimiento.programas_vinculados
        }
      },

      id_programas_SVyTT:{
        get(){
          return this.$store.state.emprendimiento.id_programa_svytt
        }
      },

      incubadoEmprendimiento:{
        get(){
          return this.$store.state.emprendimiento.id_incubado_en
        },
        set(value){
          this.$store.commit('setIncubadoEnEmprendimiento', {incubadoEmprendimiento: value})
        }
      }
    },
    methods:{
      async getProgramasSVyTT(){
        let r = await axios.get(URL_API+'api/programas-svytt');

        var arr = [];
        var first = true;
        var aux;

        r.data.forEach(function(element, index){
          if(first==true){
            aux = element.id;
            first = false;
          }
          arr.push({'value': element.id, 'text': element.nombre });
        });

        this.first_id_programas_SVyTT = aux;
        this.programas_SVyTT = arr;
      },

      async getPlataformas(){
        let r = await axios.get(URL_API+'api/plataformas-emprendimientos');

        var arr = [];
        var first = true;
        var aux;

        r.data.forEach(function(element, index){
          if(first===true){
            aux = element.id;
            first = false;
          }
          arr.push({'value': element.id, 'text': element.nombre , 'shortText': element.nombre_corto});
        });

        this.first_plataforma_principal = aux;
        this.id_plataforma_principal = aux;
        this.plataformas = arr;
      },

      async getGabinetesIndubadorasUNL(){
        let r = await axios.get(URL_API+'api/gabinetes-incubadoras-unl');

        var arr = [];
        var first = true;
        var aux;

        r.data.forEach(function(element, index){
          if(first==true){
            aux = element.id;
            first = false;
          }
          arr.push({'value': element.id, 'text': element.nombre });
        });

        this.first_gabinete = aux;
        this.id_gabinete = aux;
        this.gabinetes_emprendedores = arr;

        if(this.incubadoEmprendimiento==null){
          this.incubadoEmprendimiento = aux;
        }
      }
    },
    created(){
      this.getProgramasSVyTT()
      this.getPlataformas();
      this.getGabinetesIndubadorasUNL();
    }
  };
</script>
