<template>
  <div>
    <h4>Estudios de Mercado Internacional</h4>
    <div class="card">
      <div class="col-md-12 pt-4 pb-4">
        <div class="row">
          <div class="col-md-12">
          <!-- User Interface controls -->
          <b-row>
            <b-col sm="12" class="my-3">
              <b-button 
                class="btn btn-info btn-fill" 
                @click="nuevo()"><i class="fa fa-plus"></i> 
                  Agregar
              </b-button>
            </b-col>
          </b-row>
          </div>
          <!-- Main table element -->
          <div class="col-md-12 pb-4">
            <b-table
              class="table-estudios-mercados-emprendimiento"
              hover
              stacked="md"
              head-variant="dark"
              :items="estudios_mercados_emprendimiento"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
            >
              <template v-slot:cell(actions)="row">
                <b-button 
                  size="sm" 
                  @click="editEstudioMercado(row.item, row.index, $event.target)" 
                  class="btn btn-warning btn-fill mr-1">
                    <span class="fa fa-pencil"></span>
                </b-button>

                <b-button 
                  class="btn-danger btn-fill" 
                  size="sm" 
                  @click="deleteEstudio(row.item, row.index, $event.target)">
                    <span class="fa fa-trash"></span>
                </b-button>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

  <b-modal size="lg" id="createEstudioMercado" hide-footer>
    <div class="row">
      <div class="col-md-8">
        <label>Estudio de Mercado Internacional:</label>
          <b-form-select 
            v-model="id_estado_estudio_mercado"
            :options="estados_estudio_mercado">
          </b-form-select>
      </div>
      <div class="col-md-4">
        <label>Año:</label>
          <b-form-select 
            v-model="anio"
            :options="anios">
          </b-form-select>
      </div>
    </div>

    <div class="pt-4">
        <b-button 
          class="mt-3 pull-left btn-danger btn-fill" 
          @click="cancel">Cancelar
        </b-button>
        
        <b-button
          :disabled="id_estado_estudio_mercado==''" 
          class="mt-3 pull-right btn-info btn-fill" 
          @click="nuevoEstudioMercado">Agregar
        </b-button>
    </div>
  </b-modal>

  <b-modal size="lg" id="editEstudioMercado" hide-footer>
    <div class="row">
      <div class="col-md-8 pt-3">
        <label>Estudio de Mercado Internacional:</label>
          <b-form-select 
            v-model="id_estado_estudio_mercado_edit"
            :options="estados_estudio_mercado">
          </b-form-select>
      </div>
      <div class="col-md-4 pt-3">
        <label>Año:</label>
          <b-form-select 
            v-model="anio_edit"
            :options="anios">
          </b-form-select>
      </div>
    </div>
    <div class="pt-4">
        <b-button 
          class="mt-3 pull-left btn-danger btn-fill" 
          @click="cancel">
            Cacelar
        </b-button>
        
        <b-button 
          :disabled="estado_estudio_mercado_edit==''" 
          class="mt-3 pull-right btn-info btn-fill" 
          @click="updateEstudioMercado(editindex)">
            Actualizar
        </b-button>
    </div>
  </b-modal>
</div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    data () {
      return {
        first_estado_estudio_mercado :null,
        id_estado_estudio_mercado: null,
        estados_estudio_mercado: [],
        
        estado_estudio_mercado: 'En curso',
        anio: null,

        id_estado_estudio_mercado_edit:null,
        estado_estudio_mercado_edit:'',
        anio_edit: null,

        anios: [],

        estudios_mercados_emprendimiento: [],

        editindex: null,
        deleteIndex: null,
        fields: [
          { key: 'estado_estudio_mercado',  label: 'Estudio del Mercado', sortable: true, thStyle: {width:'60%'} },
          { key: 'anio',                    label: 'Año',                 sortable: true, thStyle: {width:'20%'} },
          { key: 'actions',                 label: 'Acciones',            sortable: false},
        ],
        sortBy: 'anio',
        sortDesc: true,
        sortDirection: 'desc',
      }
    },

    methods:{
      async getEstadosEstudioMercado(){
        let r = await axios.get(URL_API+'api/estados-mercados-internacionales');

        var arr = [];
        var first = true;
        var aux;

        r.data.forEach(function(element, index){
          if(first==true){
            aux = element.id;
            first = false;
          }
          arr.push({'value': element.id, 'text': element.nombre });
        });
        
        this.first_estado_estudio_mercado = aux;
        this.id_estado_estudio_mercado = aux;
        this.estados_estudio_mercado = arr;
      },

      getEstudiosMercadosEmprendimientos(){
        this.estudios_mercados_emprendimiento = this.$store.state.emprendimiento.estudios_mercados_emprendimiento;
      },

      nuevo(){
        this.getEstadosEstudioMercado();
        this.resetEstudioMercado();
        this.$bvModal.show('createEstudioMercado')
      },

      nuevoEstudioMercado(){
        var id = this.id_estado_estudio_mercado;
        var estado_estudio_mercado = this.estados_estudio_mercado.find(x => x.value == id).text;

        var nuevo_estudio = {
          'id':               this.$store.state.emprendimiento.id_estudios_mercados_emprendimiento,
          'id_estado_estudio_mercado': this.id_estado_estudio_mercado,
          'estado_estudio_mercado' : estado_estudio_mercado,
          'anio': this.anio,
        };

        this.$store.commit('addEstudiosMercadosEmprendimiento', nuevo_estudio);
        this.cancel();
      },

      editEstudioMercado(item, index, event){
        this.editindex = item.id; 

        this.estado_estudio_mercado_edit = item.estado_estudio_mercado,
        this.id_estado_estudio_mercado_edit = item.id_estado_estudio_mercado,
        this.anio_edit = item.anio,
        this.getEstadosEstudioMercado();
        this.$root.$emit('bv::show::modal','editEstudioMercado');
      },

      updateEstudioMercado(index){
        
        var id = this.id_estado_estudio_mercado_edit;
        var estado_estudio_mercado_edit = this.estados_estudio_mercado.find(x => x.value == id).text;
        
        for(var i=0; i<this.$store.state.emprendimiento.estudios_mercados_emprendimiento.length; i++){
          if(this.$store.state.emprendimiento.estudios_mercados_emprendimiento[i].id === index){
            this.$store.state.emprendimiento.estudios_mercados_emprendimiento[i].id_estado_estudio_mercado = this.id_estado_estudio_mercado_edit;
            this.$store.state.emprendimiento.estudios_mercados_emprendimiento[i].estado_estudio_mercado = estado_estudio_mercado_edit;
            this.$store.state.emprendimiento.estudios_mercados_emprendimiento[i].anio = this.anio_edit;
          }
        }
        this.resetEstudioMercado();
      },

      deleteEstudio(item, index, event){
        this.deleteIndex = item.id; 
        if (confirm('¿Seguro que desea eliminarlo?')) {
          this.$store.commit('deleteEstudiosMercadosEmprendimiento', this.deleteIndex);
        }
        this.resetEstudioMercado();
      },

      cancel(){
        this.resetEstudioMercado();
        this.$bvModal.hide('createEstudioMercado')
        this.$bvModal.hide('editEstudioMercado')
      },

      resetEstudioMercado() {
        var today = new Date();
        this.anio = today.getFullYear();
        this.anio_edit = today.getFullYear();
        this.id_estado_estudio_mercado = this.first_estado_estudio_mercado;
        this.id_estado_estudio_mercado_edit = this.first_estado_estudio_mercado;

        this.getEstudiosMercadosEmprendimientos();
      },

      getAnios(){
        var today = new Date();
        this.anio = today.getFullYear();

        var arr = [];
        for (var i = this.anio; i >= 2000; i--) {
          arr.push({'value': i, 'text': i });
        }
        this.anios = arr;
      },
    },

    created(){
      this.getEstadosEstudioMercado();
      this.getEstudiosMercadosEmprendimientos();
      this.getAnios();
    }
  };
</script>


<style>
.table-estudios-mercados-emprendimiento {
  table-layout: fixed;
}

.table-estudios-mercados-emprendimiento thead th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}


.table-estudios-mercados-emprendimiento thead tr th{
  padding-top: .3rem;
  padding-bottom: .3rem;
}

@media screen and (min-width: 400px) {
  .table-estudios-mercados-emprendimiento tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-estudios-mercados-emprendimiento tbody tr td:nth-child(3) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {
  .table-estudios-mercados-emprendimiento tbody tr td:nth-child(1) {
    text-align: center;
  }

  .table-estudios-mercados-emprendimiento tbody tr td:nth-child(1) {
    text-align: center;
  }

  .table-estudios-mercados-emprendimiento tbody tr td:nth-child(2) {
    text-align: center;
  }

  .table-estudios-mercados-emprendimiento tbody tr td:nth-child(2) {
    text-align: center;
  }

  .table-estudios-mercados-emprendimiento thead tr th:nth-child(3) {
    justify-content: center;
  }

  .table-estudios-mercados-emprendimiento tbody tr td:nth-child(3) {
    justify-content: center;
  }
}
</style>