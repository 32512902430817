<template>
	<div>
		<h4 class="pt-3">Necesidad de Capital</h4>   
    <b-card>
      <b-table
          class="table-necesidad-capital-emprendimiento"
          hover
          stacked="md"
          head-variant="dark"
          :items="this.necesidad_capital_emprendimiento"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
        >
          <template v-slot:cell(fecha)="row">
            {{ row.item.fecha.toString().split("-").reverse().join("/") }}
          </template>
          <template v-slot:cell(actions)="row">
            <b-button 
              size="sm" 
              @click="viewNecesidadCapital(row.item, row.index, $event.target)" 
              class="btn btn-info btn-fill mr-1">
                <span class="fa fa-eye"></span>
            </b-button>
          </template>
        </b-table>
    </b-card>

    <b-modal id="viewModalNecesidadCapital"  size="lg" title="Necesidad de Capital" hide-footer>
      <b-col cols="12" class="pt-1">
        <b-row>
          <b-col class="titulo" md="3" xs="12">
            <b>Tipo:</b>
          </b-col>
          <b-col md="9" xs="12">
            {{ this.necesidad_capital.tipo_financiamiento}}
          </b-col>
        </b-row>

        <b-row>
          <b-col class="titulo" md="3" xs="12">
            <b>Destino:</b>
          </b-col>
          <b-col md="9" xs="12">
            {{ this.necesidad_capital.destino_capital }}
          </b-col>
        </b-row>

        <b-row>
          <b-col class="titulo" md="3" xs="12">
            <b>Fecha:</b>
          </b-col>
          <b-col md="9" xs="12">
            {{ this.necesidad_capital.fecha}}
          </b-col>
        </b-row>

        <b-row>
          <b-col class="titulo" md="3" xs="12">
            <b>Monto:</b>
          </b-col>
          <b-col md="9" xs="12">
            {{ 'USD '+this.necesidad_capital.monto}}
          </b-col>
        </b-row>

        <b-row class="pt-4">
          <b-button class="mt-3 pull-left btn-danger btn-fill" @click="closeModal">Cerrar</b-button>
        </b-row>
      </b-col>
    </b-modal>
	</div>
</template>

<script>
  export default {
    props: ['necesidad_capital_emprendimiento'],
    data() {
      return {
        necesidad_capital:{
          tipo_financiamiento:null,
          destino_capital:null,
          monto: null,
          destino_capital: null,
          fecha:null
        },
        viewindex: null,
        fields: [
          { key: 'tipo_financiamiento', label: 'Tipo de Financiamiento',  sortable: true, thStyle: {width:'75%'} },
          { key: 'fecha',               label: 'Fecha',                   sortable: true},
          { key: 'actions',             label: 'Vér' }
        ],
        sortBy: 'anio',
        sortDesc: true,
        sortDirection: 'desc',
      }
    },
    methods: {
      viewNecesidadCapital(item, index, button) {
        this.necesidad_capital.tipo_financiamiento= item.tipo_financiamiento;
        this.necesidad_capital.destino_capital = item.destino_capital;
        this.necesidad_capital.monto = item.monto;
        this.necesidad_capital.fecha= item.fecha.toString().split("-").reverse().join("/");

        this.$root.$emit('bv::show::modal', 'viewModalNecesidadCapital', button);
      },

      closeModal(item, index, button){
        this.$root.$emit('bv::hide::modal', 'viewModalNecesidadCapital', button)
      }
    }
  };
</script>
<style>
.table-necesidad-capital-emprendimiento thead tr th{
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.table-necesidad-capital-emprendimiento thead tr th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 400px) {
  .table-necesidad-capital-emprendimiento tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-necesidad-capital-emprendimiento tbody tr td:nth-child(2) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {

  .table-necesidad-capital-emprendimiento thead tr th:nth-child(2) {
    justify-content: center;
  }

  .table-necesidad-capital-emprendimiento tbody tr td:nth-child(2) {
    text-align: center;
  }

  .table-necesidad-capital-emprendimiento thead tr th:nth-child(3) {
    justify-content: center;
  }

  .table-necesidad-capital-emprendimiento tbody tr td:nth-child(3) {
    justify-content: center;
  }

}
</style>