<template>
	<div :hidden="this.web==null &&  
                this.linkedin==null &&  
                this.facebook==null &&  
                this.instagram==null">

		<h4>Redes Emprendimiento:</h4>
    <b-card>
      <b-container>
        <b-row>
          <b-col cols="12" class="pt-2" :hidden="this.web==null">
            <b-row>
              <b-col class="titulo" md="3" xs="12">
                <label>Página Web:</label>
              </b-col>
              <b-col md="9" xs="12">
                <a :href="this.web" target="_blank">{{ this.web }}</a>
              </b-col>
            </b-row>
          </b-col>     

          <b-col cols="12" class="pt-2" :hidden="this.linkedin==null">
            <b-row>
              <b-col class="titulo" md="3" xs="12">
                <label>Linkedin:</label>
              </b-col>
              <b-col md="9" xs="12">
                <a :href="this.linkedin" target="_blank">{{ this.linkedin }}</a>
              </b-col>
            </b-row>
          </b-col>     

          <b-col cols="12" class="pt-2" :hidden="this.facebook==null">
            <b-row>
              <b-col class="titulo" md="3" xs="12">
                <label>Facebook:</label>
              </b-col>
              <b-col md="9" xs="12">
                <a :href="this.facebook" target="_blank">{{ this.facebook }}</a>
              </b-col>
            </b-row>
          </b-col>     

          <b-col cols="12" class="pt-2" :hidden="this.instagram==null">
            <b-row>
              <b-col class="titulo" md="3" xs="12">
                <label>Instagram:</label>
              </b-col>
              <b-col md="9" xs="12">
                <a :href="this.instagram" target="_blank">{{ this.instagram }}</a>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
	</div>
</template>

<script>
  export default {
    props: ['web', 'linkedin', 'facebook', 'instagram'],
  };
</script>
<style>
@media (min-width: 768.98px) { 
  .titulo{
    text-align: right;
  }
}
</style>