<template>
	<div>
		<h4 class="pt-3">Estadios del Emprendimiento</h4>   
    <b-card>
      <b-table
          class="tab-estadios"
          hover
          stacked="md"
          head-variant="dark"
          :items="this.integrantes"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
        >
          <template v-slot:cell(fecha)="row">
            {{ row.item.fecha.toString().split("-").reverse().join("/") }}
          </template>
        </b-table>
    </b-card>
	</div>
</template>

<script>
  export default {
    props: ['integrantes'],
    data() {
      return {
        viewindex: null,
        fields: [
          { key: 'plataforma',  label: 'Plataforma',  sortable: true},
          { key: 'estadio',     label: 'Estadio',     sortable: true},
          { key: 'situacion',   label: 'Situacion',   sortable: true},
          { key: 'fecha',       label: 'Fecha',       sortable: true},
        ],
        sortBy: 'fecha',
        sortDesc: true,
        sortDirection: 'desc',
        viewModal: {
          id: 'viewModal',
          title: '',
          content: ''
        }
      }
    }
  };
</script>
<style>
.tab-estadios thead tr th{
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.tab-estadios thead tr th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  text-align: center;
}

@media screen and (min-width: 400px) {
  .tab-estadios tbody tr td:nth-child(1) {
    text-align: left;
  }
  .tab-estadios tbody tr td:nth-child(3) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {

  .tab-estadios tbody tr td:nth-child(1) {
    text-align: center;
  }

  .tab-estadios tbody tr td:nth-child(2) {
    text-align: center;
  }

  .tab-estadios tbody tr td:nth-child(3) {
    text-align: center;
  }

  .tab-estadios thead tr th:nth-child(4) {
    justify-content: center;
  }

  .tab-estadios tbody tr td:nth-child(4) {
    justify-content: center;
  }

}
</style>