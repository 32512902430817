<template>
  <div class="wrapper">
    <side-bar>
      <sidebar-link  
        @click.native="resetPagination" 
        :to="{ name: 'EmprendimientosUNL' }">
          <i class="fa fa-bars"></i>
          Emprendimientos UNL
      </sidebar-link>
      
      <sidebar-link
        :to="{ name: 'IndicadoresUNL' }" >
          <i class="fa fa-area-chart"></i>
          Indicadores UNL
      </sidebar-link>

      <sidebar-link  
        @click.native="resetPagination" 
        :to="{ name: 'EmprendedoresUNL' }">
          <i class="fa fa-users"></i>
          Emprendedores UNL
      </sidebar-link>

      <template v-if="rol_unlbio">
        <div class="col-sm-12">
          <hr style="background-color: white;">
        </div>

        <sidebar-link
          @click.native="resetPagination" 
          :to="{ name: 'ProgramaBIO' }">
            <i class="fa fa-leaf"></i>
            Programa BIO
        </sidebar-link>
      </template>


      <template v-if="rol_emprendedores">
        <div class="col-sm-12">
          <hr style="background-color: white;">
        </div>

        <sidebar-link 
          @click.native="resetPagination" 
          :to="{ name: 'EmprendimientosPE' }">
            <i class="fa fa-lightbulb"></i>
            Emprendedores
        </sidebar-link>
      </template>

      <template v-if="rol_gide">
        <div class="col-sm-12">
          <hr style="background-color: white;">
        </div>

        <sidebar-link 
          @click.native="resetPagination" 
          :to="{ name: 'EmprendimientosGIDE' }">
            <i class="fa fa-industry"></i>
            Programa GIDE
        </sidebar-link>
      </template>

      <template v-if="rol_unlpotencia">
        <div class="col-sm-12">
          <hr style="background-color: white;">
        </div>

        <sidebar-link 
          @click.native="resetPagination" 
          :to="{ name: 'EmprendimientosUNLPotencia' }">
            <i class="fa fa-rocket"></i>
            UNLPotencia
        </sidebar-link>
      </template>

      <template v-if="rol_configuraciones">
        <div class="col-sm-12">
          <hr style="background-color: white;">
        </div>

        <sidebar-link 
          :to="{ name: 'Configuraciones' }">
            <i class="fa fa-cog"></i>
            Configuración
        </sidebar-link>
      </template>

      <template v-if="rol_gestion_usuarios">
        <div class="col-sm-12">
          <hr style="background-color: white;">
        </div>


        <sidebar-link 
          :to="{ name: 'Usuarios' }">
            <i class="fa fa-user"></i>
            Usuarios
        </sidebar-link>
      </template>
      <mobile-menu slot="content"></mobile-menu>

    </side-bar>
    <div class="main-panel" style="overflow: auto;">
      <top-navbar></top-navbar>

      <dashboard-content @click="toggleSidebar">

      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script>
  import TopNavbar from './TopNavbar.vue'
  import ContentFooter from './ContentFooter.vue'
  import DashboardContent from './Content.vue'
  import MobileMenu from './MobileMenu.vue'
  import auth from "@/logic/auth";

  export default {
    components: {
      TopNavbar,
      ContentFooter,
      DashboardContent,
      MobileMenu
    },
    data() {
      return {
        roles: [],
        rol_usuario: false,
        rol_emprendedores: false,
        rol_gide: false,
        rol_unlpotencia: false,
        rol_unlbio: false,
        rol_configuraciones: false,
        rol_gestion_usuarios:false
      }
    },
    methods: {
      getRoles(){
        try {
          //let me = await auth.me();
          let me = localStorage.getItem('roles');
          this.roles = JSON.parse(me);
        } catch (err) {
          console.log(err);
        }
      },

      rolUsuario(){
        if(this.roles.find(element => element.rol == 'usuario' )){
          this.rol_usuario=true;
        }else{
          this.rol_usuario=false;
        }
      },

      rolEmprendedores(){
        if(this.roles.find(element => element.rol == 'programa_emprendedores' )){
          this.rol_emprendedores=true;
        }else{
          this.rol_emprendedores=false;
        }
      },

      rolUNLBio(){
        if(this.roles.find(element => element.rol == 'programa_unlbio' )){
          this.rol_unlbio=true;
        }else{
          this.rol_unlbio=false;
        }
      },

      rolGIDE(){
        if(this.roles.find(element => element.rol == 'programa_gide' )){
          this.rol_gide=true;
        }else{
          this.rol_gide=false;
        }
      },

      rolUNLPotencia(){
        if(this.roles.find(element => element.rol == 'programa_unlpotencia' )){
          this.rol_unlpotencia=true;
        }else{
          this.rol_unlpotencia=false;
        }
      },

      rolConfiguraciones(){
        if(this.roles.find(element => element.rol == 'configuraciones' )){
          this.rol_configuraciones=true;
        }else{
          this.rol_configuraciones=false;
        }
      },
      
      rolGestionUsuarios(){
        if(this.roles.find(element => element.rol == 'gestion_usuarios' )){
          this.rol_gestion_usuarios=true;
        }else{
          this.rol_gestion_usuarios=false;
        }
      },
      

      toggleSidebar () {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      },

      resetPagination(){
        this.$store.state.perPage=50;
        this.$store.state.currentPage = 1;
      }
    }, 
    mounted() {
      this.getRoles();
      this.rolUsuario();
      this.rolUNLBio();
      this.rolEmprendedores();
      this.rolGIDE();
      this.rolUNLPotencia();
      this.rolConfiguraciones();
      this.rolGestionUsuarios();
    },
  }
</script>
<style lang="scss">

</style>