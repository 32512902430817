<template>
  <div>
    <b-card :title="this.titulo" :sub-title="this.subtitulo" class="p-2">
      <b-row>
        <b-col cols="12">
        <hr>
        </b-col>
      </b-row>
      <b-container>
        <b-row>
          <b-col cols="12" class="pt-4">
            <b-row>
              <b-col class="titulo pt-3" md="12" xs="12">
                <line-chart 
                  :data="this.data_chart"
                  :colors="[['#009CB6'],[ '#0000ff','#FF5733','#797979','#00FF00',
                              '#00FFFF','#000000','#FF00FF','#0000ff',
                              '#FF5733','#797979','#00FFFF','#00FF00',
                              '#000000','#FF00FF','#0000ff','#FF5733',
                              '#797979','#00FFFF','#00FF00','#000000',
                              '#FF00FF','#0000ff','#FF5733','#797979',
                              '#00FFFF','#00FF00','#000000','#FF00FF']]">
                  :download="true"
                  :stacked="true">
                </line-chart>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
	</div>
</template>

<script>
  export default {
    props: ['titulo','subtitulo', 'data_chart']
  };
</script>