<template>
	<div>
		<h4>Protección Tecnológica</h4>
    <b-card>
      <b-container>
        <b-row>
          <b-col cols="12" class="pt-2">
            <b-row>
              <b-col class="titulo" md="3" xs="12">
                <label>¿Es protegible?:</label>
              </b-col>
              <b-col md="9" xs="12">
                {{ this.proteccion_tecnologica.toUpperCase() }}
              </b-col>
            </b-row>
          </b-col>

          <template v-if="this.proteccion_tecnologica!='no'">
            <b-col cols="12" class="pt-2">
              <b-row>
                <b-col class="titulo" md="3" xs="12">
                  <label>¿Esta protegida?:</label>
                </b-col>
                <b-col md="9" xs="12">
                  {{ this.esta_protegida.toUpperCase() }}
                </b-col>
              </b-row>
            </b-col>
          </template>

          <template v-if="this.esta_protegida!='no' && this.proteccion_tecnologica!='no'">
            <b-col cols="12" class="pt-2">
              <b-row>
                <b-col class="titulo" md="3" xs="12">
                  <label>Estado protección:</label>
                </b-col>
                <b-col md="9" xs="12">
                  {{ this.estado_proteccion.toUpperCase().replace('_',' ') }}
                </b-col>
              </b-row>
            </b-col>
          </template>

        </b-row>
      </b-container>
    </b-card>
	</div>
</template>

<script>
  export default {
    props: ['proteccion_tecnologica', 'esta_protegida','estado_proteccion'],
  };
</script>