<template>
  <div>
    <h4>Datos Relacionados al Emprendimiento</h4>
    <div class="card">
      <div class="col-md-12 pt-4 pb-4">
        <div class="row">
          <div class="col-md-12 pt-3">
            <label>Nombre del Emprendimiento:<small class="text-danger">(requerido)</small></label>
            <b-form-input 
              v-model="nombreEmprendimiento" 
              name="nombreEmprendimiento"  
              autocomplete="off">
            </b-form-input>
          </div>

          <div class="col-md-12 pt-3">
            <label>Breve descripción <small>(maxímo 2000 caracteres)</small>:</label>
            <b-form-textarea 
              v-model="descripcionEmprendimiento" 
              name="descripcionEmprendimiento" 
              rows="7"
              :formatter="maxLength">
            </b-form-textarea>
          </div>

          <div class="col-md-6 pt-3">
            <label>Razón social:</label>
            <b-form-input 
              v-model="razonsocialEmprendimiento"   
              name="razonsocialEmprendimiento" 
              autocomplete="off">
            </b-form-input>
          </div>

          <div class="col-md-6 pt-3">
            <label>Palabras claves:</label>
            <b-form-tags 
              name="palabrasclavesEmprendimiento" 
              v-model="palabrasclavesEmprendimiento"
              placeholder="">
            </b-form-tags>
          </div>

          <div class="col-md-6 pt-3">
            <label>Perfil:<small class="text-danger">(requerido)</small></label>
            <b-form-select 
              v-model="perfilEmprendimiento" 
              name="perfilEmprendimiento" 
              :options="perfiles">
            </b-form-select>
          </div>

          <div class="col-md-6 pt-3">
            <label>Año de inicio:<small class="text-danger">(requerido)</small></label>
            <b-form-select 
              v-model="anioinicioEmprendimiento"
              name="anioinicioEmprendimiento" 
              :options="anios">
            </b-form-select>
          </div>

          <div class="col-md-6 pt-3">
            <label>Rubro Primario:<small class="text-danger">(requerido)</small></label>
            <b-form-select 
              v-model="rubroprincipalEmprendimiento" 
              name="rubroprincipalEmprendimiento" 
              :options="rubros_primarios">
            </b-form-select>
          </div>

          <div class="col-md-6 pt-3">
            <label>Rubro Secundario:</label>
            <b-form-select 
              v-model="rubrosecundarioEmprendimiento" 
              name="rubrosecundarioEmprendimiento" 
              :options="rubros_secundarios">
            </b-form-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    name: 'DatosRelacionadosEmprendimiento',

    data () {
      return {
        rubros_primarios: [],
        rubros_secundarios: [],
        perfiles:[],
        anios:[]
      }
    },

    computed:{
      nombreEmprendimiento:{
        get(){
          return this.$store.state.emprendimiento.nombre
        },
        set(value){
          this.$store.commit('setNombreEmprendimiento', {nombreEmprendimiento: value});
        }
      },

      descripcionEmprendimiento:{
        get(){
          return this.$store.state.emprendimiento.descripcion
        },
        set(value){
          this.$store.commit('setDescripcionEmprendimiento', {descripcionEmprendimiento: value})
        }
      },

      razonsocialEmprendimiento:{
        get(){
          return this.$store.state.emprendimiento.razon_social
        },
        set(value){
          this.$store.commit('setRazonSocialEmprendimiento', {razonsocialEmprendimiento: value})
        }
      },

      palabrasclavesEmprendimiento:{
        get(){
          return this.$store.state.emprendimiento.palabras_claves
        },
        set(value){
          this.$store.commit('setPalabrasClavesEmprendimiento', {palabrasclavesEmprendimiento: value})
        }
      },

      anioinicioEmprendimiento:{
        get(){
          return this.$store.state.emprendimiento.anio_inicio
        },
        set(value){
          this.$store.commit('setAnioInicioEmprendimiento', {anioinicioEmprendimiento: value})
        }
      },

      perfilEmprendimiento:{
        get(){
          return this.$store.state.emprendimiento.id_perfil
        },
        set(value){
          this.$store.commit('setPerfilEmprendimiento', {perfilEmprendimiento: value})
        }
      },

      rubroprincipalEmprendimiento:{
        get(){
          return this.$store.state.emprendimiento.id_rubro_principal
        },
        set(value){
          this.$store.commit('setRubroPrincipalEmprendimiento', {rubroprincipalEmprendimiento: value})
        }
      },

      rubrosecundarioEmprendimiento:{
        get(){
          return this.$store.state.emprendimiento.id_rubro_secundario
        },
        set(value){
          this.$store.commit('setRubroSecundarioEmprendimiento', {rubrosecundarioEmprendimiento: value})
        }
      },

    },
    methods:{
      async getPerfiles(){
        let r = await axios.get(URL_API+'api/perfiles');
        var arr = [];
        arr.push({'value': null, 'text': 'Seleccione un perfil', 'disabled': true });
        r.data.forEach(function(element, index, rubros){
          arr.push({'value': element.id, 'text': element.nombre });
        });
        this.perfiles = arr;
      },
      
      async getRubros(){
        let r = await axios.get(URL_API+'api/rubros');
        var arr_1 = [];
        var arr_2 = [];
        arr_1.push({'value': null, 'text': 'Seleccione un rubro', 'disabled': true });
        arr_2.push({'value': null, 'text': 'Seleccione un rubro' });
        r.data.forEach(function(element, index, rubros){
          arr_1.push({'value': element.id, 'text': element.nombre });
          arr_2.push({'value': element.id, 'text': element.nombre });
        });
        this.rubros_primarios = arr_1;
        this.rubros_secundarios = arr_2;
      },

      getAnios(){
        var today = new Date();
        this.anio = today.getFullYear();

        var arr = [];
        
        arr.push({'value': null, 'text': 'Seleccione un año', 'disabled': true });
        for (var i = this.anio; i >= 2000; i--) {
          arr.push({'value': i, 'text': i });
        }
        this.anios = arr;
      },

      maxLength(e){
        return String(e).substring(0,2000);
      }
    },
    created(){
      this.getRubros();
      this.getPerfiles();
      this.getAnios();
    },
  };
</script>
