<template>
  <div>
    <h4>Necesidades de Asesoría</h4>
    <div class="card">
      <div class="col-md-12 pt-4 pb-4">
        <div class="row">
          <div class="col-md-12">
            
          <!-- User Interface controls -->
          <b-row>
            <b-col sm="12" class="my-3">
              <b-button 
                class="btn btn-info btn-fill" 
                @click="nuevo()">
                <i class="fa fa-plus"></i> 
                  Agregar
              </b-button>
            </b-col>
          </b-row>
          </div>
          <!-- Main table element -->
          <div class="col-md-12 pb-4">
            <b-table
              class="table-necesidad-asesoria"
              hover
              stacked="md"
              head-variant="dark"
              :items="necesidades_asesoria"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              > 
              <template v-slot:cell(fecha)="row">
                    {{ row.item.fecha.toString().split("-").reverse().join("/") }}
              </template>

              <template v-slot:cell(actions)="row">
                <b-button size="sm" @click="editarNecesidadAsesoria(row.item, row.index, $event.target)" class="btn btn-warning btn-fill mr-1">
                <span class="fa fa-pencil"></span>
                </b-button>

                <b-button class="btn-danger btn-fill" size="sm" @click="deleteNecesidadAsesoria(row.item, row.index, $event.target)">
                <span class="fa fa-trash"></span>
                </b-button>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <b-modal size="lg" id="createNecesidadAsesoria" hide-footer>
      <div class="row">
        <div class="col-md-8">
          <label>Tipo de asesoría:</label>
          <b-form-select 
            v-model="id_asesoria" 
            :options="asesorias">
          </b-form-select>
        </div>

        <div class="col-md-4">
          <label>Fecha:</label>
          <b-form-input     
              v-model="fecha" 
              type="date"
              placeholder="dd/mm/aaaa"
              autocomplete="off">
          </b-form-input>
        </div>      
      </div>

      <div class="pt-4">
          <b-button 
            class="mt-3 pull-left btn-danger btn-fill" 
            @click="resetNecesidadAsesoria()">
            Cacelar
          </b-button>
          <b-button     
            :disabled="fecha===''"
            class="mt-3 pull-right btn-info btn-fill" 
            @click="nuevaNecesidadAsesoria()">
            Agregar
          </b-button>
      </div>
    </b-modal>

    <b-modal size="lg" id="editNecesidadAsesoria" hide-footer>
      <div class="row">
        <div class="col-md-8">
          <label>Tipo de asesoría:</label>
          <b-form-select 
            v-model="id_asesoria" 
            :options="asesorias">
          </b-form-select>
        </div>

        <div class="col-md-4">
          <label>Fecha:</label>
          <b-form-input     
              v-model="fecha" 
              type="date"
              placeholder="dd/mm/aaaa"
              autocomplete="off">
          </b-form-input>
        </div>      
      </div>

      <div class="pt-4">
          <b-button 
            class="mt-3 pull-left btn-danger btn-fill" 
            @click="resetNecesidadAsesoria()">
            Cacelar
          </b-button>
          <b-button     
            :disabled="fecha===''"
            class="mt-3 pull-right btn-info btn-fill" 
            @click="updateNecesidadAsesoria(editindex)">
            Actualizar
          </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    data () {
      return {
        first_asesoria: null,
        id_asesoria: null,
        asesorias: [],
        
        fecha:'',

        necesidades_asesoria:[],

        editindex: null,
        deleteIndex: null,
        fields: [
          { key: 'asesoria',                  label: 'Tipo de Asesoría',  sortable: true, thStyle: {width:'60%'} },
          { key: 'fecha',                     label: 'Fecha',             sortable: true, thStyle: {width:'20%'} },
          { key: 'actions',                   label: 'Acciones',          sortable: false },
        ],
        sortBy: null,
        sortDesc: true,
        sortDirection: 'desc',
      }
    },

    methods:{
      getNecesidadesAsesoria(){
        this.necesidades_asesoria = this.$store.state.emprendimiento.necesidad_asesoria_emprendimiento;
      },

      async getAsesorias(){
        let r = await axios.get(URL_API+'api/asesoria-especifica');

        var arr = [];
        var firts = true;
        var aux;

        r.data.forEach(function(element, index, rubros){
          if(firts==true){
            aux= element.id;
            firts=false;
          }
          arr.push({'value': element.id, 'text': element.nombre });
        });
        this.first_asesoria=aux;
        this.id_asesoria=aux;
        this.asesorias = arr;
      },

      nuevo(){
        this.resetNecesidadAsesoria();
        this.getAsesorias();
        this.$bvModal.show('createNecesidadAsesoria');
      },

      nuevaNecesidadAsesoria(){
        var id = this.id_asesoria;
        var asesoria = this.asesorias.find(x => x.value == id).text;

        var nueva_asesoria = {
          'id':                       this.$store.state.emprendimiento.id_necesidad_asesoria_emprendimiento,
          'id_asesoria':              this.id_asesoria,
          'asesoria':                 asesoria, 
          'fecha': this.fecha
          };

        this.$store.commit('addNecesidadAsesoria', nueva_asesoria);
        this.resetNecesidadAsesoria();
      },

      editarNecesidadAsesoria(item, index, event){
        this.editindex                = item.id;
        this.id_asesoria              = item.id_asesoria; 
        this.fecha = item.fecha

        this.$bvModal.show('editNecesidadAsesoria');
      },

      updateNecesidadAsesoria(index){
        var id = this.id_asesoria;
        var asesoria = this.asesorias.find(x => x.value == id).text;

        for(var i=0; i<this.$store.state.emprendimiento.necesidad_asesoria_emprendimiento.length; i++){
          if(this.$store.state.emprendimiento.necesidad_asesoria_emprendimiento[i].id === index){
            this.$store.state.emprendimiento.necesidad_asesoria_emprendimiento[i].id_asesoria               = this.id_asesoria;
            this.$store.state.emprendimiento.necesidad_asesoria_emprendimiento[i].asesoria                  = asesoria ; 
            this.$store.state.emprendimiento.necesidad_asesoria_emprendimiento[i].fecha  =  this.fecha;
          }
        }
        this.resetNecesidadAsesoria();
      },

      deleteNecesidadAsesoria(item, index, event){
        this.deleteIndex = item.id;
        if (confirm('¿Seguro que desea eliminarlo?')) {
          this.$store.commit('deleteNecesidadAsesoria', this.deleteIndex);
        }
        this.resetNecesidadAsesoria();
      },

      resetNecesidadAsesoria() {
        this.id_asesoria = this.first_asesoria;
        this.fecha ='' 
        
        this.getNecesidadesAsesoria();

        this.$bvModal.hide('createNecesidadAsesoria');
        this.$bvModal.hide('editNecesidadAsesoria');
      },
    },

    created(){
      this.getAsesorias();
      this.getNecesidadesAsesoria();
    }
  };
</script>
<style>
.table-necesidad-asesoria {
  table-layout: fixed;
}

.table-necesidad-asesoria thead th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}


.table-necesidad-asesoria thead tr th{
  padding-top: .3rem;
  padding-bottom: .3rem;
}

@media screen and (min-width: 400px) {
  .table-necesidad-asesoria tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-necesidad-asesoria tbody tr td:nth-child(3) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {
  .table-necesidad-asesoria thead tr th:nth-child(2) {
    justify-content: center;
  }

  .table-necesidad-asesoria tbody tr td:nth-child(2) {
    text-align: center;
  }

  .table-necesidad-asesoria thead tr th:nth-child(3) {
    justify-content: center;
  }

  .table-necesidad-asesoria tbody tr td:nth-child(3) {
    justify-content: center;
  }
}
</style>