<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">

          <DatosRelacionadosEmprendimiento v-if="!loading" />
          <UbicacionEmprendimiento v-if="!loading" />
          <ContactoEmprendimiento v-if="!loading" />
          <RedesEmprendimiento v-if="!loading" />

          <div class="pt-4 pb-4">
            <div class="row">
              <div class="col-md-12 ">
                <b-button 
                  class="mt-3 btn-danger btn-fill" 
                  @click="cancelar">Cancelar
                </b-button>

                <b-button 
                  class="mt-3 btn btn-info btn-fill" 
                  style="float: right;"
                  @click="siguiente">
                  Siguiente
                </b-button>

              </div>
            </div>
          </div>

          <b-modal size="md" id="errors" hide-footer>
            <div class="row">
              <div class="col-md-12 pt-3">
                <ul>
                  <li v-for="item in errors">
                    {{ item.error }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="pt-4">
              <b-button class="mt-3 pull-right btn-danger btn-fill" @click="resetModal">Aceptar</b-button>
            </div>
          </b-modal>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  const URL_API = process.env.VUE_APP_URL_API;
  import axios from "axios";
  import DatosRelacionadosEmprendimiento from 'src/components/Emprendimiento/DatosRelacionadosEmprendimiento.vue'
  import UbicacionEmprendimiento from 'src/components/Emprendimiento/UbicacionEmprendimiento.vue'
  import ContactoEmprendimiento from 'src/components/Emprendimiento/ContactoEmprendimiento.vue'
  import RedesEmprendimiento from 'src/components/Emprendimiento/RedesEmprendimiento.vue'

  export default {
    components: {
      DatosRelacionadosEmprendimiento,
      UbicacionEmprendimiento,
      ContactoEmprendimiento,
      RedesEmprendimiento
    },
    data () {
      return {
        id:null,
        loading: false,
        emprendimiento: [],
        errors:[]
      }
    },   
    
    methods:{
      currentRoute(){
        this.id = this.$route.params.id;
        this.$store.commit('setTitulo', {setTitulo: 'Programa Emprendedores'})
        this.$store.commit('setSubTitulo', {setSubTitulo: 'editar 1/5'})
      },

      getEmprendimientoEdit(){
        this.loading = true;

        axios.get(URL_API+'api/emprendimientosPE/'+this.id)
          .then(emp => {
            this.$store.state.emprendimiento = emp.data.emprendimiento.emprendimiento;
            //------------------Palabras claves------------------
            let pc=[];
            emp.data.emprendimiento.palabras_claves.forEach(element => {
              pc.push(element.palabra_clave);
            });
            this.$store.state.emprendimiento.palabras_claves = pc;
            //---------------------------------------------------
            this.$store.state.emprendimiento.id_emprendedor = 1;
            this.$store.state.emprendimiento.emprendedores = emp.data.emprendimiento.emprendedores;

            this.$store.state.emprendimiento.id_programas_vinculados = 1;
            this.$store.state.emprendimiento.programas_vinculados = emp.data.emprendimiento.programas_vinculados;

            this.$store.state.emprendimiento.id_estadios = 1;
            this.$store.state.emprendimiento.estadios = emp.data.emprendimiento.estadios;

            this.$store.state.emprendimiento.id_registros_mdn = 1;
            this.$store.state.emprendimiento.registros_mdn = emp.data.emprendimiento.registros_mdn;

            this.$store.state.emprendimiento.id_registros_pdn = 1;
            this.$store.state.emprendimiento.registros_pdn = emp.data.emprendimiento.registros_pdn;

            this.$store.state.emprendimiento.id_estado_perfil_equipo_emprendedor = 1;
            this.$store.state.emprendimiento.estado_perfil_equipo_emprendedor = emp.data.emprendimiento.estado_perfil_equipo_emprendedor;
            
            this.$store.state.emprendimiento.id_perfiles_existentes_equipo_emprendedor = 1;
            this.$store.state.emprendimiento.perfiles_existentes_equipo_emprendedor = emp.data.emprendimiento.perfiles_existentes_equipo_emprendedor;
            
            this.$store.state.emprendimiento.id_rol_miembro_equipo_emprendedor = 1;
            this.$store.state.emprendimiento.roles_miembros_equipo_emprendedor = emp.data.emprendimiento.roles_miembros_equipo_emprendedor;
            

            this.$store.state.emprendimiento.id_financiamiento_obtenido_emprendimiento = 1;
            this.$store.state.emprendimiento.financiamiento_obtenido_emprendimiento = emp.data.emprendimiento.financiamiento_obtenido_emprendimiento;
            
            this.$store.state.emprendimiento.id_premios_concursos_emprendimiento = 1;
            this.$store.state.emprendimiento.premios_concursos_emprendimiento = emp.data.emprendimiento.premios_concursos_emprendimiento;
            
            this.$store.state.emprendimiento.id_capacitaciones_emprendimiento = 1;
            this.$store.state.emprendimiento.capacitaciones_emprendimiento = emp.data.emprendimiento.capacitaciones_institucionales_emprendimiento;
            
            this.$store.state.emprendimiento.id_asesoria_emprendimiento = 1;
            this.$store.state.emprendimiento.asesorias_emprendimiento = emp.data.emprendimiento.asesorias_emprendimiento;
            
            this.$store.state.emprendimiento.id_mentoria_emprendimiento = 1;
            this.$store.state.emprendimiento.mentorias_emprendimiento = emp.data.emprendimiento.mentorias_emprendimiento;
            
            this.$store.state.emprendimiento.id_observacion_emprendimiento = 1;
            this.$store.state.emprendimiento.observaciones_emprendimiento = emp.data.emprendimiento.observaciones_emprendimiento;
            
            this.$store.state.emprendimiento.id_estudios_mercados_emprendimiento = 1;
            this.$store.state.emprendimiento.estudios_mercados_emprendimiento = emp.data.emprendimiento.estudios_mercados_emprendimiento;
            
            this.$store.state.emprendimiento.id_cooperacion_internacional_emprendimiento = 1;
            this.$store.state.emprendimiento.cooperaciones_internacionales_emprendimiento = emp.data.emprendimiento.cooperaciones_internacionales_emprendimiento;
            
            this.$store.state.emprendimiento.id_red_internacional_emprendimiento = 1;
            this.$store.state.emprendimiento.redes_internacionales_emprendimiento = emp.data.emprendimiento.redes_internacionales_emprendimiento;

            this.$store.state.emprendimiento.id_necesidad_capital_emprendimiento = 1;
            this.$store.state.emprendimiento.necesidad_capital_emprendimiento = emp.data.emprendimiento.necesidad_capital_emprendimiento;
            
            this.$store.state.emprendimiento.id_necesidad_socio_emprendimiento = 1;
            this.$store.state.emprendimiento.necesidad_socio_emprendimiento = emp.data.emprendimiento.necesidad_socio_emprendimiento;
            
            this.$store.state.emprendimiento.id_necesidad_asesoria_emprendimiento = 1;
            this.$store.state.emprendimiento.necesidad_asesoria_emprendimiento = emp.data.emprendimiento.necesidad_asesoria_emprendimiento;
            
            this.$store.state.emprendimiento.id_necesidad_internacionalizacion_emprendimiento = 1;
            this.$store.state.emprendimiento.necesidad_internacionalizacion_emprendimiento = emp.data.emprendimiento.necesidad_internacionalizacion_emprendimiento;
            
            this.$store.state.emprendimiento.id_necesidad_otra_emprendimiento = 1;
            this.$store.state.emprendimiento.necesidad_otra_emprendimiento = emp.data.emprendimiento.necesidad_otra_emprendimiento;
            
            this.loading = false;
          })
          .catch(error => {
            alert(error.response.data.message);
            this.$router.push({ name: 'EmprendimientosGIDE' })
          })
      },

      verificarEmprendimiento(){
        if(this.$store.state.emprendimiento_edit==false){
          this.getEmprendimientoEdit();
          this.$store.state.emprendimiento_edit=true;
        }
      },

      cheakComponent(){
        axios.get(URL_API+'api/programas-svytt')
          .then(response =>{
            response.data.forEach(element => {
              if(element.nombre=="Emprendedores"){
                this.$store.commit('setIDPrograma', element.id);
              }
            });
          })
      },

      siguiente(){
        this.errors=[];

        if(this.$store.state.emprendimiento.nombre==''){
          this.errors.push({'error':'Agregue el NOMBRE del emprendimiento antes de continuar'});
        }

        if(this.$store.state.emprendimiento.anio_inicio==null){
          this.errors.push({'error':'Seleccione el AÑO DE INICIO del emprendimiento antes de continuar'});
        }

        if(this.$store.state.emprendimiento.id_perfil==null){
          this.errors.push({'error':'Seleccione el PERFIL del emprendimiento antes de continuar'});
        }

        if(this.$store.state.emprendimiento.id_rubro_principal==null){
          this.errors.push({'error':'Seleccione el RUBRO PRIMARIO del emprendimiento antes de continuar'});
        }

        if(this.errors[0]){
          this.$root.$emit('bv::show::modal','errors');
        }else{
          this.$router.push( {name:'editPE2', params: { id: this.id }});
        }
      },
      resetModal(){
        this.errors=[];
        this.$root.$emit('bv::hide::modal','errors');
      },

      cancelar(){
        if (confirm('¿Seguro que desea cancelar?')) {
          this.$store.commit('clearEmprendimiento');
          this.$router.push({ name: 'EmprendimientosPE'})
        }
      },
    },

    created(){
      this.$Progress.start();
      window.scrollTo(0,-1);
      this.currentRoute();
      this.cheakComponent();
      this.verificarEmprendimiento();
      this.$Progress.finish();
    }
  };
</script>