<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <div class="container-fluid"> 

      	<h4>{{ $store.state.titulo }} <small style="color: blue;">{{ $store.state.subtitulo }}</small></h4>

        <button type="button"
                class="navbar-toggler navbar-toggler-right"
                :class="{toggled: $sidebar.showSidebar}"
                aria-controls="navigation-index"
                aria-expanded="false"
                aria-label="Toggle navigation"
                @click="toggleSidebar">
          <span class="navbar-toggler-bar burger-lines"></span>
          <span class="navbar-toggler-bar burger-lines"></span>
          <span class="navbar-toggler-bar burger-lines"></span>
        </button>

        <div class="collapse navbar-collapse justify-content-end">
          <ul class="navbar-nav ml-auto">
            <base-dropdown class="pr-4" :title="apellido.toUpperCase()+', '+nombre">
              <router-link class="dropdown-item" :to="({name: 'editProfile'})">Editar Perfil</router-link>
              <div class="divider"></div>
              <a class="dropdown-item pointer" @click="logout" >Cerrar Sesión</a>
            </base-dropdown>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
  import axios from 'axios'
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    data () {
      return {
        nombre:'',
        apellido:'',
        activeNotifications: false
      }
    },
    mounted() {
      // Set the initial number of items
      this.apellido = localStorage.getItem('lastname');
      this.nombre = localStorage.getItem('name');
    },

    methods: {
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      toggleNotificationDropDown () {
        this.activeNotifications = !this.activeNotifications
      },
      closeDropDown () {
        this.activeNotifications = false
      },
      toggleSidebar () {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      hideSidebar () {
        this.$sidebar.displaySidebar(false)
      },
      logout(){
        axios.post(URL_API+'api/auth/logout').then(response => {
          localStorage.removeItem('access_token');
          localStorage.removeItem('token_type');
          localStorage.removeItem('expires_in');
          localStorage.removeItem('name');
          localStorage.removeItem('lastname');
          localStorage.removeItem('email');
          localStorage.removeItem('isLogged');

          this.$router.push({name:'login'})
        }).catch(e => {
          console.log(e);
        });
      }

    },
    computed: {
      routeName () {
        const {name} = this.$route
        return this.capitalizeFirstLetter(name)
      }
    },
  }

</script>
<style>
  .pointer{
    cursor: pointer;
  }
</style>
