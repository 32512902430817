<template>
  <b-container fluid="lg" class="pl-4 pr-4">
    <b-row>
      <b-col cols="12" v-if="show">

        <DatosEmprendimiento 
          v-if="loading"
          :nombre="this.emprendimiento.emprendimiento.nombre"
          :descripcion="this.emprendimiento.emprendimiento.descripcion"
          :perfil="this.emprendimiento.emprendimiento.perfil"
          :razon_social="this.emprendimiento.emprendimiento.razon_social"
          :rubro_principal="this.emprendimiento.emprendimiento.rubro_principal"
          :anio_inicio="this.emprendimiento.emprendimiento.anio_inicio"
          :rubro_secundario="this.emprendimiento.emprendimiento.rubro_secundario"
          :palabras_claves="this.emprendimiento.palabras_claves"
          :correo_emprendimiento="this.emprendimiento.emprendimiento.correo_emprendimiento"
          :telefono_emprendimiento="this.emprendimiento.emprendimiento.telefono_emprendimiento"
          :provincia="this.emprendimiento.emprendimiento.provincia"
          :localidad="this.emprendimiento.emprendimiento.localidad"
        />

        <ProgramaEmprendimiento
          v-if="loading"
          :nombre_programa="this.emprendimiento.emprendimiento.nombre_programa"
          :incubado_en="this.emprendimiento.emprendimiento.incubado_en"
          :situacion_actual="this.emprendimiento.emprendimiento.situacion_actual"
        />

        <ProgramasVinculadosEmprendimiento
          v-if="loading"
          :hidden="this.emprendimiento.programas_vinculados.length==0"
          :programas_vinculados="this.emprendimiento.programas_vinculados"
        />

        <RedesEmprendimiento 
          v-if="loading"
          :web="this.emprendimiento.emprendimiento.web"
          :linkedin="this.emprendimiento.emprendimiento.linkedin"
          :facebook="this.emprendimiento.emprendimiento.facebook"
          :instagram="this.emprendimiento.emprendimiento.instagram"
        />

        <IntegrantesEmprendimiento 
          v-if="loading"
          :responsable_emprendimiento="this.emprendimiento.emprendimiento.responsable_emprendimiento"
          :integrantes="this.emprendimiento.emprendedores"
        />

        <DatosAdministrativos  
          v-if="loading"
          :num_nota_svytt="this.emprendimiento.emprendimiento.num_nota_svytt"
          :num_expediente="this.emprendimiento.emprendimiento.num_expediente"
          :fecha_alta="this.emprendimiento.emprendimiento.fecha_alta"
          :condicion="this.emprendimiento.emprendimiento.condicion"
          :fecha_baja="this.emprendimiento.emprendimiento.fecha_baja"
          :motivo_desvinculacion="this.emprendimiento.emprendimiento.motivo_desvinculacion"
        />

        <EstadiosEmprendimiento 
          v-if="loading"
          :hidden="this.emprendimiento.estadios.length==0"
          :integrantes="this.emprendimiento.estadios"
        />

        <MDNEmprendimiento 
          v-if="loading"
          :hidden="this.emprendimiento.registros_mdn.length==0"
          :registros_mdn="this.emprendimiento.registros_mdn"
        />

        <PDNEmprendimiento 
          v-if="loading"
          :hidden="this.emprendimiento.registros_pdn.length==0"
          :registros_pdn="this.emprendimiento.registros_pdn"
        />

        <PitchUrlEmprendimiento  
          v-if="loading"
          :hidden="this.emprendimiento.emprendimiento.url_pitch==null"
          :url_pitch="this.emprendimiento.emprendimiento.url_pitch"
        />

        <SolidezEquipoEmprendedor  
          v-if="loading"
          :hidden="this.emprendimiento.estado_perfil_equipo_emprendedor.length==0 && this.emprendimiento.perfiles_existentes_equipo_emprendedor.length==0 && this.emprendimiento.roles_miembros_equipo_emprendedor.length==0"
          :estado_perfil_equipo_emprendedor="this.emprendimiento.estado_perfil_equipo_emprendedor"
          :perfiles_existentes_equipo_emprendedor="this.emprendimiento.perfiles_existentes_equipo_emprendedor"
          :roles_miembros_equipo_emprendedor="this.emprendimiento.roles_miembros_equipo_emprendedor"
        />
        
        <ProteccionTecnologica 
          v-if="loading"
          :proteccion_tecnologica="this.emprendimiento.emprendimiento.proteccion_tecnologica"
          :esta_protegida="this.emprendimiento.emprendimiento.esta_protegida"
          :estado_proteccion="this.emprendimiento.emprendimiento.estado_proteccion"
        />   

        <FormalizacionJuridica 
          v-if="loading"
          :formalizacion_juridica="this.emprendimiento.emprendimiento.formalizacion_juridica"
          :fecha_estructura_juridica="this.emprendimiento.emprendimiento.fecha_estructura_juridica"
          :estructura_juridica="this.emprendimiento.emprendimiento.estructura_juridica"
        />

        <FinanciamientoObtenidoEmprendimiento 
          v-if="loading"
          :hidden="this.emprendimiento.financiamiento_obtenido_emprendimiento.length==0"
          :financiamiento_obtenido_emprendimiento="this.emprendimiento.financiamiento_obtenido_emprendimiento"
        />

        <PremiosConcursosEmprendimiento 
          v-if="loading"
          :hidden="this.emprendimiento.premios_concursos_emprendimiento.length==0"
          :concursos_emprendimiento="this.emprendimiento.premios_concursos_emprendimiento"
        />

        <CapacitacionesEmprendimiento 
          v-if="loading"
          :hidden="this.emprendimiento.capacitaciones_institucionales_emprendimiento.length==0"
          :capacitaciones_emprendimiento="this.emprendimiento.capacitaciones_institucionales_emprendimiento"
        />

        <AsesoriasEmprendimiento 
          v-if="loading"
          :hidden="this.emprendimiento.asesorias_emprendimiento.length==0"
          :asesorias_emprendimiento="this.emprendimiento.asesorias_emprendimiento"
        />

        <MentoriasEmprendimiento 
          v-if="loading"
          :hidden="this.emprendimiento.mentorias_emprendimiento.length==0"
          :mentorias_emprendimiento="this.emprendimiento.mentorias_emprendimiento"
        />

         <ObservacionesEmprendimiento 
          v-if="loading"
          :hidden="this.emprendimiento.observaciones_emprendimiento.length==0"
          :observaciones_emprendimiento="this.emprendimiento.observaciones_emprendimiento"
        />

        <EstudiosMercadosEmprendimiento 
          v-if="loading"
          :hidden="this.emprendimiento.estudios_mercados_emprendimiento.length==0"
          :estudios_mercados_emprendimiento="this.emprendimiento.estudios_mercados_emprendimiento"
        />

        <CooperacionesInternacionalesEmprendimiento 
          v-if="loading"
          :hidden="this.emprendimiento.cooperaciones_internacionales_emprendimiento.length==0"
          :cooperaciones_internacionales="this.emprendimiento.cooperaciones_internacionales_emprendimiento"
        />

        <RedesInternacionalesEmprendimiento 
          v-if="loading"
          :hidden="this.emprendimiento.redes_internacionales_emprendimiento.length==0"
          :redes_internacionales="this.emprendimiento.redes_internacionales_emprendimiento"
        />

        <NecesidadCapital 
          v-if="loading"
          :hidden="this.emprendimiento.necesidad_capital_emprendimiento.length==0"
          :necesidad_capital_emprendimiento="this.emprendimiento.necesidad_capital_emprendimiento"
        />

        <NecesidadSocio 
          v-if="loading"
          :hidden="this.emprendimiento.necesidad_socio_emprendimiento.length==0"
          :necesidad_socio="this.emprendimiento.necesidad_socio_emprendimiento"
        />

        <NecesidadAsesoria 
          v-if="loading"
          :hidden="this.emprendimiento.necesidad_asesoria_emprendimiento.length==0"
          :necesidad_asesoria="this.emprendimiento.necesidad_asesoria_emprendimiento"
        />

        <NecesidadInternacionalizacion 
          v-if="loading"
          :hidden="this.emprendimiento.necesidad_internacionalizacion_emprendimiento.length==0"
          :necesidad_internacionalizacion="this.emprendimiento.necesidad_internacionalizacion_emprendimiento"
        />

        <NecesidadOtra 
          v-if="loading"
          :hidden="this.emprendimiento.necesidad_otra_emprendimiento.length==0"
          :necesidad_otras="this.emprendimiento.necesidad_otra_emprendimiento"
        />

        <b-row>
          <b-col cols="12" align="center" class="pb-4">
            
            <router-link class="mt-3 pull-left btn btn-info btn-fill" 
                :to="{ name: 'ProgramaBIO' }"
              >Volver
            </router-link>

            <GenerarPDF 
              :id_emprendimiento="this.id"
              :emprendimiento="this.emprendimiento"
              class="mt-3 pull-right btn-fill" 
            />

          </b-col>
        </b-row>

      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import axios from "axios";
  import DatosEmprendimiento from 'src/components/Show/DatosEmprendimiento.vue'
  import RedesEmprendimiento from 'src/components/Show/RedesEmprendimiento.vue'

  import ProgramaEmprendimiento from 'src/components/Show/ProgramaEmprendimiento.vue'
  import ProgramasVinculadosEmprendimiento from 'src/components/Show/ProgramasVinculadosEmprendimiento.vue'

  import IntegrantesEmprendimiento from 'src/components/Show/IntegrantesEmprendimiento.vue'
  import DatosAdministrativos from 'src/components/Show/DatosAdministrativos.vue'

  import EstadiosEmprendimiento from 'src/components/Show/EstadiosEmprendimiento.vue'
  import MDNEmprendimiento from 'src/components/Show/MDNEmprendimiento.vue'
  import PDNEmprendimiento from 'src/components/Show/PDNEmprendimiento.vue'

  import PitchUrlEmprendimiento from 'src/components/Show/PitchUrlEmprendimiento.vue'

  import SolidezEquipoEmprendedor from 'src/components/Show/SolidezEquipoEmprendedor'
  
  import ProteccionTecnologica from 'src/components/Show/ProteccionTecnologica.vue'
  import FormalizacionJuridica from 'src/components/Show/FormalizacionJuridica.vue'

  import FinanciamientoObtenidoEmprendimiento from 'src/components/Show/FinanciamientoObtenidoEmprendimiento.vue'
  import PremiosConcursosEmprendimiento from 'src/components/Show/PremiosConcursosEmprendimiento.vue'
  import CapacitacionesEmprendimiento from 'src/components/Show/CapacitacionesEmprendimiento.vue'
  import AsesoriasEmprendimiento from 'src/components/Show/AsesoriasEmprendimiento.vue'
  import MentoriasEmprendimiento from 'src/components/Show/MentoriasEmprendimiento.vue'
  import ObservacionesEmprendimiento from 'src/components/Show/ObservacionesEmprendimiento.vue'
  import EstudiosMercadosEmprendimiento from 'src/components/Show/EstudiosMercadosEmprendimiento.vue'
  import CooperacionesInternacionalesEmprendimiento from 'src/components/Show/CooperacionesInternacionalesEmprendimiento.vue'
  import RedesInternacionalesEmprendimiento from 'src/components/Show/RedesInternacionalesEmprendimiento.vue'


  import NecesidadCapital from 'src/components/Show/NecesidadCapital.vue'
  import NecesidadSocio from 'src/components/Show/NecesidadSocio.vue'
  import NecesidadAsesoria from 'src/components/Show/NecesidadAsesoria.vue'
  import NecesidadInternacionalizacion from 'src/components/Show/NecesidadInternacionalizacion.vue'
  import NecesidadOtra from 'src/components/Show/NecesidadOtra.vue'
  import GenerarPDF from 'src/components/Show/GenerarPDF.vue'
  
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    components: {
      GenerarPDF,

      DatosEmprendimiento,
      ProgramaEmprendimiento,
      ProgramasVinculadosEmprendimiento,
      
      RedesEmprendimiento,

      IntegrantesEmprendimiento,

      DatosAdministrativos,

      EstadiosEmprendimiento,
      MDNEmprendimiento,
      PDNEmprendimiento,

      PitchUrlEmprendimiento,

      SolidezEquipoEmprendedor,
      ProteccionTecnologica,
      FormalizacionJuridica,
      FinanciamientoObtenidoEmprendimiento,
      PremiosConcursosEmprendimiento,
      CapacitacionesEmprendimiento,
      AsesoriasEmprendimiento,
      MentoriasEmprendimiento,
      ObservacionesEmprendimiento,
      EstudiosMercadosEmprendimiento,
      CooperacionesInternacionalesEmprendimiento,
      RedesInternacionalesEmprendimiento,

      NecesidadCapital, 
      NecesidadSocio, 
      NecesidadAsesoria, 
      NecesidadInternacionalizacion,
      NecesidadOtra
    },
    data () {
      return {
        id:null,
        show: false,
        loading:false,
        emprendimiento: [],
      }
    },

    methods:{
      currentRoute(){
        this.id = this.$route.params.id;
        this.$store.commit('setTitulo', {setTitulo: 'Programa UNLBio'})
        this.$store.commit('setSubTitulo', {setSubTitulo: 'ver'})
      },
      
      getEmprendimiento(){
        axios.get(URL_API+'api/emprendimientosBIO/'+this.id)
          .then( emp => {
            this.emprendimiento = emp.data.emprendimiento;
            this.show = true;
            this.loading = true;
          }).catch(error => {
            alert(error.response.data.message);
            this.$router.push({ name: 'ProgramaBIO' })
          })
      }
    },

    mounted(){
      this.$Progress.start(),
      this.currentRoute();
      this.getEmprendimiento();
      this.$Progress.finish()
    }
  };
</script>