<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="copyright text-center">
        Secretaria de Vinculación y Transferencia Tecnológica - <b>Área de Informática</b>.
      </div>
    </div>
  </footer>
</template>
<script>
  export default {}

</script>
<style>

</style>
