<template>
  <div class="content pt-4"> 
    <b-container fluid>
      <div class="card">
        <div class="col-md-12">
          <!-- User Interface controls -->
          <b-row>
            <b-col lg="6" class="my-4">
              <b-button 
                @click="nuevoEventoConcurso"
                class="btn btn-info btn-fill">
                <span class="fa fa-plus"></span> Nuevo
              </b-button>
            </b-col>

            <b-col lg="6" class="my-4">
              <b-form-group
                label-cols-sm="7"
                label-align-sm="right"
                label-size="md"
                label-for="filterInput"  
              >
              <b-form-input
                v-model="filter"
                type="search"
                id="filterInput"
                autocomplete="off"
                size="md"
                placeholder="Buscár"
              ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <!-- Main table element -->
        <div class="col-md-12 pt-4">
          <b-table
            class="table-rubros"
            hover
            head-variant="dark"
            stacked="md"
            :items="eventos_concursos"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filterIncludedFields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered"
          >
            <template v-slot:cell(actions)="row">
              <b-button 
                size="sm" 
                @click="editEventoConcurso(row.item, row.index, $event.target)" 
                class="btn btn-warning btn-fill mr-1">
                <span class="fa fa-pencil"></span>
              </b-button>
              <b-button 
                class="btn-danger btn-fill" 
                size="sm" 
                @click="deleteEventoConcurso(row.item, row.index, $event.target)">
                <span class="fa fa-trash"></span>
              </b-button>
            </template>

            <template v-slot:cell(institucion)="row">
              <template v-if="row.item.institucion=='unl'">
                UNL
              </template>
              <template v-else>
                {{ row.item.otra_institucion }}
              </template>

            </template>
          </b-table>
        </div>
        <b-row>
          <b-col sm="5" md="3" class="my-1">
            <b-form-group
              label="Por pagina"
              label-cols-sm="8"
              label-cols-md="8"
              label-cols-lg="8"
              label-align-sm="right"
              label-size="sm"
              label-for="perPageSelect"
              class="mb-0"
            >
              <b-form-select
                v-model="perPage"
                id="perPageSelect"
                size="sm"
                :options="pageOptions"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="7" md="3" class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-row>
      </div>

      <b-modal size="lg" title="Nuevo"  id="create-modal" hide-footer>
        <div class="row">
          <div class="col-md-12">
            <label>Evento o concurso:</label>
            <b-form-input 
              v-model="nombre" 
              @keydown.enter="addEventoConcurso" 
              required autocomplete="off">
            </b-form-input>
          </div>

          <div class="col-md-6 pt-3">
            <label>Institución:</label>
            <b-form-select
                v-model="institucion"
                :options="instituciones"
              >
            </b-form-select>
          </div>

          <div class="col-md-6 pt-3" :hidden="institucion!='unl'">
            <label>Plataforma:</label>
            <b-form-select
                v-model="id_plataforma"
                :options="plataformas">
            </b-form-select>
          </div>

          <div class="col-md-6 pt-3" :hidden="institucion=='unl'">
            <label>Otra institución:</label>
            <b-form-input 
              v-model="otra_institucion">
            </b-form-input>
          </div>
        </div>
        <div class="pt-4">
          <b-button 
            class="mt-3 pull-left btn-danger btn-fill" 
            @click="resetModal">Cacelar
          </b-button>
          
          <b-button 
            class="mt-3 pull-right btn-info btn-fill" 
            :disabled=" nombre=='' || (institucion=='otra' && otra_institucion=='')"
            @click="addEventoConcurso">Agregar
          </b-button>
        </div>
      </b-modal>

      <!-- Edit modal -->
      <b-modal id="edit-modal" title="Editar" size="lg" hide-footer>
        <div class="row">
          <div class="col-md-12">
            <label>Evento o concurso:</label>
            <b-form-input 
              v-model="nombre" 
              @keydown.enter="updateEventoConcurso" 
              required autocomplete="off">
            </b-form-input>
          </div>

          
          <div class="col-md-6 pt-3">
            <label>Institución:</label>
            <b-form-select
                v-model="institucion"
                :options="instituciones"
              >
            </b-form-select>
          </div>

          <div class="col-md-6 pt-3" :hidden="institucion!='unl'">
            <label>Plataforma:</label>
            <b-form-select
                v-model="id_plataforma"
                :options="plataformas">
            </b-form-select>
          </div>

          <div class="col-md-6 pt-3" :hidden="institucion=='unl'">
            <label>Otra institución:</label>
            <b-form-input 
              v-model="otra_institucion">
            </b-form-input>
          </div>
        </div>
        <div class="pt-4">
          <b-button class="mt-3 pull-left btn-danger btn-fill" @click="resetModal">Cacelar</b-button>
          <b-button class="mt-3 pull-right btn-info btn-fill" @click="updateEventoConcurso">Actualizar</b-button>
        </div>
      </b-modal>

    </b-container>
  </div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    data() {
      return {
        eventos_concursos: [],
        nombre: '',
        institucion: 'unl',
        otra_institucion: '',

        first_id_plataforma: '',
        id_plataforma: '',
        plataformas:[],
        observaciones: '',

        instituciones:[
          {value:'unl', text:'Universidad Nacional del Litoral'},
          {value:'otra', text:'Otra'},
        ],

        editindex: null,
        deleteIndex: null,
        fields: [
          { key: 'id',          label: '#',                   sortable: true, thStyle: {width:'7%'}},
          { key: 'nombre',      label: 'Eventos y concursos', sortable: true, sortDirection: 'desc', thStyle: {width:'45%'}},
          { key: 'institucion', label: 'Institución',         sortable: true, thStyle: {width:'30%'}},
          { key: 'actions',     label: 'Acciones'}
        ],
        totalRows: 1,
        pageOptions: [10, 25, 50, 100],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        editModal: {
          id: 'info-modal',
          title: '',
          content: ''
        }
      }
    },

    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },

      perPage:{
        get(){
          return this.$store.state.perPage
        },
        set(value){
          this.$store.commit('setPerPage', {perPage: value})
        }
      },

      currentPage:{
        get(){
          return this.$store.state.currentPage
        },
        set(value){
          this.$store.commit('setCurrentPage', {currentPage: value})
        }
      }
    },

    mounted() {
      // Set the initial number of items
      this.$store.commit('setTitulo', {setTitulo: 'Configuración'});
      this.$store.commit('setSubTitulo', {setSubTitulo: 'Concursos y Eventos'});
    },

    methods: {

      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },

      async getPlataformas(){
        let r = await axios.get(URL_API+'api/plataformas-emprendimientos');

        var arr = [];
        var first = true;
        var aux;

        r.data.forEach(function(element, index){
          if(first==true){
            aux = element.id;
            first = false;
          }
          arr.push({'value': element.id, 'text': element.nombre });
        });

        this.first_id_plataforma = aux;
        this.id_plataforma = aux;
        this.plataformas = arr;
      },

      async getEventosYConcursos(){
        await axios.get(URL_API+'api/eventos-concursos').then(response=>{
          this.eventos_concursos = response.data;
          this.totalRows = this.eventos_concursos.length;
        }).catch(e=>{
          alert("Oops ha ocurrido un error durante la carga, intente mas tarde.");
          console.log(e);
          this.$router.push({ name: 'EmprendimientosUNL' })
        })
      },

      nuevoEventoConcurso() {
        this.resetModal();
        this.$root.$emit('bv::show::modal', 'create-modal');
      },

      async addEventoConcurso(){
        axios.post(URL_API+'api/eventos-concursos', {
            nombre:                         this.nombre,
            institucion:                    this.institucion,
            id_plataformas_emprendimientos: this.id_plataforma,
            otra_institucion:               this.otra_institucion,
        }).then(response => {
          this.getEventosYConcursos();
        }).catch(e => {
            console.log(e);
        });
        this.resetModal();
      },

      resetModal(item, index, button) {
        this.nombre = '';
        this.institucion = 'unl',
        this.id_plataforma = this.first_id_plataforma;
        this.otra_institucion = '';

        this.$root.$emit('bv::hide::modal','create-modal');
        this.$root.$emit('bv::hide::modal', 'edit-modal');
      },

      editEventoConcurso(item, index, button) {
        this.editindex = item.id;

        this.nombre = item.nombre;
        this.institucion = item.institucion,
        this.id_plataforma = item.id_plataformas_emprendimientos;
        this.otra_institucion = item.otra_institucion;

        this.$root.$emit('bv::show::modal', 'edit-modal');
      },

      async updateEventoConcurso(item, index, button){
        await axios.put(URL_API+'api/eventos-concursos/'+this.editindex, {
            nombre:                         this.nombre,
            institucion:                    this.institucion,
            id_plataformas_emprendimientos: this.id_plataforma,
            otra_institucion:               this.otra_institucion,
        }).then(response => {
          this.getEventosYConcursos();
          this.resetModal();
        }).catch(e => {
            console.log(e);
            alert(e)
            this.resetModal();
        });
      },

      async deleteEventoConcurso(item, index, button){
        this.deleteIndex = item.id;
        if (confirm('¿Seguro que desea eliminarlo?')) {
          axios.delete(URL_API+'api/eventos-concursos/'+this.deleteIndex)
          .then(response => {
            this.deleteIndex = null;
            this.getEventosYConcursos();
          }).catch(e => {
              console.log(e);
          });
        }
      }
    },

    created(){
      this.$Progress.start(),
      this.getEventosYConcursos();
      this.getPlataformas();
      this.$Progress.finish()
    }
  }
</script>

<style>
.btn-close {
  background-color: red;
}

.table-rubros {
  table-layout: fixed;
}

.table-rubros thead th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;

}

.table-rubros thead tr th{
  padding-top: .5rem;
  padding-bottom: .5rem;
}


@media screen and (min-width: 400px) {
  .table-rubros tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-rubros tbody tr td:nth-child(3) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {
  .table-rubros tbody tr td:nth-child(1) {
    text-align: center;
  }

  .table-rubros tbody tr td:nth-child(3) {
    text-align: center;
  }

  .table-rubros thead tr th:nth-child(4) {
    justify-content: center;
  }

  .table-rubros tbody tr td:nth-child(4) {
    justify-content: center;
  }
}
</style>