<template>
  <div>
    <div class="col-md-12">
      <h5>Roles de cada miembro</h5>
    </div>

    <div class="col-md-12">
      <!-- User Interface controls -->
      <b-row>
        <b-col sm="12" class="my-3">
          <b-button
            class="btn btn-info btn-fill" 
            @click="nuevo()">
            <i class="fa fa-plus"></i> 
              Agregar
          </b-button>
        </b-col>
      </b-row>
    </div>

    <!-- Main table element -->
    <div class="col-md-12 pb-4">
      <b-table
        class="table-estados-solidez-emprendimiento"
        hover
        stacked="md"
        head-variant="dark"
        :items="roles_miembros"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
      >
        <template v-slot:cell(actions)="row">
          <b-button size="sm" @click="editRolesMiembros(row.item, row.index, $event.target)" class="btn btn-warning btn-fill mr-1">
          <span class="fa fa-pencil"></span>
          </b-button>

          <b-button class="btn-danger btn-fill" size="sm" @click="deleteMentoria(row.item, row.index, $event.target)">
          <span class="fa fa-trash"></span>
          </b-button>
        </template>
      </b-table>
    </div>

    <b-modal size="md" id="createRolesMiembros" hide-footer>
      <div class="row">
        <div class="col-md-8">
          <label>Roles de cada miembro:</label>
          <b-form-select 
            v-model="rol_miembros" 
            :options="estados_roles">
          </b-form-select>
        </div>

        <div class="col-md-4">
          <label>Año:</label>
            <b-form-select 
              v-model="anio"
              :options="anios">
            </b-form-select>
        </div>   
      </div>

      <div class="pt-4">
          <b-button 
            class="mt-3 pull-left btn-danger btn-fill" 
            @click="resetRolesMiembros()">
            Cancelar
          </b-button>
          
          <b-button
            class="mt-3 pull-right btn-info btn-fill" 
            @click="nuevaRolMiembro()">
            Agregar
          </b-button>
      </div>
    </b-modal>

    <b-modal size="md" id="editRolesMiembros" hide-footer>
      <div class="row">
        <div class="col-md-8">
          <label>Roles de cada miembro:</label>
          <b-form-select 
            v-model="rol_miembros_edit" 
            :options="estados_roles">
          </b-form-select>
        </div>

        <div class="col-md-4">
          <label>Año:</label>
            <b-form-select 
              v-model="anio_edit"
              :options="anios">
            </b-form-select>
        </div>   
      </div>
      <div class="pt-4">
          <b-button 
            class="mt-3 pull-left btn-danger btn-fill" 
            @click="resetRolesMiembros()">
              Cacelar
          </b-button>
          
          <b-button 
            class="mt-3 pull-right btn-info btn-fill" 
            @click="updateRolesMiembros(editindex)">
              Actualizar
          </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    data () {
      return {
        rol_miembros: 'En construcción',
        rol_miembros_edit: 'En construcción',

        estados_roles: [
          {value:'En construcción',text:'En construcción'},
          {value:'Finalizado',text:'Finalizado'}
        ],

        anio: null,
        anio_edit: null,
        anios: [],
        
        roles_miembros:[],

        editindex: null,
        deleteIndex: null,
        fields: [
          { key: 'estado_rol',  label: 'Roles de cada miembro', sortable: true, thStyle: {width:'60%'}  },
          { key: 'anio',        label: 'Año',                   sortable: true, thStyle: {width:'20%'}  },
          { key: 'actions',     label: 'Acciones',              sortable: false},
        ],
        sortBy: 'anio',
        sortDesc: true,
        sortDirection: 'desc',
      }
    },

    methods:{
      getRolesMiembrosEmprendimiento(){
        this.roles_miembros = this.$store.state.emprendimiento.roles_miembros_equipo_emprendedor;
      },

      nuevo(){
        this.resetRolesMiembros();
        this.$bvModal.show('createRolesMiembros');
      },

      nuevaRolMiembro(){
        var nueva_estado = {
          'id':           this.$store.state.emprendimiento.id_rol_miembro_equipo_emprendedor,
          'estado_rol':   this.rol_miembros,
          'anio':     this.anio,
        };

        this.$store.commit('addRolesMiembrosEmprendimiento', nueva_estado);
        this.resetRolesMiembros();
      },

      editRolesMiembros(item, index, event){
        this.editindex = item.id; 
        this.rol_miembros_edit = item.estado_rol;
        this.anio_edit = item.anio,

        this.$root.$emit('bv::show::modal','editRolesMiembros');
      },

      updateRolesMiembros(index){
        for(var i=0; i<this.$store.state.emprendimiento.roles_miembros_equipo_emprendedor.length; i++){
          if(this.$store.state.emprendimiento.roles_miembros_equipo_emprendedor[i].id === index){
            this.$store.state.emprendimiento.roles_miembros_equipo_emprendedor[i].estado_rol = this.rol_miembros_edit;
            this.$store.state.emprendimiento.roles_miembros_equipo_emprendedor[i].anio = this.anio_edit;
          }
        }
        this.resetRolesMiembros();
      },

      deleteMentoria(item, index, event){
        this.deleteIndex = item.id; 
        if (confirm('¿Seguro que desea eliminarlo?')) {
          this.$store.commit('deleteRolesMiembrosEmprendimiento', this.deleteIndex);
        }
        this.resetRolesMiembros();
      },

      resetRolesMiembros() {
        this.id_mentoria = this.first_mentoria;
        var today = new Date();

        this.anio = today.getFullYear();
        this.anio_edit = today.getFullYear();

        this.rol_miembros = 'En construcción';
        this.rol_miembros_edit = 'En construcción';

        this.getRolesMiembrosEmprendimiento();
        
        this.$root.$emit('bv::hide::modal','createRolesMiembros');
        this.$root.$emit('bv::hide::modal','editRolesMiembros');
      },

      getAnios(){
        var today = new Date();
        this.anio = today.getFullYear();

        var arr = [];
        for (var i = this.anio; i >= 2000; i--) {
          arr.push({'value': i, 'text': i });
        }
        this.anios = arr;
      },
    },

    created(){
      this.getRolesMiembrosEmprendimiento();
      this.getAnios();
    }
  };
</script>


<style>
.table-estados-solidez-emprendimiento {
  table-layout: fixed;
}

.table-estados-solidez-emprendimiento thead th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}


.table-estados-solidez-emprendimiento thead tr th{
  padding-top: .3rem;
  padding-bottom: .3rem;
}

@media screen and (min-width: 400px) {
  .table-estados-solidez-emprendimiento tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-estados-solidez-emprendimiento tbody tr td:nth-child(3) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {
  .table-estados-solidez-emprendimiento tbody tr td:nth-child(1) {
    text-align: center;
  }

  .table-estados-solidez-emprendimiento tbody tr td:nth-child(1) {
    text-align: center;
  }

  .table-estados-solidez-emprendimiento thead tr th:nth-child(2) {
    justify-content: center;
  }

  .table-estados-solidez-emprendimiento tbody tr td:nth-child(2) {
    text-align: center;
  }

  .table-estados-solidez-emprendimiento thead tr th:nth-child(3) {
    justify-content: center;
  }

  .table-estados-solidez-emprendimiento tbody tr td:nth-child(3) {
    justify-content: center;
  }
}
</style>