<template>
  <div>
    <h4>Necesidades de un Socio, Alianza Estrategica ó Partner</h4>
    <div class="card">
      <div class="col-md-12 pt-4 pb-4">
        <div class="row">
          <div class="col-md-12">
            
          <!-- User Interface controls -->
          <b-row>
            <b-col sm="12" class="my-3">
              <b-button 
                class="btn btn-info btn-fill" 
                @click="nuevo()"><i class="fa fa-plus"></i> 
                  Agregar
              </b-button>
            </b-col>
          </b-row>
          </div>
          <!-- Main table element -->
          <div class="col-md-12 pb-4">
            <b-table
              class="table-necesidad-socio"
              hover
              stacked="md"
              head-variant="dark"
              :items="necesidades_socio"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
            >
              <template v-slot:cell(fecha)="row">
                {{ row.item.fecha.toString().split("-").reverse().join("/") }}
              </template>

              <template v-slot:cell(actions)="row">
                <b-button size="sm" @click="editarNecesidadSocio(row.item, row.index, $event.target)" class="btn btn-warning btn-fill mr-1">
                <span class="fa fa-pencil"></span>
                </b-button>

                <b-button class="btn-danger btn-fill" size="sm" @click="deleteNecesidadCapital(row.item, row.index, $event.target)">
                <span class="fa fa-trash"></span>
                </b-button>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <b-modal size="lg" id="createNecesidadSocio" hide-footer>
      <div class="row">
        <div class="col-md-8">
          <label>Tipo de socio:</label>
          <b-form-select 
            v-model="id_tipo_socio" 
            :options="tipos_socios">
          </b-form-select>
        </div>
        <div class="col-md-4">
          <label>Fecha:</label>
          <b-form-input 
              v-model="fecha"   
              type="date"
              placeholder="dd/mm/aaaa"
              autocomplete="off">
          </b-form-input>
        </div>      
      </div>

      <div class="pt-4">
          <b-button 
            class="mt-3 pull-left btn-danger btn-fill" 
            @click="resetNecesidadSocio()">
            Cancelar
          </b-button>
          
          <b-button 
            :disabled="fecha===''"
            class="mt-3 pull-right btn-info btn-fill" 
            @click="nuevaNecesidadSocio()">
            Agregar
          </b-button>
      </div>
    </b-modal>

    <b-modal size="lg" id="editNecesidadSocio" hide-footer>
      <div class="row">
        <div class="col-md-8">
          <label>Tipo de socio:</label>
          <b-form-select 
            v-model="id_tipo_socio" 
            :options="tipos_socios">
          </b-form-select>
        </div>

        <div class="col-md-4">
          <label>Fecha:</label>
          <b-form-input 
              v-model="fecha"   
              type="date"
              placeholder="dd/mm/aaaa"
              autocomplete="off">
          </b-form-input>
        </div>      
      </div>

      <div class="pt-4">
          <b-button 
            class="mt-3 pull-left btn-danger btn-fill" 
            @click="resetNecesidadSocio()">
            Cacelar
          </b-button>
          <b-button 
            :disabled="fecha===''"
            class="mt-3 pull-right btn-info btn-fill" 
            @click="updateNecesidadCapital(editindex)">
            Actualizar
          </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    data () {
      return {
        first_tipo_socio: null,
        id_tipo_socio: null,
        tipos_socios: [],
        
        fecha:'',

        necesidades_socio:[],

        editindex: null,
        deleteIndex: null,
        fields: [
          { key: 'tipo_socio',            label: 'Tipo de Socio',   sortable: true, thStyle: {width:'60%'} },
          { key: 'fecha',                 label: 'Fecha',           sortable: true, thStyle: {width:'20%'} },
          { key: 'actions',               label: 'Acciones',        sortable: false },
        ],
        sortBy: null,
        sortDesc: true,
        sortDirection: 'desc',
      }
    },

    methods:{
      getNecesidadesSocio(){
        this.necesidades_socio = this.$store.state.emprendimiento.necesidad_socio_emprendimiento;
      },

      async getPerfilesSocios(){
        let r = await axios.get(URL_API+'api/perfil-socio');

        var arr = [];
        var firts = true;
        var aux;

        r.data.forEach(function(element, index, rubros){
          if(firts==true){
            aux= element.id;
            firts=false;
          }
          arr.push({'value': element.id, 'text': element.nombre });
        });
        this.first_tipo_socio=aux;
        this.id_tipo_socio=aux;
        this.tipos_socios = arr;
      },

      nuevo(){
        this.getPerfilesSocios();
        this.resetNecesidadSocio();
        this.$bvModal.show('createNecesidadSocio');
      },

      nuevaNecesidadSocio(){
        var id = this.id_tipo_socio;
        var tipo_socio = this.tipos_socios.find(x => x.value == id).text;

        var nueva_necesidad_capital = {
          'id':                     this.$store.state.emprendimiento.id_necesidad_socio_emprendimiento,
          'id_tipo_socio':          this.id_tipo_socio,
          'tipo_socio':             tipo_socio,
          'fecha':  this.fecha,
        };

        this.$store.commit('addNecesidadSocio', nueva_necesidad_capital);
        this.resetNecesidadSocio();
      },

      editarNecesidadSocio(item, index, event){
        this.editindex      = item.id;
        this.id_tipo_socio  = item.id_tipo_socio;
        this.fecha          = item.fecha;

        this.$bvModal.show('editNecesidadSocio');
      },

      updateNecesidadCapital(index){
        var id = this.id_tipo_socio;
        var tipo_socio = this.tipos_socios.find(x => x.value == id).text;

        for(var i=0; i<this.$store.state.emprendimiento.necesidad_socio_emprendimiento.length; i++){

          if(this.$store.state.emprendimiento.necesidad_socio_emprendimiento[i].id === index){

            this.$store.state.emprendimiento.necesidad_socio_emprendimiento[i].id_tipo_socio         = this.id_tipo_socio;
            this.$store.state.emprendimiento.necesidad_socio_emprendimiento[i].tipo_socio            = tipo_socio;
            this.$store.state.emprendimiento.necesidad_socio_emprendimiento[i].fecha = this.fecha;

          }
        }
        this.resetNecesidadSocio();
      },

      deleteNecesidadCapital(item, index, event){
        this.deleteIndex = item.id;
        if (confirm('¿Seguro que desea eliminarlo?')) {
          this.$store.commit('deleteNecesidadSocio', this.deleteIndex);
        }
        this.resetNecesidadSocio();
      },

      resetNecesidadSocio() {
        this.id_tipo_socio = this.first_tipo_socio;
        this.id_destino_capital = this.first_destino_capital;
        this.monto_dolares = null;
        this.fecha='';

        this.getNecesidadesSocio();

        this.$bvModal.hide('createNecesidadSocio');
        this.$bvModal.hide('editNecesidadSocio');
      },
    },

    created(){
      this.getPerfilesSocios();
      this.getNecesidadesSocio();
    }
  };
</script>
<style>
.table-necesidad-socio {
  table-layout: fixed;
}

.table-necesidad-socio thead th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}


.table-necesidad-socio thead tr th{
  padding-top: .3rem;
  padding-bottom: .3rem;
}

@media screen and (min-width: 400px) {
  .table-necesidad-socio tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-necesidad-socio tbody tr td:nth-child(3) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {
  .table-necesidad-socio thead tr th:nth-child(2) {
    justify-content: center;
  }

  .table-necesidad-socio tbody tr td:nth-child(2) {
    text-align: center;
  }

  .table-necesidad-socio thead tr th:nth-child(3) {
    justify-content: center;
  }

  .table-necesidad-socio tbody tr td:nth-child(3) {
    justify-content: center;
  }
}
</style>