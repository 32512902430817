<template>
	<div>
		<h4 class="pt-3">Datos Relacionados al Emprendimiento</h4>   
    <b-card>
      <b-container>
        <b-row>
          <b-col cols="12" class="pt-4">
            <b-row sm>
              <b-col class="titulo" md="3" xs="12">
                <label>Emprendimiento:</label>
              </b-col>
              <b-col md="9" xs="12">
                {{ this.nombre }}
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12" class="pt-2":hidden="this.descripcion==null">
            <b-row>
              <b-col class="titulo" md="3" xs="12">
                <label>Descripción:</label>
              </b-col>
              <b-col md="9" xs="12">
                {{ this.descripcion }}
              </b-col>
            </b-row>
          </b-col>
          
          <b-col md="12" xs="12" class="pt-2" :hidden="this.palabras_claves.length==0">
            <b-row>
              <b-col class="titulo" md="3" xs="12">
                <label>Palabras Claves:</label>
              </b-col>
              <b-col md="9" xs="12">
                <label class="pr-1" v-for="palabra in this.palabras_claves" :key="palabra.id">
                  <b-badge class="p-2" variant="primary">
                    {{ palabra.palabra_clave }}
                  </b-badge>
                </label>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12" class="pt-2" :hidden="this.correo_emprendimiento==null">
            <b-row>
              <b-col class="titulo" md="3" xs="12">
                <label>Correo Electrónico:</label>
              </b-col>
              <b-col md="9" xs="12">
                {{ this.correo_emprendimiento }}
              </b-col>
            </b-row>
          </b-col> 

          <b-col cols="12" class="pt-2" :hidden="this.telefono_emprendimiento==null">
            <b-row>
              <b-col class="titulo" md="3" xs="12">
                <label>Teléfono:</label>
              </b-col>
              <b-col md="9" xs="12">
                {{ this.telefono_emprendimiento }}
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12" class="pt-2">
            <b-row>
              <b-col class="titulo" md="3" xs="12">
                <label>Provincia:</label>
              </b-col>
              <b-col md="9" xs="12">
                {{ this.provincia }}
              </b-col>
            </b-row>
          </b-col>   

          <b-col cols="12" class="pt-2">
            <b-row>
              <b-col class="titulo" md="3" xs="12">
                <label>Localidad:</label>
              </b-col>
              <b-col md="9" xs="12">
                {{ this.localidad }}
              </b-col>
            </b-row>
          </b-col>  
          <b-col cols="12">
            <hr width="80%">
          </b-col>

          <b-col md="6" xs="12" class="pt-2">
            <b-row>
              <b-col class="titulo" md="6" xs="12">
                <label>Perfíl:</label>
              </b-col>
              <b-col md="6" xs="12">
                {{ this.perfil }}
              </b-col>
            </b-row>
          </b-col>

          <b-col md="6" xs="12" class="pt-2">
            <b-row>
              <b-col class="titulo" md="6" xs="12">
                <label>Año de inicio:</label>
              </b-col>
              <b-col md="6" xs="12">
                {{ this.anio_inicio }}
              </b-col>
            </b-row>
          </b-col>

          <b-col md="6" xs="12" class="pt-2">
            <b-row>
              <b-col class="titulo" md="6" xs="12">
                <label>Rubro primario:</label>
              </b-col>
              <b-col md="6" xs="12">
                {{ this.rubro_principal }}
              </b-col>
            </b-row>
          </b-col>

          <b-col md="6" xs="12" class="pt-2" :hidden="this.razon_social==null">
            <b-row>
              <b-col class="titulo" md="6" xs="12">
                <label>Razón Social:</label>
              </b-col>
              <b-col md="6" xs="12">
                {{ this.razon_social }}
              </b-col>
            </b-row>
          </b-col>

          <b-col md="6" xs="12" class="pt-2" :hidden="this.rubro_secundario==null">
            <b-row>
              <b-col class="titulo" md="6" xs="12">
                <label>Rubro Secundario:</label>
              </b-col>
              <b-col md="6" xs="12">
                {{ this.rubro_secundario }}
              </b-col>
            </b-row>
          </b-col>
          
        </b-row>
      </b-container>
    </b-card>
	</div>
</template>

<script>
  export default {
    props: ['nombre_programa',
            'incubado_en', 
            'situacion_actual', 
            'nombre',
            'descripcion',
            'perfil',
            'razon_social',
            'rubro_principal',
            'anio_inicio',
            'rubro_secundario',
            'palabras_claves',
            'correo_emprendimiento',
            'telefono_emprendimiento',
            'provincia',
            'localidad']
  };
</script>
<style>
@media (min-width: 768.98px) { 
  .titulo{
    text-align: right;
  }
}
</style>