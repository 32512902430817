<template>
  <div class="content"> 
    <b-container fluid class="pt-4">
      <div class="card">
        <div class="col-md-12">
          <!-- User Interface controls -->
          <b-row>
            <b-col sm="6" class="my-4">
              <b-button class="btn btn-info btn-fill" @click="nuevoEmprendimiento"><i class="fa fa-plus"></i> Nuevo Emprendimiento</b-button>
            </b-col>
            <b-col sm="6" class="my-4">
              <b-form-group
                label-cols-sm="7"
                label-align-sm="right"
                label-size="md"
                label-for="filterInput"  
              >
              <b-form-input
                v-model="filter"
                type="search"
                id="filterInput"
                autocomplete="off"
                size="md"
                placeholder="Buscár"
              ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <!-- Main table element -->
        <div class="col-md-12 pt-4">
          <b-table
            class="table-pe"
            hover
            outlined
            stacked="md"
            head-variant="dark"
            :items="emprendimientos"
            :fields="fields"
            :current-page="currentPageEmprendimientosUNL"
            :per-page="perPageEmprendimientosUNL"
            :filter="filter"
            :filterIncludedFields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered"
          >
            <template v-slot:cell(actions)="row">
              <b-button size="sm" @click="show(row.item, row.index, $event.target)" class="btn btn-info btn-fill mr-1">
                <span class="fa fa-eye"></span>
              </b-button>

              <b-button size="sm" @click="editEmprendimiento(row.item, row.index, $event.target)" class="btn btn-warning btn-fill mr-1">
                <span class="fa fa-pencil"></span>
              </b-button>

              <b-button class="btn-danger btn-fill" size="sm" @click="deleteEmprendimiento(row.item, row.index, $event.target)">
                <span class="fa fa-trash"></span>
              </b-button>
            </template>
          </b-table>
        </div>
        <b-row>
          <b-col sm="8" md="5" class="my-1">
            <b-form-group
              label="Por pagina"
              label-cols-sm="12"
              label-cols-md="8"
              label-cols-lg="4"
              label-align-sm="right"
              label-size="sm"
              label-for="perPageSelect"
              class="mb-0"
            >
              <b-form-select
                v-model="perPageEmprendimientosUNL"
                id="perPageSelect"
                size="sm"
                :options="pageOptions"
              ></b-form-select>
            </b-form-group>
          </b-col>
          
          <b-col sm="4" md="5" class="my-1">
            <b-pagination
              v-model="currentPageEmprendimientosUNL"
              :total-rows="totalRows"
              :per-page="perPageEmprendimientosUNL"
              align="center"
              size="sm"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    data() {
      return {
        ruta: null,
        emprendimientos: [],
        editindex: null,
        deleteIndex: null,
        fields: [
          { key: 'id', label: '#', sortDirection: 'desc', sortable: true, thStyle: {width:'8%'} },
          { key: 'nombre', label: 'Emprendimiento', sortable: true, thStyle: {width:'25%'}},
          { key: 'incubado_en', label: 'Gabinete', sortable: true, thStyle: {width:'15%'}},
          
          { key: 'perfil', label: 'Perfíl', sortable: true, thStyle: {width:'15%'}},
          { key: 'condicion', label: 'Condición', sortable: true,thStyle: {width:'15%'} },
          { key: 'actions', label: 'Acciones'}
        ],
        totalRows: 99999,
        pageOptions: [10, 25, 50, 100, 200],

        sortBy: 'id',
        sortDesc: true,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
      }
    },

    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },

      perPageEmprendimientosUNL:{
        get(){
          return this.$store.state.perPage
        },
        set(value){
          this.$store.commit('setPerPage', {perPage: value})
        }
      },

      currentPageEmprendimientosUNL:{
        get(){
          return this.$store.state.currentPage
        },
        set(value){
          this.$store.commit('setCurrentPage', {currentPage: value})
        }
      }
    },

    mounted() {
      // Set the initial number of items
      window.scrollTo(0, -1);
    },

    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },

      getEmprendimientos(){
        axios.get(URL_API+'api/emprendimientosPE')
          .then( emp => {
            this.emprendimiento = emp.data.emprendimientos;
            this.emprendimientos = emp.data.emprendimientos;
            this.totalRows = this.emprendimientos.length;
            this.$Progress.finish();
          }).catch(error => {
            this.$Progress.finish();
            alert(error.response.data.message);
            this.$router.push({ name: 'EmprendimientosUNL' })
          })
      },

      show(item, index, button){
        this.$router.push( {name:'showEmprendimientoPE', params:{ id: item.id } });
      },

      editEmprendimiento(item, index, button){

        this.$store.commit('clearEmprendimiento');
        //Guardo el ID del elemento q estoy editando localmente, por si refresco 
        //en alguna de las etapas  posteriores, y activo el modo emprendimiento_edit
        this.$store.state.emprendimiento_edit=false;

        //this.$router.push({ path: 'ProgramaEmprendedores/'+item.id+'/edit/1'});
        this.$router.push( {name:'editPE1', params: { id: item.id }});
      },

      async deleteEmprendimiento(item, index, button){
        this.$Progress.start(),
        this.deleteIndex = item.id;
        if (confirm('¿Seguro que desea eliminarlo?')) {
          axios.delete(URL_API+'api/emprendimientosPE/'+this.deleteIndex)
            .then(response => {
              this.$Progress.finish();
              this.deleteIndex = null;
              this.getEmprendimientos();
            }).catch(error => {
              this.$Progress.finish();
              alert(error.response.data.message);
            });
        }
      },

      nuevoEmprendimiento(){
        this.$store.commit('clearEmprendimiento');
        this.$router.push( {name:'createPE1'});
      },

      currentRoute(){
        if(screen.width<=768){
          this.$store.commit('setTitulo', {setTitulo: 'Emprendedores'})
          this.$store.commit('setSubTitulo', {setSubTitulo: ''})
        }else{
          this.$store.commit('setTitulo', {setTitulo: 'Programa Emprendedores'})
          this.$store.commit('setSubTitulo', {setSubTitulo: ''})
        }
      }
    },

    created(){
      this.$Progress.start(),
      this.getEmprendimientos(),
      this.currentRoute(),
      this.$Progress.finish()
    }
  }
</script>

<style>
.btn-close {
  background-color: red;
}

.table-pe {
  table-layout: fixed;
}

.table-pe thead th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;

}

.table-pe thead tr th{
  padding-top: .5rem;
  padding-bottom: .5rem;
}

@media screen and (min-width: 400px) {
  .table-pe tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-pe tbody tr td:nth-child(3) {
    text-transform: capitalize;
    text-align: left;
  }
}

@media screen and (min-width: 800px) {
  .table-pe tbody tr td:nth-child(1) {
    text-align: center;
  }

  .table-pe tbody tr td:nth-child(3) {
    text-transform: capitalize;
    text-align: center;
  }

  .table-pe tbody tr td:nth-child(4) {
    text-transform: capitalize;
    text-align: center;
  }

  .table-pe tbody tr td:nth-child(5) {
    text-transform: capitalize;
    text-align: center;
  }

  .table-pe thead tr th:nth-child(6) {
    justify-content: center;
  }

  .table-pe tbody tr td:nth-child(6) {
    justify-content: center;
  }
}
</style>