<template>
  <div>
    <b-card :title="this.titulo" :sub-title="this.subtitulo" class="p-2" >
      <b-row>
          <b-col cols="12">
            <hr>
          </b-col>
      </b-row>

      <div class="d-flex justify-content-center">
        <b-col cols="12" md="4" sm="12">
          <b-form-select 
            @change="getEmprendedoresCarreras()" 
            v-model="selected" 
            :options="unidades_academicas" 
            size="sm" 
            class="p2">
          </b-form-select>
        </b-col>
      </div>
      
      <b-container>
        <b-row>
          <b-col cols="12" class="pt-4">
            <b-row>
              <b-col class="titulo pt-3 pb-4" cols="12">
                <bar-chart 
                  class="p-4"
                  :height="this.count*this.height_bar+'px'"
                  :data="this.carreras_emprendedores"
                  :stacked="false"
                  :colors="[['#009CB6']]"
                  :download="true">
                </bar-chart>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
	</div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    props: ['titulo','subtitulo', 'data_chart', 'programa'],
    data() {
      return {
        count:0,
        height_bar:0,
        selected: 'todos',
        options: [],

        loading_carreras_emprendedores:true,
        carreras_emprendedores:null,

        unidades_academicas:[
          {value:'todos',text:'Todas las UA'}
        ]
      }
    },
    methods:{
      async getUA(){
        await axios.get(URL_API+'api/unidades-academicas')
          .then(response => {
            response.data.forEach(element => {
              this.unidades_academicas.push({value:element.id, text:element.unidad_academica})  
            });
          })
          .catch(error => {
            console.log(error.response)
          })
      },

      async getEmprendedoresCarreras(){
        await axios.get(URL_API+'api/cantidadEmprendedoresCarreras/'+this.programa +'/'+this.selected)
          .then(response => {
            this.carreras_emprendedores = response.data.carreras_emprendedores;
            this.loading_carreras_emprendedores=false;
            this.count = this.carreras_emprendedores.length;
            if(this.count<=5){
              this.height_bar = 72;
            }
            if(this.count>5&&this.count<=10){
              this.height_bar = 48;
            }
            if(this.count>10){
              this.height_bar = 32;
            }
          })
          .catch(error => {
            console.log(error.response)
          })
      },
    },
    created(){
      this.getUA();
      this.getEmprendedoresCarreras();
    }
  };
</script>