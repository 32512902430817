<template>
  <div class="full-screen">
    <nav class="navbar navbar-ct-default" role="navigation-demo"  style="background: #357CA5;color: white;height: 70px;">
      <div class="container">
        <!-- Brand and toggle get grouped for better mobile display -->
        <div class="navbar-header">
          <a href="https://www.unl.edu.ar/vinculacion/"  style="color: white;font-size: 18px;">
            <img src="UNL_white_redondo.png" width="42px"> Secretaría de Vinculación y Transferencia Tecnólogica
          </a>
        </div>

        <!-- Collect the nav links, forms, and other content for toggling -->
        <div class="collapse navbar-collapse" id="navigation-example-2">
          <ul class="nav navbar-nav navbar-right">
            <li>
              <router-link :to="{path:'/'}">Home</router-link>
            </li>
          </ul>
        </div>
        <!-- /.navbar-collapse -->
      </div>
      <!-- /.container-->
    </nav>
    <b-overlay :show="!login_status" rounded="sm">
      <b-col class="wrapper wrapper-full-page section content" style="background-color: #D2D6DE;">
        <b-col class="container">
          <b-row align="center" class="p-2">
            <b-col v-if="!this.cheak_login" md="5" sm="12" class="login-box" style="padding-top:1rem;">
            
              <!-- /.login-logo -->
              <b-col class="login-box-body">
                <b-col md="12" sm="12" class="title">
                  <h4>Registro Único de Emprendimientos</h4>
                  <label><small>Universidad Nacional del Litoral</small></label>
                </b-col>

                <b-row v-if="error">
                  <b-col md="12">
                    <p style="color:red">{{ msg_error }}</p>                        
                  </b-col>
                </b-row>
                
                <b-input-group class="mb-2">
                  <b-form-input 
                    type="text" 
                    placeholder="Correo Eléctronico"
                    v-model="correo_electronico"
                    :disabled="!login_status"
                    @keyup.enter="login" 
                    @keydown.space.prevent>
                  </b-form-input>
                  <b-input-group-prepend is-text>
                    <b-icon icon="person-fill"></b-icon>
                  </b-input-group-prepend>
                </b-input-group>
                
                <b-input-group class="mb-2">
                  <b-form-input 
                    type="password" 
                    placeholder="Contraseña"
                    v-model="pass"
                    :disabled="!login_status"
                    @keyup.enter="login" 
                    @keydown.space.prevent>
                  </b-form-input>
                  <b-input-group-prepend is-text>
                    <b-icon icon="lock-fill"></b-icon>
                  </b-input-group-prepend>
                </b-input-group>

                <b-row>
                  <b-col class="pt-4">
                    <button 
                      class="btn btn-primary btn-block btn-flat p-2"
                      :disabled="!login_status"
                      @click="login">
                      Iniciar sesíon
                    </button>
                  </b-col>
                </b-row>

              </b-col>
            </b-col>
          </b-row>
        </b-col>
      </b-col>
    </b-overlay>
  </div>
</template>

<script>
  import auth from "@/logic/auth";

  export default {
    data() {
      return {
        correo_electronico: '',
        pass: '',
        error: false,
        msg_error:'Error al iniciar sesión, correo electrénico ó contraseña incorrectos',
        login_status: true,
        cheak_login: true,
      }
    },
    methods: {
      async login(){
        this.login_status=false;
        try {
          await auth.login(this.correo_electronico.replace(/\s/g, ''), this.pass)
            .then((response)=>{
              localStorage.setItem('access_token', response.data.access_token);
              localStorage.setItem('token_type', response.data.token_type);
              localStorage.setItem('expires_in', response.data.expires_in);
              localStorage.setItem('isLogged', true);
            })

          let me = await auth.me();
          localStorage.setItem('id', me.data.me.id);
          localStorage.setItem('name', me.data.me.name);
          localStorage.setItem('lastname', me.data.me.lastname);
          localStorage.setItem('email', me.data.me.email);

          localStorage.setItem('roles',JSON.stringify(me.data.roles));
          this.login_status=true;
          this.$router.push({name:'EmprendimientosUNL'});
        } catch (err) {
          this.login_status=true;
          this.error=true;
          this.pass= '';
          localStorage.setItem('isLogged', false);
        }
      },

      async cheackLogin(){
        this.cheak_login=true;
        await auth.refresh().then((response)=>{
            if(localStorage.getItem('access_token')){
              this.$router.push({name:'EmprendimientosUNL'});
            }
          }).catch((err) =>{
            this.cheak_login=false;
          })
      }
    },
    created(){
      this.cheackLogin();
    },
  }
</script>
<style>
.login-box{
    width: 420px;
    border-radius: 4px;
    border-color: teal;
    
    margin: 7% auto;
    margin-top: 7%;
    margin-right: auto;
    margin-bottom: 7%;
    margin-left: auto;
    background-color: white;
    padding: 1rem;
}

.title {
  padding: 1rem;
}
.title .p .b{
  font: 900;
  font-size: 40px;
  text-transform: uppercase;
}
</style>
