<template>
  <div>
    <h4>Formalización <small>Juridica e Impositiva</small></h4>
    <div class="card">
      <div class="col-md-12 pt-4 pb-4">
        <div class="row">
          <div class="col-md-12">
            
	          <!-- User Interface controls -->
	          <b-row>
	            <b-col sm="3" class="my-3">
                <label for="pitch">Formalización:</label>
                <b-form-select
                  v-model="FormaJuridica" 
                  :options="options" 
                  autocomplete="off">
                </b-form-select>
	            </b-col>

              <b-col sm="3" class="my-3">
                <label>Fecha:</label>
                <b-form-input 
                  v-model="FechaEstructuraJuridica" 
                  type="date" 
                  placeholder="dd/mm/aaaa"
                  :disabled="FormaJuridica=='no'">
                </b-form-input>
	            </b-col>

              <b-col sm="3" class="my-3">
                <label for="pitch">Estructura Juridica:</label>
                <b-form-select
                    v-model="EstructuraJuridica" 
                    :options="formas_juridicas" 
                    :disabled="FormaJuridica=='no'">
                  </b-form-select>
	            </b-col>

              <b-col sm="3" class="my-3">
                <label for="pitch">Otra:</label>
                  <b-form-input 
                    type="text" 
                    autocomplete="off" 
                    :disabled=" EstructuraJuridica!='-1' || FormaJuridica=='no'"
                    v-model="OtraEstructuraJuridica">
                  </b-form-input>
	            </b-col>
	          </b-row>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    name: 'FormalizacionJuridica',
    data() {
      return {
        options: [
          {'value': 'si', 'text': 'SI' },
          {'value': 'no', 'text': 'NO' }
        ],

        formas_juridicas:[],
      }
    },
    methods:{
      async getFormasJuridicas(){
        let fj = await axios.get(URL_API+'api/formas-juridicas');
        
        var arr = [];
        var first = true;
        var aux;
        fj.data.forEach(function(element, index, rubros){
          if(first==true){
            aux= element.id;
            first=false;
          }
          arr.push({'value': element.id, 'text': element.nombre });
        });
        arr.push({'value': '-1', 'text': 'Otra' });
        if(this.$store.state.emprendimiento.id_estructura_juridica==null){
          this.$store.state.emprendimiento.id_estructura_juridica= aux;
        }
        this.formas_juridicas = arr;
      }
    },
    computed: {
      FormaJuridica:{
        get(){
          return this.$store.state.emprendimiento.formalizacion_juridica
        },
        set(value){
          this.$store.commit('setFormaJuridica', {FormaJuridica: value})
        }
      },

      FechaEstructuraJuridica:{
        get(){
          return this.$store.state.emprendimiento.fecha_estructura_juridica
        },
        set(value){
          this.$store.commit('setFechaEstructiraJuridica', {FechaEstructuraJuridica: value})
        }
      },

      EstructuraJuridica:{
        get(){
          return this.$store.state.emprendimiento.id_estructura_juridica
        },
        set(value){
          this.$store.commit('setEstructiraJuridica', {EstructuraJuridica: value})
        }
      },

      OtraEstructuraJuridica:{
        get(){
          return this.$store.state.emprendimiento.otra_estructura_juridica
        },
        set(value){
          this.$store.commit('setOtraEstructiraJuridica', {OtraEstructuraJuridica: value})
        }
      },
      
    },
    created(){
      this.getFormasJuridicas();
    }
  };
</script>
