import auth from '../logic/auth.js'

import DashboardLayout from '../layout/DashboardLayout.vue'
// GeneralViews
import NotFound from '../pages/NotFoundPage.vue'
import Home from '../pages/Home/Home.vue'
import SVyTT from '../pages/SVyTT/SVyTT.vue'

// Login Page
import Login from '../pages/Login/Login.vue'

// User info Page
import EditProfile from '../pages/UserProfile/EditProfile.vue'
import EditPassword from '../pages/UserProfile/UserCard.vue'

// Admin pages
import Overview from 'src/pages/Overview.vue'

import EmprendimientosUNL from 'src/pages/EmprendimientosUNL/EmprendimientosUNL.vue'
import showEmprendimientosUNL from 'src/pages/EmprendimientosUNL/show/showEmprendimientosUNL.vue'

import IndicadoresUNL from 'src/pages/Indicadores/IndicadoresUNL.vue'

import EmprendedoresUNL from 'src/pages/EmprendedoresUNL/EmprendedoresUNL.vue'
import showEmprendedoresUNL from 'src/pages/EmprendedoresUNL/show/show.vue'

import EmprendimientosBIO from 'src/pages/ProgramaBIO/EmprendimientosBIO.vue'
import showEmprendimientoBIO from 'src/pages/ProgramaBIO/show.vue'

import createBIO1 from 'src/pages/ProgramaBIO/create/firstcreate.vue'
import createBIO2 from 'src/pages/ProgramaBIO/create/secondcreate.vue'
import createBIO3 from 'src/pages/ProgramaBIO/create/thirdcreate.vue'
import createBIO4 from 'src/pages/ProgramaBIO/create/fourthcreate.vue'
import createBIO5 from 'src/pages/ProgramaBIO/create/fifthcreate.vue'

import editBIO1 from 'src/pages/ProgramaBIO/edit/firstedit.vue'
import editBIO2 from 'src/pages/ProgramaBIO/edit/secondedit.vue'
import editBIO3 from 'src/pages/ProgramaBIO/edit/thirdedit.vue'
import editBIO4 from 'src/pages/ProgramaBIO/edit/fourthedit.vue'
import editBIO5 from 'src/pages/ProgramaBIO/edit/fifthedit.vue'

import EmprendimientosPE from 'src/pages/ProgramaEmprendedores/EmprendimientosPE.vue'
import showEmprendimientoPE from 'src/pages/ProgramaEmprendedores/show.vue'
import createPE1 from 'src/pages/ProgramaEmprendedores/create/firstcreate.vue'
import createPE2 from 'src/pages/ProgramaEmprendedores/create/secondcreate.vue'
import createPE3 from 'src/pages/ProgramaEmprendedores/create/thirdcreate.vue'
import createPE4 from 'src/pages/ProgramaEmprendedores/create/fourthcreate.vue'
import createPE5 from 'src/pages/ProgramaEmprendedores/create/fifthcreate.vue'

import editPE1 from 'src/pages/ProgramaEmprendedores/edit/firstedit.vue'
import editPE2 from 'src/pages/ProgramaEmprendedores/edit/secondedit.vue'
import editPE3 from 'src/pages/ProgramaEmprendedores/edit/thirdedit.vue'
import editPE4 from 'src/pages/ProgramaEmprendedores/edit/fourthedit.vue'
import editPE5 from 'src/pages/ProgramaEmprendedores/edit/fifthedit.vue'

import EmprendimientosGIDE from 'src/pages/ProgramaGIDE/EmprendimientosGIDE.vue'
import showEmprendimientoGIDE from 'src/pages/ProgramaGIDE/show.vue'
import createGIDE1 from 'src/pages/ProgramaGIDE/create/firstcreate.vue'
import createGIDE2 from 'src/pages/ProgramaGIDE/create/secondcreate.vue'
import createGIDE3 from 'src/pages/ProgramaGIDE/create/thirdcreate.vue'
import createGIDE4 from 'src/pages/ProgramaGIDE/create/fourthcreate.vue'
import createGIDE5 from 'src/pages/ProgramaGIDE/create/fifthcreate.vue'

import editGIDE1 from 'src/pages/ProgramaGIDE/edit/firstedit.vue'
import editGIDE2 from 'src/pages/ProgramaGIDE/edit/secondedit.vue'
import editGIDE3 from 'src/pages/ProgramaGIDE/edit/thirdedit.vue'
import editGIDE4 from 'src/pages/ProgramaGIDE/edit/fourthedit.vue'
import editGIDE5 from 'src/pages/ProgramaGIDE/edit/fifthedit.vue'

import EmprendimientosUNLPotencia from 'src/pages/ProgramaUNLPotencia/EmprendimientosUNLPotencia.vue'
import showEmprendimientoUNLPotencia from 'src/pages/ProgramaUNLPotencia/show.vue'
import createUNLPotencia1 from 'src/pages/ProgramaUNLPotencia/create/firstcreate.vue'
import createUNLPotencia2 from 'src/pages/ProgramaUNLPotencia/create/secondcreate.vue'
import createUNLPotencia3 from 'src/pages/ProgramaUNLPotencia/create/thirdcreate.vue'
import createUNLPotencia4 from 'src/pages/ProgramaUNLPotencia/create/fourthcreate.vue'
import createUNLPotencia5 from 'src/pages/ProgramaUNLPotencia/create/fifthcreate.vue'

import editUNLPotencia1 from 'src/pages/ProgramaUNLPotencia/edit/firstedit.vue'
import editUNLPotencia2 from 'src/pages/ProgramaUNLPotencia/edit/secondedit.vue'
import editUNLPotencia3 from 'src/pages/ProgramaUNLPotencia/edit/thirdedit.vue'
import editUNLPotencia4 from 'src/pages/ProgramaUNLPotencia/edit/fourthedit.vue'
import editUNLPotencia5 from 'src/pages/ProgramaUNLPotencia/edit/fifthedit.vue'

import Configuraciones from 'src/pages/Configuraciones/Configuraciones.vue'

import Rubros from 'src/pages/Configuraciones/Rubros/Rubros.vue'
import Perfiles from 'src/pages/Configuraciones/Perfiles/Perfiles.vue'
import Carreras from 'src/pages/Configuraciones/Carreras/Carreras.vue'
import TiposFinanciamiento from 'src/pages/Configuraciones/TiposFinanciamiento/TiposFinanciamiento.vue'
import FinCapital from 'src/pages/Configuraciones/FinCapital/FinCapital.vue'
import PerfilesSocio from 'src/pages/Configuraciones/PerfilesSocio/PerfilesSocio.vue'
import AsesoriaEspecial from 'src/pages/Configuraciones/AsesoriaEspecial/AsesoriaEspecial.vue'
import ServicioInternazionalizacion from 'src/pages/Configuraciones/ServicioInternazionalizacion/ServicioInternazionalizacion.vue'
import EstadosMDNyPDN from 'src/pages/Configuraciones/EstadosMDNyPDN/EstadosMDNyPDN.vue'
import SolidezEquipo from 'src/pages/Configuraciones/SolidezEquipo/SolidezEquipo.vue'
import CapacitacionesInstitucionales from 'src/pages/Configuraciones/CapacitacionesInstitucionales/CapacitacionesInstitucionales.vue'
import AsesoriasUNL from 'src/pages/Configuraciones/AsesoriasUNL/AsesoriasUNL.vue'
import Mentorias from 'src/pages/Configuraciones/Mentorias/Mentorias.vue'
import ConcursosYEventos from 'src/pages/Configuraciones/ConcursosYEventos/ConcursosYEventos.vue'
import GabineteEmprendedores from 'src/pages/Configuraciones/GabineteEmprendedores/GabineteEmprendedores.vue'
import IncubadorasGIDE from 'src/pages/Configuraciones/IncubadorasGIDE/IncubadorasGIDE.vue'
import GabineteIncubadorasUNL from 'src/pages/Configuraciones/GabineteIncubadorasUNL/GabineteIncubadorasUNL.vue'
import Usuarios from 'src/pages/Usuarios/Usuarios.vue'

import VueRouter from 'vue-router'

const URL_API = process.env.VUE_APP_URL_API;

const routes = [
  // {
  //   path: '/',
  //   component: DashboardLayout,
  //   redirect: '/rue/SVyTT'
  // },
  // {
  //   path: '/rue',
  //   component: DashboardLayout,
  //   redirect: '/rue/SVyTT'
  // },
  // {
  //   path: '/home',
  //   component: Home,
  //   name: 'home'
  // },
  { 
    path: '/',
    component: SVyTT,
    name: 'SVyTT'
  },

  { 
    path: '/rue/login',
    component: Login,
    name: 'login'
  },

  {
    path: '/rue/admin',
    component: DashboardLayout,
    redirect: '/rue/admin/EmprendimientosUNL',
    children: [
      {
        path: 'EmprendimientosUNL',
        name: 'EmprendimientosUNL',
        component: EmprendimientosUNL
      },
      {
        path: 'EmprendimientosUNL/ver/:id',
        name: 'verEmprendimientosUNL',
        component: showEmprendimientosUNL,
        props: true
      },
      {
        path: 'IndicadoresUNL',
        name: 'IndicadoresUNL',
        component: IndicadoresUNL
      },
      {
        path: 'EmprendedoresUNL',
        name: 'EmprendedoresUNL',
        component: EmprendedoresUNL
      },
      {
        path: 'EmprendedoresUNL/ver/:id',
        name: 'verEmprendedoresUNL',
        component: showEmprendedoresUNL,
        props: true
      },

      //------INDICADORES------//
      {
        path: 'overview',
        name: 'Overview',
        component: Overview
      },
      

      //------EDIT INFO USER------//
      {
        path: 'editProfile',
        name: 'editProfile',
        component: EditProfile
      },

      //------EDIT INFO USER------//
      {
        path: 'editPassword',
        name: 'editPassword',
        component: EditPassword
      },

      //-----------------------//
      //-----PROGRAMA BIO------//
      //-----------------------//

      {
        path: 'ProgramaBIO',
        name: 'ProgramaBIO',
        component: EmprendimientosBIO
      },

      {
        path: 'ProgramaBIO/ver/:id',
        name: 'showEmprendimientoBIO',
        component: showEmprendimientoBIO,
        props: true
      },
      //--------CREATE-----------//
      {
        path: 'ProgramaBIO/nuevo/1',
        name: 'createBIO1',
        component: createBIO1
      },
      {
        path: 'ProgramaBIO/nuevo/2',
        name: 'createBIO2',
        component: createBIO2,
        props: true
      },
      {
        path: 'ProgramaBIO/nuevo/3',
        name: 'createBIO3',
        component: createBIO3,
        props: true
      },
      {
        path: 'ProgramaBIO/nuevo/4',
        name: 'createBIO4',
        component: createBIO4,
        props: true
      },
      {
        path: 'ProgramaBIO/nuevo/5',
        name: 'createBIO5',
        component: createBIO5,
        props: true
      },
      //--------EDIT----------//
      {
        path: 'ProgramaBIO/:id/edit/1',
        name: 'editBIO1',
        component: editBIO1,
        props: true
      },
      {
        path: 'ProgramaBIO/:id/edit/2',
        name: 'editBIO2',
        component: editBIO2,
        props: true
      },
      {
        path: 'ProgramaBIO/:id/edit/3',
        name: 'editBIO3',
        component: editBIO3,
        props: true
      },
      {
        path: 'ProgramaBIO/:id/edit/4',
        name: 'editBIO4',
        component: editBIO4,
        props: true
      },
      {
        path: 'ProgramaBIO/:id/edit/5',
        name: 'editBIO5',
        component: editBIO5,
        props: true
      },

      //-----------------------//
      //-----EMPRENDEDORES-----//
      //-----------------------//

      {
        path: 'ProgramaEmprendedores',
        name: 'EmprendimientosPE',
        component: EmprendimientosPE
      },

      {
        path: 'ProgramaEmprendedores/ver/:id',
        name: 'showEmprendimientoPE',
        component: showEmprendimientoPE,
        props: true
      },
      
      {
        path: 'ProgramaEmprendedores/nuevo/1',
        name: 'createPE1',
        component: createPE1
      },
      {
        path: 'ProgramaEmprendedores/nuevo/2',
        name: 'createPE2',
        component: createPE2,
        props: true
      },
      {
        path: 'ProgramaEmprendedores/nuevo/3',
        name: 'createPE3',
        component: createPE3,
        props: true
      },
      {
        path: 'ProgramaEmprendedores/nuevo/4',
        name: 'createPE4',
        component: createPE4,
        props: true
      },
      {
        path: 'ProgramaEmprendedores/nuevo/5',
        name: 'createPE5',
        component: createPE5,
        props: true
      },

      //--------EDIT----------//
      {
        path: 'ProgramaEmprendedores/:id/edit/1',
        name: 'editPE1',
        component: editPE1,
        props: true
      },
      {
        path: 'ProgramaEmprendedores/:id/edit/2',
        name: 'editPE2',
        component: editPE2,
        props: true
      },
      {
        path: 'ProgramaEmprendedores/:id/edit/3',
        name: 'editPE3',
        component: editPE3,
        props: true
      },
      {
        path: 'ProgramaEmprendedores/:id/edit/4',
        name: 'editPE4',
        component: editPE4,
        props: true
      },
      {
        path: 'ProgramaEmprendedores/:id/edit/5',
        name: 'editPE5',
        component: editPE5,
        props: true
      },

      //-----------------------//
      //-----PROGRAMA GIDE-----//
      //-----------------------//

      {
        path: 'ProgramaGIDE',
        name: 'EmprendimientosGIDE',
        component: EmprendimientosGIDE
      },

      {
        path: 'ProgramaGIDE/ver/:id',
        name: 'showEmprendimientoGIDE',
        component: showEmprendimientoGIDE,
        props: true
      },
      
      {
        path: 'ProgramaGIDE/nuevo/1',
        name: 'createGIDE1',
        component: createGIDE1
      },
      {
        path: 'ProgramaGIDE/nuevo/2',
        name: 'createGIDE2',
        component: createGIDE2,
        props: true
      },
      {
        path: 'ProgramaGIDE/nuevo/3',
        name: 'createGIDE3',
        component: createGIDE3,
        props: true
      },
      {
        path: 'ProgramaGIDE/nuevo/4',
        name: 'createGIDE4',
        component: createGIDE4,
        props: true
      },
      {
        path: 'ProgramaGIDE/nuevo/5',
        name: 'createGIDE5',
        component: createGIDE5,
        props: true
      },

      //--------EDIT----------//
      {
        path: 'ProgramaGIDE/:id/edit/1',
        name: 'editGIDE1',
        component: editGIDE1,
        props: true
      },
      {
        path: 'ProgramaGIDE/:id/edit/2',
        name: 'editGIDE2',
        component: editGIDE2,
        props: true
      },
      {
        path: 'ProgramaGIDE/:id/edit/3',
        name: 'editGIDE3',
        component: editGIDE3,
        props: true
      },
      {
        path: 'ProgramaGIDE/:id/edit/4',
        name: 'editGIDE4',
        component: editGIDE4,
        props: true
      },
      {
        path: 'ProgramaGIDE/:id/edit/5',
        name: 'editGIDE5',
        component: editGIDE5,
        props: true
      },

      //-----------------------//
      //-PROGRAMA UNLPotencia--//
      //-----------------------//

      {
        path: 'ProgramaUNLPotencia',
        name: 'EmprendimientosUNLPotencia',
        component: EmprendimientosUNLPotencia
      },

      {
        path: 'ProgramaUNLPotencia/ver/:id',
        name: 'showEmprendimientoUNLPotencia',
        component: showEmprendimientoUNLPotencia,
        props: true
      },
      
      {
        path: 'ProgramaUNLPotencia/nuevo/1',
        name: 'createUNLPotencia1',
        component: createUNLPotencia1
      },
      {
        path: 'ProgramaUNLPotencia/nuevo/2',
        name: 'createUNLPotencia2',
        component: createUNLPotencia2,
        props: true
      },
      {
        path: 'ProgramaUNLPotencia/nuevo/3',
        name: 'createUNLPotencia3',
        component: createUNLPotencia3,
        props: true
      },
      {
        path: 'ProgramaUNLPotencia/nuevo/4',
        name: 'createUNLPotencia4',
        component: createUNLPotencia4,
        props: true
      },
      {
        path: 'ProgramaUNLPotencia/nuevo/5',
        name: 'createUNLPotencia5',
        component: createUNLPotencia5,
        props: true
      },

      //--------EDIT----------//
      {
        path: 'ProgramaUNLPotencia/:id/edit/1',
        name: 'editUNLPotencia1',
        component: editUNLPotencia1,
        props: true
      },
      {
        path: 'ProgramaUNLPotencia/:id/edit/2',
        name: 'editUNLPotencia2',
        component: editUNLPotencia2,
        props: true
      },
      {
        path: 'ProgramaUNLPotencia/:id/edit/3',
        name: 'editUNLPotencia3',
        component: editUNLPotencia3,
        props: true
      },
      {
        path: 'ProgramaUNLPotencia/:id/edit/4',
        name: 'editUNLPotencia4',
        component: editUNLPotencia4,
        props: true
      },
      {
        path: 'ProgramaUNLPotencia/:id/edit/5',
        name: 'editUNLPotencia5',
        component: editUNLPotencia5,
        props: true
      },

      //-----------------------//
      //----Configuraciones----//
      //-----------------------//
      {
        path: 'Configuraciones',
        name: 'Configuraciones',
        component: Configuraciones
      },

      //-----------------------//
      //--------RUBROS---------//
      //-----------------------//
      {
        path: 'Configuraciones/Rubros',
        name: 'Rubros',
        component: Rubros
      },

      //-----------------------//
      //-------PERFILES--------//
      //-----------------------//
      {
        path: 'Configuraciones/Perfiles',
        name: 'Perfiles',
        component: Perfiles
      },

      //-----------------------//
      //--------Carreras-------//
      //-----------------------//
      {
        path: 'Configuraciones/Carreras',
        name: 'Carreras',
        component: Carreras
      },

      //-------------------------//
      //-Tipos de Financiamiento-//
      //-------------------------//
      {
        path: 'Configuraciones/TiposFinanciamiento',
        name: 'TiposFinanciamiento',
        component: TiposFinanciamiento
      },

      //-------------------------//
      //-----Fin del Capiral-----//
      //-------------------------//
      {
        path: 'Configuraciones/FinCapital',
        name: 'FinCapital',
        component: FinCapital
      },

      //-------------------------//
      //-----Perfiles Socios-----//
      //-------------------------//
      {
        path: 'Configuraciones/PerfilesSocio',
        name: 'PerfilesSocio',
        component: PerfilesSocio
      },

      //-------------------------//
      //---Asesorias Especiales--//
      //-------------------------//
      {
        path: 'Configuraciones/AsesoriaEspecial',
        name: 'AsesoriaEspecial',
        component: AsesoriaEspecial
      },

      //-------------------------//
      //---Internacionalizacion--//
      //-------------------------//
      {
        path: 'Configuraciones/ServicioInternazionalizacion',
        name: 'ServicioInternazionalizacion',
        component: ServicioInternazionalizacion
      },

      //-------------------------//
      //----------MDN------------//
      //-------------------------//
      {
        path: 'Configuraciones/EstadosMDNyPDN',
        name: 'EstadosMDNyPDN',
        component: EstadosMDNyPDN
      },

      //-------------------------//
      //-----Solidez Equipo------//
      //-------------------------//
      {
        path: 'Configuraciones/SolidezEquipo',
        name: 'SolidezEquipo',
        component: SolidezEquipo
      },

      //--------------------------------//
      //-Capacitaciones Institucionales-//
      //--------------------------------//
      {
        path: 'Configuraciones/CapacitacionesInstitucionales',
        name: 'CapacitacionesInstitucionales',
        component: CapacitacionesInstitucionales
      },

      //--------------------------------//
      //--------AsesoriasUNL---------------//
      //--------------------------------//
      {
        path: 'Configuraciones/AsesoriasUNL',
        name: 'AsesoriasUNL',
        component: AsesoriasUNL
      },

      //--------------------------------//
      //--------Mentorias---------------//
      //--------------------------------//
      {
        path: 'Configuraciones/Mentorias',
        name: 'Mentorias',
        component: Mentorias
      },

      //--------------------------------//
      //------Concursos Y Eventos-------//
      //--------------------------------//
      {
        path: 'Configuraciones/ConcursosYEventos',
        name: 'ConcursosYEventos',
        component: ConcursosYEventos
      },

      //--------------------------------//
      //---GABINETES INCUBADORAS UNL----//
      //--------------------------------//
      {
        path: 'Configuraciones/GabineteIncubadorasUNL',
        name: 'GabineteIncubadorasUNL',
        component: GabineteIncubadorasUNL
      },

      //--------------------------------//
      //---GABINETES INCUBADORAS UNL----//
      //--------------------------------//
      {
        path: 'Usuarios',
        name: 'Usuarios',
        component: Usuarios
      }
    ]
  },
  { path: '*', component: NotFound }
]

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  mode: 'history',
  linkActiveClass: 'nav-item active',
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }
  }
})

// GOOD
router.beforeEach((to, from, next) => {
  if (  to.name !== 'login' &&
        to.path !== '/' &&
        to.name !== 'SVyTT' &&
        !localStorage.getItem('isLogged')
      ){
    next({ name: 'login' })
  }

  //Controlo que el usuario tenga el rol necesario antes de cargar
  //la pagina a la que esta intentando acceder

  let roles = JSON.parse(localStorage.getItem('roles'));
  let has_role = false;
  switch (to.name) {
    case 'ProgramaBIO':
      roles.forEach(element => {
        if(element.rol=='programa_unlbio'){
          has_role = true;
        }
      });
      if(!has_role){
        next({ name: 'EmprendimientosUNL' });
      }
      break;
    case 'EmprendimientosPE':
      roles.forEach(element => {
        if(element.rol=='programa_emprendedores'){
          has_role = true;
        }
      });
      if(!has_role){
        next({ name: 'EmprendimientosUNL' });
      }
      break;
    case 'EmprendimientosGIDE':
      roles.forEach(element => {
        if(element.rol=='programa_gide'){
          has_role = true;
        }
      });
      if(!has_role){
        next({ name: 'EmprendimientosUNL' });
      }
      break;
    case 'Configuraciones':
      roles.forEach(element => {
        if(element.rol=='configuraciones'){
          has_role = true;
        }
      });
      if(!has_role){
        next({ name: 'EmprendimientosUNL' });
      }
      break;
    case 'Usuarios':
      roles.forEach(element => {
        if(element.rol=='gestion_usuarios'){
          has_role = true;
        }
      });
      if(!has_role){
        next({ name: 'EmprendimientosUNL' });
      }
      break;
  }

  //Chequeo que para acceder a cualquier parametro de configuracion
  //el usuario tenga el rol configuraciones
  if(to.path.indexOf('Configuraciones')!=-1){
    roles.forEach(element => {
      if(element.rol=='configuraciones'){
        has_role=true;
      }
    });
    if(!has_role){
      next({ name: 'EmprendimientosUNL' });
    }
  }
  next();
})

const guard = function(to, from, next) {
  // check for valid auth token
  /*
  axios.get(URL_API+'api/auth/me').then(response => {
      // Token is valid, so continue
      next();
  }).catch(error => {
      // There was an error so redirect
      window.location.href = "/login";
  })
  */
};

export default router