<template>
	<div>
		<h4>Pitch del Emprendimiento</h4>
    <b-card>
      <b-container>
        <b-row>
          <b-col cols="12" class="pt-2">
            <b-row>
              <b-col class="titulo" md="3" xs="12">
                <label>Video Pitch:</label>
              </b-col>
              <b-col md="9" xs="12">
                <a :href="this.url_pitch" target="_blank">{{ this.url_pitch }}</a>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
	</div>
</template>

<script>
  export default {
    props: ['url_pitch'],
  };
</script>