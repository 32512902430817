<template>
  <div>
    <div class="col-md-12">
      <h5>Perfíl del equipo emprendedor</h5>
    </div>

    <div class="col-md-12">
      <!-- User Interface controls -->
      <b-row>
        <b-col sm="12" class="my-3">
          <b-button 
            class="btn btn-info btn-fill" 
            @click="nuevo()">
            <i class="fa fa-plus"></i> 
              Agregar
          </b-button>
        </b-col>
      </b-row>
    </div>

    <!-- Main table element -->
    <div class="col-md-12 pb-4">
      <b-table
        class="table-estados-solidez-emprendimiento"
        hover
        stacked="md"
        head-variant="dark"
        :items="estados_perfiles_emprendimiento"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
      >
        <template v-slot:cell(actions)="row">
          <b-button size="sm" @click="editEstadoPerfil(row.item, row.index, $event.target)" class="btn btn-warning btn-fill mr-1">
          <span class="fa fa-pencil"></span>
          </b-button>

          <b-button class="btn-danger btn-fill" size="sm" @click="deleteMentoria(row.item, row.index, $event.target)">
          <span class="fa fa-trash"></span>
          </b-button>
        </template>
      </b-table>
    </div>

    <b-modal size="md" id="createEstadoPerfil" hide-footer>
      <div class="row">
        <div class="col-md-8">
          <label>Perfíl del equipo emprendedor:</label>
          <b-form-select 
            v-model="estado_perfil" 
            :options="estados_perfiles">
          </b-form-select>
        </div>

        <div class="col-md-4">
          <label>Año:</label>
            <b-form-select 
              v-model="anio"
              :options="anios">
            </b-form-select>
        </div>   
      </div>

      <div class="pt-4">
          <b-button 
            class="mt-3 pull-left btn-danger btn-fill" 
            @click="resetEstadoPerfil()">
            Cancelar
          </b-button>
          
          <b-button
            class="mt-3 pull-right btn-info btn-fill" 
            @click="nuevaEstadoPerfil()">
            Agregar
          </b-button>
      </div>
    </b-modal>

    <b-modal size="md" id="editEstadoPerfil" hide-footer>
      <div class="row">
        <div class="col-md-8">
          <label>Perfíl del equipo emprendedor:</label>
          <b-form-select 
            v-model="estado_perfil_edit" 
            :options="estados_perfiles">
          </b-form-select>
        </div>

        <div class="col-md-4">
          <label>Año:</label>
            <b-form-select 
              v-model="anio_edit"
              :options="anios">
            </b-form-select>
        </div>   
      </div>
      <div class="pt-4">
          <b-button 
            class="mt-3 pull-left btn-danger btn-fill" 
            @click="resetEstadoPerfil()">
              Cacelar
          </b-button>
          
          <b-button 
            class="mt-3 pull-right btn-info btn-fill" 
            @click="updateMentoria(editindex)">
              Actualizar
          </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    data () {
      return {
        estado_perfil: 'En construcción',
        estado_perfil_edit: '',

        estados_perfiles: [
          {value:'En construcción',text:'En construcción'},
          {value:'Finalizado',text:'Finalizado'}
        ],

        anio: null,
        anio_edit: null,
        anios: [],
        
        estados_perfiles_emprendimiento:[],

        editindex: null,
        deleteIndex: null,
        fields: [
          { key: 'estado_perfil', label: 'Estado Perfíl del Equipo',  sortable: true, thStyle: {width:'60%'}  },
          { key: 'anio',          label: 'Año',                       sortable: true, thStyle: {width:'20%'}  },
          { key: 'actions',       label: 'Acciones',                  sortable: false},
        ],
        sortBy: 'anio',
        sortDesc: true,
        sortDirection: 'desc',
      }
    },

    methods:{
      getEstadosPerfilEmprendimiento(){
        this.estados_perfiles_emprendimiento = this.$store.state.emprendimiento.estado_perfil_equipo_emprendedor;
      },

      nuevo(){
        this.resetEstadoPerfil();
        this.$bvModal.show('createEstadoPerfil');
      },

      nuevaEstadoPerfil(){
        var nueva_estado = {
          'id':             this.$store.state.emprendimiento.id_estado_perfil_equipo_emprendedor,
          'estado_perfil':  this.estado_perfil,
          'anio':  this.anio,
        };

        this.$store.commit('addEstadoPerfilEmprendimiento', nueva_estado);
        this.resetEstadoPerfil();
      },

      editEstadoPerfil(item, index, event){
        this.editindex = item.id; 
        this.estado_perfil_edit = item.estado_perfil,
        this.anio_edit = item.anio,
        this.$bvModal.show('editEstadoPerfil');
      },

      updateMentoria(index){
        for(var i=0; i<this.$store.state.emprendimiento.estado_perfil_equipo_emprendedor.length; i++){
          if(this.$store.state.emprendimiento.estado_perfil_equipo_emprendedor[i].id === index){
            this.$store.state.emprendimiento.estado_perfil_equipo_emprendedor[i].estado_perfil = this.estado_perfil_edit;
            this.$store.state.emprendimiento.estado_perfil_equipo_emprendedor[i].anio = this.anio_edit;
          }
        }
        this.resetEstadoPerfil();
      },

      deleteMentoria(item, index, event){
        this.deleteIndex = item.id; 
        if (confirm('¿Seguro que desea eliminarlo?')) {
          this.$store.commit('deleteEstadoPerfilEmprendimiento', this.deleteIndex);
        }
        this.resetEstadoPerfil();
      },

      resetEstadoPerfil() {
        this.id_mentoria = this.first_mentoria;
        var today = new Date();
        this.anio = today.getFullYear();
        this.estado_perfil='En construcción';

        this.getEstadosPerfilEmprendimiento();
        
        this.$bvModal.hide('createEstadoPerfil');
        this.$bvModal.hide('editEstadoPerfil');
      },

      getAnios(){
        var today = new Date();
        this.anio = today.getFullYear();

        var arr = [];
        for (var i = this.anio; i >= 2000; i--) {
          arr.push({'value': i, 'text': i });
        }
        this.anios = arr;
      },
    },

    created(){
      this.getEstadosPerfilEmprendimiento();
      this.getAnios();
    }
  };
</script>
<style>
.table-estados-solidez-emprendimiento {
  table-layout: fixed;
}

.table-estados-solidez-emprendimiento thead th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}


.table-estados-solidez-emprendimiento thead tr th{
  padding-top: .3rem;
  padding-bottom: .3rem;
}

@media screen and (min-width: 400px) {
  .table-estados-solidez-emprendimiento tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-estados-solidez-emprendimiento tbody tr td:nth-child(3) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {
  .table-estados-solidez-emprendimiento tbody tr td:nth-child(1) {
    text-align: center;
  }

  .table-estados-solidez-emprendimiento tbody tr td:nth-child(1) {
    text-align: center;
  }

  .table-estados-solidez-emprendimiento thead tr th:nth-child(2) {
    justify-content: center;
  }

  .table-estados-solidez-emprendimiento tbody tr td:nth-child(2) {
    text-align: center;
  }

  .table-estados-solidez-emprendimiento thead tr th:nth-child(3) {
    justify-content: center;
  }

  .table-estados-solidez-emprendimiento tbody tr td:nth-child(3) {
    justify-content: center;
  }
}
</style>