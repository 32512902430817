<template>
  <div>
    <h4>Histórico de Modelo de Negocio</h4>
    <div class="card">
      <div class="col-md-12 pt-4 pb-4">
        <div class="row">
          <div class="col-md-12">
            
          <!-- User Interface controls -->
          <b-row>
            <b-col sm="12" class="my-3">
              <b-button 
                class="btn btn-info btn-fill" 
                @click="nuevo()"><i class="fa fa-plus"></i> 
                  Agregar
              </b-button>
            </b-col>
          </b-row>
          </div>
          <!-- Main table element -->
          <div class="col-md-12 pb-4">
            <b-table
              class="table-registro-mdn"
              hover
              stacked="md"
              head-variant="dark"
              :items="registros_mdn"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
            >
              <template v-slot:cell(fecha)="row">
                {{ row.item.fecha.toString().split("-").reverse().join("/") }}
              </template>

              <template v-slot:cell(actions)="row">
                <b-button 
                  size="sm" 
                  @click="editarEstadoMDN(row.item, row.index, $event.target)" 
                  class="btn btn-warning btn-fill mr-1">
                  <span class="fa fa-pencil"></span>
                </b-button>

                <b-button 
                  class="btn-danger btn-fill" 
                  size="sm" 
                  @click="deleteMDN(row.item, row.index, $event.target)">
                  <span class="fa fa-trash"></span>
                </b-button>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <b-modal size="lg" id="createMDN" hide-footer>
      <div class="row">
        <div class="col-md-8">
          Estado MDN:
          <b-form-select 
            v-model="id_estado_mdn" 
            :options="estados_mdn">
          </b-form-select>
        </div>

        <div class="col-md-4">
          Fecha:
          <b-form-input 
              v-model="fecha"   
              type="date"
              placeholder="dd/mm/aaaa"
              autocomplete="off">
          </b-form-input>
        </div>      
      </div>

      <div class="pt-4">
          <b-button 
            class="mt-3 pull-left btn-danger btn-fill" 
            @click="resetModalMDN">Cancelar
          </b-button>
          
          <b-button 
            :disabled="fecha===''"
            class="mt-3 pull-right btn-info btn-fill" 
            @click="nuevoEstadoMDN">Agregar
          </b-button>
      </div>
    </b-modal>

    <b-modal size="lg" id="editMDN" title="Editar MDN" hide-footer>
      <div class="row">
        <div class="col-md-8 pt-3">
          Estado MDN:
          <b-form-select 
            v-model="id_estado_mdn" 
            :options="estados_mdn">
          </b-form-select>
        </div>

        <div class="col-md-4 pt-3">
          Fecha:
          <b-form-input
            v-model="fecha"   
            type="date" 
            placeholder="dd/mm/aaaa">
          </b-form-input>
        </div>
      </div>  
      <div class="pt-4">
        <b-button 
          class="mt-3 pull-left btn-danger btn-fill" 
          @click="resetModalMDN">
          Cacelar
        </b-button>
        <b-button 
          class="mt-3 pull-right btn-info btn-fill" 
          @click="updateMDN(editindex)">
          Actualizar
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    data () {
      return {
        first_estado_mdn: null,
        id_estado_mdn: null,
        estados_mdn: [],

        fecha: '',

        registros_mdn: [],

        editindex: null,
        deleteIndex: null,
        fields: [
          { key: 'estado_mdn',  label: 'Estado',    sortable: true, thStyle: {width:'60%'}},
          { key: 'fecha',       label: 'Fecha',     sortable: true },
          { key: 'actions',     label: 'Acciones',  sortable: false},
        ],
        sortBy: null,
        sortDesc: true,
        sortDirection: 'desc',
      }
    },

    methods:{
      async getEstadosMDN(){
        let r = await axios.get(URL_API+'api/estados-mdn');

        var arr = [];
        var first = true;
        var aux;

        r.data.forEach(function(element, index){
          if(first==true){
            aux = element.id;
            first = false;
          }
          arr.push({'value': element.id, 'text': element.nombre });
        });
        
        this.first_estado_mdn = aux;
        this.id_estado_mdn = aux;
        this.estados_mdn = arr;
      },

      getRegistrosMDN(){
        this.registros_mdn = this.$store.state.emprendimiento.registros_mdn;
      },

      nuevo(){
        this.getEstadosMDN();
        this.$bvModal.show('createMDN');
      },

      nuevoEstadoMDN(){
        var id = this.id_estado_mdn;
        var estado_mdn = this.estados_mdn.find(x => x.value == id).text;

        var nuevo_registro_mdn = {
          'id':               this.$store.state.emprendimiento.id_registros_mdn,
          'id_estado_mdn':    this.id_estado_mdn,
          'estado_mdn':       estado_mdn,
          'fecha': this.fecha
        };

        this.$store.commit('addRegistroMDN', nuevo_registro_mdn);
        this.resetModalMDN();
      },

      editarEstadoMDN(item, index, event){
        this.editindex = item.id; 
        this.id_estado_mdn = item.id_estado_mdn,
        this.fecha = item.fecha,
        this.$bvModal.show('editMDN');
      },

      updateMDN(index){
        var id = this.id_estado_mdn;
        var estado_mdn = this.estados_mdn.find(x => x.value == id).text;

        for(var i=0; i<this.$store.state.emprendimiento.registros_mdn.length; i++){
          if(this.$store.state.emprendimiento.registros_mdn[i].id === index){

            this.$store.state.emprendimiento.registros_mdn[i].id_estado_mdn = this.id_estado_mdn;
            this.$store.state.emprendimiento.registros_mdn[i].estado_mdn = estado_mdn;
            this.$store.state.emprendimiento.registros_mdn[i].fecha = this.fecha;
          }
        }
        this.resetModalMDN();
      },

      deleteMDN(item, index, event){
        this.deleteIndex = item.id;
        if (confirm('¿Seguro que desea eliminarlo?')) {
          this.$store.commit('deleteRegistroMDN', this.deleteIndex);
        }
        this.resetModalMDN();
      },

      resetModalMDN() {
        this.id_estado_mdn = this.first_estado_mdn;
        this.fecha = '';
        this.getRegistrosMDN();
        this.$bvModal.hide('createMDN');
        this.$bvModal.hide('editMDN');
      },
    },

    created(){
      this.getEstadosMDN();
      this.getRegistrosMDN();
    }
  };
</script>
<style>
.table-registro-mdn {
  table-layout: fixed;
}

.table-registro-mdn thead th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}


.table-registro-mdn thead tr th{
  padding-top: .3rem;
  padding-bottom: .3rem;
}

@media screen and (min-width: 400px) {
  .table-registro-mdn tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-registro-mdn tbody tr td:nth-child(3) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {
  .table-registro-mdn tbody tr td:nth-child(1) {
    text-align: center;
  }

  .table-registro-mdn tbody tr td:nth-child(1) {
    text-align: center;
  }

  .table-registro-mdn thead tr th:nth-child(2) {
    justify-content: center;
  }

  .table-registro-mdn tbody tr td:nth-child(2) {
    text-align: center;
  }

  .table-registro-mdn thead tr th:nth-child(3) {
    justify-content: center;
  }

  .table-registro-mdn tbody tr td:nth-child(3) {
    justify-content: center;
  }
}
</style>