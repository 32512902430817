<template>
	<div>
		<h4 class="pt-3">Redes Internacionales</h4>   
    <b-card>
      <b-table
          class="table-cooperacion-internacional-emprendimiento"
          hover
          stacked="md"
          head-variant="dark"
          :items="this.redes_internacionales"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
        >
          <template v-slot:cell(actions)="row">
            <b-button 
              size="sm" 
              @click="viewRed(row.item, row.index, $event.target)" 
              class="btn btn-info btn-fill mr-1">
                <span class="fa fa-eye"></span>
            </b-button>
          </template>
        </b-table>
    </b-card>

    <b-modal id="viewModalRed"  size="lg" title="Red Internacional" hide-footer>
      <b-col cols="12" class="pt-1">
        <b-row>
          <b-col class="titulo" md="3" xs="12">
            <b>Red:</b>
          </b-col>
          <b-col md="9" xs="12">
            {{ this.red_internacional.red }}
          </b-col>
        </b-row>

        <b-row>
          <b-col class="titulo" md="3" xs="12">
            <b>Año:</b>
          </b-col>
          <b-col md="9" xs="12">
            {{ this.red_internacional.anio }}
          </b-col>
        </b-row>

          <template v-if="this.red_internacional.descripcion !== ''">
            <b-row>
              <b-col class="titulo" md="3" xs="12">
                <b>Descripción:</b>
              </b-col>
              <b-col md="9" xs="12">
                {{ this.red_internacional.descripcion }}
              </b-col>
            </b-row>
        </template>

        <b-row class="pt-4">
          <b-button class="mt-3 pull-left btn-danger btn-fill" @click="closeModal">Cerrar</b-button>
        </b-row>
      </b-col>
    </b-modal>
	</div>
</template>

<script>
  export default {
    props: ['redes_internacionales'],
    data() {
      return {
        red_internacional:{
          red:null,
          anio: null,
          descripcion: null
        },
        viewindex: null,
        fields: [
          { key: 'red',           label: 'Red',    sortable: true, thStyle: {width:'80%'} },
          { key: 'anio',          label: 'Año',         sortable: true},
          { key: 'actions',       label: 'Vér' }
        ],
        sortBy: 'anio',
        sortDesc: true,
        sortDirection: 'desc',
      }
    },
    methods: {
      viewRed(item, index, button) {
        this.red_internacional.red = item.red;
        this.red_internacional.institucion = item.institucion;
        this.red_internacional.anio = item.anio;
        this.red_internacional.descripcion = item.descripcion;

        this.$root.$emit('bv::show::modal', 'viewModalRed', button);
      },

      closeModal(item, index, button){
        this.$root.$emit('bv::hide::modal', 'viewModalRed', button)
      }
    }
  };
</script>
<style>
.table-cooperacion-internacional-emprendimiento thead tr th{
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.table-cooperacion-internacional-emprendimiento thead tr th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 400px) {
  .table-cooperacion-internacional-emprendimiento tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-cooperacion-internacional-emprendimiento tbody tr td:nth-child(2) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {

  .table-cooperacion-internacional-emprendimiento thead tr th:nth-child(2) {
    justify-content: center;
  }

  .table-cooperacion-internacional-emprendimiento tbody tr td:nth-child(2) {
    text-align: center;
  }

  .table-cooperacion-internacional-emprendimiento thead tr th:nth-child(3) {
    justify-content: center;
  }

  .table-cooperacion-internacional-emprendimiento tbody tr td:nth-child(3) {
    justify-content: center;
  }

}
</style>