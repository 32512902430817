<template>
  <div>
    <h4>Capacitaciones Institucionales</h4>
    <div class="card">
      <div class="col-md-12 pt-4 pb-4">
        <div class="row">
          <div class="col-md-12">
          <!-- User Interface controls -->
          <b-row>
            <b-col sm="12" class="my-3">
              <b-button 
                class="btn btn-info btn-fill" 
                @click="nuevo()">
                <i class="fa fa-plus"></i> 
                  Agregar
              </b-button>
            </b-col>
          </b-row>
          </div>
          <!-- Main table element -->
          <div class="col-md-12 pb-4">
            <b-table
              class="table-capacitaciones-emprendimiento"
              hover
              stacked="md"
              head-variant="dark"
              :items="capacitaciones_emprendimiento"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
            >
              <template v-slot:cell(actions)="row">
                <b-button size="sm" @click="editCapacitacion(row.item, row.index, $event.target)" class="btn btn-warning btn-fill mr-1">
                <span class="fa fa-pencil"></span>
                </b-button>

                <b-button class="btn-danger btn-fill" size="sm" @click="deleteCapacitacion(row.item, row.index, $event.target)">
                <span class="fa fa-trash"></span>
                </b-button>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

  <b-modal size="lg" id="createCapacitacion" hide-footer>
    <div class="row"> 
      <div class="col-md-10">
        <label>Capacitación:</label>
        <b-form-select 
          v-model="id_capacitacion" 
          :options="capacitaciones_institucionales">
        </b-form-select>
      </div>  
      <div class="col-md-2">
        <label>Año:</label>
          <b-form-select 
            v-model="anio"
            :options="anios">
          </b-form-select>
      </div>   
    </div>

    <div class="pt-4">
        <b-button 
          class="mt-3 pull-left btn-danger btn-fill" 
          @click="resetCapacitacion">Cancelar
        </b-button>
        
        <b-button 
          class="mt-3 pull-right btn-info btn-fill" 
          @click="addCapacitacion">Agregar
        </b-button>
    </div>
  </b-modal>

  <b-modal size="lg" id="editCapacitacion" hide-footer>
    <div class="row">
      <div class="col-md-10 pt-3">
        <label>Capacitación:</label> 
        <b-form-select 
          v-model="id_capacitacion_edit" 
          :options="capacitaciones_institucionales">
        </b-form-select>
      </div>

      <div class="col-md-2 pt-3">
        <label>Año:</label>
          <b-form-select 
            v-model="anio_edit"
            :options="anios">
          </b-form-select>
      </div> 
    </div>  
    <div class="pt-4">
        <b-button class="mt-3 pull-left btn-danger btn-fill" @click="resetCapacitacion">Cacelar</b-button>
        <b-button class="mt-3 pull-right btn-info btn-fill" @click="updateCapacitacion(editindex)">Actualizar</b-button>
    </div>
  </b-modal>
</div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    data () {
      return {
        first_capacitacion: null,
        id_capacitacion: null,
        capacitaciones_institucionales: [],

        fecha_estado_mdn: '',

        capacitaciones_emprendimiento: [],

        anio: null,
        anios: [],

        id_capacitacion_edit:null,
        anio_edit: null,

        editindex: null,
        deleteIndex: null,
        fields: [
          { key: 'capacitacion',  label: 'Capacitación',  sortable: true, thStyle: {width:'60%'}  },
          { key: 'anio',          label: 'Año',           sortable: true, thStyle: {width:'20%'}  },
          { key: 'actions',       label: 'Acciones',      sortable: false},
        ],
        sortBy: 'anio',
        sortDesc: true,
        sortDirection: 'desc',
      }
    },

    methods:{
      async getCapacitacionesInstitucionales(){
        let r = await axios.get(URL_API+'api/capacitaciones-institucionales');

        var arr = [];
        var first = true;
        var aux;

        r.data.forEach(function(element, index){
          if(first==true){
            aux = element.id;
            first = false;
          }
          arr.push({'value': element.id, 'text': element.capacitacion });
        });
        
        this.first_capacitacion = aux;
        this.id_capacitacion = aux;
        this.capacitaciones_institucionales = arr;
      },

      getCapacitacionesEmprendimientos(){
        this.capacitaciones_emprendimiento = this.$store.state.emprendimiento.capacitaciones_emprendimiento;
      },

      nuevo(){
        this.getCapacitacionesInstitucionales();
        this.resetCapacitacion();
        this.$bvModal.show('createCapacitacion')
      },

      addCapacitacion(){
        var id = this.id_capacitacion;
        var capacitacion = this.capacitaciones_institucionales.find(x => x.value == id).text;

        var nueva_capacitacion = {
          'id':                 this.$store.state.emprendimiento.id_capacitaciones_emprendimiento,
          'id_capacitacion':    this.id_capacitacion,
          'capacitacion':       capacitacion,
          'anio':               this.anio,
        };

        this.$store.commit('addCapacitacionEmprendimiento', nueva_capacitacion);
        this.resetCapacitacion();
      },

      editCapacitacion(item, index, event){
        this.getCapacitacionesInstitucionales();
        this.editindex = item.id; 
        this.id_capacitacion_edit = item.id_capacitacion,
        this.anio_edit = item.anio,
        this.$bvModal.show('editCapacitacion');
      },

      updateCapacitacion(index){
        var id = this.id_capacitacion_edit;
        var capacitacion_edit = this.capacitaciones_institucionales.find(x => x.value == id).text;
         
        for(var i=0; i<this.$store.state.emprendimiento.capacitaciones_emprendimiento.length; i++){
          if(this.$store.state.emprendimiento.capacitaciones_emprendimiento[i].id === index){
            this.$store.state.emprendimiento.capacitaciones_emprendimiento[i].id_capacitacion = this.id_capacitacion_edit;
            this.$store.state.emprendimiento.capacitaciones_emprendimiento[i].capacitacion = capacitacion_edit;
            this.$store.state.emprendimiento.capacitaciones_emprendimiento[i].anio = this.anio_edit;
          }
        }
        this.resetCapacitacion();
      },

      deleteCapacitacion(item, index, event){
        this.deleteIndex = item.id; 
        if (confirm('¿Seguro que desea eliminarlo?')) {
          this.$store.commit('deleteCapacitacionEmprendimiento', this.deleteIndex);
        }
        this.resetCapacitacion();
      },

      resetCapacitacion() {
        this.id_capacitacion = this.first_capacitacion;
        var today = new Date();
        this.anio = today.getFullYear();

        this.getCapacitacionesEmprendimientos();
        
        this.$root.$emit('bv::hide::modal','createCapacitacion');
        this.$root.$emit('bv::hide::modal','editCapacitacion');
      },

      getAnios(){
        var today = new Date();
        this.anio = today.getFullYear();

        var arr = [];
        for (var i = this.anio; i >= 2000; i--) {
          arr.push({'value': i, 'text': i });
        }
        this.anios = arr;
      },
    },

    created(){
      this.getCapacitacionesInstitucionales();
      this.getCapacitacionesEmprendimientos();
      this.getAnios();
    }
  };
</script>


<style>
.table-capacitaciones-emprendimiento {
  table-layout: fixed;
}

.table-capacitaciones-emprendimiento thead th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}


.table-capacitaciones-emprendimiento thead tr th{
  padding-top: .3rem;
  padding-bottom: .3rem;
}

@media screen and (min-width: 400px) {
  .table-capacitaciones-emprendimiento tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-capacitaciones-emprendimiento tbody tr td:nth-child(3) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {
  .table-capacitaciones-emprendimiento tbody tr td:nth-child(2) {
    text-align: center;
  }

  .table-capacitaciones-emprendimiento tbody tr td:nth-child(2) {
    text-align: center;
  }

  .table-capacitaciones-emprendimiento thead tr th:nth-child(3) {
    justify-content: center;
  }

  .table-capacitaciones-emprendimiento tbody tr td:nth-child(3) {
    justify-content: center;
  }
}
</style>