<template>
  <div>
    <div class="col-md-12">
      <h5>Perfiles existentes</h5>
    </div>

    <div class="col-md-12">
      <!-- User Interface controls -->
      <b-row>
        <b-col sm="12" class="my-3">
          <b-button 
            class="btn btn-info btn-fill" 
            @click="nuevo()">
            <i class="fa fa-plus"></i> 
              Agregar
          </b-button>
        </b-col>
      </b-row>
    </div>

    <!-- Main table element -->
    <div class="col-md-12 pb-4">
      <b-table
        class="table-estados-solidez-emprendimiento"
        hover
        stacked="md"
        head-variant="dark"
        :items="perfiles_existentes"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
      >
        <template v-slot:cell(actions)="row">
          <b-button size="sm" @click="editPerfilesExistentes(row.item, row.index, $event.target)" class="btn btn-warning btn-fill mr-1">
          <span class="fa fa-pencil"></span>
          </b-button>

          <b-button class="btn-danger btn-fill" size="sm" @click="deletePerfilesExistentes(row.item, row.index, $event.target)">
          <span class="fa fa-trash"></span>
          </b-button>
        </template>
      </b-table>
    </div>

    <b-modal size="md" id="createPerfilesExistentes" hide-footer>
      <div class="row">
        
        <div class="col-md-12">
          <label>Año:</label>
            <b-form-select 
              v-model="anio"
              :options="anios">
            </b-form-select>
        </div> 

        <div class="col-md-12 pt-4">
          <label>Perfiles existentes del equipo Emprendedor:</label>
          <b-form-group class="pl-4">
            <b-form-checkbox
              v-for="option in solidez_perfiles_miembros_equipo"
              v-model="selected"
              :key="option.value"
              :value="option.value">
            {{ option.text }}
            </b-form-checkbox>
          </b-form-group>
        </div>          
      </div>

      <div class="pt-4">
          <b-button 
            class="mt-3 pull-left btn-danger btn-fill" 
            @click="resetPerfilesExistentes()">
            Cancelar
          </b-button>
          
          <b-button
            :disabled="selected==''" 
            class="mt-3 pull-right btn-info btn-fill" 
            @click="nuevaPerfilExistente()">
            Agregar
          </b-button>
      </div>
    </b-modal>

    <b-modal size="md" id="editPerfilesExistentes" hide-footer>
      <div class="row">
        
        <div class="col-md-12">
          <label>Año:</label>
            <b-form-select 
              v-model="anio_edit"
              :options="anios">
            </b-form-select>
        </div> 

        <div class="col-md-12 pt-4">
          <label>Perfiles existentes del equipo Emprendedor:</label>
          <b-form-group class="pl-4">
            <b-form-checkbox
              v-for="option in solidez_perfiles_miembros_equipo"
              v-model="selected_edit"
              :key="option.value"
              :value="option.value">
              {{ option.text }}
            </b-form-checkbox>
          </b-form-group>
        </div>          
      </div>
      <div class="pt-4">
          <b-button 
            class="mt-3 pull-left btn-danger btn-fill" 
            @click="resetPerfilesExistentes()">
              Cacelar
          </b-button>
          
          <b-button 
            :disabled="selected_edit==''" 
            class="mt-3 pull-right btn-info btn-fill" 
            @click="updateRolesMiembros(editindex)">
              Actualizar
          </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    data () {
      return {
        anio: null,
        anio_edit: null,
        anios: [],
        
        perfiles_existentes:[],

        selected:[],
        selected_edit:[],
        perfiles_seleccionados:[],

        first_solidez_perfiles_miembros_equipo: null,
        id_solidez_perfiles_miembros_equipo: null,
        solidez_perfiles_miembros_equipo:[],

        editindex: null,
        deleteIndex: null,
        fields: [
          { key: 'perfiles_existentes',     label: 'Perfiles existentes',   sortable: true, thStyle: {width:'60%'}  },
          { key: 'anio',                    label: 'Año',                   sortable: true, thStyle: {width:'20%'}  },
          { key: 'actions',                 label: 'Acciones',              sortable: false},
        ],
        sortBy: 'anio',
        sortDesc: true,
        sortDirection: 'desc',
      }
    },

    methods:{
      async getPerfilesMiembrosEquipo(){
        let r = await axios.get(URL_API+'api/solidez-perfiles-miembros-equipo');

        var arr = [];
        var first = true;
        var aux;

        r.data.forEach(function(element, index){
          if(first==true){
            aux = element.id;
            first = false;
          }
          arr.push({'value': element.id, 'text': element.nombre });
        });
        
        this.first_solidez_perfiles_miembros_equipo = aux;
        this.id_solidez_perfiles_miembros_equipo = aux;
        this.solidez_perfiles_miembros_equipo = arr;
      },
      
      getPerfilesExistentesEmprendimiento(){
        this.perfiles_existentes = this.$store.state.emprendimiento.perfiles_existentes_equipo_emprendedor;
      },

      nuevo(){
        this.resetPerfilesExistentes();
        this.getPerfilesMiembrosEquipo();
        this.$bvModal.show('createPerfilesExistentes');
      },

      nuevaPerfilExistente(){
        this.perfiles_seleccionados = [];

        this.solidez_perfiles_miembros_equipo.forEach(e=>{
          this.selected.forEach(s=>{
            if(e.value==s){
              this.perfiles_seleccionados += e.text+', ';
            }
          })
        })

        this.perfiles_seleccionados = this.perfiles_seleccionados.slice(0, -2)

        var nueva_perfil = {
          'id': this.$store.state.emprendimiento.id_perfiles_existentes_equipo_emprendedor,
          'id_perfiles_existentes':   this.selected,
          'perfiles_existentes':      this.perfiles_seleccionados,
          'anio':                     this.anio,
        };

        this.$store.commit('addPerfilesEquipoEmprendimiento', nueva_perfil);
        this.resetPerfilesExistentes();
      },

      editPerfilesExistentes(item, index, event){
        this.editindex = item.id; 
        this.anio_edit = item.anio;
        this.selected_edit = item.id_perfiles_existentes;

        this.$bvModal.show('editPerfilesExistentes');
      },

      updateRolesMiembros(index){
        this.perfiles_seleccionados = [];

        this.solidez_perfiles_miembros_equipo.forEach(e=>{
          this.selected_edit.forEach(s=>{
            if(e.value==s){
              this.perfiles_seleccionados += e.text+', ';
            }
          })
        })

        this.perfiles_seleccionados = this.perfiles_seleccionados.slice(0, -2)

        for(var i=0; i<this.$store.state.emprendimiento.perfiles_existentes_equipo_emprendedor.length; i++){
          if(this.$store.state.emprendimiento.perfiles_existentes_equipo_emprendedor[i].id === index){
            this.$store.state.emprendimiento.perfiles_existentes_equipo_emprendedor[i].id_perfiles_existentes = this.selected_edit;
            this.$store.state.emprendimiento.perfiles_existentes_equipo_emprendedor[i].perfiles_existentes = this.perfiles_seleccionados;
            this.$store.state.emprendimiento.perfiles_existentes_equipo_emprendedor[i].anio = this.anio_edit;
          }
        }
        this.resetPerfilesExistentes();
      },

      deletePerfilesExistentes(item, index, event){
        this.deleteIndex = item.id; 
        if (confirm('¿Seguro que desea eliminarlo?')) {
          this.$store.commit('deletePerfilesEquipoEmprendimiento', this.deleteIndex);
        }
        this.resetPerfilesExistentes();
      },

      resetPerfilesExistentes() {
        var today = new Date();

        this.anio = today.getFullYear();
        this.anio_edit = today.getFullYear();

        this.selected = [];
        this.perfiles_seleccionados = '';

        this.getPerfilesExistentesEmprendimiento();
        
        this.$bvModal.hide('createPerfilesExistentes');
        this.$bvModal.hide('editPerfilesExistentes');
      },

      getAnios(){
        var today = new Date();
        this.anio = today.getFullYear();

        var arr = [];
        for (var i = this.anio; i >= 2000; i--) {
          arr.push({'value': i, 'text': i });
        }
        this.anios = arr;
      },
    },

    created(){
      this.getPerfilesMiembrosEquipo();
      this.getPerfilesExistentesEmprendimiento();
      this.getAnios();
    }
  };
</script>


<style>
.table-estados-solidez-emprendimiento {
  table-layout: fixed;
}

.table-estados-solidez-emprendimiento thead th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}


.table-estados-solidez-emprendimiento thead tr th{
  padding-top: .3rem;
  padding-bottom: .3rem;
}

@media screen and (min-width: 400px) {
  .table-estados-solidez-emprendimiento tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-estados-solidez-emprendimiento tbody tr td:nth-child(3) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {
  .table-estados-solidez-emprendimiento tbody tr td:nth-child(1) {
    text-align: center;
  }

  .table-estados-solidez-emprendimiento tbody tr td:nth-child(1) {
    text-align: center;
  }

  .table-estados-solidez-emprendimiento thead tr th:nth-child(2) {
    justify-content: center;
  }

  .table-estados-solidez-emprendimiento tbody tr td:nth-child(2) {
    text-align: center;
  }

  .table-estados-solidez-emprendimiento thead tr th:nth-child(3) {
    justify-content: center;
  }

  .table-estados-solidez-emprendimiento tbody tr td:nth-child(3) {
    justify-content: center;
  }
}
</style>