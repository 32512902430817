<template>
  <div>
    <h3>Solidez Equipo Emprendedor</h3>
    <div class="card">
      <div class="col-md-12 pt-4 pb-4">
        <div class="row">
          <PerfilEquipoEmprendimiento />
          <PerfilesExistentesEmprendimiento />
          <RolesMiembrosEmprendimiento />
        </div>
      </div>
    </div>
</div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  import PerfilEquipoEmprendimiento from 'src/components/Emprendimiento/PerfilEquipoEmprendimiento.vue'
  import RolesMiembrosEmprendimiento from 'src/components/Emprendimiento/RolesMiembrosEmprendimiento.vue'
  import PerfilesExistentesEmprendimiento from 'src/components/Emprendimiento/PerfilesExistentesEmprendimiento.vue'

  export default {
    components: {
      PerfilEquipoEmprendimiento,
      RolesMiembrosEmprendimiento,
      PerfilesExistentesEmprendimiento
    }
  };
</script>