<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          
          <DatosAdministrativosEmprendimiento />
          <InfoEmprendimiento />
          <VincularOtroProgramaEmprendimiento v-show="this.$store.state.emprendimiento.id_programa_svytt==3" />
          <ProgramasVinculados v-show="this.$store.state.emprendimiento.id_programa_svytt!=3" />
          
          <div class="col-md-12 pt-4 pb-4">
            <div class="row">
              <div class="col-sm-12">
                <b-button 
                  class="mt-3 btn btn-danger btn-fill" 
                  style="float: left;"
                  @click="volver">
                  Volver
                </b-button>

                <b-button 
                  class="mt-3 btn btn-info btn-fill" 
                  style="float: right;"
                  @click="siguiente">
                  Siguiente
                </b-button>
              </div>
            </div>
          </div>

          <b-modal size="md" id="errors" hide-footer>
            <div class="row">
              <div class="col-md-12 pt-3">
                <ul>
                  <li v-for="item in errors">
                    {{ item.error }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="pt-4">
              <b-button 
                class="mt-3 pull-right btn-danger btn-fill" 
                @click="resetModalErrors">
                Aceptar
              </b-button>
            </div>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;
  import DatosAdministrativosEmprendimiento from 'src/components/Emprendimiento/DatosAdministrativosEmprendimiento.vue'
  import InfoEmprendimiento from 'src/components/Emprendimiento/InfoEmprendimiento.vue'
  import VincularOtroProgramaEmprendimiento from 'src/components/Emprendimiento/VincularOtroProgramaEmprendimiento.vue'
  import ProgramasVinculados from 'src/components/Emprendimiento/ProgramasVinculados.vue'

  export default {
    components: {
      DatosAdministrativosEmprendimiento,
      InfoEmprendimiento,
      VincularOtroProgramaEmprendimiento,
      ProgramasVinculados
    },
    data () {
      return { 
        id:null,
        errors:[]
      }
    },        

    methods:{
      scrollToTop(){
        window.scrollTo(0, -1)
      },

      currentRoute(){
        this.id = this.$route.params.id;
        this.$store.commit('setTitulo', {setTitulo: 'Programa UNLBio'})
        this.$store.commit('setSubTitulo', {setSubTitulo: 'editar 3/5'})
      },

      verificarEmprendimiento(){
        if(this.$store.state.emprendimiento_edit==false){
          this.$router.push( {name:'editBIO1', params: { id: this.id }});
        }
      },

      siguiente(){
        this.errors=[];

        if(this.$store.state.emprendimiento.fecha_alta==''){
          this.errors.push({'error':'Seleccione la FECHA DE ALTA del emprendimiento antes de continuar'});
        }

        if(this.$store.state.emprendimiento.condicion=='Desvinculado'){
          if(this.$store.state.emprendimiento.fecha_baja==''||this.$store.state.emprendimiento.fecha_baja==null){
            this.errors.push({'error':'Seleccione la FECHA DE BAJA del emprendimiento antes de continuar'});
          }  
        }

        if(this.errors[0]){
          this.$root.$emit('bv::show::modal','errors');
        }else{
          this.$router.push( {name:'editBIO4', params: { id: this.id }});
        }
      },

      resetModalErrors(){
        this.errors=[];
        this.$root.$emit('bv::hide::modal','errors');
      },
      volver(){
        this.$router.push( {name:'editBIO2', params: { id: this.id }});
      }
    },
    created(){
      this.$Progress.start(),
      this.scrollToTop(),
      this.currentRoute();
      this.verificarEmprendimiento();
      this.$Progress.finish()
    }
  };
</script>