<template>
  <div>
    <h4>Programas Vinculados</h4>
    <div class="card">
      <div class="col-md-12 pt-4 pb-4">
        <div class="row">

          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
              <!-- User Interface controls -->
              <b-row>
                <b-col sm="12" class="my-3">
                  <b-button 
                    class="btn btn-info btn-fill" 
                    @click="nuevo()">
                    <i class="fa fa-plus"></i> 
                      Agregar
                  </b-button>
                </b-col>
              </b-row>
              </div>
              <!-- Main table element -->
              <div class="col-md-12 pb-1">
                <b-table
                  class="table-vincular-otro-programa"
                  hover
                  stacked="md"
                  head-variant="dark"
                  :items="programas_vinculados"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                >
                  <template v-slot:cell(fecha)="row">
                    {{ row.item.fecha.toString().split("-").reverse().join("/") }}
                  </template>

                  <template v-slot:cell(actions)="row">
                    <b-button size="sm" @click="editProgramaVinculado(row.item, row.index, $event.target)" class="btn btn-warning btn-fill mr-1">
                    <span class="fa fa-pencil"></span>
                    </b-button>

                    <b-button class="btn-danger btn-fill" size="sm" @click="deleteProgramaVinculado(row.item, row.index, $event.target)">
                    <span class="fa fa-trash"></span>
                    </b-button>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal size="lg" id="createPlataforma" title="Vincular a otro programa" hide-footer>
      <div class="row">
        
        <div class="col-md-4 col-sm-12 pt-3">
          <label>Programa SVyTT:</label>
          <b-form-select
            v-model="id_otro_programas_SVyTT"
            :options="programas_SVyTT">
          </b-form-select>
        </div>

        <div class="col-md-4 col-sm-12 pt-3">
          <label>Incubado en:</label>
          <b-form-select
            v-model="id_otro_gabinete" 
            :options="gabinetes_emprendedores" 
            autocomplete="off">
          </b-form-select>
        </div>

        <div class="col-md-4 col-sm-12 pt-3">
          <label>Fecha:<small class="text-danger">(requerido)</small></label>
          <b-form-input 
              v-model="fecha"   
              type="date"
              placeholder="dd/mm/aaaa"
              autocomplete="off">
          </b-form-input>
        </div>  

        <div class="col-md-12 pt-3">
          <label>Motivo:</label>
            <b-form-textarea
              v-model="observacion">
            </b-form-textarea>
        </div> 

      </div>
      <div class="pt-4">
          <b-button 
            class="mt-3 pull-left btn-danger btn-fill" 
            @click="resetModal()">
            Cancelar
          </b-button>
          <b-button 
            :disabled="fecha==''"
            class="mt-3 pull-right btn-info btn-fill" 
            @click="vincular()">
            Vincular
          </b-button>
      </div>
    </b-modal>

    <b-modal size="lg" id="editProgramaVinculado" title="Vincular a otro programa" hide-footer>
      <div class="row">
        
        <div class="col-md-4 col-sm-12 pt-3">
          <label>Programa SVyTT:</label>
          <b-form-select
            v-model="id_otro_programas_SVyTT"
            :options="programas_SVyTT">
          </b-form-select>
        </div>

        <div class="col-md-4 col-sm-12 pt-3">
          <label>Incubado en:</label>
          <b-form-select
            v-model="id_otro_gabinete" 
            :options="gabinetes_emprendedores" 
            autocomplete="off">
          </b-form-select>
        </div>

        <div class="col-md-4 col-sm-12 pt-3">
          <label>Fecha:<small class="text-danger">(requerido)</small></label>
          <b-form-input 
              v-model="fecha"   
              type="date"
              placeholder="dd/mm/aaaa"
              autocomplete="off">
          </b-form-input>
        </div>  

        <div class="col-md-12 pt-3">
          <label>Motivo:</label>
            <b-form-textarea
              v-model="observacion">
            </b-form-textarea>
        </div> 

      </div>
      <div class="pt-4">
          <b-button 
            class="mt-3 pull-left btn-danger btn-fill" 
            @click="resetModal()">
            Cancelar
          </b-button>
          <b-button 
            :disabled="fecha==''"
            class="mt-3 pull-right btn-info btn-fill" 
            @click="updatePrograma(editindex)">
            Actualizar
          </b-button>
      </div>
    </b-modal>

  </div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    name: 'VincularOtroProgramaEmprendimiento',
    data () {
      return {
        first_otro_gabinete: null,
        id_otro_gabinete: null,
        gabinetes_emprendedores: [],

        first_id_otro_programas_SVyTT: null,
        id_otro_programas_SVyTT: null,
        programas_SVyTT: [],

        fecha: '',
        observacion:'',

        programas_vinculados: [],
        editindex: null,
        deleteIndex: null,
        fields: [
          { key: 'otro_programas_SVyTT',  label: 'Programa',        sortable: true },
          { key: 'otro_gabinete',         label: 'Gabinete',        sortable: true, thStyle: {width:'20%'}},
          { key: 'observacion',           label: 'Motivo',          sortable: true, thStyle: {width:'35%'} },
          { key: 'fecha',                 label: 'Fecha',           sortable: true },
          { key: 'actions',               label: 'Acciones',        sortable: false},
        ],
        sortBy: 'fecha',
        sortDesc: false,
        sortDirection: 'desc',
      }
    },
    methods:{
      async getProgramasSVyTT(){
        let r = await axios.get(URL_API+'api/programas-svytt');

        var arr = [];
        var first = true;
        var aux;

        r.data.forEach(function(element, index){
          if(element.nombre!='--'){
            if(first==true){
              aux = element.id;
              first = false;
            }

            arr.push({'value': element.id, 'text': element.nombre });
          }
        });

        this.first_id_otro_programas_SVyTT = aux;
        this.id_otro_programas_SVyTT = aux;
        this.programas_SVyTT = arr;
      },

      async getGabinetesIndubadorasUNL(){
        let r = await axios.get(URL_API+'api/gabinetes-incubadoras-unl');

        var arr = [];
        var first = true;
        var aux;

        r.data.forEach(function(element, index){
          if(first==true){
            aux = element.id;
            first = false;
          }
          arr.push({'value': element.id, 'text': element.nombre });
        });

        this.first_otro_gabinete = aux;
        this.id_otro_gabinete = aux;
        this.gabinetes_emprendedores = arr;
      },

      getProgramasVinculados(){
        this.programas_vinculados = this.$store.state.emprendimiento.programas_vinculados;
      },

      nuevo(){
        this.fecha = '';
        this.observacion = '';
        this.id_otro_gabinete = this.first_otro_gabinete;
        this.id_otro_programas_SVyTT = this.first_id_otro_programas_SVyTT;
        this.getProgramasSVyTT();
        this.$bvModal.show('createPlataforma');
      },

      editProgramaVinculado(item, index, event){
        this.editindex = item.id;

        this.id_otro_gabinete = item.id_otro_gabinete;
        this.otro_gabinete = item.otro_gabinete;

        this.id_otro_programas_SVyTT = item.id_otro_programas_SVyTT;

        this.fecha = item.fecha,

        this.observacion = item.observacion,

        this.$bvModal.show('editProgramaVinculado');
      },

      resetModal() {
        this.fecha = '';
        this.id_otro_gabinete = this.first_otro_gabinete;
        this.id_otro_programas_SVyTT = this.first_id_otro_programas_SVyTT;
        this.$bvModal.hide('createPlataforma');
        this.$bvModal.hide('editProgramaVinculado');
      },

      vincular(){
        var id1 = this.id_otro_programas_SVyTT;
        var otro_programas_SVyTT = this.programas_SVyTT.find(x => x.value == id1).text;

        var id2 = this.id_otro_gabinete;
        var otro_gabinete = this.gabinetes_emprendedores.find(x => x.value == id2).text;

        var nuevo_programa_vinculado = {
          'id':                         this.$store.state.emprendimiento.id_programas_vinculados,
          'id_otro_programas_SVyTT':    this.id_otro_programas_SVyTT,
          'otro_programas_SVyTT':       otro_programas_SVyTT,
          'id_otro_gabinete':           this.id_otro_gabinete,
          'otro_gabinete':              otro_gabinete,
          'fecha':                      this.fecha,
          'observacion':                this.observacion
        };

        this.$store.commit('addProgramasVinculados', nuevo_programa_vinculado);
        this.getProgramasVinculados();
        this.resetModal();
      },

      updatePrograma(index){
        
        var id1 = this.id_otro_programas_SVyTT;
        var otro_programas_SVyTT = this.programas_SVyTT.find(x => x.value == id1).text;

        var id2 = this.id_otro_gabinete;
        var otro_gabinete = this.gabinetes_emprendedores.find(x => x.value == id2).text;

        for(var i=0; i<this.$store.state.emprendimiento.programas_vinculados.length; i++){
          if(this.$store.state.emprendimiento.programas_vinculados[i].id === index){

            this.$store.state.emprendimiento.programas_vinculados[i].id_otro_programas_SVyTT = this.id_otro_programas_SVyTT;
            this.$store.state.emprendimiento.programas_vinculados[i].otro_programas_SVyTT = otro_programas_SVyTT;

            this.$store.state.emprendimiento.programas_vinculados[i].id_otro_gabinete = this.id_otro_gabinete;
            this.$store.state.emprendimiento.programas_vinculados[i].otro_gabinete = otro_gabinete;

            this.$store.state.emprendimiento.programas_vinculados[i].fecha = this.fecha;

            this.$store.state.emprendimiento.programas_vinculados[i].observacion = this.observacion;
          }
        }
        this.resetModal();
      },

      deleteProgramaVinculado(item, index, event){
        this.deleteIndex = item.id;
        if (confirm('¿Seguro que desea eliminarlo?')) {
          this.$store.commit('deleteProgramasVinculados', this.deleteIndex);
        }
        this.resetModal();
      },

    },
    created(){
      this.getProgramasSVyTT()
      this.getGabinetesIndubadorasUNL();
      this.getProgramasVinculados();
    }
  };
</script>
<style>
.table-vincular-otro-programa {
  table-layout: fixed;
}

.table-vincular-otro-programa thead th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}


.table-vincular-otro-programa thead tr th{
  padding-top: .5rem;
  padding-bottom: .5rem;
}

@media screen and (min-width: 400px) {
  .table-vincular-otro-programa tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-vincular-otro-programa tbody tr td:nth-child(3) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {
  .table-vincular-otro-programa tbody tr td:nth-child(1) {
    text-align: center;
  }

  .table-vincular-otro-programa tbody tr td:nth-child(1) {
    text-align: center;
  }


  .table-vincular-otro-programa thead tr th:nth-child(2) {
    justify-content: center;
  }

  .table-vincular-otro-programa tbody tr td:nth-child(2) {
    text-align: center;
  }

  .table-vincular-otro-programa thead tr th:nth-child(4) {
    justify-content: center;
  }

  .table-vincular-otro-programa tbody tr td:nth-child(4) {
    text-align: center;
  }

  .table-vincular-otro-programa thead tr th:nth-child(5) {
    justify-content: center;
  }

  .table-vincular-otro-programa tbody tr td:nth-child(5) {
    justify-content: center;
  }
}
</style>