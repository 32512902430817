<template>
  <div class="content pt-4"> 
    <b-container fluid>
      <div class="card">
        <div class="col-md-12">
          <!-- User Interface controls -->
          <b-row>
            <b-col lg="6" class="my-4">
              <b-button 
                @click="nuevaCarrera" 
                class="btn btn-info btn-fill">
                <span class="fa fa-plus"></span> Nuevo
              </b-button>
            </b-col>
            <b-col lg="6" class="my-4">
              <b-form-group
                label-cols-sm="7"
                label-align-sm="right"
                label-size="md"
                label-for="filterInput"  
              >
              <b-form-input
                v-model="filter"
                type="search"
                id="filterInput"
                autocomplete="off"
                size="md"
                placeholder="Buscár"
              ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <!-- Main table element -->
        <div class="col-md-12 pt-4">
          <b-table
            class="table-carreras"
            hover
            stacked="md"
            head-variant="dark"
            :items="carreras"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filterIncludedFields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered"
          >
            <template v-slot:cell(name)="row">
              {{ row.value.first }} {{ row.value.last }}
            </template>

            <template v-slot:cell(actions)="row">
              <b-button size="sm" @click="edit(row.item, row.index, $event.target)" class="btn btn-warning btn-fill mr-1">
                <span class="fa fa-pencil"></span>
              </b-button>
              <b-button class="btn-danger btn-fill" size="sm" @click="deleteCarrera(row.item, row.index, $event.target)">
                <span class="fa fa-trash"></span>
              </b-button>
            </template>

            <template v-slot:row-details="row">
              <b-card>
                <ul>
                  <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                </ul>
              </b-card>
            </template>
          </b-table>
        </div>
        <b-row>
          <b-col sm="5" md="3" class="my-1">
            <b-form-group
              label="Por pagina"
              label-cols-sm="8"
              label-cols-md="8"
              label-cols-lg="8"
              label-align-sm="right"
              label-size="sm"
              label-for="perPageSelect"
              class="mb-0"
            >
              <b-form-select
                v-model="perPage"
                id="perPageSelect"
                size="sm"
                :options="pageOptions"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="7" md="3" class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-row>
      </div>
      <b-modal 
        size="lg" 
        id="create-modal" 
        hide-footer
        title="Nuevo">
        <div class="row">
          <div class="col-md-7 col-sm-12">
            <label>Carrera:</label>
            <b-form-input v-model="carrera" @keyup.enter="addCarrera" required autocomplete="off"></b-form-input>
          </div>
          <div class="col-md-5 col-sm-12">
            <label>Unidad Academica:</label>
            <b-form-select v-model="id_ua" :options="unidades_academicas"></b-form-select>
          </div>
        </div>
        <div class="pt-4">
          <b-button 
            class="mt-3 pull-left btn-danger btn-fill" 
            @click="resetModal">Cancelar
          </b-button>
          <b-button 
            class="mt-3 pull-right btn-info btn-fill" 
            :disabled="!carrera"
            @click="addCarrera">Agregar
          </b-button>
        </div>
      </b-modal>

      <!-- Edit modal -->
      <b-modal 
        id="edit-modal" 
        :id="editModal.id" 
        title="Editar" 
        size="lg" 
        hide-footer>
        <div class="row">
          <div class="col-md-7">
            <p>Carrera:</p>
            <b-form-input v-model="carrera" @keyup.enter="updateCarrera" required autocomplete="off"></b-form-input>
          </div>
          <div class="col-md-5">
            <p>Unidad Academica:</p>
            <b-form-select v-model="id_ua" :options="unidades_academicas"></b-form-select>
          </div>
        </div>
        <div class="pt-4">
          <b-button 
            class="mt-3 pull-left btn-danger btn-fill" 
            @click="resetModal">Cancelar
          </b-button>
          <b-button 
            class="mt-3 pull-right btn-info btn-fill" 
            @click="updateCarrera"
            :disabled="!carrera">Actualizar
          </b-button>
        </div>
      </b-modal>

    </b-container>
  </div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    data() {
      return {
        carreras: [],
        unidades_academicas: [],
        firts_ua: null,
        id_ua: null,
        carrera: '',
        editindex: null,
        deleteIndex: null,
        fields: [
          { key: 'id', label: '#', sortable: true, thStyle: {width:'10%'}},
          { key: 'nombre', label: 'Carrera', sortable: true, sortDirection: 'desc', thStyle: {width:'35%'}},
          { key: 'unidad_academica', label: 'Unidad Académica', sortable: true, thStyle: {width:'35%'}},
          { key: 'actions', label: 'Acciones' }
        ],
        totalRows: 1,
        pageOptions: [10, 25, 50, 100],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        editModal: {
          id: 'info-modal',
          title: '',
          content: ''
        }
      }
    },

    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },

      perPage:{
        get(){
          return this.$store.state.perPage
        },
        set(value){
          this.$store.commit('setPerPage', {perPage: value})
        }
      },

      currentPage:{
        get(){
          return this.$store.state.currentPage
        },
        set(value){
          this.$store.commit('setCurrentPage', {currentPage: value})
        }
      }
    },

    mounted() {
      // Set the initial number of items
      this.$store.commit('setTitulo', {setTitulo: 'Configuración'});
      this.$store.commit('setSubTitulo', {setSubTitulo: 'Carreras'});
    },

    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },

      async getCarreras(){
        await axios.get(URL_API+'api/carreras').then(response=>{
          this.carreras = response.data.carreras;
          this.totalRows = this.carreras.length
          var arr = [];
          var firts = true;
          var aux;
          response.data.ua.forEach(function(element, index, rubros){
            if(firts==true){
              aux= element.id;
              firts=false;
            }
            arr.push({'value': element.id, 'text': element.unidad_academica });
          });
          this.firts_ua = aux;
          this.id_ua = aux;
          this.unidades_academicas = arr;          
          this.$Progress.finish()
        }).catch(e=>{
          alert("Oops ha ocurrido un error durante la carga, intente mas tarde.");
          console.log(e);
          this.$router.push({ name: 'EmprendimientosUNL' })
        })
      },

      nuevaCarrera() {
        this.resetModal();
        this.$root.$emit('bv::show::modal', 'create-modal');
      },

      async addCarrera(){
        axios.post(URL_API+'api/carreras', {
            nombre: this.carrera,
            id_ua: this.id_ua,
        }).then(response => {
          this.resetModal();
          this.getCarreras();
        }).catch(e => {
            console.log(e);
        });
        this.$root.$emit('bv::hide::modal','create-modal');
      },

      resetModal(item, index, button) {
        this.carrera = '';
        this.id_ua = this.firts_ua;
        this.$root.$emit('bv::hide::modal','create-modal');
        this.$root.$emit('bv::hide::modal', this.editModal.id, button)
      },

      edit(item, index, button) {
        this.editindex = item.id;
        this.editModal.title =  'Editar carrera';
        this.carrera = item.nombre;
        this.id_ua = item.id_ua;
        this.$root.$emit('bv::show::modal', this.editModal.id, button);
      },

      async updateCarrera(item, index, button){
        await axios.put(URL_API+'api/carreras/'+this.editindex, {
            nombre: this.carrera,
            id_ua: this.id_ua
        }).then(response => {
          this.carrera='';
          this.getCarreras();
          this.resetModal();
        }).catch(e => {
            console.log(e);
            this.resetModal();
        });
      },

      async deleteCarrera(item, index, button){
        this.deleteIndex = item.id;
        if (confirm('¿Seguro que desea eliminarlo?')) {
          axios.delete(URL_API+'api/carreras/'+this.deleteIndex)
          .then(response => {
            this.deleteIndex = null;
            this.getCarreras();
          }).catch(e => {
              console.log(e);
          });
        }
      }
    },

    created(){
      this.$Progress.start(),
      this.getCarreras()
    }
  }
</script>

<style>

.table-carreras thead tr th{
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.table-carreras thead th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 400px) {
  .table-carreras tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-carreras tbody tr td:nth-child(3) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {
  .table-carreras tbody tr td:nth-child(1) {
    text-align: center;
  }

  .table-carreras tbody tr td:nth-child(1) {
    text-align: center;
  }


  .table-carreras thead tr th:nth-child(4) {
    justify-content: center;
  }

  .table-carreras tbody tr td:nth-child(4) {
    justify-content: center;
  }
}
</style>