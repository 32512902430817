<template>
  <div class="row">
    
    <div class="padding_loading" v-if="this.loading_perfiles">
      <div class="loader paddings"></div>
    </div>

    <div class="col-12" v-if="!this.loading_perfiles">
      <h4>Emprendimientos Vinculados</h4>
      <hr>
    </div>

    <div class="col-12">
      <ColumnCard
        v-if="!this.loading_perfiles"
        :titulo="'Perfiles de los Emprendimientos'"
        :subtitulo="this.subtitulo"
        :data_chart="this.perfiles_emprendimientos"
      /> 
    </div>
    <div class="col-12">
      <BarCard
        cols="4"
        v-if="!this.loading_rubros"
        :titulo="'Rubros de los Emprendimientos'"
        :subtitulo="this.subtitulo"
        :data_chart="this.rubros_emprendimientos"
      />
    </div>
    <div class="col-12">
      <ColumnCard
        v-if="!this.loading_gabinetes_incubadoras"
        :titulo="'Gabinetes/Incubadoras de los Emprendimientos'"
        :subtitulo="this.subtitulo"
        :data_chart="this.gabinetes_incubadoras_emprendimientos"
      />
    </div>

    <div class="col-12">
      <ColumnCard
        v-if="!this.loading_emprendimientos_vinculados_anio"
        :titulo="'Emprendimientos Vinculados (por año)'"
        :subtitulo="this.subtitulo"
        :data_chart="this.emprendimientos_vinculados_anio"
      />
    </div>

    <div class="col-12">
      <AreaCard
        v-if="!this.loading_emprendimientos_vinculados"
        :titulo="'Emprendimientos Vinculados (acumulado)'"
        :subtitulo="this.subtitulo"
        :data_chart="this.emprendimientos_vinculados"
      />
    </div>

    <div class="col-12">
      <ColumnCard
        v-if="!this.loading_emprendimientos_egresados_anio"
        :titulo="'Emprendimientos Egresados'"
        :subtitulo="this.subtitulo"
        :data_chart="this.emprendimientos_egresados_anio"
      />
    </div>

    <div class="col-12">
      <EmprendimientosEgresadosCard 
        :titulo="'Emprendimientos Egresados'"
        :subtitulo="this.subtitulo"
        :programa="this.programa"
      />
    </div>

    <div class="col-xl-6 col-md-6 col-sm-12">
      <PieCard
        v-if="!this.loading_emprendedores_unl"
        :titulo="'Emprendimientos Protegibles'"
        :subtitulo="this.subtitulo"
        :data_chart="this.emprendimientos_protegibles"
      />
    </div>

    <div class="col-xl-6 col-md-6 col-sm-12">
      <PieCard
        v-if="!this.loading_emprendedores_unl"
        :titulo="'Emprendimientos con Formalización Juridica'"
        :subtitulo="this.subtitulo"
        :data_chart="this.emprendimientos_formalizados"
      />
    </div>

    <div class="col-12" v-show="!this.loading_emprendimientos_desvinculados_motivo">
      <h4>Emprendimientos Desvinculados</h4>
      <hr>
    </div>

    <div class="col-xl-4 col-md-4 col-sm-12">
      <InfoEmprendimientosDesvinculadosCard
        v-if="!this.loading_emprendimientos_desvinculados_motivo"
        :titulo="'Egreso vs Desvinculación'"
        :subtitulo="this.subtitulo"
        :data="this.emprendimientos_desvinculados_motivo"
      />
    </div>

    <div class="col-xl-8 col-md-8 col-sm-12">
      <PieCard
        v-if="!this.loading_emprendimientos_desvinculados_motivo"
        :titulo="'Egreso vs Desvinculación'"
        :suffix="'%'"
        :subtitulo="this.subtitulo"
        :data_chart="this.emprendimientos_desvinculados_motivo_percent"
      />
    </div>

    <div class="col-12">
      <ColumnCard
        v-if="!this.loading_emprendimientos_desvinculados_anio"
        :titulo="'Emprendimientos Desvinculados (por año)'"
        :subtitulo="this.subtitulo"
        :data_chart="this.emprendimientos_desvinculados_anio"
      />
    </div>

    <div class="col-12" v-show="!this.loading_emprendedores_unl">
      <h4>Emprendedores UNL</h4>
      <hr>
    </div>

    <div class="col-xl-5 col-md-5 col-sm-12">
      <InfoEmprendedorCard
        v-if="!this.loading_emprendedores_unl"
        :titulo="'Emprendedores UNL'"
        :subtitulo="this.subtitulo"
        :data="this.emprendedores_unl"
      />
    </div>

    <div class="col-xl-7 col-md-7 col-sm-12">
      <DonutCard
        v-if="!this.loading_emprendedores_unl"
        :titulo="'Emprendedores UNL'"
        :subtitulo="this.subtitulo"
        :data_chart="this.genero_emprendedores"
      />
    </div>

    <div class="col-xl-6 col-md-6 col-sm-12">
      <PieCard
        v-if="!this.loading_emprendedores_universidad"
        :titulo="'Emprendedores UNL'"
        :suffix="'%'"
        :subtitulo="this.subtitulo"
        :data_chart="this.emprendedores_universidad"
      />
    </div>

    <div class="col-xl-6 col-md-6 col-sm-12">
      <PieCard
        v-if="!this.loading_emprendedores_graduados_estudiantes"
        :titulo="'Graduados y Estudiantes UNL'"
        :suffix="'%'"
        :subtitulo="this.subtitulo"
        :data_chart="this.emprendedores_graduados_estudiantes"
      />
    </div>

    <div class="col-12">
      <ColumnCard
        v-if="!this.loading_ua_emprendedores"
        :titulo="'Emprendedores y Unidades Academicas'"
        :subtitulo="this.subtitulo"
        :data_chart="this.ua_emprendedores"
      />
    </div>
    <div class="col-12">
      <EmprendedoresCarrerasCard
        cols="4"
        :programa="this.programa"
        :titulo="'Emprendedores y Carreras'"
        :subtitulo="this.subtitulo"
      />
    </div>

  </div>
</template>

<script>
  import AreaCard from 'src/components/Indicadores/Card/AreaCard.vue'
  import ColumnCard from 'src/components/Indicadores/Card/ColumnCard.vue'
  import BarCard from 'src/components/Indicadores/Card/BarCard.vue'
  import LineCard from 'src/components/Indicadores/Card/LineCard.vue'
  import PieCard from 'src/components/Indicadores/Card/PieCard.vue'
  import DonutCard from 'src/components/Indicadores/Card/DonutCard.vue'
  import InfoEmprendedorCard from 'src/components/Indicadores/InfoEmprendedorCard.vue'
  import InfoEmprendimientosDesvinculadosCard from 'src/components/Indicadores/Card/InfoEmprendimientosDesvinculadosCard.vue'
  import EmprendimientosEgresadosCard from 'src/components/Indicadores/Card/EmprendimientosEgresadosCard.vue'
  import EmprendedoresCarrerasCard from 'src/components/Indicadores/Card/EmprendedoresCarrerasCard.vue'

  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    components: {
      AreaCard,
      ColumnCard,
      BarCard,
      LineCard,
      PieCard,
      DonutCard,
      InfoEmprendedorCard,
      InfoEmprendimientosDesvinculadosCard,
      EmprendimientosEgresadosCard,
      EmprendedoresCarrerasCard
    },
    props: ['programa', 'subtitulo'],
    data () {
      return {
        loading_perfiles: true,
        perfiles_emprendimientos:null,

        loading_rubros: true,
        rubros_emprendimientos:null,

        loading_gabinetes_incubadoras:true,
        gabinetes_incubadoras_emprendimientos:null,

        loading_emprendimientos_vinculados:true,
        emprendimientos_vinculados:null,

        loading_emprendimientos_vinculados_anio:true,
        emprendimientos_vinculados_anio:null,

        loading_emprendimientos_desvinculados_anio:true,
        emprendimientos_desvinculados_anio:null,

        loading_emprendimientos_protegibles: true,
        emprendimientos_protegibles:null,

        loading_emprendimientos_egresados_anio:true,
        emprendimientos_egresados_anio:null,

        loading_emprendimientos_formalizados:true,
        emprendimientos_formalizados:null,

        loading_emprendedores_unl:true,
        emprendedores_unl:null,
        genero_emprendedores_unl:null,

        loading_emprendedores_universidad:true,
        emprendedores_universidad:null,

        loading_emprendedores_graduados_estudiantes:true,
        emprendedores_graduados_estudiantes:null,

        loading_ua_emprendedores:true,
        ua_emprendedores: null,

        loading_emprendimientos_desvinculados_motivo: true,
        emprendimientos_desvinculados_motivo: null,
        emprendimientos_desvinculados_motivo_percent: null,
      }
    },
    methods:{
      async getPerfilesEmprendimientos(){
        await axios.get(URL_API+'api/cantidadEmprendimientosPerfilesUNL/'+this.programa)
          .then(response => {
            this.perfiles_emprendimientos = response.data.perfiles_emprendimientos;
            this.loading_perfiles=false;
          })
          .catch(error => {
            console.log(error.response)
          })
      },

      async getRubrosPrimariosEmprendimientos(){
        await axios.get(URL_API+'api/cantidadEmprendimientosRubros/'+this.programa)
          .then(response => {
            this.rubros_emprendimientos = response.data.rubros_emprendimientos;
            this.loading_rubros=false;
          })
          .catch(error => {
            console.log(error.response)
          })
      },

      async getGabinetesIncubadorasEmprendimientos(){
        await axios.get(URL_API+'api/cantidadEmprendimientosGabinetesIncubadoras/'+this.programa)
          .then(response => {
            this.gabinetes_incubadoras_emprendimientos = response.data.gabinetes_incubadoras_emprendimientos;
            this.loading_gabinetes_incubadoras=false;
          })
          .catch(error => {
            console.log(error.response)
          })
      },

      async getEmprendimientosVinculados(){
        await axios.get(URL_API+'api/cantidadEmprendimientosVinculados/'+this.programa)
          .then(response => {
            this.emprendimientos_vinculados = response.data.emprendimientos_vinculados;
            this.loading_emprendimientos_vinculados=false;
          })
          .catch(error => {
            console.log(error.response)
          })
      },

      async getEmprendimientosVinculadosAnio(){
        await axios.get(URL_API+'api/cantidadEmprendimientosVinculadosAnio/'+this.programa)
          .then(response => {
            this.emprendimientos_vinculados_anio = response.data.emprendimientos_vinculados_anio;
            this.loading_emprendimientos_vinculados_anio=false;
          })
          .catch(error => {
            console.log(error.response)
          })
      },

      async getEmprendimientosDesvinculadosAnio(){
        await axios.get(URL_API+'api/cantidadEmprendimientosDesvinculadosAnio/'+this.programa)
          .then(response => {
            this.emprendimientos_desvinculados_anio = response.data.emprendimientos_desvinculados_anio;
            this.loading_emprendimientos_desvinculados_anio=false;
          })
          .catch(error => {
            console.log(error.response)
          })
      },

      async getEmprendimientosDesvinculadosMotivo(){
        await axios.get(URL_API+'api/cantidadEmprendimientosDesvinculadosMotivo/'+this.programa)
          .then(response => {
            this.emprendimientos_desvinculados_motivo = response.data.emprendimientos_desvinculados_motivo;
            this.emprendimientos_desvinculados_motivo_percent = response.data.emprendimientos_desvinculados_motivo_percent;
            this.loading_emprendimientos_desvinculados_motivo=false;
          })
          .catch(error => {
            console.log(error.response)
          })
      },

      async getEmprendimientosEgresadosAnio(){
        await axios.get(URL_API+'api/cantidadEmprendimientosEgresados/'+this.programa)
          .then(response => {
            this.emprendimientos_egresados_anio = response.data.emprendimientos_egresados_anio;
            this.loading_emprendimientos_egresados_anio=false;
          })
          .catch(error => {
            console.log(error.response)
          })
      },

      async getProteccionTecnologicaEmprendimientos(){
        await axios.get(URL_API+'api/cantidadEmprendimientosProtegibles/'+this.programa)
          .then(response => {
            this.emprendimientos_protegibles = response.data.emprendimientos_protegibles;
            this.loading_emprendimientos_protegibles=false;
          })
          .catch(error => {
            console.log(error.response)
          })
      },

      async getFormalizacionJuridicaEmprendimientos(){
        await axios.get(URL_API+'api/cantidadEmprendimientosFormalizados/'+this.programa)
          .then(response => {
            this.emprendimientos_formalizados = response.data.emprendimientos_formalizados;
            this.loading_emprendimientos_formalizados=false;
          })
          .catch(error => {
            console.log(error.response)
          })
      },

      async getEmprendedoresEmprendimientos(){
        await axios.get(URL_API+'api/cantidadEmprendedores/'+this.programa)
          .then(response => {
            this.genero_emprendedores = response.data.genero_emprendedores;
            this.emprendedores_unl = response.data.emprendedores;
            this.loading_emprendedores_unl=false;
          })
          .catch(error => {
            console.log(error.response)
          })
      },

      async getEmprendedoresUniversidad(){
        await axios.get(URL_API+'api/cantidadEmprendedoresUNL/'+this.programa)
          .then(response => {
            this.emprendedores_universidad = response.data.emprendedores_universitarios;
            this.loading_emprendedores_universidad=false;
          })
          .catch(error => {
            console.log(error.response)
          })
      },

      async getEmprendedoresGraduadosEstudiantes(){
        await axios.get(URL_API+'api/cantidadEmprendedoresGraduadosEstudiantes/'+this.programa)
          .then(response => {
            this.emprendedores_graduados_estudiantes = response.data.emprendedores_graduados_estudiantes;
            this.loading_emprendedores_graduados_estudiantes=false;
          })
          .catch(error => {
            console.log(error.response)
          })
      },

      async getEmprendedoresUA(){
        await axios.get(URL_API+'api/cantidadEmprendedoresUA/'+this.programa)
          .then(response => {
            this.ua_emprendedores = response.data.ua_emprendedores;
            this.loading_ua_emprendedores=false;
          })
          .catch(error => {
            console.log(error.response)
          })
      }

    },
    created(){
      this.$Progress.start();

      //Emprendimientos
      this.getPerfilesEmprendimientos();
      this.getRubrosPrimariosEmprendimientos();
      this.getGabinetesIncubadorasEmprendimientos();
      this.getEmprendimientosVinculados();
      this.getEmprendimientosVinculadosAnio();
      this.getEmprendimientosDesvinculadosAnio();
      this.getEmprendimientosDesvinculadosMotivo();
      //this.getEmprendimientosEgresadosAnio();
      this.getProteccionTecnologicaEmprendimientos();
      this.getFormalizacionJuridicaEmprendimientos();

      //Emprendedores
      this.getEmprendedoresEmprendimientos();
      this.getEmprendedoresUniversidad();
      this.getEmprendedoresGraduadosEstudiantes();
      this.getEmprendedoresUA();

      this.$Progress.finish();
    }
  };
</script>
<style>
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.padding_loading{
  padding-top: 15vh;
  padding-bottom: 50vh;
  padding-left: 35vw;
  padding-right: 35vw;
}
</style>