<template>
  <div class="contact-us full-screen">
    <nav class="navbar navbar-ct-default" role="navigation-demo"  style="background: #357CA5;color: white;height: 70px;">
      <div class="container">
        <!-- Brand and toggle get grouped for better mobile display -->
        <div class="navbar-header">
          <a href="https://www.unl.edu.ar/vinculacion/"  style="color: white;font-size: 18px;">
            <img class="pr-2" src="UNL_white_redondo.png" width="60px">
            {{ this.title }}
          </a>
        </div>

        <!-- Collect the nav links, forms, and other content for toggling -->
        <div class="collapse navbar-collapse" id="navigation-example-2">
          <ul class="nav navbar-nav navbar-right">
            <li>
              <router-link :to="{path:'/'}">Home</router-link>
            </li>
          </ul>
        </div>
        <!-- /.navbar-collapse -->
      </div>
      <!-- /.container-->
    </nav>
    <div class="wrapper wrapper-full-page section content" style="background-color: #D2D6DE;">
      <div class="">
        <div class="container">
          <div class="row p-1" align="center">

            <div class="col-md-12 pt-4 mt-4">
              <card class="col-md-6 col-sm-12 text-center p-1 border-primary">

                <div class="col-md-12 col-sm-12 text-center p-2">
                  <a 
                    href="https://www.unl.edu.ar/vinculacion/unlbio/buscador/" 
                    class="btn btn-info btn-block p-4 btn-zoom">
                    Buscador UNLBio
                  </a>
                </div>

                <div class="col-md-12 col-sm-12 text-center p-2">
                  <a 
                    href="https://servicios.unl.edu.ar/chequeo" 
                    class="btn btn-info btn-block p-4 btn-zoom">
                    Chequeo Digital
                  </a>
                </div>

                <div class="col-md-12 col-sm-12 text-center p-2">
                  <a 
                    :href="this.hostname+'/primer_empleo/public/'" 
                    class="btn btn-info btn-block p-4 btn-zoom">
                    Feria de Empleo y Posgrado 2021
                  </a>
                </div>

                <div class="col-md-12 col-sm-12 text-center p-2">
                  <a 
                    href="https://www.unl.edu.ar/ofertatecnologica/" 
                    class="btn btn-info btn-block p-4 btn-zoom">
                    Oferta Tecnológica UNL
                  </a>
                </div>

                <div class="col-md-12 col-sm-12 text-center p-2">
                  <router-link 
                    :to="{name:'login'}"
                    class="btn btn-info btn-block p-4 btn-zoom">
                      RUE UNL
                  </router-link>
                </div>

                <div class="col-md-12 col-sm-12 text-center p-2">
                  <a 
                    :href="this.hostname+'/sigis'" 
                    class="btn btn-info btn-block p-4 btn-zoom">
                    SIGIS
                  </a>
                </div>

              </card>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        title:' Secretaría de Vinculación y Transferencia Tecnólogica',
        hostname: null,
        width_screen: null,
        height_screen: null
      }
    },
    methods: {
      getHostName() {
        this.hostname = location.hostname;
        switch (this.hostname) {
          case 'svytt.unl.edu.ar':
            this.hostname="http://svytt.unl.edu.ar";
            break;
          
          case 'sigis.intranet.unl':
            this.hostname="http://sigis4.intranet.unl";
            break;

          case '192.168.80.117':
            this.hostname="http://192.168.80.116";
            break;
        
          default:
            this.hostname=".";
            break;
        }
      },

      getScreenSize(){
        this.width_screen = window.innerWidth;
        this.height_screen = window.innerHeight;

        if(this.width_screen < 768){
          this.title = 'SVyTT';
        }else{
          this.title = 'Secretaría de Vinculación y Transferencia Tecnólogica';
        }
      }

/*
        if(this.hostname!='svytt.unl.edu.ar'){
          if(this.hostname=='192.168.80.117'){
            this.hostname="http://192.168.80.116";
          }else{
            this.hostname="http://sigis4.intranet.unl";
          }
          
        }else{
          this.hostname=".";
        }
*/
    },
    mounted(){
      this.getHostName();
      this.getScreenSize();
    }
  }

</script>
<style scoped>

  .btn-zoom{
    shadow: 0 0 10px #ffffff;
    transition: transform .1s; /* Animation */
  }

  .btn-zoom:hover{
    box-shadow: 0 0 20px rgba(214,154,214,1);
    transform: scale(1.02); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .botones{
    width: 80px;
    height: 80px;
    padding: .5rem;
    padding-top: 3rem;
    vertical-align: middle;
    text-align: center;
    size: 14px;

    transition: transform .2s; /* Animation */
  }

  .botones:hover {
    transform: scale(1.15); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  .fa{
    width: 24px;
  }

@media screen and (min-width: 400px) {
  .botones{
    width: 100px;
    height: 100px;
    padding: .2rem;
    padding-top: 1rem;
    vertical-align: middle;
    text-align: center;
    size: 12px;
  }

  .fa{
    width: 24px;
  }
}

@media screen and (min-width: 800px) {
  .botones{
    width: 125px;
    height: 125px;
    padding: 0rem;
    padding-top: 1rem;
    vertical-align: middle;
    text-align: center;
  }

  .fa{
    width: 32px;
  }
}

@media screen and (min-width: 1200px) {
  .botones{
    width: 175px;
    height: 175px;
    padding: .5rem;
    padding-top: 4.5rem;
    vertical-align: middle;
    text-align: center;
  }

  .fa{
    width: 48px;
  }
}
</style>
