<template>
  <div>
    <h4>Asesorías/Asistencias Técnicas</h4>
    <div class="card">
      <div class="col-md-12 pt-4 pb-4">
        <div class="row">
          <div class="col-md-12">
          <!-- User Interface controls -->
          <b-row>
            <b-col sm="12" class="my-3">
              <b-button 
                v-b-modal.createAsesoria
                class="btn btn-info btn-fill" 
                @click="nuevo"><i class="fa fa-plus"></i> 
                  Agregar
              </b-button>
            </b-col>
          </b-row>
          </div>
          <!-- Main table element -->
          <div class="col-md-12 pb-4">
            <b-table
              class="table-asesorias-emprendimiento"
              hover
              stacked="md"
              head-variant="dark"
              :items="asesorias_emprendimiento"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
            >
              <template v-slot:cell(actions)="row">
                <b-button size="sm" @click="editAsesoria(row.item, row.index, $event.target)" class="btn btn-warning btn-fill mr-1">
                <span class="fa fa-pencil"></span>
                </b-button>

                <b-button class="btn-danger btn-fill" size="sm" @click="deleteCapacitacion(row.item, row.index, $event.target)">
                <span class="fa fa-trash"></span>
                </b-button>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

  <b-modal size="lg" id="createAsesoria" hide-footer>
    <div class="row">
      <div class="col-md-8">
        <label>Asesoría:</label>
        <b-form-select 
          v-model="id_asesoria" 
          :options="asesorias_unl">
        </b-form-select>
      </div>

      <div class="col-md-4">
        <label>Año:</label>
          <b-form-select 
            v-model="anio"
            :options="anios">
          </b-form-select>
      </div>      
    </div>

    <div class="pt-4">
        <b-button 
          class="mt-3 pull-left btn-danger btn-fill" 
          @click="resetAsesoria">Cancelar
        </b-button>
        
        <b-button 
          class="mt-3 pull-right btn-info btn-fill" 
          @click="nuevaAsesoria">Agregar
        </b-button>
    </div>
  </b-modal>

  <b-modal size="lg" id="editAsesoria" hide-footer>
    <div class="row">
      <div class="col-md-8 pt-3">
        <label>Capacitación:</label> 
        <b-form-select 
          v-model="id_asesoria_edit" 
          :options="asesorias_unl">
        </b-form-select>
      </div>

      <div class="col-md-4 pt-3">
        <label>Año:</label>
          <b-form-select 
            v-model="anio_edit"
            :options="anios">
          </b-form-select>
      </div> 
    </div>  
    <div class="pt-4">
        <b-button class="mt-3 pull-left btn-danger btn-fill" @click="resetAsesoria">Cacelar</b-button>
        <b-button class="mt-3 pull-right btn-info btn-fill" @click="updateCapacitacion(editindex)">Actualizar</b-button>
    </div>
  </b-modal>
</div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    data () {
      return {
        first_asesoria: null,
        id_asesoria: null,
        asesorias_unl: [],

        fecha_estado_mdn: '',

        asesorias_emprendimiento: [],

        anio: null,
        anios: [],

        id_asesoria_edit:null,
        anio_edit: null,

        editindex: null,
        deleteIndex: null,
        fields: [
          { key: 'asesoria',  label: 'Asesoría',  sortable: true, thStyle: {width:'65%'}  },
          { key: 'anio',      label: 'Año',       sortable: true, thStyle: {width:'15%'}  },
          { key: 'actions',   label: 'Acciones',  sortable: false},
        ],
        sortBy: 'anio',
        sortDesc: true,
        sortDirection: 'desc',
      }
    },

    methods:{
      async getAsesoriasUNL(){
        let r = await axios.get(URL_API+'api/asesorias-unl');

        let arr = [];
        let first = true;
        let aux;

        r.data.forEach(function(element, index){
          if(first==true){
            aux = element.id;
            first = false;
          }
          arr.push({'value': element.id, 'text': element.nombre });
        });
        
        this.first_asesoria = aux;
        this.id_asesoria = aux;
        this.asesorias_unl = arr;
      },

      getAsesoriasEmprendimientos(){
        this.asesorias_emprendimiento = this.$store.state.emprendimiento.asesorias_emprendimiento;
      },

      nuevo(){
        this.getAsesoriasUNL();
        this.resetAsesoria();
        this.$bvModal.show('createAsesoria')
      },

      nuevaAsesoria(){
        var id = this.id_asesoria;
        var asesoria = this.asesorias_unl.find(x => x.value == id).text;

        var nueva_asesoria = {
          'id':                 this.$store.state.emprendimiento.id_asesoria_emprendimiento,
          'id_asesoria':    this.id_asesoria,
          'asesoria':       asesoria,
          'anio':  this.anio,
        };

        this.$store.commit('addAsesoriaEmprendimiento', nueva_asesoria);
        this.resetAsesoria();
      },

      editAsesoria(item, index, event){
        this.editindex = item.id; 
        this.id_asesoria_edit = item.id_asesoria,
        this.anio_edit = item.anio,
        this.getAsesoriasUNL();
        this.$bvModal.show('editAsesoria')
      },

      updateCapacitacion(index){
        var id = this.id_asesoria_edit;
        var asesoria_edit = this.asesorias_unl.find(x => x.value == id).text;
         
        for(var i=0; i<this.$store.state.emprendimiento.asesorias_emprendimiento.length; i++){
          if(this.$store.state.emprendimiento.asesorias_emprendimiento[i].id === index){
            this.$store.state.emprendimiento.asesorias_emprendimiento[i].id_asesoria = this.id_asesoria_edit;
            this.$store.state.emprendimiento.asesorias_emprendimiento[i].asesoria = asesoria_edit;
            this.$store.state.emprendimiento.asesorias_emprendimiento[i].anio = this.anio_edit;
          }
        }
        this.resetAsesoria();
      },

      deleteCapacitacion(item, index, event){
        this.deleteIndex = item.id; 
        if (confirm('¿Seguro que desea eliminarlo?')) {
          this.$store.commit('deleteAsesoriaEmprendimiento', this.deleteIndex);
        }
        this.resetAsesoria();
      },

      resetAsesoria() {
        this.id_asesoria = this.first_asesoria;
        var today = new Date();
        this.anio = today.getFullYear();

        this.getAsesoriasEmprendimientos();
        
        this.$bvModal.hide('createAsesoria')
        this.$bvModal.hide('editAsesoria')
      },

      getAnios(){
        var today = new Date();
        this.anio = today.getFullYear();

        var arr = [];
        for (var i = this.anio; i >= 2000; i--) {
          arr.push({'value': i, 'text': i });
        }
        this.anios = arr;
      },
    },

    created(){
      this.getAsesoriasUNL();
      this.getAsesoriasEmprendimientos();
      this.getAnios();
    }
  };
</script>


<style>
.table-asesorias-emprendimiento {
  table-layout: fixed;
}

.table-asesorias-emprendimiento thead th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}


.table-asesorias-emprendimiento thead tr th{
  padding-top: .3rem;
  padding-bottom: .3rem;
}

@media screen and (min-width: 400px) {
  .table-asesorias-emprendimiento tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-asesorias-emprendimiento tbody tr td:nth-child(3) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {
  .table-asesorias-emprendimiento thead tr th:nth-child(2) {
    justify-content: center;
  }

  .table-asesorias-emprendimiento tbody tr td:nth-child(2) {
    text-align: center;
  }
  .table-asesorias-emprendimiento thead tr th:nth-child(3) {
    justify-content: center;
  }

  .table-asesorias-emprendimiento tbody tr td:nth-child(3) {
    justify-content: center;
  }
}
</style>