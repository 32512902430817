<template>
  <div class="content pt-4"> 
    <b-container fluid>

      <b-card>
        <b-row>
          <b-col md="4" sm="12" style="padding-top:1rem;">
            <label label-for="input-default">Apellido:</label>
            <b-form-input 
              type="text" 
              v-model="lastname" 
              required 
              autocomplete="off">
            </b-form-input>
          </b-col>

          <b-col md="4" sm="12" style="padding-top:1rem;">
            <label label-for="input-default">Nombre:</label>
            <b-form-input 
              type="text" 
              v-model="name" 
              required 
              autocomplete="off">
            </b-form-input>
          </b-col>

          <b-col md="4" sm="12" style="padding-top:1rem;">
            <label label-for="input-default">Correo electrónico:</label>
            <b-form-input 
              type="email" 
              v-model="email" 
              required 
              autocomplete="off"
              @keydown.space.prevent>
            </b-form-input>
          </b-col>

          <b-col md="12" sm="12" style="padding-top:1rem;">
            <label label-for="input-default">Mis roles:</label>
            <br>
            <div v-for="r in roles" style="display: inline-block; padding:.2rem;">
              <label class="caja">{{ r.name }}</label>
            </div>
          </b-col>

          <b-col cols="12" style="padding-top:.5rem;">
            <hr>
          </b-col>

          <b-col md="12" sm="12" style="padding-top:1rem;">
            <b-form-checkbox v-model="edit_password" name="check-button" switch>
              Cambiar contraseña
            </b-form-checkbox>
          </b-col>

          <b-col md="4" sm="12" style="padding-top:1rem;">
            <label label-for="input-default">Contraseña actual:</label>
            <b-input-group>
              <b-form-input 
                :state="validate_password"
                v-model="password" 
                :type="passwordFieldType1" 
                :disabled="!edit_password"
                @keydown.space.prevent>
              </b-form-input>
              <b-input-group-prepend is-text>
                <b-form-checkbox 
                  switch class="mr-n2" 
                  @change="switchVisibility(1)" 
                  :disabled="!edit_password">
                </b-form-checkbox>
              </b-input-group-prepend>
            </b-input-group>
          </b-col>

          <b-col md="4" sm="12" style="padding-top:1rem;">
            <label label-for="input-default">Contraseña nueva:</label>
            <b-input-group>
              <b-form-input 
                :state="validate_new_password"
                v-model="new_password" 
                :type="passwordFieldType2" 
                :disabled="!edit_password"
                @keydown.space.prevent>
              </b-form-input>
              <b-input-group-prepend is-text>
                <b-form-checkbox 
                  switch 
                  class="mr-n2" 
                  @change="switchVisibility(2)" 
                  :disabled="!edit_password">
                </b-form-checkbox>
              </b-input-group-prepend>
            </b-input-group>
          </b-col>

          <b-col md="4" sm="12" style="padding-top:1rem;">
            <label label-for="input-default">Confirmar nueva contraseña:</label>
            <b-input-group>
              <b-form-input
                :state="validate_new_password"
                v-model="new_password_confirm" 
                :type="passwordFieldType3" 
                :disabled="!edit_password"
                @keydown.space.prevent>
              </b-form-input>
              <b-input-group-prepend is-text>
                <b-form-checkbox 
                  switch 
                  class="mr-n2" 
                  @change="switchVisibility(3)" 
                  :disabled="!edit_password">
                </b-form-checkbox>
              </b-input-group-prepend>
            </b-input-group>
          </b-col>


          <b-col cols="12" style="padding: 5rem 1rem 1rem 1rem;">
            <b-button
              :disabled="this.loading"
              class="mt-3 btn-danger btn-fill" 
              @click="cancelar">Cancelar
            </b-button>

            <b-button 
              :disabled="this.loading"
              class="mt-3 btn btn-info btn-fill" 
              style="float: right;"
              @click="validate">
              Actualizar
            </b-button>
          </b-col>

          <b-modal size="md" id="errors" hide-footer>
            <div class="row">
              <div class="col-md-12 pt-3">
                <ul>
                  <li v-for="item in errors">
                    {{ item.error }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="pt-4">
              <b-button 
                class="mt-3 pull-right btn-danger btn-fill" 
                @click="resetModal">Aceptar
              </b-button>
            </div>
          </b-modal>

        </b-row>
      </b-card>
    </b-container>
  </div>
</template>
<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    data () {
      return {
        id:null,
        name: '',
        lastname: '',
        email: '',
        email_confirm: '',
        edit_password: false,
        password:'',
        passwordFieldType1: 'password',
        validate_password:null,
        
        new_password:'',
        passwordFieldType2: 'password',
        validate_new_password:null,

        new_password_confirm:'',
        passwordFieldType3: 'password',

        roles:[],

        errors:[],
        loading:false,
      }
    },
    methods: {
      currentRoute(){
        this.$store.commit('setTitulo', {setTitulo: 'Perfil'});
        this.$store.commit('setSubTitulo', {setSubTitulo: 'editar'});
      },

      async getMe(){
        this.id = localStorage.getItem('id')
        try{
          let me = await axios.get(URL_API + "api/user-perfil/"+this.id);
          this.id = me.data.me.id;
          this.name = me.data.me.name;
          this.lastname = me.data.me.lastname;
          this.email = me.data.me.email;
          this.roles = me.data.roles;
        }catch (err) {
          console.log(me)
        }
        
      },

      switchVisibility(val) {
        switch (val) {
          case 1:
            this.passwordFieldType1 = this.passwordFieldType1 === 'password' ? 'text' : 'password'
            break;

          case 2:
            this.passwordFieldType2 = this.passwordFieldType2 === 'password' ? 'text' : 'password'
            break;
          
          case 3:
            this.passwordFieldType3 = this.passwordFieldType3 === 'password' ? 'text' : 'password'
            break;
        
          default:
            break;
        }
      },

      validate(){
        if(this.new_password == this.new_password_confirm){
          this.update();
        }else{
          this.validate_new_password=false;
        }
      },

      cancelar(){
        this.$router.push( {name:'EmprendimientosUNL'});
      },

      async update(){
        this.loading=true;
        await axios.put(URL_API + "api/user-perfil/"+this.id, {
          name: this.name,
          lastname: this.lastname,
          email: this.email,
          edit_password: this.edit_password,
          password: this.password,
          new_password: this.new_password
        }).then((response) => {
          if(response.status==201){
            localStorage.setItem('name', this.name);
            localStorage.setItem('lastname', this.lastname);
            localStorage.setItem('email', this.email);
            this.$router.push( {name:'EmprendimientosUNL'});
          }
        }).catch((error) => {
         if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error aca', error.message);
          }
          this.loading=false;
          this.errors.push({'error':'Contraseña invalida'});
          this.$root.$emit('bv::show::modal','errors');
        });
        
        
      },

      resetModal(){
        this.errors=[];
        this.$root.$emit('bv::hide::modal','errors');
      },
    },

    mounted() {
      this.getMe();
      this.currentRoute();
    },
  }

</script>
<style>
.caja {
  font-family: sans-serif;
  font-size: 18px;
  font-weight: 100;
  color: #ffffff!important;
  background: #889ccf;
  margin: 0 0 10px;
  overflow: hidden;
  padding: 10px;
  display: inline-block;
  border-radius: 5px;
}
</style>
