<template>
  <div>
    <h4>Ubicación del Emprendimiento</h4>
    <div class="card">
      <div class="col-md-12 pt-4 pb-4">
        <div class="row">

          <div class="col-md-6 pt-3">
            <label>Provincia:<small class="text-danger">(requerido)</small></label>
            <b-form-select 
              v-model="id_provincia" 
              v-on:change="setProvincia()" 
              :options="provincias">
            </b-form-select>
          </div>

          <div class="col-md-6 pt-3">
            <label>Localidad:<small class="text-danger">(requerido)</small></label>
            <b-form-select 
              v-model="id_localidad"
              v-on:change="setLocalidad()" 
              :options="localidades">
            </b-form-select>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    name: 'UbicacionEmprendimiento',

    data () {
      return {
        firts_provincia: null,
        id_provincia : null,
        provincias: [],

        id_localidad: null,
        localidades: []
      }
    },

    computed:{
      idProvinciaEmprendimiento:{
        get(){
          return this.$store.state.emprendimiento.id_provincia
        },
        set(value){
          this.$store.commit('setProvinciaEmprendimiento', {idProvinciaEmprendimiento: value})
        }
      },

      idLocalidadEmprendimiento:{
        get(){
          return this.$store.state.emprendimiento.id_localidad
        },
        set(value){
          this.$store.commit('setLocalidadEmprendimiento', {idLocalidadEmprendimiento: value})
        }
      }
    },
    methods:{
      setInitValues(){
        this.id_provincia = this.$store.state.emprendimiento.id_provincia;
        this.id_localidad = this.$store.state.emprendimiento.id_localidad
      },

      setProvincia(){
        this.idProvinciaEmprendimiento = this.id_provincia;
        this.getLocalidades2();
      },

      setLocalidad(){
        this.idLocalidadEmprendimiento = this.id_localidad;
      },

      async getProvincias(){
        const that = this;
        await axios.get(URL_API+'api/provincias')
          .then( response => {
            var arr = [];
            var firts = true;
            var aux;
            response.data.forEach(function(element, index, rubros){
              if(firts==true){
                aux= element.id;
                firts=false;
              }
              arr.push({'value': element.id, 'text': element.nombre });
            });
            that.firts_provincia = aux;
            that.provincias = arr;
          })
      },

      async getLocalidades(){
        const that = this;
        await axios.get(URL_API+'api/localidades-provincia/'+this.id_provincia)
          .then( response =>{
            var arr = [];
            var firts = true;
            var aux;
            response.data.forEach(function(element, index, rubros){
              if(firts==true){
                aux= element.id;
                firts=false;
              }
              arr.push({'value': element.id, 'text': element.nombre });
            });
            if(!this.$store.state.emprendimiento.id_localidad)
            {
              this.id_localidad = aux;
            }
            that.localidades = arr;
          })
      },

      async getLocalidades2(){
        const that = this;
 
        await axios.get(URL_API+'api/localidades-provincia/'+this.id_provincia)
          .then( response =>{
            var arr = [];
            var firts = true;
            var aux;
            response.data.forEach(function(element, index, rubros){
              if(firts==true){
                aux= element.id;
                firts=false;
              }
              arr.push({'value': element.id, 'text': element.nombre });
            });
            that.firts_localidad = aux;
            that.id_localidad = aux;
            that.idLocalidadEmprendimiento = aux;
            that.localidades = arr;
          })
      }
    },
    created(){
      this.setInitValues();
      this.getProvincias();
      this.getLocalidades();
    },
  };
</script>
