<template>
  <div>
    <h4>Datos administrativos</h4>
    <div class="card">
      <div class="col-md-12 pt-4 pb-4">
        <div class="row">
          <div class="col-sm-4 pt-3">
            <label>Número de nota SVyTT:</label>
            <b-form-input 
              type="text" 
              v-model="numNotaSVyTTEmprendimiento" 
              @keypress="onlyNumber" 
              autocomplete="off"></b-form-input>
          </div>

          <div class="col-sm-4 pt-3">
            <label>Número de Expediente:</label>
            <b-form-input 
              type="text"
              autocomplete="off"
              v-model="numExpedienteEmprendimiento" 
              @keypress="onlyWordNumber">
            </b-form-input>
          </div>

          <div class="col-sm-4 pt-3">
            <label>Fecha de alta:<small class="text-danger">(requerido)</small></label>
            <b-form-input 
              type="date" 
              v-model="fechaAltaEmprendimiento">
            </b-form-input>
          </div>

          <div class="col-sm-4 pt-3">
            <label>Condición:<small class="text-danger">(requerido)</small></label>
            <b-form-select 
              v-model="condicionEmprendimiento" 
              :options="condicion_emprendimiento">
            </b-form-select>
          </div>

          <div class="col-sm-4 pt-3">
            <label>Fecha de baja:<small class="text-danger">(requerido)</small></label>
            <b-form-input 
              type="date" 
              v-model="fechaBajaEmprendimiento"
              :disabled="condicionEmprendimiento == 'Vinculado'">
            </b-form-input>
          </div>

          <div class="col-sm-4 pt-3">
            <label>Motivo:</label>
            <b-form-select 
              v-model="motivoDesvinculacionEmprendimiento" 
              :options="motivos_desvinculacion" 
              :disabled="condicionEmprendimiento == 'Vinculado'">
            </b-form-select>
          </div>

        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    name: 'DatosAdministrativosEmprendimiento',

    data () {
      return {
        condicion_emprendimiento: [
            {value:'Vinculado', text:'Vinculado'},
            {value:'Desvinculado', text:'Desvinculado'},
          ],
        motivos_desvinculacion:[
          {value:'Egreso', text:'Egreso'},
          {value:'Desvinculación', text:'Desvinculación'}
        ]
      }
    },

    computed: {
      numNotaSVyTTEmprendimiento:{
        get(){
          return this.$store.state.emprendimiento.num_nota_svytt
        },
        set(value){
          this.$store.commit('setNumeroNotaSVyTTEmprendimiento', {numNotaSVyTTEmprendimiento: value})
        }
      },

      numExpedienteEmprendimiento:{
        get(){
          return this.$store.state.emprendimiento.num_expediente
        },
        set(value){
          this.$store.commit('setNumeroExpedienteEmprendimiento', {numExpedienteEmprendimiento: value})
        }
      },

      fechaAltaEmprendimiento:{
        get(){
          return this.$store.state.emprendimiento.fecha_alta
        },
        set(value){
          this.$store.commit('setFechaAltaEmprendimiento', {fechaAltaEmprendimiento: value})
        }
      },

      condicionEmprendimiento:{
        get(){
          return this.$store.state.emprendimiento.condicion
        },
        set(value){
          this.$store.commit('setCondicionEmprendimiento', {condicionEmprendimiento: value})
        }
      },

      fechaBajaEmprendimiento:{
        get(){
          return this.$store.state.emprendimiento.fecha_baja
        },
        set(value){
          this.$store.commit('setFechaBajaEmprendimiento', {fechaBajaEmprendimiento: value})
        }
      },

      motivoDesvinculacionEmprendimiento:{
        get(){
          return this.$store.state.emprendimiento.motivo_desvinculacion
        },
        set(value){
          this.$store.commit('setMotivoDesvinculacionEmprendimiento', {motivoDesvinculacionEmprendimiento: value})
        }
      },
    },
    methods:{
      onlyNumber ($event) {
        //console.log($event.keyCode); //keyCodes value
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        if ((keyCode < 48 || keyCode > 57) && keyCode!== 47 && keyCode!== 45) {
          $event.preventDefault();
        }
      },

      onlyWordNumber ($event) {
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        if ((keyCode < 48 || keyCode > 57) &&
            (keyCode < 65 || keyCode > 90) && 
            (keyCode < 97 || keyCode > 122) && 
            keyCode!== 47 && keyCode!== 45) {
          $event.preventDefault();
        }
      },
    },
  };
</script>
