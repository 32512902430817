<template>
  <div>
    <h4>Redes del Emprendimiento:</h4>
    <div class="card">
      <div class="col-md-12 pt-4 pb-4">
        <div class="row">
          <div class="col-md-6 pt-3">
            <label>Pagina Web:</label>
            <b-input-group>
              <b-input-group-prepend>
                <span class="input-group-text"><i class="fa fa-globe"></i></span>
              </b-input-group-prepend>
              <b-form-input 
                v-model="webEmprendimiento" 
                name="webEmprendimiento" 
                autocomplete="off">
              </b-form-input>
            </b-input-group>
          </div>

          <div class="col-md-6 pt-3">
            <label>Linkedin:</label>
            <b-input-group>
              <b-input-group-prepend>
                <span class="input-group-text"><i class="fab fa-linkedin"></i></span>
              </b-input-group-prepend>
              <b-form-input 
                v-model="linkedinEmprendimiento"
                autocomplete="off">
              </b-form-input>
            </b-input-group>
          </div>

          <div class="col-md-6 pt-3">
            <label>Facebook:</label>
            <b-input-group>
              <b-input-group-prepend>
                <span class="input-group-text"><i class="fab fa-facebook"></i></span>
              </b-input-group-prepend>
              <b-form-input 
                v-model="facebookEmprendimiento"
                autocomplete="off">
              </b-form-input>
            </b-input-group>
          </div>

          <div class="col-md-6 pt-3">
            <label>Instagram:</label>
            <b-input-group>
              <b-input-group-prepend>
                <span class="input-group-text"><i class="fab fa-instagram"></i></span>
              </b-input-group-prepend>
              <b-form-input 
                v-model="instagramEmprendimiento" 
                autocomplete="off">
              </b-form-input>
            </b-input-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'RedesEmprendimiento',
    computed:{
      webEmprendimiento:{
        get(){
          return this.$store.state.emprendimiento.web
        },
        set(value){
          this.$store.commit('setWebEmprendimiento', {webEmprendimiento: value})
        }
      },

      facebookEmprendimiento:{
        get(){
          return this.$store.state.emprendimiento.facebook
        },
        set(value){
          this.$store.commit('setFacebookEmprendimiento', {facebookEmprendimiento: value})
        }
      },

      instagramEmprendimiento:{
        get(){
          return this.$store.state.emprendimiento.instagram
        },
        set(value){
          this.$store.commit('setInstagramEmprendimiento', {instagramEmprendimiento: value})
        }
      },

      linkedinEmprendimiento:{
        get(){
          return this.$store.state.emprendimiento.linkedin
        },
        set(value){
          this.$store.commit('setLinkedinEmprendimiento', {linkedinEmprendimiento: value})
        }
      }
    }
  };
</script>
