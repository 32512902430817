<template>
	<div>
		<h4 class="pt-3">Integrantes del Emprendimiento</h4>   
    <b-card>
      <p><b>RESPONSABLE: </b>{{ this.responsable_emprendimiento }}</p>
      <b-table
          class="tab-integrantes"
          hover
          stacked="md"
          head-variant="dark"
          :items="this.integrantes"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
        >
          <template v-slot:cell(actions)="row">
            <b-button size="sm" @click="edit(row.item, row.index, $event.target)" class="btn btn-info btn-fill mr-1">
              <span class="fa fa-eye"></span>
            </b-button>
          </template>
        </b-table>
    </b-card>

    <b-modal :id="viewModal.id" size="lg" title="Emprendedor" hide-footer>

      <b-col cols="12" class="pt-1">
        <b-row>
          <b-col class="titulo" md="4" xs="12">
            <b>Apellido:</b>
          </b-col>
          <b-col md="8" xs="12">
            {{ this.emprendedor.apellido }}
          </b-col>
        </b-row>

        <b-row>
          <b-col class="titulo" md="4" xs="12">
            <b>Nombre:</b>
          </b-col>
          <b-col md="8" xs="12">
            {{ this.emprendedor.nombre }}
          </b-col>
        </b-row>

        <b-row>
          <b-col class="titulo" md="4" xs="12">
            <b>DNI:</b>
          </b-col>
          <b-col md="8" xs="12">
            {{ this.emprendedor.dni }}
          </b-col>
        </b-row>

        <b-row>
          <b-col class="titulo" md="4" xs="12">
            <b>CUIT/CUIL:</b>
          </b-col>
          <b-col md="8" xs="12">
            {{ this.emprendedor.cuit_cuil }}
          </b-col>
        </b-row>

        <b-row>
          <b-col class="titulo" md="4" xs="12">
            <b>Correo Electrónico:</b>
          </b-col>
          <b-col md="8" xs="12">
            {{ this.emprendedor.correo_electronico }}
          </b-col>
        </b-row>

        <b-row v-if="this.emprendedor.telefono!=''">
          <b-col class="titulo" md="4" xs="12">
            <b>Teléfono:</b>
          </b-col>
          <b-col md="8" xs="12">
            {{ this.emprendedor.telefono }}
          </b-col>
        </b-row>

        <b-row v-if="this.emprendedor.celular!=''">
          <b-col class="titulo" md="4" xs="12">
            <b>Celular:</b>
          </b-col>
          <b-col md="8" xs="12">
            {{ this.emprendedor.celular }}
          </b-col>
        </b-row>

        <b-row v-if="this.emprendedor.genero!=''">
          <b-col class="titulo" md="4" xs="12">
            <b>Genero:</b>
          </b-col>
          <b-col md="8" xs="12">
            {{ this.emprendedor.genero }}
          </b-col>
        </b-row>

        <hr>

        <b-row>
          <b-col class="titulo" md="4" xs="12">
            <b>Provincia:</b>
          </b-col>
          <b-col md="8" xs="12">
            {{ this.emprendedor.provincia }}
          </b-col>
        </b-row>

        <b-row>
          <b-col class="titulo" md="4" xs="12">
            <b>Localidad:</b>
          </b-col>
          <b-col md="8" xs="12">
            {{ this.emprendedor.departamento }}
          </b-col>
        </b-row>

        <hr>

        <b-row>
          <b-col class="titulo" md="4" xs="12">
            <b>Nivel educativo:</b>
          </b-col>
          <b-col md="8" xs="12">
            {{ this.emprendedor.nivel_formacion }}
          </b-col>
        </b-row>
        
        <b-row>
          <b-col class="titulo" md="4" xs="12">
            <b>Condición:</b>
          </b-col>
          <b-col md="8" xs="12">
            {{ this.emprendedor.condicion_formacion }}
          </b-col>
        </b-row>

        <hr>

        <template v-if="this.emprendedor.nivel_formacion=='Universitario'">
        
          <b-row v-if="this.emprendedor.universidad=='unl'">
            <b-col class="titulo" md="4" xs="12">
              <b>Universidad:</b>
            </b-col>
            <b-col md="8" xs="12">
              Universidad Nacional del Litoral
            </b-col>

            <b-col class="titulo" md="4" xs="12">
              <b>Unidad Académica:</b>
            </b-col>
            <b-col md="8" xs="12">
              {{ this.emprendedor.unidad_academica }}
            </b-col>

            <b-col class="titulo" md="4" xs="12">
              <b>Carrera:</b>
            </b-col>
            <b-col md="8" xs="12">
              {{ this.emprendedor.carrera }}
            </b-col>
          </b-row>

          <b-row v-if="this.emprendedor.universidad=='otro'">
            <b-col class="titulo" md="4" xs="12">
              <b>Universidad:</b>
            </b-col>
            <b-col md="8" xs="12">
              {{ this.emprendedor.otra_universidad }}
            </b-col>

            <b-col class="titulo" md="4" xs="12">
              <b>Carrera:</b>
            </b-col>
            <b-col md="8" xs="12">
              {{ this.emprendedor.otra_carrera }}
            </b-col>
          </b-row>
          
        </template>

        <template v-if="this.emprendedor.otros_datos!=''">
          <b-row>
            <b-col class="titulo" md="4" xs="12">
              <b>Otros datos:</b>
            </b-col>
            <b-col md="8" xs="12">
              {{ this.emprendedor.otros_datos }}
            </b-col>
          </b-row>
        </template>
      </b-col>

      <div class="pt-4">
        <b-button class="mt-3 pull-left btn-danger btn-fill" @click="closeModal">Cerrar</b-button>
      </div>
    </b-modal>
	</div>
</template>

<script>
  export default {
    props: ['integrantes', 'responsable_emprendimiento'],
    data() {
      return {
        emprendedor: {
          nombre:null,
          apellido:null,
          dni: null,
          cuit_cuil: null,
          correo_electronico: null,
          telefono: null,
          celular: null,
          genero: null,
          provincia: null,
          departamento: null,
          condicion_formacion: null,
          nivel_formacion: null,
          universidad: null,
          unidad_academica: null,
          carrera: null,
          otra_universidad: null,
          otra_carrera: null,
          otros_datos: null
        },
        viewindex: null,
        fields: [
          { key: 'dni', label: 'DNI', sortable: true},
          { key: 'apellido', label: 'Apellido', sortable: true, sortDirection: 'desc'},
          { key: 'nombre', label: 'Nombre', sortable: true},
          { key: 'correo_electronico', label: 'Correo Electrónico', sortable: true},
          { key: 'actions', label: 'Vér' }
        ],
        sortBy: 'dni',
        sortDesc: true,
        sortDirection: 'desc',
        viewModal: {
          id: 'viewModal',
          title: '',
          content: ''
        }
      }
    },

    methods: {
      edit(item, index, button) {
        this.emprendedor.apellido = item.apellido;
        this.emprendedor.nombre = item.nombre;
        this.emprendedor.dni = item.dni;
        this.emprendedor.cuit_cuil = item.cuit_cuil;
        this.emprendedor.correo_electronico = item.correo_electronico;
        this.emprendedor.telefono = item.telefono;
        this.emprendedor.celular = item.celular;
        this.emprendedor.genero = item.genero[0].toUpperCase() + item.genero.slice(1);

        this.emprendedor.provincia = item.provincia;
        this.emprendedor.departamento = item.departamento;

        this.emprendedor.condicion_formacion = item.condicion_formacion[0].toUpperCase() + item.condicion_formacion.slice(1);
        this.emprendedor.nivel_formacion = item.nivel_formacion[0].toUpperCase() + item.nivel_formacion.slice(1);

        this.emprendedor.universidad = item.universidad;
        this.emprendedor.unidad_academica = item.unidad_academica;
        this.emprendedor.carrera = item.carrera;

        this.emprendedor.otra_universidad = item.otra_universidad;
        this.emprendedor.otra_carrera = item.otra_carrera;

        this.emprendedor.otros_datos = item.otros_datos;

        this.perfil = item.id;
        this.$root.$emit('bv::show::modal', this.viewModal.id, button);
      },

      closeModal(item, index, button){
        this.$root.$emit('bv::hide::modal', this.viewModal.id, button)
      }
    }
  };
</script>
<style>
.tab-integrantes thead tr th{
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.tab-integrantes thead th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 400px) {
  .tab-integrantes tbody tr td:nth-child(1) {
    text-align: left;
  }
  .tab-integrantes tbody tr td:nth-child(3) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {
  .tab-integrantes tbody tr td:nth-child(1) {
    text-align: center;
  }

  .tab-integrantes tbody tr td:nth-child(1) {
    text-align: center;
  }


  .tab-integrantes thead tr th:nth-child(5) {
    justify-content: center;
  }

  .tab-integrantes tbody tr td:nth-child(5) {
    justify-content: center;
  }
}
</style>