<template>
  <div>
    <h4>Necesidades de Capital</h4>
    <div class="card">
      <div class="col-md-12 pt-4 pb-4">
        <div class="row">
          <div class="col-md-12">
            
          <!-- User Interface controls -->
          <b-row>
            <b-col sm="12" class="my-3">
              <b-button 
                class="btn btn-info btn-fill" 
                @click="nuevo()">
                <i class="fa fa-plus"></i> 
                  Agregar
              </b-button>
            </b-col>
          </b-row>
          </div>
          <!-- Main table element -->
          <div class="col-md-12 pb-4">
            <b-table
              class="table-necesidad-capital"
              hover
              stacked="md"
              head-variant="dark"
              :items="necesidades_capital"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
            >
              <template v-slot:cell(fecha)="row">
                {{ row.item.fecha.toString().split("-").reverse().join("/") }}
              </template>

              <template v-slot:cell(actions)="row">
                <b-button size="sm" @click="editarNecesidadCapital(row.item, row.index, $event.target)" class="btn btn-warning btn-fill mr-1">
                <span class="fa fa-pencil"></span>
                </b-button>

                <b-button class="btn-danger btn-fill" size="sm" @click="deleteNecesidadCapital(row.item, row.index, $event.target)">
                <span class="fa fa-trash"></span>
                </b-button>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

  <b-modal size="lg" id="createNecesidadCapital" hide-footer>
    <div class="row">
      <div class="col-md-6">
        <label>Tipo de capital:</label>
        <b-form-select 
          v-model="id_tipo_financiamiento" 
          :options="tipos_financiamientos">
        </b-form-select>
      </div>

      <div class="col-md-6">
        <label>Destino de la inversión:</label>
        
        <b-form-select 
          v-model="id_destino_capital" 
          :options="destinos_inversion">
        </b-form-select>
      </div>

      <div class="col-md-6 pt-3">
        <label>Monto en Dolares:</label>
        <b-input-group size="md" prepend="$">
          <b-form-input type="number" 
            v-model="monto">
          </b-form-input>
        </b-input-group>
      </div>

      <div class="col-md-6 pt-3">
        <label>Fecha:</label>
        <b-form-input 
            v-model="fecha"   
            type="date"
            placeholder="dd/mm/aaaa"
            autocomplete="off">
        </b-form-input>
      </div>      
    </div>

    <div class="pt-4">
        <b-button 
          class="mt-3 pull-left btn-danger btn-fill" 
          @click="resetNecesidadCapital">Cancelar
        </b-button>
        
        <b-button 
          :disabled=" fecha.length!==10 || monto===''"
          class="mt-3 pull-right btn-info btn-fill" 
          @click="nuevaNecesidadCapital">Agregar
        </b-button>
    </div>
  </b-modal>

  <b-modal size="lg" id="editNecesidadCapital" hide-footer>
    <div class="row">
      <div class="col-md-6">
        Tipo de capital:
        <b-form-select 
          v-model="id_tipo_financiamiento" 
          :options="tipos_financiamientos">
        </b-form-select>
      </div>

      <div class="col-md-6">
        Destino de la inversión:
        <b-form-select 
          v-model="id_destino_capital" 
          :options="destinos_inversion">
        </b-form-select>
      </div>

      <div class="col-md-6 pt-3">
        <label>Monto en Dolares:</label>
        <b-input-group size="md" prepend="$">
          <b-form-input type="number" 
            v-model="monto">
          </b-form-input>
        </b-input-group>
      </div>

      <div class="col-md-6 pt-3">
        <label>Fecha:</label>
        <b-form-input 
            v-model="fecha"   
            type="date"
            placeholder="dd/mm/aaaa"
            autocomplete="off">
        </b-form-input>
      </div>      
    </div>

    <div class="pt-4">
        <b-button class="mt-3 pull-left btn-danger btn-fill" @click="resetNecesidadCapital">Cacelar</b-button>
        <b-button class="mt-3 pull-right btn-info btn-fill" @click="updateNecesidadCapital(editindex)">Actualizar</b-button>
    </div>
  </b-modal>
</div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    data () {
      return {
        first_tipo_financiamiento: null,
        id_tipo_financiamiento: null,
        tipos_financiamientos: [],
        
        first_destino_capital: null,
        id_destino_capital: null,
        destinos_inversion:[],

        monto: null,

        fecha:'',

        necesidades_capital:[],

        editindex: null,
        deleteIndex: null,
        fields: [
          { key: 'tipo_financiamiento', label: 'Tipo de Financiamiento',  sortable: true, thStyle: {width:'30%'} },
          { key: 'destino_capital',     label: 'Destino Financiamiento',  sortable: true, thStyle: {width:'30%'} },
          { key: 'fecha',               label: 'Fecha',      sortable: true,   thStyle: {width:'20%'} },
          { key: 'actions',             label: 'Acciones',      sortable: false},
        ],
        sortBy: null,
        sortDesc: true,
        sortDirection: 'desc',
      }
    },

    methods:{
      getNecesidadesCapital(){
        this.necesidades_capital = this.$store.state.emprendimiento.necesidad_capital_emprendimiento;
      },

      async getTiposFinanciamiento(){
        let r = await axios.get(URL_API+'api/tipo-financiamiento');

        var arr = [];
        var firts = true;
        var aux;

        r.data.forEach(function(element, index, rubros){
          if(firts==true){
            aux= element.id;
            firts=false;
          }
          arr.push({'value': element.id, 'text': element.tipo });
        });
        this.first_tipo_financiamiento=aux;
        this.id_tipo_financiamiento=aux;
        this.tipos_financiamientos = arr;
      },

      async getFinCapital(){
        let r = await axios.get(URL_API+'api/fin-capital');

        var arr = [];
        var firts = true;
        var aux;

        r.data.forEach(function(element, index, rubros){
          if(firts==true){
            aux= element.id;
            firts=false;
          }
          arr.push({'value': element.id, 'text': element.nombre });
        });
        this.first_destino_capital=aux;
        this.id_destino_capital=aux;
        this.destinos_inversion = arr;
      },

      nuevo(){
        this.resetNecesidadCapital();
        this.getTiposFinanciamiento();
        this.getFinCapital();
        this.$bvModal.show('createNecesidadCapital');
      },

      nuevaNecesidadCapital(){
        var id = this.id_tipo_financiamiento;
        var tipo_financiamiento = this.tipos_financiamientos.find(x => x.value == id).text;

        var id2 = this.id_destino_capital;
        var destino_capital = this.destinos_inversion.find(x => x.value == id2).text;

        var nueva_necesidad_capital = {
          'id':                     this.$store.state.emprendimiento.id_necesidad_capital_emprendimiento,
          'id_tipo_financiamiento': this.id_tipo_financiamiento,
          'tipo_financiamiento':    tipo_financiamiento,
          'id_destino_capital':     this.id_destino_capital,
          'destino_capital':        destino_capital,
          'monto':                  this.monto,
          'fecha':                  this.fecha,
        };

        this.$store.commit('addNecesidadCapital', nueva_necesidad_capital);
        this.resetNecesidadCapital();
      },

      editarNecesidadCapital(item, index, event){
        this.editindex                = item.id; 
        this.id_tipo_financiamiento   = item.id_tipo_financiamiento;
        this.id_destino_capital       = item.id_destino_capital;
        this.monto                    = item.monto;
        this.fecha  = item.fecha;

        this.$bvModal.show('editNecesidadCapital');
      },

      updateNecesidadCapital(index){
        var id = this.id_tipo_financiamiento;
        var tipo_financiamiento = this.tipos_financiamientos.find(x => x.value == id).text;

        var id2 = this.id_destino_capital;
        var destino_capital = this.destinos_inversion.find(x => x.value == id2).text;

        for(var i=0; i<this.$store.state.emprendimiento.necesidad_capital_emprendimiento.length; i++){

          if(this.$store.state.emprendimiento.necesidad_capital_emprendimiento[i].id === index){

            this.$store.state.emprendimiento.necesidad_capital_emprendimiento[i].id_tipo_financiamiento  = this.id_tipo_financiamiento;
            this.$store.state.emprendimiento.necesidad_capital_emprendimiento[i].tipo_financiamiento     = tipo_financiamiento;
            this.$store.state.emprendimiento.necesidad_capital_emprendimiento[i].id_destino_capital      = this.id_destino_capital;
            this.$store.state.emprendimiento.necesidad_capital_emprendimiento[i].destino_capital         = destino_capital;
            this.$store.state.emprendimiento.necesidad_capital_emprendimiento[i].monto                   = this.monto;
            this.$store.state.emprendimiento.necesidad_capital_emprendimiento[i].fecha = this.fecha;

          }
        }
        this.resetNecesidadCapital();
      },

      deleteNecesidadCapital(item, index, event){
        this.deleteIndex = item.id;
        if (confirm('¿Seguro que desea eliminarlo?')) {
          this.$store.commit('deleteNecesidadCapital', this.deleteIndex);
        }
        this.resetNecesidadCapital();
      },

      resetNecesidadCapital() {
        this.id_tipo_financiamiento = this.first_tipo_financiamiento;
        this.id_destino_capital = this.first_destino_capital;
        this.monto = null;
        this.fecha='';

        this.getNecesidadesCapital();

        this.$bvModal.hide('createNecesidadCapital');
        this.$bvModal.hide('editNecesidadCapital');
      },
    },

    created(){
      this.getTiposFinanciamiento();
      this.getFinCapital();
      this.getNecesidadesCapital();
    }
  };
</script>
<style>
.table-necesidad-capital {
  table-layout: fixed;
}

.table-necesidad-capital thead th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}


.table-necesidad-capital thead tr th{
  padding-top: .3rem;
  padding-bottom: .3rem;
}

@media screen and (min-width: 400px) {
  .table-necesidad-capital tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-necesidad-capital tbody tr td:nth-child(3) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {
  .table-necesidad-capital thead tr th:nth-child(3) {
    justify-content: center;
  }

  .table-necesidad-capital tbody tr td:nth-child(3) {
    text-align: center;
  }

  .table-necesidad-capital thead tr th:nth-child(4) {
    justify-content: center;
  }

  .table-necesidad-capital tbody tr td:nth-child(4) {
    justify-content: center;
  }
}
</style>