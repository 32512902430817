<template>
  <div>
    <h4>Estadio del Emprendimiento</h4>
    <div class="card">
      <div class="col-md-12 pt-4 pb-4">
        <div class="row">
          <div class="col-md-12">
          <!-- User Interface controls -->
          <b-row>
            <b-col sm="12" class="my-3">
              <b-button 
                class="btn btn-info btn-fill" 
                @click="nuevo()">
                <i class="fa fa-plus"></i> 
                  Agregar
              </b-button>
            </b-col>
          </b-row>
          </div>
          <!-- Main table element -->
          <div class="col-md-12 pb-4">
            <b-table
              class="table-registro-estadio"
              hover
              stacked="md"
              head-variant="dark"
              :items="registros_estadios"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
            >
              <template v-slot:cell(fecha)="row">
                {{ row.item.fecha.toString().split("-").reverse().join("/") }}
              </template>

              <template v-slot:cell(actions)="row">
                <b-button size="sm" @click="editarEstadio(row.item, row.index, $event.target)" class="btn btn-warning btn-fill mr-1">
                <span class="fa fa-pencil"></span>
                </b-button>

                <b-button class="btn-danger btn-fill" size="sm" @click="deleteEstadio(row.item, row.index, $event.target)">
                <span class="fa fa-trash"></span>
                </b-button>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

  <b-modal size="lg" id="createEstadio" hide-footer>
    <div class="row">

      <div class="col-sm-12">
        Plataforma de innovación:
        <b-form-select 
          v-model="id_plataformas_emprendimientos" 
          :options="plataformas">
        </b-form-select>
      </div>

      <div class="col-md-4 pt-3">
        Estadío:
        <b-form-select 
          v-model="id_estadio_emprendimientos" 
          :options="estadios">
        </b-form-select>
      </div>

      <div class="col-md-4 pt-3">
        Situación:
        <b-form-select 
          v-model="id_situacion_estadios" 
          :options="situacions">
        </b-form-select>
      </div>

      <div class="col-md-4 pt-3">
        Fecha:
        <b-form-input 
            v-model="fecha"   
            type="date"
            placeholder="dd/mm/aaaa"
            autocomplete="off">
        </b-form-input>
      </div>
    </div>
    <div class="pt-4">
        <b-button 
          class="mt-3 pull-left btn-danger btn-fill" 
          @click="resetModal()">
          Cancelar
        </b-button>
        <b-button 
          :disabled="fecha===''"
          class="mt-3 pull-right btn-info btn-fill" 
          @click="nuevoEstadio()">
          Agregar
        </b-button>
    </div>
  </b-modal>

  <b-modal size="lg" id="editEstadio" hide-footer>
    <div class="row">

      <div class="col-sm-12 pt-3">
        Plataforma de innovación:
        <b-form-select 
          v-model="id_plataformas_emprendimientos_edit" 
          :options="plataformas">
        </b-form-select>
      </div>

      <div class="col-md-4 pt-3">
        Estadío:
        <b-form-select 
          v-model="id_estadio_emprendimientos_edit" 
          :options="estadios">
        </b-form-select>
      </div>

      <div class="col-md-4 pt-3">
        Situación:
        <b-form-select 
          v-model="id_situacion_estadios_edit" 
          :options="situacions">
        </b-form-select>
      </div>

      <div class="col-md-4 pt-3">
        Fecha:
        <b-form-input 
            v-model="fecha_edit"   
            type="date"
            placeholder="dd/mm/aaaa"
            autocomplete="off">
        </b-form-input>
      </div>
    </div>
    <div class="pt-4">
        <b-button 
          class="mt-3 pull-left btn-danger btn-fill" 
          @click="resetModal()">
          Cacelar
        </b-button>
        <b-button
          :disabled="fecha_edit===''"
          class="mt-3 pull-right btn-info btn-fill" 
          @click="updateEstadio(editindex)">
          Actualizar
        </b-button>
    </div>
  </b-modal>
</div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    data () {
      return {
        first_plataforma: null,
        id_plataformas_emprendimientos: null,
        plataformas:[],

        first_estadio: null,
        id_estadio_emprendimientos: null,
        estadios: [],

        first_situacion: null,
        id_situacion_estadios: null,
        situacions:[],

        fecha: '',

        registros_estadios: [],

        //--------Editar Estadios--------
        id_plataformas_emprendimientos_edit:null,
        id_estadio_emprendimientos_edit: null,
        id_situacion_estadios_edit: null,
        fecha_edit: '',

        editindex: null,
        deleteIndex: null,
        fields: [
          { key: 'plataforma',      label: 'Plataforma',sortable: true },
          { key: 'estadio',         label: 'Estadío',   sortable: true },
          { key: 'situacion',       label: 'Situación', sortable: true },
          { key: 'fecha',           label: 'Fecha',     sortable: true },
          { key: 'actions',         label: 'Acciones',  sortable: false},
        ],
        sortBy: 'fecha',
        sortDesc: false,
        sortDirection: 'desc',
      }
    },

    methods:{
      async getPlataformas(){
        let r = await axios.get(URL_API+'api/plataformas-emprendimientos');

        var arr = [];
        var first = true;
        var aux;

        r.data.forEach(function(element, index){
          if(first===true){
            aux = element.id;
            first = false;
          }
          arr.push({'value': element.id, 'text': element.nombre , 'shortText': element.nombre_corto});
        });

        this.first_plataforma = aux;
        this.id_plataformas_emprendimientos = aux;
        this.plataformas = arr;
      },

      async getEstadios(){
        let r = await axios.get(URL_API+'api/estadio-emprendimiento');

        var arr = [];
        var first = true;
        var aux;

        r.data.forEach(function(element, index){
          if(first==true){
            aux = element.id;
            first = false;
          }
          arr.push({'value': element.id, 'text': element.estadio });
        });

        this.first_estadio = aux;
        this.id_estadio_emprendimientos = aux;
        this.estadios = arr;
      },

      async getSituacionEstadios(){
        let r = await axios.get(URL_API+'api/situacion-estadio');

        var arr = [];
        var first = true;
        var aux;

        r.data.forEach(function(element, index){
          if(first==true){
            aux = element.id;
            first = false;
          }
          arr.push({'value': element.id, 'text': element.nombre });
        });

        this.first_situacion = aux;
        this.id_situacion_estadios = aux;
        this.situacions = arr;
      },

      getRegistrosEstadios(){
        this.registros_estadios = this.$store.state.emprendimiento.estadios;
      },

      nuevo(){
        this.getPlataformas();
        this.getEstadios();
        this.getSituacionEstadios();
        this.$bvModal.show('createEstadio');
      },

      nuevoEstadio(){
        var id = this.id_plataformas_emprendimientos;
        var plataforma = this.plataformas.find(x => x.value == id).shortText;

        var id = this.id_estadio_emprendimientos;
        var estadio = this.estadios.find(x => x.value == id).text;

        var id = this.id_situacion_estadios;
        var situacion = this.situacions.find(x => x.value == id).text;

        var nuevo_estadio = {
          'id':           this.$store.state.emprendimiento.id_estadios,
          'id_plataformas_emprendimientos':this.id_plataformas_emprendimientos,
          'plataforma':   plataforma,
          'id_estadio_emprendimientos':   this.id_estadio_emprendimientos,
          'estadio':      estadio,
          'id_situacion_estadios': this.id_situacion_estadios,
          'situacion'   : situacion,
          'fecha':this.fecha
        };

        this.$store.commit('addEstadio', nuevo_estadio);
        this.resetModal();
      },

      editarEstadio(item, index, event){
        this.editindex = item.id;
        this.id_plataformas_emprendimientos_edit = item.id_plataformas_emprendimientos;
        this.id_estadio_emprendimientos_edit = item.id_estadio_emprendimientos;
        this.id_situacion_estadios_edit = item.id_situacion_estadios;
        this.fecha_edit = item.fecha;

        this.$bvModal.show('editEstadio');
      },

      updateEstadio(index){
        var id = this.id_plataformas_emprendimientos_edit;
        var plataforma_edit = this.plataformas.find(x => x.value == id).shortText;

        var id = this.id_estadio_emprendimientos_edit;
        var estadio_edit = this.estadios.find(x => x.value == id).text;

        var id = this.id_situacion_estadios_edit;
        var situacion_edit = this.situacions.find(x => x.value == id).text;

        for(var i=0; i<this.$store.state.emprendimiento.estadios.length; i++){
          if(this.$store.state.emprendimiento.estadios[i].id === index){

            this.$store.state.emprendimiento.estadios[i].id_plataformas_emprendimientos  = this.id_plataformas_emprendimientos_edit;
            this.$store.state.emprendimiento.estadios[i].plataforma     = plataforma_edit;

            this.$store.state.emprendimiento.estadios[i].id_estadio_emprendimientos     = this.id_estadio_emprendimientos_edit;
            this.$store.state.emprendimiento.estadios[i].estadio        = estadio_edit;

            this.$store.state.emprendimiento.estadios[i].id_situacion_estadios   = this.id_situacion_estadios_edit;
            this.$store.state.emprendimiento.estadios[i].situacion      = situacion_edit;

            this.$store.state.emprendimiento.estadios[i].fecha  = this.fecha_edit;
          }
        }
        this.resetModal();
      },

      deleteEstadio(item, index, event){
        this.deleteIndex = item.id;
        if (confirm('¿Seguro que desea eliminarlo?')) {
          this.$store.commit('deleteEstadio', this.deleteIndex);
        }
        this.resetModal();
      },

      resetModal() {
        this.id_plataformas_emprendimientos = this.first_plataforma;
        this.id_estadio_emprendimientos    = this.first_estadio;
        this.id_situacion_estadios  = this.first_situacion;
        this.fecha = '';

        this.id_plataformas_emprendimientos_edit   = null;
        this.id_estadio_emprendimientos_edit      = null;
        this.id_situacion_estadios_edit    = null;
        this.fecha_edit   = '';

        this.$bvModal.hide('createEstadio');
        this.$bvModal.hide('editEstadio');
      },
    },

    created(){
      this.getPlataformas();
      this.getEstadios();
      this.getSituacionEstadios();
      this.getRegistrosEstadios();
    }
  };
</script>
<style>
.table-registro-estadio {
  table-layout: fixed;
}

.table-registro-estadio thead th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}


.table-registro-estadio thead tr th{
  padding-top: .5rem;
  padding-bottom: .5rem;
}

@media screen and (min-width: 400px) {
  .table-registro-estadio tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-registro-estadio tbody tr td:nth-child(3) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {
  .table-registro-estadio tbody tr td:nth-child(1) {
    text-align: center;
  }

  .table-registro-estadio tbody tr td:nth-child(1) {
    text-align: center;
  }


  .table-registro-estadio thead tr th:nth-child(2) {
    justify-content: center;
  }

  .table-registro-estadio tbody tr td:nth-child(2) {
    text-align: center;
  }

  .table-registro-estadio thead tr th:nth-child(3) {
    justify-content: center;
  }

  .table-registro-estadio tbody tr td:nth-child(3) {
    text-align: center;
  }

  .table-registro-estadio thead tr th:nth-child(4) {
    justify-content: center;
  }

  .table-registro-estadio tbody tr td:nth-child(4) {
    text-align: center;
  }

  .table-registro-estadio thead tr th:nth-child(5) {
    justify-content: center;
  }

  .table-registro-estadio tbody tr td:nth-child(5) {
    justify-content: center;
  }
}
</style>