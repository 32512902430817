<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">

          <DatosAdministrativosEmprendimiento />
          <InfoEmprendimiento />
          <VincularOtroProgramaEmprendimiento />

          <div class="col-md-12 pt-4 pb-4">
            <div class="row">
              <div class="col-sm-12">
                <router-link 
                  class="mt-3 btn btn-danger btn-fill"
                  :to="({name: 'createUNLPotencia2'})" >
                  Volver
                </router-link>

                <b-button 
                  class="mt-3 btn btn-info btn-fill" 
                  style="float: right;"
                  @click="siguiente">
                  Siguiente
                </b-button>
              </div>
            </div>
          </div>


          <b-modal size="md" id="errors" hide-footer>
            <div class="row">
              <div class="col-md-12 pt-3">
                <ul>
                  <li v-for="item in errors">
                    {{ item.error }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="pt-4">
              <b-button 
                class="mt-3 pull-right btn-danger btn-fill" 
                @click="resetModalErrors">
                Aceptar
              </b-button>
            </div>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;
  import DatosAdministrativosEmprendimiento from 'src/components/Emprendimiento/DatosAdministrativosEmprendimiento.vue'
  import InfoEmprendimiento from 'src/components/Emprendimiento/InfoEmprendimiento.vue'
  import VincularOtroProgramaEmprendimiento from 'src/components/Emprendimiento/VincularOtroProgramaEmprendimiento.vue'

  export default {
    components: {
      DatosAdministrativosEmprendimiento,
      InfoEmprendimiento,
      VincularOtroProgramaEmprendimiento
    },
    data () {
      return { 
        errors:[],
        condicion_emprendimiento: [
            {value:'Vinculado', text:'Vinculado'},
            {value:'Desvinculado', text:'Desvinculado'},
          ],
        motivos_desvinculacion:[
          {value:'Egreso', text:'Egreso'},
          {value:'Desvinculación', text:'Desvinculación'}
        ]
      }
    },        

    methods:{
      scrollToTop(){
        window.scrollTo(0, -1)
      },

      currentRoute(){
          this.$store.commit('setSubTitulo', {setSubTitulo: 'nuevo 3/5'})
      },

      onlyNumber ($event) {
        //console.log($event.keyCode); //keyCodes value
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        if ((keyCode < 48 || keyCode > 57) && keyCode!== 47 && keyCode!== 45) {
          $event.preventDefault();
        }
      },

      verificarEmprendimiento(){
        if(this.$store.state.emprendimiento_create==false){
          this.$router.push( {name:'createUNLPotencia1'});
        }
      },

      siguiente(){
        this.errors=[];

        if(this.$store.state.emprendimiento.fecha_alta==''){
          this.errors.push({'error':'Seleccione la FECHA DE ALTA del emprendimiento antes de continuar'});
        }

        if(this.$store.state.emprendimiento.condicion=='Desvinculado'){
          if(this.$store.state.emprendimiento.fecha_baja==''||this.$store.state.emprendimiento.fecha_baja==null){
            this.errors.push({'error':'Seleccione la FECHA DE BAJA del emprendimiento antes de continuar'});
          }  
        }

        if(this.errors[0]){
          this.$root.$emit('bv::show::modal','errors');
        }else{
          this.$router.push({name:'createUNLPotencia4'});
        }
      },

      resetModalErrors(){
        this.errors=[];
        this.$root.$emit('bv::hide::modal','errors');
      },
    },

    computed:{
      numNotaSVyTTEmprendimiento:{
        get(){
          return this.$store.state.emprendimiento.num_nota_svytt
        },
        set(value){
          this.$store.commit('setNumeroNotaSVyTTEmprendimiento', {numNotaSVyTTEmprendimiento: value})
        }
      },

      numExpedienteEmprendimiento:{
        get(){
          return this.$store.state.emprendimiento.num_expediente
        },
        set(value){
          this.$store.commit('setNumeroExpedienteEmprendimiento', {numExpedienteEmprendimiento: value})
        }
      },

      fechaAltaEmprendimiento:{
        get(){
          return this.$store.state.emprendimiento.fecha_alta
        },
        set(value){
          this.$store.commit('setFechaAltaEmprendimiento', {fechaAltaEmprendimiento: value})
        }
      },

      condicionEmprendimiento:{
        get(){
          return this.$store.state.emprendimiento.condicion
        },
        set(value){
          this.$store.commit('setCondicionEmprendimiento', {condicionEmprendimiento: value})
        }
      },

      fechaBajaEmprendimiento:{
        get(){
          return this.$store.state.emprendimiento.fecha_baja
        },
        set(value){
          this.$store.commit('setFechaBajaEmprendimiento', {fechaBajaEmprendimiento: value})
        }
      },

      motivoDesvinculacionEmprendimiento:{
        get(){
          return this.$store.state.emprendimiento.motivo_desvinculacion
        },
        set(value){
          this.$store.commit('setMotivoDesvinculacionEmprendimiento', {motivoDesvinculacionEmprendimiento: value})
        }
      },
    },

    created(){
      this.$Progress.start();
      this.scrollToTop();
      this.verificarEmprendimiento();
      this.currentRoute();
      this.$Progress.finish();
    }

  };
</script>
<style>

</style>
