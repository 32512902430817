<template>
	<div>
		<h4>Datos Administrativos</h4>
    <b-card>
      <b-container>
        <b-row>
          <b-col md="6" xs="12" class="pt-2">
            <b-row>
              <b-col class="titulo" md="6" xs="12">
                <label>N° Nota SVyTT:</label>
              </b-col>
              <b-col cols="6">
                {{ ((this.num_nota_svytt!=null) ? this.num_nota_svytt : '--') }}
              </b-col>
            </b-row>
          </b-col>

          <b-col md="6" xs="12" class="pt-2">
            <b-row>
              <b-col class="titulo" md="6" xs="12">
                <label>N° Expediente:</label>
              </b-col>
              <b-col cols="6">
                {{ ((this.num_expediente!=null) ? this.num_expediente : '--') }}
              </b-col>
            </b-row>
          </b-col>

          <b-col md="6" xs="12" class="pt-2">
            <b-row>
              <b-col class="titulo" md="6" xs="12">
                <label>Fecha de Alta:</label>
              </b-col>
              <b-col cols="6">
                {{ this.fecha_alta.split("-").reverse().join("/") }}
              </b-col>
            </b-row>
          </b-col>

          <b-col md="6" xs="12" class="pt-2">
            <b-row>
              <b-col class="titulo" md="6" xs="12">
                <label>Condicion:</label>
              </b-col>
              <b-col cols="6">
                <div>{{ this.condicion }}</div>
              </b-col>
            </b-row>
          </b-col>

          <template v-if="this.condicion=='Desvinculado'">
            <b-col md="6" xs="12" class="pt-2">
              <b-row>
                <b-col class="titulo" md="6" xs="12">
                  <label>Fecha de Baja:</label>
                </b-col>
                <b-col cols="6">
                  {{ this.fecha_baja.split("-").reverse().join("/") }}
                </b-col>
              </b-row>
            </b-col>

            <b-col md="6" xs="12" class="pt-2">
              <b-row>
                <b-col class="titulo" md="6" xs="12">
                  <label>Motivo Baja:</label>
                </b-col>
                <b-col cols="6">
                  <div style="text-transform: capitalize;">{{ this.motivo_desvinculacion }}</div>
                </b-col>
              </b-row>
            </b-col>  

          </template>
        </b-row>
      </b-container>
    </b-card>
	</div>
</template>

<script>
  export default {
    props: ['num_nota_svytt', 'num_expediente', 'fecha_alta', 'condicion', 'fecha_baja', 'motivo_desvinculacion'],
  };
</script>
<style>
@media (min-width: 768.98px) { 
  .titulo{
    text-align: right;
  }
}
</style>