<template>
    <div>
      <stats-card class="pb-2">
        <div slot="header" class="icon-success">
          <i class="fas fa-users text-info"></i>
        </div>
        <div slot="content">
          <p class="card-category">Emprendedores <br>{{ (this.subtitulo=='Emprendimientos UNL')?'UNL':this.subtitulo }}</p>
          <h4 class="card-title">{{ this.data.emprendedores }}</h4>
        </div>
      </stats-card>

      <stats-card class="pb-1">
        <div slot="header" class="icon-success">
          <i class="fas fa-venus text-info"></i>
        </div>
        <div slot="content">
          <p class="card-category">Emprendedoras <br> femeninas</p>
          <h4 class="card-title">{{ this.data.emprendedores_femeninas }}</h4>
        </div>
      </stats-card>

      <stats-card class="pb-1">
        <div slot="header" class="icon-success">
          <i class="fas fa-mars text-info"></i>
        </div>
        <div slot="content">
          <p class="card-category">Emprendedores <br> masculinos</p>
          <h4 class="card-title">{{ this.data.emprendedores_masculinos }}</h4>
        </div>
      </stats-card>

      <stats-card class="pb-1">
        <div slot="header" class="icon-success">
          <i class="fas fa-mars-stroke-h text-info"></i>
        </div>
        <div slot="content">
          <p class="card-category">Emprendedores <br> otros</p>
          <h4 class="card-title">{{ this.data.emprendedores_otros }}</h4>
        </div>
      </stats-card>
    </div>
</template>

<script>
  import ChartCard from 'src/components/Cards/ChartCard.vue'
  import StatsCard from 'src/components/Cards/StatsCard.vue'
  import LTable from 'src/components/Table.vue'

  export default {
    components: {
      LTable,
      ChartCard,
      StatsCard
    },
    props: ['titulo','subtitulo', 'data']
  };
</script>