<template>
	<div>
		<h4>Formalización <small>juridica e impositiva</small></h4>
    <b-card>
      <b-container>
        <b-row>
          <b-col cols="12" class="pt-2">
            <b-row>
              <b-col class="titulo" md="3" xs="12">
                <label>Formalización Juridica:</label>
              </b-col>
              <b-col md="9" xs="12">
                {{ this.formalizacion_juridica.toUpperCase() }}
              </b-col>
            </b-row>
          </b-col>
          
          <template v-if="this.formalizacion_juridica!='no'">
            <b-col cols="12" class="pt-2">
              <b-row>
                <b-col class="titulo" md="3" xs="12">
                  <label>Fecha de alta:</label>
                </b-col>
                <b-col md="9" xs="12">
                  {{ this.fecha_estructura_juridica.split('-').reverse().join('/') }}
                </b-col>
              </b-row>
            </b-col>
          </template>

          <template v-if="this.formalizacion_juridica!='no'">
            <b-col cols="12" class="pt-2">
              <b-row>
                <b-col class="titulo" md="3" xs="12">
                  <label>Tipo:</label>
                </b-col>
                <b-col md="9" xs="12">
                  {{ this.estructura_juridica }}
                </b-col>
              </b-row>
            </b-col>
          </template>

        </b-row>
      </b-container>
    </b-card>
	</div>
</template>

<script>
  export default {
    props: ['formalizacion_juridica', 'fecha_estructura_juridica', 'estructura_juridica'],
  };
</script>