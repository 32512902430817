<template>
  <ul class="nav nav-mobile-menu">
    <li class="nav-item">
      <a class="nav-link" @click="logout">
        <span class="no-icon">Cerrar Sesión</span>
      </a>
    </li>
  </ul>
</template>
<script>
  import axios from 'axios'
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    name: 'mobile-menu',

    methods:{
      logout(){
        axios.post(URL_API+'api/auth/logout').then(response => {
          localStorage.removeItem('access_token');
          localStorage.removeItem('token_type');
          localStorage.removeItem('expires_in');
          localStorage.removeItem('name');
          localStorage.removeItem('lastname');
          localStorage.removeItem('email');

          this.$router.push({name:'login'})
        }).catch(e => {
          console.log(e);
        });
      }
    }
  }
</script>
<style>
</style>
