<template>
  <div>
    <h4>Necesidades de Internacionalización ó Escalabilidad</h4>
    <div class="card">
      <div class="col-md-12 pt-4 pb-4">
        <div class="row">
          <div class="col-md-12">
            
          <!-- User Interface controls -->
          <b-row>
            <b-col sm="12" class="my-3">
              <b-button 
                class="btn btn-info btn-fill" 
                @click="nuevo()">
                <i class="fa fa-plus"></i> 
                  Agregar
              </b-button>
            </b-col>
          </b-row>
          </div>
          <!-- Main table element -->
          <div class="col-md-12 pb-4">
            <b-table
              class="table-necesidad-internacionalizacion"
              hover
              stacked="md"
              head-variant="dark"
              :items="necesidades_internacionalizacion"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              > 
              <template v-slot:cell(fecha)="row">
                    {{ row.item.fecha.toString().split("-").reverse().join("/") }}
              </template>

              <template v-slot:cell(actions)="row">
                <b-button size="sm" @click="editarNecesidadInternacionalizacion(row.item, row.index, $event.target)" class="btn btn-warning btn-fill mr-1">
                <span class="fa fa-pencil"></span>
                </b-button>

                <b-button class="btn-danger btn-fill" size="sm" @click="deleteNecesidadInternacionalizacion(row.item, row.index, $event.target)">
                <span class="fa fa-trash"></span>
                </b-button>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <b-modal size="lg" id="createNecesidadInternacionalizacion" hide-footer>
      <div class="row">
        <div class="col-md-8">
          <label>Tipo de internacionalización:</label>
          <b-form-select 
            v-model="id_internacionalizacion" 
            :options="tipos_internacionalizacion">
          </b-form-select>
        </div>

        <div class="col-md-4">
          <label>Fecha:</label>
          <b-form-input     
              v-model="fecha" 
              type="date"
              placeholder="dd/mm/aaaa"
              autocomplete="off">
          </b-form-input>
        </div>      
      </div>

      <div class="pt-4">
          <b-button 
            class="mt-3 pull-left btn-danger btn-fill" 
            @click="resetNecesidadInternacionalizacion()">
            Cacelar
          </b-button>
          <b-button     
            :disabled="fecha===''"
            class="mt-3 pull-right btn-info btn-fill" 
            @click="nuevaNecesidadInternacionalizacion()">
            Agregar
          </b-button>
      </div>
    </b-modal>

    <b-modal size="lg" id="editNecesidadInternacionalizacion" hide-footer>
      <div class="row">
        <div class="col-md-8">
          <label>Tipo de Internacionalización:</label>
          <b-form-select 
            v-model="id_internacionalizacion" 
            :options="tipos_internacionalizacion">
          </b-form-select>
        </div>

        <div class="col-md-4">
          <label>Fecha:</label>
          <b-form-input     
              v-model="fecha" 
              type="date"
              placeholder="dd/mm/aaaa"
              autocomplete="off">
          </b-form-input>
        </div>      
      </div>

      <div class="pt-4">
          <b-button 
            class="mt-3 pull-left btn-danger btn-fill" 
            @click="resetNecesidadInternacionalizacion()">
            Cacelar
          </b-button>
          <b-button     
            :disabled="fecha===''"
            class="mt-3 pull-right btn-info btn-fill" 
            @click="updateNecesidadInternacionalizacion(editindex)">
            Actualizar
          </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    data () {
      return {
        first_asesoria: null,
        id_internacionalizacion: null,
        tipos_internacionalizacion: [],
        
        fecha:'',

        necesidades_internacionalizacion:[],

        editindex: null,
        deleteIndex: null,
        fields: [
          { key: 'internacionalizacion',  label: 'Tipo de Internacionalizacion',  sortable: true, thStyle: {width:'60%'} },
          { key: 'fecha',                 label: 'Fecha',                         sortable: true, thStyle: {width:'20%'} },
          { key: 'actions',               label: 'Acciones',                      sortable: false },
        ],
        sortBy: null,
        sortDesc: true,
        sortDirection: 'desc',
      }
    },

    methods:{
      getNecesidadesInternacionalizacion(){
        this.necesidades_internacionalizacion = this.$store.state.emprendimiento.necesidad_internacionalizacion_emprendimiento;
      },

      async getTiposInternacionalizacion(){
        let r = await axios.get(URL_API+'api/servicios-internacionalizacion');

        var arr = [];
        var firts = true;
        var aux;

        r.data.forEach(function(element, index, rubros){
          if(firts==true){
            aux= element.id;
            firts=false;
          }
          arr.push({'value': element.id, 'text': element.nombre });
        });
        this.first_asesoria=aux;
        this.id_internacionalizacion=aux;
        this.tipos_internacionalizacion = arr;
      },

      nuevo(){
        this.resetNecesidadInternacionalizacion();
        this.getTiposInternacionalizacion();
        this.$bvModal.show('createNecesidadInternacionalizacion');
      },

      nuevaNecesidadInternacionalizacion(){
        var id = this.id_internacionalizacion;
        var internacionalizacion = this.tipos_internacionalizacion.find(x => x.value == id).text;

        var nueva_internacionalizacion = {
          'id':                                   this.$store.state.emprendimiento.id_necesidad_internacionalizacion_emprendimiento,
          'id_internacionalizacion':              this.id_internacionalizacion,
          'internacionalizacion':                 internacionalizacion, 
          'fecha': this.fecha
          };

        this.$store.commit('addNecesidadInternacionalizacion', nueva_internacionalizacion);
        this.resetNecesidadInternacionalizacion();
      },

      editarNecesidadInternacionalizacion(item, index, event){
        this.editindex                = item.id;
        this.id_internacionalizacion  = item.id_internacionalizacion; 
        this.fecha                    = item.fecha

        this.$bvModal.show('editNecesidadInternacionalizacion');
      },

      updateNecesidadInternacionalizacion(index){
        var id = this.id_internacionalizacion;
        var internacionalizacion = this.tipos_internacionalizacion.find(x => x.value == id).text;

        for(var i=0; i<this.$store.state.emprendimiento.necesidad_internacionalizacion_emprendimiento.length; i++){

          if(this.$store.state.emprendimiento.necesidad_internacionalizacion_emprendimiento[i].id === index){

            this.$store.state.emprendimiento.necesidad_internacionalizacion_emprendimiento[i].id_internacionalizacion               = this.id_internacionalizacion;
            this.$store.state.emprendimiento.necesidad_internacionalizacion_emprendimiento[i].internacionalizacion                  = internacionalizacion ; 
            this.$store.state.emprendimiento.necesidad_internacionalizacion_emprendimiento[i].fecha  =  this.fecha;

          }
        }
        this.resetNecesidadInternacionalizacion();
      },

      deleteNecesidadInternacionalizacion(item, index, event){
        this.deleteIndex = item.id;
        if (confirm('¿Seguro que desea eliminarlo?')) {
          this.$store.commit('deleteNecesidadInternacionalizacion', this.deleteIndex);
        }
        this.resetNecesidadInternacionalizacion();
      },

      resetNecesidadInternacionalizacion() {
        this.id_internacionalizacion = this.first_asesoria;
        this.fecha ='' 
        
        this.getNecesidadesInternacionalizacion();

        this.$bvModal.hide('createNecesidadInternacionalizacion');
        this.$bvModal.hide('editNecesidadInternacionalizacion');
      },
    },

    created(){
      this.getTiposInternacionalizacion();
      this.getNecesidadesInternacionalizacion();
    }
  };
</script>
<style>
.table-necesidad-internacionalizacion {
  table-layout: fixed;
}

.table-necesidad-internacionalizacion thead th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}


.table-necesidad-internacionalizacion thead tr th{
  padding-top: .3rem;
  padding-bottom: .3rem;
}

@media screen and (min-width: 400px) {
  .table-necesidad-internacionalizacion tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-necesidad-internacionalizacion tbody tr td:nth-child(3) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {
  .table-necesidad-internacionalizacion thead tr th:nth-child(2) {
    justify-content: center;
  }

  .table-necesidad-internacionalizacion tbody tr td:nth-child(2) {
    text-align: center;
  }

  .table-necesidad-internacionalizacion thead tr th:nth-child(3) {
    justify-content: center;
  }

  .table-necesidad-internacionalizacion tbody tr td:nth-child(3) {
    justify-content: center;
  }
}
</style>