<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <NecesidadCapitalEmprendimiento />
          <NecesidadSocioEmprendimiento />
          <NecesidadAsesoriaEmprendimiento />
          <NecesidadInternacionalizacionEmprendimiento />
          <NecesidadOtrasEmprendimiento />
        </div>

        <div class="col-md-12 pt-4 pb-4">
          <div class="row">
            <div class="col-sm-12">
              <router-link 
                :disabled="enviando"
                class="mt-3 btn btn-danger btn-fill"
                :to="({name: 'createBIO4'})" >
                Volver
              </router-link>

              <b-button 
                :disabled="enviando"
                class="mt-3 btn btn-info btn-fill" 
                @click="enviar" 
                style="float: right;">Guardar
              </b-button>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  import NecesidadCapitalEmprendimiento from 'src/components/Emprendimiento/NecesidadCapitalEmprendimiento.vue'
  import NecesidadSocioEmprendimiento from 'src/components/Emprendimiento/NecesidadSocioEmprendimiento.vue'
  import NecesidadAsesoriaEmprendimiento from 'src/components/Emprendimiento/NecesidadAsesoriaEmprendimiento.vue'
  import NecesidadInternacionalizacionEmprendimiento from 'src/components/Emprendimiento/NecesidadInternacionalizacionEmprendimiento.vue'
  import NecesidadOtrasEmprendimiento from 'src/components/Emprendimiento/NecesidadOtrasEmprendimiento.vue'

  export default {
    components: {
      NecesidadCapitalEmprendimiento,
      NecesidadSocioEmprendimiento,
      NecesidadAsesoriaEmprendimiento,
      NecesidadInternacionalizacionEmprendimiento,
      NecesidadOtrasEmprendimiento
    },
    data () {
      return {
        enviando: false,
      }
    },        

    methods:{
      currentRoute(){
          this.$store.commit('setSubTitulo', {setSubTitulo: 'nuevo 5/5'})
      },

      verificarEmprendimiento(){
        if(this.$store.state.emprendimiento_create==false){
          this.$router.push( {name:'createBIO1'});
        }
      },

      enviar(){
        this.$Progress.start();
        this.enviando=true;

        const response = axios.post(URL_API+'api/emprendimientosBIO', {
          nombre:                 this.$store.state.emprendimiento.nombre,
          descripcion:            this.$store.state.emprendimiento.descripcion,
          razon_social:           this.$store.state.emprendimiento.razon_social,
          palabras_claves:        this.$store.state.emprendimiento.palabras_claves,
          anio_inicio:            this.$store.state.emprendimiento.anio_inicio,
          id_perfil:              this.$store.state.emprendimiento.id_perfil,
          id_rubro_principal:     this.$store.state.emprendimiento.id_rubro_principal,
          id_rubro_secundario:    this.$store.state.emprendimiento.id_rubro_secundario,
          id_provincia:           this.$store.state.emprendimiento.id_provincia,
          id_localidad:           this.$store.state.emprendimiento.id_localidad,
          correo_emprendimiento:  this.$store.state.emprendimiento.correo_emprendimiento,
          telefono_emprendimiento:this.$store.state.emprendimiento.telefono_emprendimiento,
          web:                    this.$store.state.emprendimiento.web,
          facebook:               this.$store.state.emprendimiento.facebook,
          instagram:              this.$store.state.emprendimiento.instagram,
          linkedin:               this.$store.state.emprendimiento.linkedin,

          //-------Step two---------//

          emprendedores:          JSON.stringify(this.$store.state.emprendimiento.emprendedores),
          dni_responsable_emprendimiento: this.$store.state.emprendimiento.dni_responsable_emprendimiento,
          
          //-------Step three---------//

          num_nota_svytt:         this.$store.state.emprendimiento.num_nota_svytt,
          num_expediente:         this.$store.state.emprendimiento.num_expediente,
          fecha_alta:             this.$store.state.emprendimiento.fecha_alta,
          condicion:              this.$store.state.emprendimiento.condicion,
          fecha_baja:             this.$store.state.emprendimiento.fecha_baja,
          motivo_desvinculacion:  this.$store.state.emprendimiento.motivo_desvinculacion,

          id_incubado_en:         this.$store.state.emprendimiento.id_incubado_en,

          programas_vinculados:   JSON.stringify(this.$store.state.emprendimiento.programas_vinculados),

          //-------Step four---------//

          estadios:               JSON.stringify(this.$store.state.emprendimiento.estadios),

          registros_mdn:          JSON.stringify(this.$store.state.emprendimiento.registros_mdn),

          registros_pdn:          JSON.stringify(this.$store.state.emprendimiento.registros_pdn),

          url_pitch:              this.$store.state.emprendimiento.url_pitch,

          estado_perfil_equipo_emprendedor:   JSON.stringify(this.$store.state.emprendimiento.estado_perfil_equipo_emprendedor),

          perfiles_existentes_equipo_emprendedor:   JSON.stringify(this.$store.state.emprendimiento.perfiles_existentes_equipo_emprendedor),

          roles_miembros_equipo_emprendedor:   JSON.stringify(this.$store.state.emprendimiento.roles_miembros_equipo_emprendedor),

          proteccion_tecnologica: this.$store.state.emprendimiento.proteccion_tecnologica,
          esta_protegida:         this.$store.state.emprendimiento.esta_protegida,
          estado_proteccion:      this.$store.state.emprendimiento.estado_proteccion,

          formalizacion_juridica:  this.$store.state.emprendimiento.formalizacion_juridica,
          fecha_estructura_juridica:this.$store.state.emprendimiento.fecha_estructura_juridica,
          id_estructura_juridica:  this.$store.state.emprendimiento.id_estructura_juridica,
          otra_estructura_juridica:this.$store.state.emprendimiento.otra_estructura_juridica,

          financiamiento_obtenido_emprendimiento: JSON.stringify(this.$store.state.emprendimiento.financiamiento_obtenido_emprendimiento),

          premios_concursos_emprendimiento: JSON.stringify(this.$store.state.emprendimiento.premios_concursos_emprendimiento),

          capacitaciones_emprendimiento:          JSON.stringify(this.$store.state.emprendimiento.capacitaciones_emprendimiento),

          asesorias_emprendimiento:               JSON.stringify(this.$store.state.emprendimiento.asesorias_emprendimiento),

          mentorias_emprendimiento:               JSON.stringify(this.$store.state.emprendimiento.mentorias_emprendimiento),

          observaciones_emprendimiento:           JSON.stringify(this.$store.state.emprendimiento.observaciones_emprendimiento),

          estudios_mercados_emprendimiento:       JSON.stringify(this.$store.state.emprendimiento.estudios_mercados_emprendimiento),

          cooperaciones_internacionales_emprendimiento: JSON.stringify(this.$store.state.emprendimiento.cooperaciones_internacionales_emprendimiento),

          redes_internacionales_emprendimiento:   JSON.stringify(this.$store.state.emprendimiento.redes_internacionales_emprendimiento),

          //-------Step five---------//

          necesidad_capital_emprendimiento: JSON.stringify(this.$store.state.emprendimiento.necesidad_capital_emprendimiento),

          necesidad_socio_emprendimiento: JSON.stringify(this.$store.state.emprendimiento.necesidad_socio_emprendimiento),

          necesidad_asesoria_emprendimiento: JSON.stringify(this.$store.state.emprendimiento.necesidad_asesoria_emprendimiento),

          necesidad_internacionalizacion_emprendimiento: JSON.stringify(this.$store.state.emprendimiento.necesidad_internacionalizacion_emprendimiento),

          necesidad_otra_emprendimiento:  JSON.stringify(this.$store.state.emprendimiento.necesidad_otra_emprendimiento)
        }).then(res => {
          if(res.data.success){
            this.$Progress.finish();
            this.$router.push({ name: 'ProgramaBIO'});
            this.enviando=false;
          }
        }).catch(error => {
          this.$Progress.finish();
          this.enviando=false;
          alert(error.response.data.message);
        });
      }
    },

    created(){
      this.$Progress.start();
      window.scrollTo(0,-1);
      this.verificarEmprendimiento();
      this.currentRoute();
      this.$Progress.finish();
    },

  };
</script>
<style>
</style>
