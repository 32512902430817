<template>
	<div>
		<h5 class="pt-2">Estado de perfiles del equipo Emprendedor</h5>   

      <b-table
          class="table-estado_perfil_equipo_emprendedor"
          hover
          stacked="md"
          head-variant="dark"
          :items="this.estado_perfil_equipo_emprendedor"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
        >
          <template v-slot:cell(fecha)="row">
            {{ row.item.fecha.toString().split("-").reverse().join("/") }}
          </template>
        </b-table>

	</div>
</template>

<script>
  export default {
    props: ['estado_perfil_equipo_emprendedor'],
    data() {
      return {
        fields: [
          { key: 'estado_perfil',  label: 'Estado', sortable: true, thStyle: {width:'80%'} },
          { key: 'anio',           label: 'Año',    sortable: true}
        ],
        sortBy: 'anio',
        sortDesc: true,
        sortDirection: 'desc',
      }
    }
  };
</script>
<style>
.table-estado_perfil_equipo_emprendedor thead tr th{
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.table-estado_perfil_equipo_emprendedor thead tr th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 400px) {
  .table-estado_perfil_equipo_emprendedor tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-estado_perfil_equipo_emprendedor tbody tr td:nth-child(2) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {

  .table-estado_perfil_equipo_emprendedor tbody tr td:nth-child(1) {
    text-align: center;
  }

  .table-estado_perfil_equipo_emprendedor thead tr th:nth-child(2) {
    justify-content: center;
  }

  .table-estado_perfil_equipo_emprendedor tbody tr td:nth-child(2) {
    justify-content: center;
  }

}
</style>