<template>
  <div class="content">
    <div class="container-fluid pt-4">

      <EmprendimientosVinculados
        v-if="!this.loading"
        :emprendimientos_unl="this.emprendimientos_unl"
        :emprendimientos_PE="this.emprendimientos_PE"
        :emprendimientos_PG="this.emprendimientos_PG"
        :emprendimientos_PB="this.emprendimientos_PB"
        :emprendimientos_PP="this.emprendimientos_PP"
      />
      
    </div>
  </div>
</template>
<script>
  import ChartCard from 'src/components/Cards/ChartCard.vue'
  import StatsCard from 'src/components/Cards/StatsCard.vue'
  import LTable from 'src/components/Table.vue'

  import EmprendimientosVinculados from 'src/components/Indicadores/EmprendimientosVinculados.vue'

  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    components: {
      LTable,
      ChartCard,
      StatsCard,

      EmprendimientosVinculados,
    },
    data () {
      return {
        loading: true,
        emprendimientos_unl:0,
        emprendimientos_PE: 0,
        emprendimientos_PG: 0,
        emprendimientos_PB: 0,
        emprendimientos_PP: 0,

        subtitulo:'Emprendimientos UNL',
      }
    },
    methods:{
      currentRoute(){
        this.$store.commit('setTitulo', {setTitulo: 'Indicadores UNL'});
        this.$store.commit('setSubTitulo', {setSubTitulo: ''});
      },

      async getIndicadores(){
        try {
          const response = await axios.get(URL_API+'api/indicadoresUNL');
          this.emprendimientos_unl = response.data.emprendimientos;
          this.emprendimientos_PE = response.data.emprendimientos_PE;
          this.emprendimientos_PG = response.data.emprendimientos_PG;
          this.emprendimientos_PB = response.data.emprendimientos_PB;
          this.emprendimientos_PP = response.data.emprendimientos_PP;

          this.emprendedores = response.data.emprendedores;
          this.emprendedores_masculinos = response.data.emprendedores_masculinos;
          this.emprendedores_femeninas = response.data.emprendedores_femeninas;
          this.emprendedores_otros = response.data.emprendedores_otros;
          this.loading=false;
        } catch (error) {
            console.log(error.response)
        }
      },

    },

    created(){
      this.$Progress.start();
      this.currentRoute();
      this.getIndicadores();
      window.scrollTo(0, -1);
      this.$Progress.finish();
    }
  }
</script>
