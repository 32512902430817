<template>
    <div>
      <stats-card class="p-4">
        <div slot="header" class="icon-success">
          <i class="fas fa-border-all text-info"></i>
        </div>
        <div slot="content">
          <p class="card-category">Emprendimientos <br> Desvinculados</p>
          <h4 class="card-title">{{ this.data.total }}</h4>
        </div>
      </stats-card>

      <stats-card class="p-4">
        <div slot="header" class="icon-success">
          <i class="fas fa-flag-checkered text-info"></i>
        </div>
        <div slot="content">
          <p class="card-category">Desvinculado por<br> Egreso</p>
          <h4 class="card-title">{{ this.data.egreso }}</h4>
        </div>
      </stats-card>

      <stats-card class="p-4">
        <div slot="header" class="icon-success">
          <i class="fas fa-heart-broken text-info"></i>
        </div>
        <div slot="content">
          <p class="card-category">Desvinculado por<br> otros motivos</p>
          <h4 class="card-title">{{ this.data.desvinculado }}</h4>
        </div>
      </stats-card>
    </div>
</template>

<script>
  import ChartCard from 'src/components/Cards/ChartCard.vue'
  import StatsCard from 'src/components/Cards/StatsCard.vue'
  import LTable from 'src/components/Table.vue'

  export default {
    components: {
      LTable,
      ChartCard,
      StatsCard
    },
    props: ['titulo','subtitulo', 'data']
  };
</script>