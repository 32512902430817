import axios from "axios";

const URL_API = process.env.VUE_APP_URL_API;

export default {
  login(email, password) {
    const user = { email, password };
    return axios.post(URL_API + "api/auth/login", user);
    // axios.post(URL_API + "api/auth/login", user)
    //   .then((response)=>{
    //     return response;
    //   }).catch((error) => {
    //       return error;
    //     }
    //   );
  },
  
  async refresh(){
    localStorage.setItem('isLogged', false);
    
    let refresh;
    await axios.post(URL_API + "api/auth/refresh")
      .then((result) => {
        refresh = result;
        localStorage.setItem('access_token', refresh.data.access_token);
        localStorage.setItem('token_type', refresh.data.token_type);
        localStorage.setItem('expires_in', refresh.data.expires_in); 
        localStorage.setItem('isLogged', true);
      });
    return refresh;
  },

  async me(){
    localStorage.setItem('isLogged', false);
    let me;
    await axios.post(URL_API + "api/auth/me")
      .then((result) => {
        me = result;
        console.log(me);
        localStorage.setItem('isLogged', true);
      });
    return me;
  }
};