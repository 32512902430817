<template>
	<div>
		<h4 class="pt-3">Datos del Emprendedor</h4>   
    <b-card>
      <b-container>
        <b-row>
          <b-col cols="6" class="pt-4">
            <b-row sm>
              <b-col class="titulo" md="6" xs="12">
                <label>Nombre:</label>
              </b-col>
              <b-col md="6" xs="12">
                {{ this.nombre }}
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="6" class="pt-4" :hidden="this.apellido==null">
            <b-row>
              <b-col class="titulo" md="6" xs="12">
                <label>Apellido:</label>
              </b-col>
              <b-col md="6" xs="12">
                {{ this.apellido }}
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="6" class="pt-2":hidden="this.dni==null">
            <b-row>
              <b-col class="titulo" md="6" xs="12">
                <label>DNI:</label>
              </b-col>
              <b-col md="6" xs="12">
                {{ this.dni }}
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="6" class="pt-2":hidden="this.cuit_cuil==null">
            <b-row>
              <b-col class="titulo" md="6" xs="12">
                <label>CUIT/CUIL:</label>
              </b-col>
              <b-col md="6" xs="12">
                {{ this.cuit_cuil }}
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="6" class="pt-2":hidden="this.correo_electronico==null">
            <b-row>
              <b-col class="titulo" md="6" xs="12">
                <label>Correo Electrónico:</label>
              </b-col>
              <b-col md="6" xs="12">
                {{ this.correo_electronico }}
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="6" class="pt-2":hidden="this.telefono==null||this.telefono==''">
            <b-row>
              <b-col class="titulo" md="6" xs="12">
                <label>Teléfono:</label>
              </b-col>
              <b-col md="6" xs="12">
                {{ this.telefono }}
              </b-col>
            </b-row>
          </b-col>

           <b-col cols="6" class="pt-2":hidden="this.celular==null||this.celular==''">
            <b-row>
              <b-col class="titulo" md="6" xs="12">
                <label>Celular:</label>
              </b-col>
              <b-col md="6" xs="12">
                {{ this.celular }}
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="6" class="pt-2":hidden="this.genero==null">
            <b-row>
              <b-col class="titulo" md="6" xs="12">
                <label>Género:</label>
              </b-col>
              <b-col md="6" xs="12">
                {{ this.genero.charAt(0).toUpperCase() + this.genero.slice(1) }}
              </b-col>
            </b-row>
          </b-col>

           <b-col cols="6" class="pt-2" :hidden="this.provincia==null">
            <b-row>
              <b-col class="titulo" md="6" xs="12">
                <label>Provincia:</label>
              </b-col>
              <b-col md="6" xs="12">
                {{ this.provincia }}
              </b-col>
            </b-row>
          </b-col>

           <b-col cols="6" class="pt-2" :hidden="this.departamento==null">
            <b-row>
              <b-col class="titulo" md="6" xs="12">
                <label>Departamento:</label>
              </b-col>
              <b-col md="6" xs="12">
                {{ this.departamento }}
              </b-col>
            </b-row>
          </b-col>

          <b-col class="pt-2 pb-2" cols="12">
            <hr width="80%">
          </b-col>

           <b-col cols="6" class="pt-2" :hidden="this.nivel_formacion==null">
            <b-row>
              <b-col class="titulo" md="6" xs="12">
                <label>Nivel educativo:</label>
              </b-col>
              <b-col md="6" xs="12">
                {{ this.nivel_formacion.charAt(0).toUpperCase() + this.nivel_formacion.slice(1) }}
              </b-col>
            </b-row>
          </b-col>

           <b-col cols="6" class="pt-2" :hidden="this.condicion_formacion==null">
            <b-row>
              <b-col class="titulo" md="6" xs="12">
                <label>Condición:</label>
              </b-col>
              <b-col md="6" xs="12">
                {{ this.condicion_formacion.charAt(0).toUpperCase() + this.condicion_formacion.slice(1) }}
              </b-col>
            </b-row>
          </b-col>

          <b-col 
            cols="6" 
            class="pt-2" 
            v-if="this.nivel_formacion=='universitario'&&this.universidad=='unl'">
            <b-row>
              <b-col class="titulo" md="6" xs="12">
                <label>Universidad:</label>
              </b-col>
              <b-col md="6" xs="12">
                Universidad Nacional del Litoral
              </b-col>
            </b-row>
          </b-col>

          <b-col 
            cols="6" 
            class="pt-2" 
            v-if="this.nivel_formacion=='universitario'&&this.universidad=='unl'">
            <b-row>
              <b-col class="titulo" md="6" xs="12">
                <label>Unidad Academica:</label>
              </b-col>
              <b-col md="6" xs="12">
                {{ this.unidad_academica }}
              </b-col>
            </b-row>
          </b-col>

          <b-col 
            cols="6" 
            class="pt-2" 
            v-if="this.nivel_formacion=='universitario'&&this.universidad=='unl'">
            <b-row>
              <b-col class="titulo" md="6" xs="12">
                <label>Carrera:</label>
              </b-col>
              <b-col md="6" xs="12">
                {{ this.carrera }}
              </b-col>
            </b-row>
          </b-col>

          <b-col 
            cols="12" 
            class="pt-2" 
            :hidden="this.otros_datos==''">
            <b-row>
              <b-col class="titulo" md="3" xs="12">
                <label>Otros Datos:</label>
              </b-col>
              <b-col md="9" xs="12">
                {{ this.otros_datos }}
              </b-col>
            </b-row>
          </b-col>

          <b-col class="pt-2 pb-2" cols="12">
            <hr width="80%">
          </b-col>

           <b-col cols="12" class="pt-2">
            <b-row>
              <b-col class="titulo pt-2" md="3" xs="12">
                <label>Emprendimiento/os:</label>
              </b-col>
              <b-col 
                md="9" 
                xs="12">
                <b-col 
                  v-for="emp in this.emprendimientos"
                  :key="emp.id"
                  md="12" 
                  xs="12"
                  class="pb-2">

                  <router-link 
                    class="btn btn-info"
                    v-if="emp.condicion=='Vinculado'"
                    :to="{ name: 'verEmprendimientosUNL', params:{id: emp.id}}">
                    {{ emp.emprendimiento }}
                  </router-link>

                  <b-button 
                    variant="info"
                    disabled
                    v-if="emp.condicion=='Desvinculado'">
                    {{ emp.emprendimiento }}
                  </b-button>

                </b-col>
              </b-col>
            </b-row>
          </b-col>

        </b-row>
      </b-container>
    </b-card>
	</div>
</template>

<script>
  export default {
    props: [
      'nombre',
      'apellido',
      'dni',
      'cuit_cuil',
      'correo_electronico',
      'telefono',
      'celular',
      'genero',
      'provincia',
      'departamento',
      'condicion_formacion',
      'nivel_formacion',
      'universidad',
      'unidad_academica',
      'carrera', 
      'otros_datos',
      'id_emprendimiento',
      'emprendimientos'
    ]
  };
</script>
<style>
@media (min-width: 768.98px) { 
  .titulo{
    text-align: right;
  }
}
</style>