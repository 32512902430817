<template>
  <div>
    <h4>Datos Relacionados a los Emprendedores</h4>
    <div class="card">
      <div class="col-md-12 pt-4 pb-4">
        <div class="row">

          <div class="col-md-12">
            <!-- User Interface controls -->
            <b-row>

              <b-col lg="6" class="my-4">
                <b-button 
                  @click="nuevo()"
                  class="btn btn-info btn-fill">
                    <span class="fa fa-plus"></span> Agregar integrante
                </b-button>    
              </b-col>

              <b-col lg="6" class="my-4">
                <b-form-group
                  label-cols-sm="7"
                  label-align-sm="right"
                  label-size="md"
                  label-for="filterInput"  
                >
                <b-form-input
                  v-model="filter"
                  type="search"
                  id="filterInput"
                  autocomplete="off"
                  size="md"
                  placeholder="Buscár"
                ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <!-- Main table element -->
          <div class="col-md-12 pt-4">
            <b-table
              class="table-emprendedores"
              hover
              stacked="md"
              head-variant="dark"
              :items="emprendedores"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :filterIncludedFields="filterOn"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              @filtered="onFiltered"
            >
              <template v-slot:cell(integrante)="row">
                {{ row.item.apellido.toUpperCase() }}, {{ row.item.nombre }}
              </template>

              <template v-slot:cell(responsable)="row">
                <b-form-radio 
                  v-model="dni_responsable_emprendimiento"
                  :value="row.item.dni"
                  >
                </b-form-radio>
              </template>

              <template v-slot:cell(actions)="row">

                <b-button 
                  size="sm" 
                  @click="edit(row.item, row.index, $event.target)" 
                  class="btn btn-warning btn-fill mr-1">
                  <span class="fa fa-pencil"></span>
                </b-button>

                <b-button 
                  class="btn-danger btn-fill" 
                  size="sm" 
                  @click="deleteEmprendedor(row.item, row.index, $event.target)">
                  <span class="fa fa-trash"></span>
                </b-button>
                
              </template>

            </b-table>
          </div>
        </div>
      </div>
    </div>

    <b-modal size="lg" id="nuevoEmprendedor" title="Nuevo Emprendedor" hide-footer>
      <div class="row">
        <div class="col-md-6 pt-3">
          Nombre:<small class="text-danger">(requerido)</small>
          <b-form-input v-model="nombre" autocomplete="off"></b-form-input>
        </div>
        <div class="col-md-6 pt-3">
          Apellido:<small class="text-danger">(requerido)</small>
          <b-form-input v-model="apellido" autocomplete="off"></b-form-input>
        </div>

        <div class="col-md-6 pt-3">
          DNI:<small class="text-danger">(requerido)</small>
          <b-form-input 
            v-model="dni" 
            type="text" 
            @keypress="onlyNumber"
            @paste.prevent
            autocomplete="off">
          </b-form-input>
        </div>

        <div class="col-md-6 pt-3">
          CUIT/CUIL:<small class="text-danger">(requerido)</small>
          <b-form-input 
            v-model="cuit_cuil" 
            @keypress="onlyNumber" 
            @paste.prevent
            v-mask="'##-########-##'">
          </b-form-input>
        </div>

        <div class="col-md-6 pt-3">
          Fecha de nacimiento:<small class="text-danger">(requerido)</small>
          <b-form-input 
            v-model="fecha_nac"   
            type="date" 
            placeholder="dd/mm/aaaa">
          </b-form-input>
        </div>

        <div class="col-md-6 pt-3">
          Genero:<small class="text-danger">(requerido)</small>
          <b-form-select 
            v-model="genero" 
            :options="generos">
          </b-form-select>
        </div>

        <div class="col-md-6 pt-3">
          Correo Electrónico:
          <b-form-input 
            v-model="correo_electronico" 
            @keydown.space="(event) => event.preventDefault()"
            autocomplete="off">
          </b-form-input>
        </div>
        <div class="col-md-6 pt-3">
          Teléfono:
          <b-form-input 
            v-model="telefono" 
            type="text" 
            @keypress="onlyPhoneNumber"
            @paste.prevent
            autocomplete="off">
          </b-form-input>
        </div>

        <div class="col-md-6 pt-3">
          Celular:
          <b-form-input 
            v-model="celular" 
            type="text" 
            @keypress="onlyPhoneNumber"
            @paste.prevent
            autocomplete="off">
          </b-form-input>
        </div>

        <div class="col-md-12 pt-4">
          <h4>Lugar de residencia:</h4>
        </div>

        <div class="col-md-6 pt-3">
          Provincia:
          <b-form-select v-model="id_provincia" v-on:change="getLocalidades" :options="provincias"></b-form-select>
        </div>

        <div class="col-md-6 pt-3">
          Localidad:
          <b-form-select v-model="id_departamento" :options="localidades"></b-form-select>
        </div>

        <div class="col-md-12 pt-4">
          <h4>Nivel educativo:</h4>
        </div>

        <div class="col-md-6 pt-3">
          Condición:
          <b-form-select v-model="condicion_formacion" :options="condicion_estudios"></b-form-select>
        </div>

        <div class="col-md-6 pt-3">
          Nivel de estudios alcanzados:
          <b-form-select v-model="nivel_formacion" :options="niveles_formacion"></b-form-select>
        </div>

        <div class="col-md-6 pt-3">
          Universidad:
          <b-form-select v-model="universidad" :options="universidades" :disabled="nivel_formacion != 'universitario'"></b-form-select>
        </div>

        <div class="col-md-6 pt-3" :hidden="universidad == 'otro'">
          Unidad Academica:
          <b-form-select v-model="id_unidad_academica" v-on:change="getCarrerasUA" :options="unidades_academicas" :disabled="nivel_formacion != 'universitario'"></b-form-select>
        </div>
        <div class="col-md-6 pt-3" :hidden="universidad == 'otro'">
          Carrera:
          <b-form-select v-model="id_carrera" :options="carreras" :disabled="nivel_formacion != 'universitario'"></b-form-select>
        </div>

        <div class="col-md-6 pt-3" :hidden="universidad != 'otro'">
          Universidad:
          <b-form-input v-model="otra_universidad" autocomplete="off" :disabled="nivel_formacion != 'universitario'"></b-form-input>
        </div>

        <div class="col-md-6 pt-3" :hidden="universidad != 'otro'">
          Carrera:
          <b-form-input v-model="otra_carrera" autocomplete="off" :disabled="nivel_formacion != 'universitario'"></b-form-input>
        </div>

        <div class="col-md-12 pt-3">
          Otros datos:
          <b-form-textarea v-model="otros_datos" rows="4"></b-form-textarea>
        </div>
      </div>
      <div class="pt-4">
        <b-button 
          class="mt-3 pull-left btn-danger btn-fill" 
          @click="resetModal">
          Cacelar
        </b-button>
        
        <template v-if="universidad!='otro'">
          <b-button 
            class="mt-3 pull-right btn-info btn-fill" 
            @click="addEmprendedor"
            :disabled=" nombre =='' ||
                        apellido =='' ||
                        dni =='' ||
                        cuit_cuil =='' ||
                        fecha_nac ==null
            ">
            Agregar
          </b-button>
        </template>

        <template v-if="universidad=='otro'">
          <b-button 
            class="mt-3 pull-right btn-info btn-fill" 
            @click="addEmprendedor"
            :disabled=" nombre =='' ||
                        apellido =='' ||
                        dni =='' ||
                        cuit_cuil =='' ||
                        fecha_nac ==null ||
                        otra_universidad == '' ||
                        otra_carrera == ''
            ">
            Agregar
          </b-button>
        </template>

      </div>
    </b-modal>

    <b-modal size="lg" id="editarEmprendedor" title="Editar Emprendedor" hide-footer>
      <div class="row">
        <div class="col-md-6 pt-3">
          <label>Nombre:<small class="text-danger">(requerido)</small></label>
          <b-form-input v-model="nombre" autocomplete="off"></b-form-input>
        </div>
        <div class="col-md-6 pt-3">
          Apellido:<small class="text-danger">(requerido)</small>
          <b-form-input v-model="apellido" autocomplete="off"></b-form-input>
        </div>

        <div class="col-md-6 pt-3">
          DNI:<small class="text-danger">(requerido)</small>
          <b-form-input 
            v-model="dni" 
            type="text" 
            @keypress="onlyNumber" 
            @paste.prevent
            autocomplete="off">
          </b-form-input>
        </div>

        <div class="col-md-6 pt-3">
          CUIT/CUIL:<small class="text-danger">(requerido)</small>
          <b-form-input 
            v-model="cuit_cuil" 
            type="text" 
            @keypress="onlyNumber" 
            @paste.prevent
            v-mask="'##-########-##'">
          </b-form-input>
        </div>

        <div class="col-md-6 pt-3">
          Fecha de nacimiento:<small class="text-danger">(requerido)</small>
          <b-form-input 
            v-model="fecha_nac"   
            type="date" 
            placeholder="dd/mm/aaaa">
          </b-form-input>
        </div>

        <div class="col-md-6 pt-3">
          Genero:<small class="text-danger">(requerido)</small>
          <b-form-select 
            v-model="genero" 
            :options="generos">
          </b-form-select>
        </div>

        <div class="col-md-6 pt-3">
          Correo Electrónico:
          <b-form-input 
            v-model="correo_electronico" 
            @keydown.space="(event) => event.preventDefault()"
            autocomplete="off">
          </b-form-input>
        </div>
        <div class="col-md-6 pt-3">
          Teléfono:
          <b-form-input 
            v-model="telefono" 
            type="text" 
            @keypress="onlyPhoneNumber"
            @paste.prevent
            autocomplete="off">
          </b-form-input>
        </div>

        <div class="col-md-6 pt-3">
          Celular:
          <b-form-input 
            v-model="celular" 
            type="text" 
            @keypress="onlyPhoneNumber"
            @paste.prevent
            autocomplete="off">
          </b-form-input>
        </div>

        <div class="col-md-12 pt-4">
          <h4>Lugar de residencia:</h4>
        </div>

        <div class="col-md-6 pt-3">
          Provincia:
          <b-form-select 
            v-model="id_provincia" 
            v-on:change="getLocalidades" 
            :options="provincias">
          </b-form-select>
        </div>

        <div class="col-md-6 pt-3">
          Localidad:
          <b-form-select 
            v-model="id_departamento" 
            :options="localidades">
          </b-form-select>
        </div>

        <div class="col-md-12 pt-4">
          <h4>Nivel educativo:</h4>
        </div>

        <div class="col-md-6 pt-3">
          Nivel de estudios alcanzados:
          <b-form-select 
            v-model="nivel_formacion" 
            :options="niveles_formacion">
          </b-form-select>
        </div>
        
        <div class="col-md-6 pt-3">
          Condición:
          <b-form-select 
            v-model="condicion_formacion" 
            :options="condicion_estudios">
          </b-form-select>
        </div>

        <div class="col-md-6 pt-3">
          Universidad:
          <b-form-select 
            v-model="universidad" 
            :options="universidades" 
            :disabled="nivel_formacion != 'universitario'">
          </b-form-select>
        </div>

        <div class="col-md-6 pt-3" :hidden="universidad == 'otro'">
          Unidad Academica:
          <b-form-select 
            v-model="id_unidad_academica" 
            v-on:change="getCarrerasUA" 
            :options="unidades_academicas" 
            :disabled="nivel_formacion != 'universitario'">
          </b-form-select>
        </div>
        <div class="col-md-6 pt-3" :hidden="universidad == 'otro'">
          Carrera:
          <b-form-select 
            v-model="id_carrera" 
            :options="carreras" 
            :disabled="nivel_formacion != 'universitario'">
          </b-form-select>
        </div>

        <div class="col-md-6 pt-3" :hidden="universidad != 'otro'">
          Universidad:
          <b-form-input 
            v-model="otra_universidad" 
            autocomplete="off" 
            :disabled="nivel_formacion != 'universitario'">
          </b-form-input>
        </div>

        <div class="col-md-6 pt-3" :hidden="universidad != 'otro'">
          Carrera:
          <b-form-input   
            v-model="otra_carrera" 
            autocomplete="off" 
            :disabled="nivel_formacion != 'universitario'">
          </b-form-input>
        </div>

        <div class="col-md-12 pt-3">
          Otros datos:
          <b-form-textarea v-model="otros_datos" rows="4"></b-form-textarea>
        </div>
      </div>
      <div class="pt-4">
        <b-button 
          class="mt-3 pull-left btn-danger btn-fill" 
          @click="resetModal">
          Cacelar
        </b-button>

        <template v-if="universidad!='otro'">
          <b-button 
            class="mt-3 pull-right btn-info btn-fill" 
            @click="update(editindex)"
            :disabled=" nombre =='' ||
                        apellido =='' ||
                        dni =='' ||
                        cuit_cuil =='' ||
                        fecha_nac ==null
            ">
            Actualizar
          </b-button>
        </template>

        <template v-if="universidad=='otro'">
          <b-button 
            class="mt-3 pull-right btn-info btn-fill" 
            @click="update(editindex)"
            :disabled=" nombre =='' ||
                        apellido =='' ||
                        dni =='' ||
                        cuit_cuil =='' ||
                        fecha_nac ==null ||
                        otra_universidad == '' ||
                        otra_carrera == ''
            ">
            Actualizar
          </b-button>
        </template>

      </div>
    </b-modal>

    <b-modal size="md" id="errorEmprendedor" title="Error Emprendedor" hide-footer>
      <div class="row">
        <div class="col-md-12 pt-3" v-show="this.error_dni">
          <label>El DNI y CUIT/CUIL no parecen validos. Estos datos son importantes para el sistema, 
            si no dispone de ellos intente buscarlos en la opción EMPRENDEDORES UNL para corroborar
            que no este cargado en otro emprendimiento y poder copiar los datos. En caso de no estar
            cargado en RUE intente buscarlos en Google, Dateas o CuitOnline a traves del 
            nombre y apellido.
          </label>
        </div>
        <div class="col-md-12 pt-3" v-show="this.error_correo">
          <label>El correo electrónico introducido no es valido. Estos datos son importantes para el sistema, 
            si no dispone de ellos intente buscarlos en la opción EMPRENDEDORES UNL para corroborar
            que no este cargado en otro emprendimiento y poder copiar los datos. En caso de no estar
            cargado en RUE deje el campo sin completar.
          </label>
        </div>
      </div>
      <div class="pt-4">
        <b-button 
          class="mt-3 pull-right btn-info btn-fill" 
          @click="resetModalError">
          Aceptar
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    data () {
      return {
        nombre: '',
        apellido: '',
        dni: '',

        cuit_cuil: '',
        correo_electronico: '',
        telefono: '',
        celular: '',
        
        firts_provincia: null,
        id_provincia: 20,
        provincias: [],

        id_departamento: null,
        localidades: [],

        fecha_nac: null,

        genero: 'otro',
        generos: [
          {'value':'masculino', 'text':'Masculino'},
          {'value':'femenino', 'text':'Femenino'},
          {'value':'otro', 'text':'Otro'},
        ],

        condicion_formacion: 'estudiante',
        condicion_estudios: [
          {'value':'estudiante', 'text':'Estudiante'},
          {'value':'graduado', 'text':'Graduado'},
          {'value':'inconclusos', 'text':'Inconclusos'},
        ],

        nivel_formacion: 'primario',
        niveles_formacion: [
          {'value':'primario', 'text':'Primario'},
          {'value':'secundario', 'text':'Secundario'},
          {'value':'terciario', 'text':'Terciario'},
          {'value':'universitario', 'text':'Universitario'},
        ],
      
        universidad: 'unl',
        universidades: [
          {'value':'unl', 'text':'Universidad Nacional del Litoral'},
          {'value':'otro', 'text':'Otro'},
        ],

        firts_ua: null,
        id_unidad_academica: null,
        unidades_academicas: [],

        firts_carrera: null,
        id_carrera: null,
        carreras: [],

        otros_datos: '',

        otra_universidad: '',

        otra_carrera: '',

        emprendedores: [],

        error_dni:false,
        error_correo:false,

        editindex: null,
        deleteIndex: null,
        fields: [
          { key: 'dni',                 label: 'DNI',                 sortable: true },
          { key: 'integrante',          label: 'Integrante',          sortable: true },
          { key: 'correo_electronico',  label: 'Correo Electrónico',  sortable: true },
          { key: 'responsable',         label: 'responsable',         sortable: true },
          { key: 'actions',             label: 'Acciones'}
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 25,
        pageOptions: [10, 25, 50],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
      }
    },
    
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },

      dni_responsable_emprendimiento:{
        get(){
          return this.$store.state.emprendimiento.dni_responsable_emprendimiento;
        },
        set(value){
          this.$store.commit('setDNIResponsableEmprendimiento', value);
        }
      }
    },

    methods:{
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },

      validEmail: function (email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      },

      validDNI: function (email) {
        //var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var re = (/^[\d]{1,3}\.?[\d]{3,3}\.?[\d]{3,3}$/)
        return re.test(email);
      },

      onlyNumber ($event) {
        //console.log($event.keyCode); //keyCodes value
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        if (keyCode < 48 || keyCode > 57) { 
          $event.preventDefault();
        }
      },

      onlyPhoneNumber ($event) {
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        if((keyCode != 40) // keyCode de parentesis
            && (keyCode != 41) // keyCode de parentesis
            && (keyCode != 45) // keyCode de guines
            && (keyCode < 48 || keyCode > 57)) { // keyCode de numeros entre 0 y 9
          $event.preventDefault();
        }
      },

      async getUA(){
        let r = await axios.get(URL_API+'api/unidades-academicas');
        var arr = [];
        var firts = true;
        var aux;
        r.data.forEach(function(element, index, rubros){
          if(firts==true){
            aux= element.id;
            firts=false;
          }
          arr.push({'value': element.id, 'text': element.unidad_academica });
        });
        this.firts_ua = aux;
        this.id_unidad_academica = aux;
        this.unidades_academicas = arr;
      },

      async getCarrerasUA(){
        let r = await axios.get(URL_API+'api/carreras-ua/'+this.id_unidad_academica);

        var arr = [];
        var firts = true;
        var aux;

        if(r.data.length > 0){

          r.data.forEach(function(element, index, rubros){
            if(firts==true){
              aux= element.id;
              firts=false;
            }
            arr.push({'value': element.id, 'text': element.nombre });
          });
        }else{
          aux=null;
          arr.push({'value': 'null', 'text': 'No hay carreras cargadas' });
        }
        this.firts_carrera = aux;
        this.id_carrera = aux;
        this.carreras = arr;
      },

      async getProvincias(){
        let r = await axios.get(URL_API+'api/provincias');

        var arr = [];
        var firts = true;
        var aux;
        r.data.forEach(function(element, index, rubros){
          if(firts==true){
            aux= element.id;
            firts=false;
          }
          arr.push({'value': element.id, 'text': element.nombre });
        });
        this.firts_provincia = aux;
        this.id_provincia = 20;
        this.provincias = arr;
      },

      async getLocalidades(){
        let r = await axios.get(URL_API+'api/departamentos-provincia/'+this.id_provincia);

        var arr = [];
        var firts = true;
        var aux;
        r.data.forEach(function(element, index, rubros){
          if(firts==true){
            aux= element.id;
            firts=false;
          }
          arr.push({'value': element.id, 'text': element.nombre });
        });
        this.firts_localidad = aux;
        this.id_departamento = aux;
        this.localidades = arr;
      },

      async getLocalidades2(){
        let r = await axios.get(URL_API+'api/departamentos-provincia/'+this.id_provincia);
        var arr = [];
        var firts = true;
        var aux;
        r.data.forEach(function(element, index, rubros){
          if(firts==true){
            aux= element.id;
            firts=false;
          }
          arr.push({'value': element.id, 'text': element.nombre });
        });
        this.localidades = arr;
      },

      getEmprendedores(){
        this.emprendedores = this.$store.state.emprendimiento.emprendedores;
      },

      nuevo(){
        this.$bvModal.show('nuevoEmprendedor');
      },

      addEmprendedor(){
        var dni_validate = this.validDNI(this.dni);
        var email_validate = (this.correo_electronico!='') ? this.validEmail(this.correo_electronico) : true;

        if( dni_validate && email_validate ){
          var emprendedor= {
            'id':                   this.$store.state.emprendimiento.id_emprendedor,
            'nombre':               this.nombre,
            'apellido':             this.apellido,
            'dni':                  this.dni,
            'cuit_cuil':            this.cuit_cuil,
            'correo_electronico':   this.correo_electronico,
            'telefono':             this.telefono,
            'celular':              this.celular,
            'fecha_nac':            this.fecha_nac.split("/").reverse().join("-"),
            'genero':               this.genero,

            'id_provincia':         this.id_provincia,
            'id_departamento':      this.id_departamento,

            'condicion_formacion':  this.condicion_formacion,
            'nivel_formacion':      this.nivel_formacion,
            
            'universidad':          this.universidad,
            'id_unidad_academica':  this.id_unidad_academica,
            'id_carrera':           this.id_carrera,

            //Si es OTRA universidad:
            'otra_universidad':     this.otra_universidad,
            'otra_carrera':         this.otra_carrera,

            'otros_datos':          this.otros_datos
          };
          this.$store.commit('addEmprendedor', emprendedor);
          this.getEmprendedores();
          this.resetModal();
        }else{
          this.error_dni = false;
          this.error_correo = false;

          if(!dni_validate){
            this.error_dni = true;
          }
          if (!email_validate) {
            this.error_correo = true;
          }
          this.$bvModal.show('errorEmprendedor');
        }
        
      },

      edit(item, index, button) {    
        this.editindex=item.id;

        this.nombre = item.nombre;
        this.apellido = item.apellido;
        this.dni = item.dni;
        this.cuit_cuil = item.cuit_cuil;
        this.correo_electronico = item.correo_electronico;
        this.celular = item.celular;
        this.telefono = item.telefono;
        this.fecha_nac = item.fecha_nac;
        this.genero  = item.genero;

        this.id_provincia = item.id_provincia;
        this.id_departamento = item.id_departamento;

        this.nivel_formacion  = item.nivel_formacion;
        this.condicion_formacion  = item.condicion_formacion;
        this.universidad  = item.universidad;

        this.id_unidad_academica = item.id_unidad_academica;
        this.id_carrera = item.id_carrera;

        this.otra_universidad = item.otra_universidad;
        this.otra_carrera = item.otra_carrera;
        
        this.otros_datos  = item.otros_datos;

        this.getLocalidades2();

        this.$bvModal.show('editarEmprendedor');
      },

      update(index){
        for(var i=0; i<this.$store.state.emprendimiento.emprendedores.length; i++){
          if(this.$store.state.emprendimiento.emprendedores[i].id==this.editindex){
            this.$store.state.emprendimiento.emprendedores[i].nombre = this.nombre;
            this.$store.state.emprendimiento.emprendedores[i].apellido = this.apellido;
            this.$store.state.emprendimiento.emprendedores[i].dni = this.dni;
            this.$store.state.emprendimiento.emprendedores[i].cuit_cuil = this.cuit_cuil;
            this.$store.state.emprendimiento.emprendedores[i].correo_electronico = this.correo_electronico;
            this.$store.state.emprendimiento.emprendedores[i].celular = this.celular;
            this.$store.state.emprendimiento.emprendedores[i].telefono = this.telefono;
            this.$store.state.emprendimiento.emprendedores[i].fecha_nac = this.fecha_nac;
            this.$store.state.emprendimiento.emprendedores[i].genero = this.genero;

            this.$store.state.emprendimiento.emprendedores[i].id_provincia = this.id_provincia;
            this.$store.state.emprendimiento.emprendedores[i].id_departamento = this.id_departamento;

            this.$store.state.emprendimiento.emprendedores[i].nivel_formacion = this.nivel_formacion;
            this.$store.state.emprendimiento.emprendedores[i].condicion_formacion = this.condicion_formacion;
            this.$store.state.emprendimiento.emprendedores[i].universidad = this.universidad;

            this.$store.state.emprendimiento.emprendedores[i].id_unidad_academica = this.id_unidad_academica;
            this.$store.state.emprendimiento.emprendedores[i].id_carrera = this.id_carrera;

            this.$store.state.emprendimiento.emprendedores[i].otra_universidad = this.otra_universidad;
            this.$store.state.emprendimiento.emprendedores[i].otra_carrera = this.otra_carrera;

            this.$store.state.emprendimiento.emprendedores[i].otros_datos = this.otros_datos;
          }
        }
        this.getEmprendedores();
        this.resetModal();
      },

      async deleteEmprendedor(item, index, button){
        this.deleteIndex = item.id;
        if (confirm('¿Seguro que desea eliminarlo?')) {
          this.$store.commit('deleteEmprendedor', this.deleteIndex);
        }
      },

      resetModal() {
        this.nombre = '',
        this.apellido = '',
        this.dni = '',
        this.cuit_cuil = '',
        this.correo_electronico = '',
        this.celular = '',
        this.telefono = '',
        this.fecha_nac = null,
        this.genero  = 'otro',

        this.id_provincia = 20,
        this.id_departamento = null,

        this.nivel_formacion  = 'primario',
        this.universidad  = 'unl',

        this.id_unidad_academica = this.firts_ua,
        this.id_carrera = null,

        this.otra_universidad = '',
        this.otra_carrera = '',
        
        this.otros_datos  = '',

        this.getProvincias();
        this.getLocalidades();

        this.$bvModal.hide('nuevoEmprendedor');
        this.$bvModal.hide('editarEmprendedor');
      },

      resetModalError(){
        this.error_dni = false;
        this.error_correo = false;
        this.$bvModal.hide('errorEmprendedor');
      }
    },

    created(){
      this.getUA();
      this.getCarrerasUA();
      this.getEmprendedores();
      this.getProvincias();
      this.getLocalidades();
    }
  };
</script>


<style>
</style>