<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          
          <EmprendedoresEmprendimiento />

          <div class="pt-4 pb-4">
            <div class="row">
              <div class="col-md-12 ">
                <b-button 
                  class="mt-3 btn btn-danger btn-fill" 
                  style="float: left;"
                  @click="backPage()">
                  Volver
                </b-button>
                
                <b-button 
                  class="mt-3 btn btn-info btn-fill" 
                  style="float: right;"
                  @click="nextPage()">
                  Siguiente
                </b-button>
              </div>
            </div>
          </div>

          <b-modal size="md" id="errors" hide-footer>
            <div class="row">
              <div class="col-md-12 pt-3">
                <ul>
                  <li v-for="item in errors">
                    {{ item.error }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="pt-4">
              <b-button 
                class="mt-3 pull-right btn-danger btn-fill" 
                @click="resetModalErrors()">
                Aceptar
              </b-button>
            </div>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;
  import EmprendedoresEmprendimiento from 'src/components/Emprendimiento/EmprendedoresEmprendimiento.vue'

  export default {
    components: {
      EmprendedoresEmprendimiento
    },
    data () {
      return {
        id:null,
        errors:[]
      }
    },
    methods:{
      currentRoute(){
        this.id = this.$route.params.id;
        this.$store.commit('setTitulo', {setTitulo: 'Programa UNLPotencia'})
        this.$store.commit('setSubTitulo', {setSubTitulo: 'editar 2/5'})
      },
  
      verificarEmprendimiento(){
        if(this.$store.state.emprendimiento_edit==false){
          this.render =false;
          this.$router.push( {name:'editUNLPotencia1', params: { id: this.id }});
        }
      },

      nextPage(){
        this.errors=[];

        if(this.$store.state.emprendimiento.emprendedores.length==0){
          this.errors.push({'error':'Agregue al menos un INTEGRANTE del emprendimiento antes de continuar'});
        }

        if(this.$store.state.emprendimiento.dni_responsable_emprendimiento==null){
          this.errors.push({'error':'Seleccione el RESPONSABLE del emprendimiento antes de continuar'});
        }

        if(this.errors[0]){
          this.$root.$emit('bv::show::modal','errors');
        }else{
          this.$router.push( {name:'editUNLPotencia3', params: { id: this.id }});
        }
      },

      backPage(){
        this.$router.push( {name:'editUNLPotencia1', params: { id: this.id }});
      },

      resetModalErrors(){
        this.errors=[];
        this.$root.$emit('bv::hide::modal','errors');
      }
    },

    created(){
      this.$Progress.start();
      window.scrollTo(0,-1);
      this.currentRoute();
      this.verificarEmprendimiento();
      this.$Progress.finish();
    }
  };
</script>
<style>
.table-emprendedores {
  table-layout: fixed;
}

.table-emprendedores thead th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}


.table-emprendedores thead tr th{
  padding-top: .5rem;
  padding-bottom: .5rem;
}

@media screen and (min-width: 400px) {
  .table-emprendedores tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-emprendedores tbody tr td:nth-child(3) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {
  .table-emprendedores tbody tr td:nth-child(1) {
    text-align: center;
  }

  .table-emprendedores tbody tr td:nth-child(1) {
    text-align: center;
  }


  .table-emprendedores thead tr th:nth-child(4) {
    justify-content: center;
  }

  .table-emprendedores tbody tr td:nth-child(4) {
    text-align: center;
  }

  .table-emprendedores thead tr th:nth-child(5) {
    justify-content: center;
  }

  .table-emprendedores tbody tr td:nth-child(5) {
    justify-content: center;
  }
}
</style>