import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		login: false,
		titulo: '',
		subtitulo: '',
		currentPage: 1,
		perPage:50,

		emprendimiento_create:false,
		emprendimiento_edit:false,
		id_programa:null,
		emprendimiento: {
			nombre: '',
			descripcion: '',
			razon_social: '',
			palabras_claves: [],
			anio_inicio: null,
			id_perfil: null,
			id_rubro_principal: null,
			id_rubro_secundario: null,

			//UBICACION DEL EMPRENDIMIENTO
			id_provincia: 20,
			id_departamento: null,
			id_localidad: null,

			//CONTACTO DEL EMPRENDIMIENTO
			correo_emprendimiento: '',
			telefono_emprendimiento: '',

			//REDES DEL EMPRENDIMIENTO
			web: '',
			facebook: '',
			instagram: '',
			linkedin: '',

			//EMPRENDEDORES
			id_emprendedor: 1,
			emprendedores: [],
			dni_responsable_emprendimiento: null,

			//DATOS ADMINISTRATIVOS
			num_nota_svytt: null,
			num_expediente: null,
			fecha_alta: '',
			condicion: 'Vinculado',
			fecha_baja: '',
			motivo_desvinculacion: 'Egreso',

			id_incubado_en: null,

			id_programas_vinculados: 1,
			programas_vinculados: [],

			//ESTADIO DEL EMPRENDIMIENTO
			id_estadios: 1,
			estadios:[],
			
			id_registros_mdn: 1,
			registros_mdn: [],

			id_registros_pdn: 1,
			registros_pdn: [],

			url_pitch: null,


			id_estado_perfil_equipo_emprendedor: 1,
			estado_perfil_equipo_emprendedor:[],

			id_perfiles_existentes_equipo_emprendedor: 1,
			perfiles_existentes_equipo_emprendedor:[],

			id_rol_miembro_equipo_emprendedor: 1,
			roles_miembros_equipo_emprendedor:[],


			proteccion_tecnologica: 'no',
			esta_protegida: 'no',
			estado_proteccion: 'en_proceso',


			formalizacion_juridica: 'no',
			fecha_estructura_juridica: '',
			id_estructura_juridica: null,
			otra_estructura_juridica: '',


			id_financiamiento_obtenido_emprendimiento: 1,
			financiamiento_obtenido_emprendimiento: [],

			
			id_premios_concursos_emprendimiento: 1,
			premios_concursos_emprendimiento: [],


			id_capacitaciones_emprendimiento: 1,
			capacitaciones_emprendimiento: [],


			id_asesoria_emprendimiento: 1,
			asesorias_emprendimiento: [],


			id_mentoria_emprendimiento: 1,
			mentorias_emprendimiento: [],


			id_observacion_emprendimiento: 1,
			observaciones_emprendimiento: [],


			id_estudios_mercados_emprendimiento: 1,
			estudios_mercados_emprendimiento: [],


			id_cooperacion_internacional_emprendimiento: 1,
			cooperaciones_internacionales_emprendimiento: [],


			id_red_internacional_emprendimiento: 1,
			redes_internacionales_emprendimiento: [],

			//NECESIDADES DEL EMPRENDIMIENTO

			id_necesidad_capital_emprendimiento:1,
			necesidad_capital_emprendimiento:[],

			id_necesidad_socio_emprendimiento: 1,
			necesidad_socio_emprendimiento:  [],

			id_necesidad_asesoria_emprendimiento: 1,
			necesidad_asesoria_emprendimiento: [],

			id_necesidad_internacionalizacion_emprendimiento: 1,
			necesidad_internacionalizacion_emprendimiento: [],

			id_necesidad_otra_emprendimiento: 1,
			necesidad_otra_emprendimiento: []
		},
		indicadores:{
			
		}
	},
	mutations: {
		setTitulo(state, payload){
			this.state.titulo = payload.setTitulo;
		},

		setSubTitulo(state, payload){
			this.state.subtitulo = payload.setSubTitulo;
		},

		setIsCreating(state, payload){
			this.state.isCreating = payload;
		},

		clearEmprendimiento(state){
			this.state.emprendimiento_create = false,
			this.state.emprendimiento_edit = false,
			this.state.id_programa = null,
			this.state.emprendimiento.nombre = '';
			this.state.emprendimiento.descripcion = '';
			this.state.emprendimiento.razon_social = '';
			this.state.emprendimiento.palabras_claves = [];
			this.state.emprendimiento.anio_inicio = null;
			this.state.emprendimiento.id_perfil = null;
			this.state.emprendimiento.id_rubro_principal = null;
			this.state.emprendimiento.id_rubro_secundario = null;
			this.state.emprendimiento.id_provincia = 20;
			this.state.emprendimiento.id_departamento = null;
			this.state.emprendimiento.id_localidad = null;
			this.state.emprendimiento.correo_emprendimiento = '';
			this.state.emprendimiento.telefono_emprendimiento = '';
			this.state.emprendimiento.web = '';
			this.state.emprendimiento.facebook = '';
			this.state.emprendimiento.instagram = '';
			this.state.emprendimiento.linkedin = '';
			
			this.state.emprendimiento.id_emprendedor = 1;
			this.state.emprendimiento.emprendedores = [];
			this.state.emprendimiento.dni_responsable_emprendimiento = null;

			this.state.emprendimiento.num_nota_svytt = '';
			this.state.emprendimiento.num_expediente = '';
			this.state.emprendimiento.fecha_alta = '';
			this.state.emprendimiento.condicion = 'Vinculado';
			this.state.emprendimiento.fecha_baja = '';
			this.state.emprendimiento.motivo_desvinculacion = 'Egreso';
//-------------------------------------------------------------------------------
			
			this.state.emprendimiento.id_incubado_en = null;

			this.state.emprendimiento.id_programas_vinculados = 1;
			this.state.emprendimiento.programas_vinculados = [];

			this.state.emprendimiento.estadios = [];

			this.state.emprendimiento.id_registros_mdn = 1;
			this.state.emprendimiento.registros_mdn = [];

			this.state.emprendimiento.id_registros_pdn = 1;
			this.state.emprendimiento.registros_pdn = [];

			this.state.emprendimiento.url_pitch = null;


			this.state.emprendimiento.id_estado_perfil_equipo_emprendedor = 1;
			this.state.emprendimiento.estado_perfil_equipo_emprendedor = [];

			this.state.emprendimiento.id_perfiles_existentes_equipo_emprendedor = 1;
			this.state.emprendimiento.perfiles_existentes_equipo_emprendedor = [];

			this.state.emprendimiento.id_rol_miembro_equipo_emprendedor = 1;
			this.state.emprendimiento.roles_miembros_equipo_emprendedor = [];

			this.state.emprendimiento.proteccion_tecnologica = 'no';
			this.state.emprendimiento.esta_protegida = 'no';
			this.state.emprendimiento.estado_proteccion = 'en_proceso';

			this.state.emprendimiento.formalizacion_juridica = 'no';
			this.state.emprendimiento.fecha_estructura_juridica = '';
			this.state.emprendimiento.id_estructura_juridica = null;
			this.state.emprendimiento.otra_estructura_juridica = '';

			this.state.emprendimiento.id_financiamiento_obtenido_emprendimiento = 1;
			this.state.emprendimiento.financiamiento_obtenido_emprendimiento = [];

			this.state.emprendimiento.id_premios_concursos_emprendimiento = 1;
			this.state.emprendimiento.premios_concursos_emprendimiento = [];

			this.state.emprendimiento.id_capacitaciones_emprendimiento = 1;
			this.state.emprendimiento.capacitaciones_emprendimiento = [];

			this.state.emprendimiento.id_asesoria_emprendimiento = 1;
			this.state.emprendimiento.asesorias_emprendimiento = [];

			this.state.emprendimiento.id_mentoria_emprendimiento = 1;
			this.state.emprendimiento.mentorias_emprendimiento = [];

			this.state.emprendimiento.id_observacion_emprendimiento = 1;
			this.state.emprendimiento.observaciones_emprendimiento = [];

			this.state.emprendimiento.id_estudios_mercados_emprendimiento = 1;
			this.state.emprendimiento.estudios_mercados_emprendimiento = [];

			this.state.emprendimiento.id_cooperacion_internacional_emprendimiento = 1;
			this.state.emprendimiento.cooperaciones_internacionales_emprendimiento = [];

			this.state.emprendimiento.id_red_internacional_emprendimiento = 1;
			this.state.emprendimiento.redes_internacionales_emprendimiento = [];

//-------------------------------------------------------------------------------

			this.state.emprendimiento.id_necesidad_capital_emprendimiento = 1;
			this.state.emprendimiento.necesidad_capital_emprendimiento = [];

			this.state.emprendimiento.id_necesidad_socio_emprendimiento = 1;
			this.state.emprendimiento.necesidad_socio_emprendimiento = [];

			this.state.emprendimiento.id_necesidad_asesoria_emprendimiento = 1;
			this.state.emprendimiento.necesidad_asesoria_emprendimiento = [];

			this.state.emprendimiento.id_necesidad_internacionalizacion_emprendimiento = 1;
			this.state.emprendimiento.necesidad_internacionalizacion_emprendimiento = [];

			this.state.emprendimiento.id_necesidad_otra_emprendimiento = 1;
			this.state.emprendimiento.necesidad_otra_emprendimiento = [];
			
		},
		//-------------------------------------------------
		//-------------SET EDIT EMPRENDIMIENTO-------------
		//-------------------------------------------------
		
		setEmprendmientoCreate(state, payload){
			this.state.emprendimiento_create = payload.emprendmientoCreate;
		},

		//-------------------------------------------------
		//-------------SET EDIT EMPRENDIMIENTO-------------
		//-------------------------------------------------
		
		setEmprendmientoEdit(state, payload){
			this.state.emprendimiento_edit = payload.emprendmientoEdit;
		},

		//-------------------------------------------------
		//----------------SET ID PROGRAMA------------------
		//-------------------------------------------------
		
		setIDPrograma(state, payload){
			this.state.id_programa = payload;
		},

		//-------------------------------------------------
		//----------------SET ID PROGRAMA EMPRENDIMIENTO------------------
		//-------------------------------------------------
		
		setIDProgramaEmprendimiento(state, payload){
			this.state.emprendimiento.id_programa_svytt = payload;
		},

		//-------------------------------------------------
		//----------------TABLE PAGINATION-----------------
		//-------------------------------------------------
		
		setCurrentPage(state, payload){
			this.state.currentPage = payload.currentPage;
		},

		setPerPage(state, payload){
			this.state.perPage = payload.perPage;
		},


		
		//-------------------------------------------------
		//------------------FIRST STEP---------------------
		//-------------------------------------------------
		
		setNombreEmprendimiento(state, payload){
			this.state.emprendimiento.nombre = payload.nombreEmprendimiento;
		},

		setDescripcionEmprendimiento(state, payload){
			this.state.emprendimiento.descripcion = payload.descripcionEmprendimiento;
		},

		setRazonSocialEmprendimiento(state, payload){
			this.state.emprendimiento.razon_social = payload.razonsocialEmprendimiento;
		},

		setPalabrasClavesEmprendimiento(state, payload){
			this.state.emprendimiento.palabras_claves = payload.palabrasclavesEmprendimiento;
		},

		setAnioInicioEmprendimiento(state, payload){
			this.state.emprendimiento.anio_inicio = payload.anioinicioEmprendimiento
		},

		setPerfilEmprendimiento(state, payload){
			this.state.emprendimiento.id_perfil = payload.perfilEmprendimiento;
		},

		setRubroPrincipalEmprendimiento(state, payload){
			this.state.emprendimiento.id_rubro_principal = payload.rubroprincipalEmprendimiento;
		},

		setRubroSecundarioEmprendimiento(state, payload){
			this.state.emprendimiento.id_rubro_secundario = payload.rubrosecundarioEmprendimiento;
		},

		setProvinciaEmprendimiento(state, payload){
			this.state.emprendimiento.id_provincia = payload.idProvinciaEmprendimiento;
		},

		setDepartamentoEmprendimiento(state, payload){
			this.state.emprendimiento.id_departamento = payload.idLocalidadEmprendimiento;
		},

		setLocalidadEmprendimiento(state, payload){
			this.state.emprendimiento.id_localidad = payload.idLocalidadEmprendimiento;
		},

		setEmailEmprendimiento(state, payload){
			this.state.emprendimiento.correo_emprendimiento = payload.emailEmprendimiento;
		},

		setTelefonoEmprendimiento(state, payload){
			this.state.emprendimiento.telefono_emprendimiento = payload.telefonoEmprendimiento;
		},

		setWebEmprendimiento(state, payload){
			this.state.emprendimiento.web = payload.webEmprendimiento;
		},

		setFacebookEmprendimiento(state, payload){
			this.state.emprendimiento.facebook = payload.facebookEmprendimiento;
		},

		setInstagramEmprendimiento(state, payload){
			this.state.emprendimiento.instagram = payload.instagramEmprendimiento;
		},

		setLinkedinEmprendimiento(state, payload){
			this.state.emprendimiento.linkedin = payload.linkedinEmprendimiento;
		},

		//-------------------------------------------------
		//-----------------SECOND STEP---------------------
		//-------------------------------------------------

		setDNIResponsableEmprendimiento(state, payload){
			this.state.emprendimiento.dni_responsable_emprendimiento = payload;
		},

		setEmprendedores(state, payload){
			this.state.emprendimiento.emprendedores = payload;
		},

		addEmprendedor(state, payload){
			this.state.emprendimiento.emprendedores.push(payload);
			this.state.emprendimiento.id_emprendedor++;
		},

		deleteEmprendedor(state, payload){
			var index = this.state.emprendimiento.emprendedores.findIndex(x => x.id ===payload);
			if(this.state.emprendimiento.emprendedores[index].dni==this.state.emprendimiento.dni_responsable_emprendimiento){
				this.state.emprendimiento.dni_responsable_emprendimiento=null;
			}
			this.state.emprendimiento.emprendedores.splice(index, 1);
		},

		//-------------------------------------------------
		//-----------------THIRD STEP----------------------
		//-------------------------------------------------

		setNumeroNotaSVyTTEmprendimiento(state, payload){
			this.state.emprendimiento.num_nota_svytt = payload.numNotaSVyTTEmprendimiento;
		},

		setNumeroExpedienteEmprendimiento(state, payload){
			this.state.emprendimiento.num_expediente = payload.numExpedienteEmprendimiento;
		},

		setFechaAltaEmprendimiento(state, payload){
			this.state.emprendimiento.fecha_alta = payload.fechaAltaEmprendimiento;
		},

		setCondicionEmprendimiento(state, payload){
			this.state.emprendimiento.condicion = payload.condicionEmprendimiento;
			if(this.state.emprendimiento.condicion=='Vinculado'){
				this.state.emprendimiento.fecha_baja='';
				this.state.emprendimiento.motivo_desvinculacion= 'Egreso';
			}else{
				this.state.emprendimiento.motivo_desvinculacion= 'Egreso';
			}
		},

		setFechaBajaEmprendimiento(state, payload){
			this.state.emprendimiento.fecha_baja = payload.fechaBajaEmprendimiento;
		},

		setMotivoDesvinculacionEmprendimiento(state, payload){
			this.state.emprendimiento.motivo_desvinculacion = payload.motivoDesvinculacionEmprendimiento;
		},

		//------------------------------------------------

		setIncubadoEnEmprendimiento(state, payload){
			this.state.emprendimiento.id_incubado_en = payload.incubadoEmprendimiento;
		},

		//------------------------------------------------
		
		addProgramasVinculados(state, payload){
			this.state.emprendimiento.programas_vinculados.push(payload);
			this.state.emprendimiento.id_programas_vinculados++;
		},

		deleteProgramasVinculados(state, payload){
			var index = this.state.emprendimiento.programas_vinculados.findIndex(x => x.id ===payload);
			this.state.emprendimiento.programas_vinculados.splice(index, 1);
		},

		//-------------------------------------------------
		//-----------------FOURTH STEP---------------------
		//-------------------------------------------------

		addEstadio(state, payload){
			this.state.emprendimiento.estadios.push(payload);
			this.state.emprendimiento.id_estadios++;
		},

		deleteEstadio(state, payload){
			var index = this.state.emprendimiento.estadios.findIndex(x => x.id ===payload);
			this.state.emprendimiento.estadios.splice(index, 1);
		},
		//------------------------------------------------
		
		addRegistroMDN(state, payload){
			this.state.emprendimiento.registros_mdn.push(payload);
			this.state.emprendimiento.id_registros_mdn++;
		},

		deleteRegistroMDN(state, payload){
			var index = this.state.emprendimiento.registros_mdn.findIndex(x => x.id ===payload);
			this.state.emprendimiento.registros_mdn.splice(index, 1);
		},

		//------------------------------------------------
		
		addRegistroPDN(state, payload){
			this.state.emprendimiento.registros_pdn.push(payload);
			this.state.emprendimiento.id_registros_pdn++;
		},

		deleteRegistroPDN(state, payload){
			var index = this.state.emprendimiento.registros_pdn.findIndex(x => x.id ===payload);
			this.state.emprendimiento.registros_pdn.splice(index, 1);
		},

		//------------------------------------------------

		setPitchEmprendimiento(state, payload){
			this.state.emprendimiento.url_pitch = payload.pitchEmprendimiento;
		},

		//------------------------------------------------

		addEstadoPerfilEmprendimiento(state, payload){
			this.state.emprendimiento.estado_perfil_equipo_emprendedor.push(payload);
			this.state.emprendimiento.id_estado_perfil_equipo_emprendedor++;
		},

		deleteEstadoPerfilEmprendimiento(state, payload){
			var index = this.state.emprendimiento.estado_perfil_equipo_emprendedor.findIndex(x => x.id ===payload);
			this.state.emprendimiento.estado_perfil_equipo_emprendedor.splice(index, 1);
		},

		//------------------------------------------------

		addPerfilesEquipoEmprendimiento(state, payload){
			this.state.emprendimiento.perfiles_existentes_equipo_emprendedor.push(payload);
			this.state.emprendimiento.id_perfiles_existentes_equipo_emprendedor++;
		},

		deletePerfilesEquipoEmprendimiento(state, payload){
			var index = this.state.emprendimiento.perfiles_existentes_equipo_emprendedor.findIndex(x => x.id ===payload);
			this.state.emprendimiento.perfiles_existentes_equipo_emprendedor.splice(index, 1);
		},

		//------------------------------------------------

		addRolesMiembrosEmprendimiento(state, payload){
			this.state.emprendimiento.roles_miembros_equipo_emprendedor.push(payload);
			this.state.emprendimiento.id_rol_miembro_equipo_emprendedor++;
		},

		deleteRolesMiembrosEmprendimiento(state, payload){
			var index = this.state.emprendimiento.roles_miembros_equipo_emprendedor.findIndex(x => x.id ===payload);
			this.state.emprendimiento.roles_miembros_equipo_emprendedor.splice(index, 1);
		},

		//------------------------------------------------


		setProteccionTecnologica(state, payload){
			this.state.emprendimiento.proteccion_tecnologica = payload.ProteccionTecnologica;
		},

		setEstaProtegida(state, payload){
			this.state.emprendimiento.esta_protegida = payload.EstaProtegida;
		},

		setEstadoProteccion(state, payload){
			this.state.emprendimiento.estado_proteccion = payload.EstadoProteccion;
		},

		setFormaJuridica(state, payload){
			this.state.emprendimiento.formalizacion_juridica = payload.FormaJuridica;
		},

		setFechaEstructiraJuridica(state, payload){
			this.state.emprendimiento.fecha_estructura_juridica = payload.FechaEstructuraJuridica;
		},

		setEstructiraJuridica(state, payload){
			this.state.emprendimiento.id_estructura_juridica = payload.EstructuraJuridica;
		},

		setOtraEstructiraJuridica(state, payload){
			this.state.emprendimiento.otra_estructura_juridica = payload.OtraEstructuraJuridica;
		},
		
		//------------------------------------------------
		
		addFinanciamientoObtenidoEmprendimiento(state, payload){
			this.state.emprendimiento.financiamiento_obtenido_emprendimiento.push(payload);
			this.state.emprendimiento.id_financiamiento_obtenido_emprendimiento++;
		},

		deleteFinanciamientoObtenidoEmprendimiento(state, payload){
			var index = this.state.emprendimiento.financiamiento_obtenido_emprendimiento.findIndex(x => x.id ===payload);
			this.state.emprendimiento.financiamiento_obtenido_emprendimiento.splice(index, 1);
		},

		//------------------------------------------------
		
		addPremioMencionEmprendimiento(state, payload){
			this.state.emprendimiento.premios_concursos_emprendimiento.push(payload);
			this.state.emprendimiento.id_premios_concursos_emprendimiento++;
		},

		deletePremioMencionEmprendimiento(state, payload){
			var index = this.state.emprendimiento.premios_concursos_emprendimiento.findIndex(x => x.id ===payload);
			this.state.emprendimiento.premios_concursos_emprendimiento.splice(index, 1);
		},

		//------------------------------------------------
		
		addCapacitacionEmprendimiento(state, payload){
			this.state.emprendimiento.capacitaciones_emprendimiento.push(payload);
			this.state.emprendimiento.id_capacitaciones_emprendimiento++;
		},

		deleteCapacitacionEmprendimiento(state, payload){
			var index = this.state.emprendimiento.capacitaciones_emprendimiento.findIndex(x => x.id ===payload);
			this.state.emprendimiento.capacitaciones_emprendimiento.splice(index, 1);
		},

		//------------------------------------------------
		
		addAsesoriaEmprendimiento(state, payload){
			this.state.emprendimiento.asesorias_emprendimiento.push(payload);
			this.state.emprendimiento.id_asesoria_emprendimiento++;
		},

		deleteAsesoriaEmprendimiento(state, payload){
			var index = this.state.emprendimiento.asesorias_emprendimiento.findIndex(x => x.id ===payload);
			this.state.emprendimiento.asesorias_emprendimiento.splice(index, 1);
		},

		//------------------------------------------------
		
		addMentoriaEmprendimiento(state, payload){
			this.state.emprendimiento.mentorias_emprendimiento.push(payload);
			this.state.emprendimiento.id_mentoria_emprendimiento++;
		},

		deleteMentoriaEmprendimiento(state, payload){
			var index = this.state.emprendimiento.mentorias_emprendimiento.findIndex(x => x.id ===payload);
			this.state.emprendimiento.mentorias_emprendimiento.splice(index, 1);
		},

		//------------------------------------------------
		
		addObservacionEmprendimiento(state, payload){
			this.state.emprendimiento.observaciones_emprendimiento.push(payload);
			this.state.emprendimiento.id_observacion_emprendimiento++;
		},

		deleteObservacionEmprendimiento(state, payload){
			var index = this.state.emprendimiento.observaciones_emprendimiento.findIndex(x => x.id ===payload);
			this.state.emprendimiento.observaciones_emprendimiento.splice(index, 1);
		},

		//------------------------------------------------
		
		addEstudiosMercadosEmprendimiento(state, payload){
			this.state.emprendimiento.estudios_mercados_emprendimiento.push(payload);
			this.state.emprendimiento.id_estudios_mercados_emprendimiento++;
		},

		deleteEstudiosMercadosEmprendimiento(state, payload){
			var index = this.state.emprendimiento.estudios_mercados_emprendimiento.findIndex(x => x.id ===payload);
			this.state.emprendimiento.estudios_mercados_emprendimiento.splice(index, 1);
		},

		//------------------------------------------------
		
		addCooperacionesInternacionalesEmprendimiento(state, payload){
			this.state.emprendimiento.cooperaciones_internacionales_emprendimiento.push(payload);
			this.state.emprendimiento.id_cooperacion_internacional_emprendimiento++;
		},

		deleteCooperacionesInternacionalesEmprendimiento(state, payload){
			var index = this.state.emprendimiento.cooperaciones_internacionales_emprendimiento.findIndex(x => x.id ===payload);
			this.state.emprendimiento.cooperaciones_internacionales_emprendimiento.splice(index, 1);
		},

		//------------------------------------------------
		
		addRedesInternacionalesEmprendimiento(state, payload){
			this.state.emprendimiento.redes_internacionales_emprendimiento.push(payload);
			this.state.emprendimiento.id_red_internacional_emprendimiento++;
		},

		deleteRedesInternacionalesEmprendimiento(state, payload){
			var index = this.state.emprendimiento.redes_internacionales_emprendimiento.findIndex(x => x.id ===payload);
			this.state.emprendimiento.redes_internacionales_emprendimiento.splice(index, 1);
		},

		//-------------------------------------------------
		//-----------------FIFHT STEP----------------------
		//-------------------------------------------------

		addNecesidadCapital(state, payload){
			this.state.emprendimiento.necesidad_capital_emprendimiento.push(payload);
			this.state.emprendimiento.id_necesidad_capital_emprendimiento++;
		},

		deleteNecesidadCapital(state, payload){
			var index = this.state.emprendimiento.necesidad_capital_emprendimiento.findIndex(x => x.id ===payload);
			this.state.emprendimiento.necesidad_capital_emprendimiento.splice(index, 1);
		},

		//------------------------------------------------

		addNecesidadSocio(state, payload){
			this.state.emprendimiento.necesidad_socio_emprendimiento.push(payload);
			this.state.emprendimiento.id_necesidad_socio_emprendimiento++;
		},

		deleteNecesidadSocio(state, payload){
			var index = this.state.emprendimiento.necesidad_socio_emprendimiento.findIndex(x => x.id ===payload);
			this.state.emprendimiento.necesidad_socio_emprendimiento.splice(index, 1);
		},

		//------------------------------------------------

		addNecesidadAsesoria(state, payload){
			this.state.emprendimiento.necesidad_asesoria_emprendimiento.push(payload);
			this.state.emprendimiento.id_necesidad_asesoria_emprendimiento++;
		},

		deleteNecesidadAsesoria(state, payload){
			var index = this.state.emprendimiento.necesidad_asesoria_emprendimiento.findIndex(x => x.id ===payload);
			this.state.emprendimiento.necesidad_asesoria_emprendimiento.splice(index, 1);
		},

		//------------------------------------------------

		addNecesidadInternacionalizacion(state, payload){
			this.state.emprendimiento.necesidad_internacionalizacion_emprendimiento.push(payload);
			this.state.emprendimiento.id_necesidad_internacionalizacion_emprendimiento++;
		},

		deleteNecesidadInternacionalizacion(state, payload){
			var index = this.state.emprendimiento.necesidad_internacionalizacion_emprendimiento.findIndex(x => x.id ===payload);
			this.state.emprendimiento.necesidad_internacionalizacion_emprendimiento.splice(index, 1);
		},

		//------------------------------------------------

		addOtrasNecesidadesEmprendimiento(state, payload){
			this.state.emprendimiento.necesidad_otra_emprendimiento.push(payload);
			this.state.emprendimiento.id_necesidad_otra_emprendimiento++;
		},

		deleteOtrasNecesidadesEmprendimiento(state, payload){
			var index = this.state.emprendimiento.necesidad_otra_emprendimiento.findIndex(x => x.id ===payload);
			this.state.emprendimiento.necesidad_otra_emprendimiento.splice(index, 1);
		},

	},
	actions: {

	}
})