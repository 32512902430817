<template>
  <div>
    <h4>Redes Internacionales</h4>
    <div class="card">
      <div class="col-md-12 pt-4 pb-4">
        <div class="row">
          <div class="col-md-12">
          <!-- User Interface controls -->
          <b-row>
            <b-col sm="12" class="my-3">
              <b-button 
                class="btn btn-info btn-fill" 
                @click="nuevo()">
                <i class="fa fa-plus"></i> 
                  Agregar
              </b-button>
            </b-col>
          </b-row>
          </div>
          <!-- Main table element -->
          <div class="col-md-12 pb-4">
            <b-table
              class="table-redes-internacionales-emprendimiento"
              hover
              stacked="md"
              head-variant="dark"
              :items="redes_internacionales_emprendimiento"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
            >
              <template v-slot:cell(actions)="row">
                <b-button 
                  size="sm" 
                  @click="editRedInternacional(row.item, row.index, $event.target)" 
                  class="btn btn-warning btn-fill mr-1">
                    <span class="fa fa-pencil"></span>
                </b-button>

                <b-button 
                  class="btn-danger btn-fill" 
                  size="sm" 
                  @click="deleteEstudio(row.item, row.index, $event.target)">
                    <span class="fa fa-trash"></span>
                </b-button>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <!------------------------------------------------------------------>
    <!----------------Create Modal create------------------------------->
    <!------------------------------------------------------------------>

    <b-modal size="lg" id="createRedInternacional" hide-footer>
      <div class="row">
        <div class="col-md-8">
          <label>Red:</label>
            <b-form-input  
              v-model="red">
            </b-form-input>
        </div>
        <div class="col-md-4">
          <label>Año:</label>
            <b-form-select 
              v-model="anio"
              :options="anios">
            </b-form-select>
        </div>   

        <div class="col-md-12 pt-3">
          <label>Descripción:</label>
            <b-form-textarea  
              v-model="descripcion">
            </b-form-textarea>
        </div>
      </div>

      <div class="pt-4">
          <b-button 
            class="mt-3 pull-left btn-danger btn-fill" 
            @click="resetRedInternacional()">
            Cancelar
          </b-button>
          
          <b-button
            :disabled="red==''" 
            class="mt-3 pull-right btn-info btn-fill" 
            @click="nuevaRed()">
            Agregar
          </b-button>
      </div>
    </b-modal>

    <!------------------------------------------------------------------>
    <!----------------Edit Modal create--------------------------------->
    <!------------------------------------------------------------------>

    <b-modal size="lg" id="editRedInternacional" hide-footer>
      <div class="row">
        <div class="col-md-8">
          <label>Red:</label>
            <b-form-input  
              v-model="red_edit">
            </b-form-input>
        </div>
        <div class="col-md-4">
          <label>Año:</label>
            <b-form-select 
              v-model="anio_edit"
              :options="anios">
            </b-form-select>
        </div>   

        <div class="col-md-12 pt-3">
          <label>Descripción:</label>
            <b-form-textarea  
              v-model="descripcion_edit">
            </b-form-textarea>
        </div>
      </div>
      <div class="pt-4">
          <b-button 
            class="mt-3 pull-left btn-danger btn-fill" 
            @click="resetRedInternacional()">
              Cacelar
          </b-button>
          
          <b-button 
            :disabled="red_edit==''" 
            class="mt-3 pull-right btn-info btn-fill" 
            @click="updateRed(editindex)">
              Actualizar
          </b-button>
      </div>
    </b-modal>

    <!------------------------------------------------------------------>
    <!------------------------------------------------------------------>
    <!------------------------------------------------------------------>

  </div>
</template>

<script>
  export default {
    data () {
      return {
        anio: null,
        anio_edit: null,
        red:'',
        red_edit:'',
        descripcion:'',
        descripcion_edit:'',

        anios: [],

        redes_internacionales_emprendimiento: [],

        editindex: null,
        deleteIndex: null,
        fields: [
          { key: 'red',       label: 'Red',       sortable: true, thStyle: {width:'60%'} },
          { key: 'anio',      label: 'Año',       sortable: true, thStyle: {width:'20%'} },
          { key: 'actions',   label: 'Acciones',  sortable: false},
        ],
        sortBy: 'anio',
        sortDesc: true,
        sortDirection: 'desc',
      }
    },

    methods:{
      getRedesInternacionalesEmprendimiento(){
        this.redes_internacionales_emprendimiento = this.$store.state.emprendimiento.redes_internacionales_emprendimiento;
      },

      nuevo(){
        this.resetRedInternacional();
        this.$bvModal.show('createRedInternacional');
      },

      nuevaRed(){
        var nueva_red = {
          'id':           this.$store.state.emprendimiento.id_red_internacional_emprendimiento,
          'red':          this.red,
          'descripcion':  this.descripcion,
          'anio':         this.anio
        };

        this.$store.commit('addRedesInternacionalesEmprendimiento', nueva_red);
        this.resetRedInternacional();
      },

      editRedInternacional(item, index, event){
        this.editindex = item.id; 

        this.red_edit = item.red,
        this.descripcion_edit = item.descripcion,
        this.anio_edit = item.anio,

        this.$bvModal.show('editRedInternacional');
      },

      updateRed(index){        
        for(var i=0; i<this.$store.state.emprendimiento.redes_internacionales_emprendimiento.length; i++){
          if(this.$store.state.emprendimiento.redes_internacionales_emprendimiento[i].id === index){
            this.$store.state.emprendimiento.redes_internacionales_emprendimiento[i].red = this.red_edit;
            this.$store.state.emprendimiento.redes_internacionales_emprendimiento[i].descripcion = this.descripcion_edit;
            this.$store.state.emprendimiento.redes_internacionales_emprendimiento[i].anio = this.anio_edit;
          }
        }
        this.resetRedInternacional();
      },

      deleteEstudio(item, index, event){
        this.deleteIndex = item.id; 
        if (confirm('¿Seguro que desea eliminarlo?')) {
          this.$store.commit('deleteEstudiosMercadosEmprendimiento', this.deleteIndex);
        }
        this.resetRedInternacional();
      },

      resetRedInternacional() {
        var today = new Date();
        this.anio = today.getFullYear();
        this.anio_edit = today.getFullYear();
        this.red = '';
        this.red_edit = '';
        this.descripcion = '';
        this.descripcion_edit = '';

        this.getRedesInternacionalesEmprendimiento();
        
        this.$bvModal.hide('createRedInternacional');
        this.$bvModal.hide('editRedInternacional');
      },

      getAnios(){
        var today = new Date();
        this.anio = today.getFullYear();

        var arr = [];
        for (var i = this.anio; i >= 2000; i--) {
          arr.push({'value': i, 'text': i });
        }
        this.anios = arr;
      },
    },

    created(){
      this.getRedesInternacionalesEmprendimiento();
      this.getAnios();
    }
  };
</script>


<style>
.table-redes-internacionales-emprendimiento {
  table-layout: fixed;
}

.table-redes-internacionales-emprendimiento thead th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}


.table-redes-internacionales-emprendimiento thead tr th{
  padding-top: .3rem;
  padding-bottom: .3rem;
}

@media screen and (min-width: 400px) {
  .table-redes-internacionales-emprendimiento tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-redes-internacionales-emprendimiento tbody tr td:nth-child(3) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {
  .table-redes-internacionales-emprendimiento tbody tr td:nth-child(2) {
    text-align: center;
  }

  .table-redes-internacionales-emprendimiento tbody tr td:nth-child(2) {
    text-align: center;
  }


  .table-redes-internacionales-emprendimiento thead tr th:nth-child(3) {
    justify-content: center;
  }

  .table-redes-internacionales-emprendimiento tbody tr td:nth-child(3) {
    justify-content: center;
  }
}
</style>