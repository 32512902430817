<template>
  <div>
    <b-card :title="this.titulo" :sub-title="this.subtitulo" class="p-2">
      <b-row>
          <b-col cols="12">
            <hr>
          </b-col>
      </b-row>

      <div class="d-flex justify-content-center">
        <b-col cols="12" md="4" sm="12">
          <b-form-select 
            @change="getEmprendimientosEgresadosEstadios()" 
            v-model="selected" 
            :options="options" 
            size="sm" 
            class="p2">
          </b-form-select>
        </b-col>
      </div>
      
      <b-container>
        <b-row>
          <b-col cols="12" class="pt-4">
            <b-row>
              <b-col class="titulo pt-3" xl="12" md="12" xs="12">
                <column-chart 
                  :data="this.emprendimientos_egresados_anio"
                  :stacked="false"
                  :colors="[['#009CB6']]"
                  :download="true">
                </column-chart>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
	</div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    props: ['titulo','subtitulo', 'data_chart', 'programa'],
    data() {
      return {
        selected: 1,
        options: [],
        loading_emprendimientos_egresados_anio:true,
        emprendimientos_egresados_anio:null,
      }
    },
    methods:{
      async getEmprendimientosEgresadosEstadios(){
        await axios.get(URL_API+'api/cantidadEmprendimientosEgresados/'+this.programa+'/'+this.selected)
          .then(response => {
            this.emprendimientos_egresados_anio = response.data.emprendimientos_egresados_anio;
            this.loading_emprendimientos_egresados_anio=false;
          })
          .catch(error => {
            console.log(error.response)
          })
      },

      async getEstadios(){
        await axios.get(URL_API+'api/estadio-emprendimiento')
          .then(response => {
            response.data.forEach(element => {
              this.options.push({value:element.id, text:element.estadio})  
            });
          })
          .catch(error => {
            console.log(error.response)
          })
      },
    },
    created(){
      this.getEmprendimientosEgresadosEstadios();
      this.getEstadios();
    }
  };
</script>