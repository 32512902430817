<template>
	<div>
		<h4 class="pt-3">Asesorias</h4>   
    <b-card>
      <b-table
          class="table-asesorias-emprendimiento"
          hover
          stacked="md"
          head-variant="dark"
          :items="this.asesorias_emprendimiento"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
        >
        </b-table>
    </b-card>
	</div>
</template>

<script>
  export default {
    props: ['asesorias_emprendimiento'],
    data() {
      return {
        fields: [
          { key: 'asesoria',  label: 'Asesoria',    sortable: true, thStyle: {width:'80%'} },
          { key: 'anio',      label: 'Año',         sortable: true}
        ],
        sortBy: 'anio',
        sortDesc: true,
        sortDirection: 'desc',
      }
    }
  };
</script>
<style>
.table-asesorias-emprendimiento thead tr th{
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.table-asesorias-emprendimiento thead tr th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 400px) {
  .table-asesorias-emprendimiento tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-asesorias-emprendimiento tbody tr td:nth-child(2) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {
  .table-asesorias-emprendimiento thead tr th:nth-child(2) {
    justify-content: center;
  }

  .table-asesorias-emprendimiento tbody tr td:nth-child(2) {
    justify-content: center;
  }
}
</style>