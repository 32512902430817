<template>
  <div>
    <h4>Financiamiento Obtenido <small>ANR/crédico/inversión</small></h4>
    <div class="card">
      <div class="col-md-12 pt-4 pb-4">
        <div class="row">
          <div class="col-md-12">
          <!-- User Interface controls -->
          <b-row>
            <b-col sm="12" class="my-3">
              <b-button 
                class="btn btn-info btn-fill" 
                @click="nuevo()"><i class="fa fa-plus"></i> 
                  Agregar
              </b-button>
            </b-col>
          </b-row>
          </div>
          <!-- Main table element -->
          <div class="col-md-12 pb-4">
            <b-table
              class="table-financiamiento-obtenido"
              hover
              stacked="md"
              head-variant="dark"
              :items="financiamiento_obtenido"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
            >
              <template v-slot:cell(fecha_financiamiento)="row">
                {{ row.item.fecha_financiamiento.toString().split("-").reverse().join("/") }}
              </template>

              <template v-slot:cell(actions)="row">
                <b-button size="sm" @click="editarFinanciamientoObtenido(row.item, row.index, $event.target)" class="btn btn-warning btn-fill mr-1">
                <span class="fa fa-pencil"></span>
                </b-button>

                <b-button class="btn-danger btn-fill" size="sm" @click="deleteEstadio(row.item, row.index, $event.target)">
                <span class="fa fa-trash"></span>
                </b-button>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

  <b-modal size="lg" id="createFinanciamientoObtenido" hide-footer>
    <div class="row">
      <div class="col-sm-8">
        Nombre de la linea de financiamiento:
        <b-form-input 
          v-model="nombre_linea">
        </b-form-input>
      </div>
      <div class="col-md-4">
        Fecha:
        <b-form-input 
            v-model="fecha_financiamiento"   
            type="date" 
            placeholder="dd/mm/aaaa"
            autocomplete="off">
        </b-form-input>
      </div>

      <div class="col-md-6 pt-3">
        Modalidad financiamiento:
        <b-form-select 
          v-model="modalidad_financiamiento" 
          :options="modalidades_financiamiento">
        </b-form-select>
      </div>
      <div class="col-md-6 pt-3">
        Otra modalidad:
        <b-form-input 
          :disabled="modalidad_financiamiento!='otro'"
          v-model="otra_modalidad_financiamiento">
        </b-form-input>
      </div>

      <div class="col-md-4 pt-3">
        Recibió acompañamiento:
        <b-form-select 
          v-model="recibio_acompanamiento" 
          :options="opciones">
        </b-form-select>
      </div>
      <div class="col-md-8 pt-3">
         Plataforma de Innovación:
        <b-form-select 
          :disabled="recibio_acompanamiento=='no'"
          v-model="id_plataforma_innovacion" 
          :options="plataformas_innovacion">
        </b-form-select>
      </div>

      <div class="col-md-12 pt-3">
        <hr>
      </div>

      <div class="col-md-4 pt-3">
        Adjudicación:
        <b-form-select 
          v-model="adjudicacion_financiamiento" 
          :options="opciones">
        </b-form-select>
      </div>
      <div class="col-sm-4 pt-3">
        Monto otorgado:
        <b-input-group prepend="$">
          <b-form-input 
            type="number"
            :disabled="adjudicacion_financiamiento=='no'"
            v-model="monto_financiamiento">
          </b-form-input>
        </b-input-group>
      </div>
      <div class="col-sm-4 pt-3">
        Contraparte:
        <b-input-group prepend="$">
          <b-form-input 
            type="number"
            :disabled="adjudicacion_financiamiento=='no'"
            v-model="contraparte_financiamiento">
          </b-form-input>
        </b-input-group>
      </div>
      <div class="col-md-4 pt-3">
        Fecha adjudicación:
        <b-form-input
          :disabled="adjudicacion_financiamiento=='no'" 
          v-model="fecha_adjudicacion_financiamiento"   
          type="date"
          placeholder="dd/mm/aaaa"
          autocomplete="off">
        </b-form-input>
      </div>
      <div class="col-md-4 pt-3">
        Fecha devolución:
        <b-form-input 
          :disabled="adjudicacion_financiamiento=='no'"
          v-model="fecha_devolucion_financiamiento"   
          type="date" 
          placeholder="dd/mm/aaaa"
          autocomplete="off">
        </b-form-input>
      </div>
      <div class="col-md-4 pt-3">
        Duración de la ejecución:
        <b-form-select 
          :disabled="adjudicacion_financiamiento=='no'"
          v-model="duracion_ejecucion" 
          :options="duracion">
        </b-form-select>
      </div>
      
      <div class="col-md-12 pt-1">
        <hr>
      </div>

      <div class="col-md-12 pt-1">
        Destino del capital:
        <b-form-select 
          v-model="id_fin_capital" 
          :options="fin_capitales">
        </b-form-select>
      </div>
      <div class="col-sm-12 pt-3">
        Observaciones:
        <b-form-textarea 
          v-model="observacion">
        </b-form-textarea>
      </div>
    </div>
    <div class="pt-4">
        <b-button 
          class="mt-3 pull-left btn-danger btn-fill" 
          @click="resetFinanciamientoObtenido">Cancelar
        </b-button>
        
        <b-button 
          :disabled="fecha_financiamiento===''||nombre_linea===''"
          class="mt-3 pull-right btn-info btn-fill" 
          @click="agregarFinanciamiento()">Agregar
        </b-button>
    </div>
  </b-modal>

  <b-modal size="lg" id="editFinanciamientoObtenido" hide-footer>
        <div class="row">
      <div class="col-sm-8">
        Nombre de la linea de financiamiento:
        <b-form-input 
          v-model="nombre_linea_edit">
        </b-form-input>
      </div>
      <div class="col-md-4">
        Fecha:
        <b-form-input 
            v-model="fecha_financiamiento_edit"   
            type="date" 
            placeholder="dd/mm/aaaa"
            autocomplete="off">
        </b-form-input>
      </div>

      <div class="col-md-6 pt-3">
        Modalidad financiamiento:
        <b-form-select 
          v-model="modalidad_financiamiento_edit" 
          :options="modalidades_financiamiento">
        </b-form-select>
      </div>
      <div class="col-md-6 pt-3">
        Otra modalidad:
        <b-form-input 
          :disabled="modalidad_financiamiento_edit!='otro'"
          v-model="otra_modalidad_financiamiento_edit">
        </b-form-input>
      </div>

      <div class="col-md-4 pt-3">
        Recibió acompañamiento:
        <b-form-select 
          v-model="recibio_acompanamiento_edit" 
          :options="opciones">
        </b-form-select>
      </div>
      <div class="col-md-8 pt-3">
         Plataforma de Innovación:
        <b-form-select 
          :disabled="recibio_acompanamiento_edit=='no'"
          v-model="id_plataforma_innovacion_edit" 
          :options="plataformas_innovacion">
        </b-form-select>
      </div>

      <div class="col-md-4 pt-3">
        Adjudicación:
        <b-form-select 
          v-model="adjudicacion_financiamiento_edit" 
          :options="opciones">
        </b-form-select>
      </div>
      <div class="col-sm-4 pt-3">
        Monto otorgado:
        <b-input-group prepend="$">
          <b-form-input 
            type="number"
            :disabled="adjudicacion_financiamiento_edit=='no'"
            v-model="monto_financiamiento_edit">
          </b-form-input>
        </b-input-group>
      </div>
      <div class="col-sm-4 pt-3">
        Contraparte:
        <b-input-group prepend="$">
          <b-form-input 
            type="number"
            :disabled="adjudicacion_financiamiento_edit=='no'"
            v-model="contraparte_financiamiento_edit">
          </b-form-input>
        </b-input-group>
      </div>
      <div class="col-md-4 pt-3">
        Fecha adjudicación:
        <b-form-input
          :disabled="adjudicacion_financiamiento_edit=='no'" 
          v-model="fecha_adjudicacion_financiamiento_edit"   
          type="date"
          placeholder="dd/mm/aaaa"
          autocomplete="off">
        </b-form-input>
      </div>
      <div class="col-md-4 pt-3">
        Fecha devolución:
        <b-form-input 
          :disabled="adjudicacion_financiamiento_edit=='no'"
          v-model="fecha_devolucion_financiamiento_edit"   
          type="date" 
          placeholder="dd/mm/aaaa"
          autocomplete="off">
        </b-form-input>
      </div>
      <div class="col-md-4 pt-3">
        Duración de la ejecución:
        <b-form-select 
          :disabled="adjudicacion_financiamiento_edit=='no'"
          v-model="duracion_ejecucion_edit" 
          :options="duracion">
        </b-form-select>
      </div>
      
      <div class="col-md-12 pt-1">
        <hr>
      </div>

      <div class="col-md-12 pt-1">
        Destino del capital:
        <b-form-select 
          v-model="id_fin_capital_edit" 
          :options="fin_capitales">
        </b-form-select>
      </div>
      <div class="col-sm-12 pt-3">
        Observaciones:
        <b-form-textarea 
          v-model="observacion_edit">
        </b-form-textarea>
      </div>
    </div>
    <div class="pt-4">
        <b-button class="mt-3 pull-left btn-danger btn-fill" @click="resetFinanciamientoObtenido">Cacelar</b-button>
        <b-button class="mt-3 pull-right btn-info btn-fill" @click="updateFinanciamientoObtenido(editindex)">Actualizar</b-button>
    </div>
  </b-modal>
</div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    data () {
      return {
        nombre_linea: '',
        nombre_linea_edit: '',
        fecha_financiamiento: null,
        fecha_financiamiento_edit: null,

        recibio_acompanamiento: 'no',
        recibio_acompanamiento_edit: 'no',
        opciones:[
          {value:'si', text:'SI'},
          {value:'no', text:'NO'}
        ],

        modalidad_financiamiento: 'ANR',
        otra_modalidad_financiamiento: '',
        modalidades_financiamiento:[
          {value: 'ANR', text:'ANR'},
          {value: 'Crédito', text:'Crédito'},
          {value: 'Inversión', text:'Inversión'},
          {value: 'Préstamo', text:'Préstamo'},
          {value: 'otro', text:'Otro'},
        ],

        modalidad_financiamiento_edit: '',
        otra_modalidad_financiamiento_edit: '',

        id_plataforma_innovacion: null,
        id_plataforma_innovacion_edit: null,

        first_plataforma_innovacion: null,
        first_plataforma_innovacion_edit: null,

        plataformas_innovacion:[],

        adjudicacion_financiamiento: 'no',
        adjudicacion_financiamiento_edit: 'no',

        monto_financiamiento: '',
        monto_financiamiento_edit: '',

        contraparte_financiamiento: '',
        contraparte_financiamiento_edit: '',

        fecha_adjudicacion_financiamiento: '',
        fecha_adjudicacion_financiamiento_edit: '',

        fecha_devolucion_financiamiento: '',
        fecha_devolucion_financiamiento_edit: '',

        duracion_ejecucion: 1,
        duracion_ejecucion_edit: 1,
        duracion:[
          {value:1, text:'1 año'},
          {value:2, text:'2 años'},
          {value:3, text:'3 años'},
          {value:4, text:'4 años'},
          {value:5, text:'5 años'},
          {value:6, text:'6 años'},
          {value:7, text:'7 años'},
          {value:8, text:'8 años'},
          {value:9, text:'9 años'},
          {value:10, text:'10 años'},
        ],

        id_fin_capital:null,
        id_fin_capital_edit: null,
        first_fin_capitales: null,
        fin_capitales: [],

        observacion:'',
        observacion_edit:'',

        financiamiento_obtenido: [],

        editindex: null,
        deleteIndex: null,
        fields: [
          { key: 'nombre_linea',          label: 'Linea de financiamiento', sortable: true,   thStyle: {width:'60%'}},
          { key: 'fecha_financiamiento',  label: 'Fecha',                   sortable: true,   thStyle: {width:'20%'} },
          { key: 'actions',               label: 'Acciones',                sortable: false}
        ],
        sortBy: null,
        sortDesc: false,
        sortDirection: 'desc',
      }
    },

    methods:{
      async getFinCapital(){
        let r = await axios.get(URL_API+'api/fin-capital');

        var arr = [];
        var first = true;
        var aux;
        r.data.forEach(function(element, index, rubros){
          if(first==true){
            aux= element.id;
            first=false;
          }
          arr.push({'value': element.id, 'text': element.nombre });
        });
        
        this.first_fin_capital = aux;
        this.id_fin_capital=aux;
        this.fin_capitales = arr;
      },

      async getPlataformas(){
        let r = await axios.get(URL_API+'api/plataformas-emprendimientos');

        var arr = [];
        var first = true;
        var aux;

        r.data.forEach(function(element, index){
          if(first===true){
            aux = element.id;
            first = false;
          }
          arr.push({'value': element.id, 'text': element.nombre , 'shortText': element.nombre_corto});
        });

        this.first_plataforma_innovacion = aux;
        this.id_plataforma_innovacion = aux;
        this.plataformas_innovacion = arr;
      },

      getFinanciamientoObtenido(){
        this.financiamiento_obtenido = this.$store.state.emprendimiento.financiamiento_obtenido_emprendimiento;
      },

      nuevo(){
        this.getFinCapital();
        this.getPlataformas();
        this.resetFinanciamientoObtenido();
        this.$bvModal.show('createFinanciamientoObtenido');
      },

      agregarFinanciamiento(){
        var nuevo_financiamiento = {
          'id':                           this.$store.state.emprendimiento.id_financiamiento_obtenido_emprendimiento,
          'nombre_linea':                 this.nombre_linea,
          'fecha_financiamiento':         this.fecha_financiamiento,
          'modalidad_financiamiento':     this.modalidad_financiamiento,
          'otra_modalidad_financiamiento':this.otra_modalidad_financiamiento,
          'recibio_acompanamiento':       this.recibio_acompanamiento,
          'id_plataforma_innovacion':     this.id_plataforma_innovacion,
          'adjudicacion_financiamiento':  this.adjudicacion_financiamiento,
          'monto_financiamiento':         this.monto_financiamiento,
          'contraparte_financiamiento':   this.contraparte_financiamiento,
          'fecha_adjudicacion_financiamiento': this.fecha_adjudicacion_financiamiento,
          'fecha_devolucion_financiamiento': this.fecha_devolucion_financiamiento,
          'duracion_ejecucion':           this.duracion_ejecucion,
          'id_fin_capital':               this.id_fin_capital,
          'observacion':                  this.observacion
        };

        this.$store.commit('addFinanciamientoObtenidoEmprendimiento', nuevo_financiamiento);
        this.resetFinanciamientoObtenido();
      },

      editarFinanciamientoObtenido(item, index, event){
        this.editindex = item.id;
        this.nombre_linea_edit                        = item.nombre_linea;
        this.fecha_financiamiento_edit                = item.fecha_financiamiento;
        this.modalidad_financiamiento_edit            = item.modalidad_financiamiento;
        this.otra_modalidad_financiamiento_edit       = item.otra_modalidad_financiamiento;
        this.recibio_acompanamiento_edit              = item.recibio_acompanamiento;
        this.id_plataforma_innovacion_edit               = item.id_plataforma_innovacion;
        this.adjudicacion_financiamiento_edit         = item.adjudicacion_financiamiento;
        this.monto_financiamiento_edit                = item.monto_financiamiento;
        this.contraparte_financiamiento_edit          = item.contraparte_financiamiento;
        this.fecha_adjudicacion_financiamiento_edit   = item.fecha_adjudicacion_financiamiento;
        this.fecha_devolucion_financiamiento_edit     = item.fecha_devolucion_financiamiento;
        this.id_fin_capital_edit                      = item.id_fin_capital;
        this.observacion_edit                         = item.observacion;

        this.$bvModal.show('editFinanciamientoObtenido');
      },

      updateFinanciamientoObtenido(index){
        var l = this.$store.state.emprendimiento.financiamiento_obtenido_emprendimiento.length;
        for(var i=0; i<l; i++){
          
          if(this.$store.state.emprendimiento.financiamiento_obtenido_emprendimiento[i].id === index){

            this.$store.state.emprendimiento.financiamiento_obtenido_emprendimiento[i].nombre_linea                 = this.nombre_linea_edit;
            this.$store.state.emprendimiento.financiamiento_obtenido_emprendimiento[i].fecha_financiamiento         = this.fecha_financiamiento_edit;

            this.$store.state.emprendimiento.financiamiento_obtenido_emprendimiento[i].modalidad_financiamiento     = this.modalidad_financiamiento_edit;
            this.$store.state.emprendimiento.financiamiento_obtenido_emprendimiento[i].otra_modalidad_financiamiento= this.otra_modalidad_financiamiento_edit;

            this.$store.state.emprendimiento.financiamiento_obtenido_emprendimiento[i].recibio_acompanamiento       = this.recibio_acompanamiento_edit;
            this.$store.state.emprendimiento.financiamiento_obtenido_emprendimiento[i].id_plataforma_innovacion        = this.id_plataforma_innovacion_edit;

            this.$store.state.emprendimiento.financiamiento_obtenido_emprendimiento[i].adjudicacion_financiamiento  = this.adjudicacion_financiamiento_edit;

            this.$store.state.emprendimiento.financiamiento_obtenido_emprendimiento[i].monto_financiamiento         = this.monto_financiamiento_edit;
            this.$store.state.emprendimiento.financiamiento_obtenido_emprendimiento[i].contraparte_financiamiento   = this.contraparte_financiamiento_edit;

            this.$store.state.emprendimiento.financiamiento_obtenido_emprendimiento[i].fecha_adjudicacion_financiamiento= this.fecha_adjudicacion_financiamiento_edit;
            this.$store.state.emprendimiento.financiamiento_obtenido_emprendimiento[i].fecha_devolucion_financiamiento  = this.fecha_devolucion_financiamiento_edit;
            this.$store.state.emprendimiento.financiamiento_obtenido_emprendimiento[i].duracion_ejecucion               = this.duracion_ejecucion_edit;

            this.$store.state.emprendimiento.financiamiento_obtenido_emprendimiento[i].id_fin_capital               = this.id_fin_capital_edit;
            this.$store.state.emprendimiento.financiamiento_obtenido_emprendimiento[i].observacion                  = this.observacion_edit;
          }
        }
        this.resetFinanciamientoObtenido();
      },

      deleteEstadio(item, index, event){
        this.deleteIndex = item.id;
        if (confirm('¿Seguro que desea eliminarlo?')) {
          this.$store.commit('deleteFinanciamientoObtenidoEmprendimiento', this.deleteIndex);
        }
        this.resetFinanciamientoObtenido();
      },

      resetFinanciamientoObtenido() {
        this.nombre_linea                 = '';
        this.fecha_financiamiento         = '';
        this.modalidad_financiamiento     = 'ANR'
        this.otra_modalidad_financiamiento= '',
        this.recibio_acompanamiento       = 'no';
        this.plataforma_innovacion        = 'emprendedores';

        this.adjudicacion_financiamiento  = 'no';
        this.monto_financiamiento         = '';
        this.contraparte_financiamiento   = '';
        this.fecha_adjudicacion_financiamiento = '';
        this.fecha_devolucion_financiamiento = '';
        this.duracion_ejecucion           = 1;

        this.id_fin_capital               = this.first_fin_capital;
        this.observacion                  = '';

        this.getFinanciamientoObtenido();

        this.$bvModal.hide('createFinanciamientoObtenido');
        this.$bvModal.hide('editFinanciamientoObtenido');
      },
    },

    created(){
      this.getFinCapital();
      this.getPlataformas();
      this.getFinanciamientoObtenido();
    }
  };
</script>
<style>
.table-financiamiento-obtenido {
  table-layout: fixed;
}

.table-financiamiento-obtenido thead th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}

.table-financiamiento-obtenido thead tr th{
  padding-top: .5rem;
  padding-bottom: .5rem;
}

@media screen and (min-width: 400px) {
  .table-financiamiento-obtenido tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-financiamiento-obtenido tbody tr td:nth-child(3) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {

  .table-financiamiento-obtenido thead tr th:nth-child(2) {
    justify-content: center;
  }

  .table-financiamiento-obtenido tbody tr td:nth-child(2) {
    text-align: center;
  }


  .table-financiamiento-obtenido thead tr th:nth-child(3) {
    justify-content: center;
  }

  .table-financiamiento-obtenido tbody tr td:nth-child(3) {
    justify-content: center;
  }
}
</style>