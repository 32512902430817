<template>
  <div>
    <h4>Contacto del Emprendimiento:</h4>
    <div class="card">
      <div class="col-md-12 pt-4 pb-4">
        <div class="row">
          <div class="col-md-6 pt-3">
            <label>Correo Electrónico:</label>
            <b-input-group size="md" prepend="@">
              <b-form-input 
                v-model="emailEmprendimiento" 
                name="emailEmprendimiento" 
                autocomplete="off">
              </b-form-input>
            </b-input-group>
          </div>

          <div class="col-md-6 pt-3">
            <label>Teléfono:</label>
            <b-input-group>
              <b-input-group-prepend>
                <span class="input-group-text"><i class="fa fa-phone"></i></span>
              </b-input-group-prepend>
              <b-form-input 
                type="text"
                @keypress="onlyNumber" 
                v-model="telefonoEmprendimiento" 
                name="telefonoEmprendimiento" 
                autocomplete="off">
              </b-form-input>
            </b-input-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ContactoEmprendimiento',
    computed:{
      emailEmprendimiento:{
        get(){
          return this.$store.state.emprendimiento.correo_emprendimiento
        },
        set(value){
          this.$store.commit('setEmailEmprendimiento', {emailEmprendimiento: value})
        }
      },

      telefonoEmprendimiento:{
        get(){
          return this.$store.state.emprendimiento.telefono_emprendimiento
        },
        set(value){
          this.$store.commit('setTelefonoEmprendimiento', {telefonoEmprendimiento: value})
        }
      },
    },
    methods:{
      onlyNumber ($event) {
        //console.log($event.keyCode); //keyCodes value
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        if ((keyCode < 48 || keyCode > 57) && keyCode!== 41 && keyCode!== 40 && keyCode!== 45 && keyCode !== 46) {
          $event.preventDefault();
        }
      },
    }
  };
</script>
