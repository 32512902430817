/*!
 =========================================================
 * Vue Light Bootstrap Dashboard - v2.0.0 (Bootstrap 4)
 =========================================================

 * Product Page: http://www.creative-tim.com/product/light-bootstrap-dashboard
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import Vue from 'vue'
import App from './App.vue'
import store from './store'

// Axios
import axios from 'axios'
import VueAxios from 'vue-axios'

// V-Mask
import VueMask from 'v-mask'
Vue.use(VueMask);

// Chartkick importadas por CDN desde el index
//dado que tenia problemas para instalar Chart.js
Vue.use(Chartkick);

// LightBootstrap plugin
import LightBootstrap from './light-bootstrap-main'

// BootstrapVue
import { BootstrapVue, IconsPlugin, BSpinner } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// router setup
import VueRouter from 'vue-router'
import router from './routes/routes'

import './registerServiceWorker'
// plugin setup
Vue.use(VueRouter)
Vue.use(LightBootstrap)
Vue.use(VueAxios, axios)

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)


//------ VUE PROGRESS--------//
import VueProgressBar from 'vue-progressbar'
Vue.use(VueProgressBar, {
  color: '#009BB6',
  failedColor: 'red',
  thickness: '6px',
  transition: {
    speed: '0.9s',
    opacity: '0.9s',
    termination: 750
  },
})

//Interceptors de axios
// Request interceptor for API calls
import auth from './logic/auth'
//alert(location.hostname);
axios.interceptors.request.use(
  config => {
    let auth_token = localStorage.getItem('access_token');
    if(!auth_token){
      
      localStorage.removeItem('access_token');
      localStorage.removeItem('token_type');
      localStorage.removeItem('expires_in');
      localStorage.removeItem('name');
      localStorage.removeItem('lastname');
      localStorage.removeItem('email');

      if(router.history.current.name !== 'login'){
        router.push({ 
          path: '/rue/login',
          name: 'login'  
        })
      }
    }
    config.headers.Authorization = 'Bearer ' + auth_token;
    return config;
  },
  error => {
    return Promise.reject(error)
  }
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error)
  },
  async function (error) {
    const originalRequest = error.response;

    if (error.response.status === 401 && !originalRequest._retry) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('token_type');
      localStorage.removeItem('expires_in');
      localStorage.removeItem('name');
      localStorage.removeItem('lastname');
      localStorage.removeItem('email');

      if(router.history.current.name !== 'login'){
        router.push({ 
          path: '/rue/login',
          name: 'login'  
        })
      }
    }
  }
);

/* eslint-disable no-new */
new Vue({
  store,
  el: '#app',
  render: h => h(App),
  router
})
