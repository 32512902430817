<template>
  <div class="content pt-4"> 
    <b-container fluid>
      <div class="card">
        <div class="col-md-12">
          <!-- User Interface controls -->
          <b-row>
            <b-col lg="6" class="my-4">
              <b-button 
                @click="nuevo"
                class="btn btn-info btn-fill">
                  <span class="fa fa-plus"></span>
                   Agregar usuario
                </b-button>
            </b-col>
            <b-col lg="6" class="my-4">
              <b-form-group
                label-cols-sm="7"
                label-align-sm="right"
                label-size="md"
                label-for="filterInput"  
              >
              <b-form-input
                v-model="filter"
                type="search"
                id="filterInput"
                autocomplete="off"
                size="md"
                placeholder="Buscár"
              ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <!-- Main table element -->
        <div class="col-md-12 pt-4">
          <b-table
            class="table-usuarios"
            hover
            head-variant="dark"
            stacked="md"
            :items="usuarios"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filterIncludedFields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered"
          >
            <template v-slot:cell(user)="row">
              {{ row.item.lastname.toUpperCase() }}, {{ row.item.name }}
            </template>

            <template v-slot:cell(actions)="row">
              <b-button size="sm" @click="edit(row.item, row.index, $event.target)" class="btn btn-warning btn-fill mr-1">
                <span class="fa fa-pencil"></span>
              </b-button>
              <b-button class="btn-danger btn-fill" size="sm" @click="deleteUser(row.item, row.index, $event.target)">
                <span class="fa fa-trash"></span>
              </b-button>
            </template>

            <template v-slot:row-details="row">
              <b-card>
                <ul>
                  <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                </ul>
              </b-card>
            </template>
          </b-table>
        </div>
        <b-row>
          <b-col sm="5" md="3" class="my-1">
            <b-form-group
              label="Por pagina"
              label-cols-sm="8"
              label-cols-md="8"
              label-cols-lg="8"
              label-align-sm="right"
              label-size="sm"
              label-for="perPageSelect"
              class="mb-0"
            >
              <b-form-select
                v-model="perPage"
                id="perPageSelect"
                size="sm"
                :options="pageOptions"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="7" md="3" class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-row>
      </div>
      <b-modal size="md" id="create-modal" title="Nuevo usuario" hide-footer>
        <div class="row">
          <div class="col-md-12" style="padding-top:.5rem;">
            Apellido:
            <b-form-input 
              v-model="lastname" 
              @keydown.enter="addNuevo" 
              required 
              autocomplete="off">
            </b-form-input>
          </div>

          <div class="col-md-12" style="padding-top:.5rem;">
            Nombre:
            <b-form-input 
              v-model="name" 
              @keydown.enter="addNuevo" 
              required 
              autocomplete="off">
            </b-form-input>
          </div>

          <div class="col-md-12" style="padding-top:.5rem;">
            Correo electrónico:
            <b-form-input 
              type="email" 
              v-model="email" 
              @keydown.enter="addNuevo" 
              required 
              autocomplete="off">
            </b-form-input>
          </div>

          <div class="col-md-12" style="padding-top:.5rem;">
            Confirmar correo electrónico:
            <b-form-input 
              :state="email==email_confirm"
              type="email" 
              v-model="email_confirm" 
              @keydown.enter="addNuevo" 
              required 
              autocomplete="off">
            </b-form-input>
          </div>

          <div 
            v-if="lastname!=''"
            class="col-md-12" 
            style="padding-top:.5rem;">
            Contraeña
            <b-col
              cols="12"
              align="center"
              style="background-color:#FF6A59; color:white;border-radius: 4px; padding:.5rem;">
              <div style=" font-size: 27px;font-weight: 100;">{{ password }}</div>
            </b-col>
          </div>

          <div class="col-md-12" style="padding-top:.5rem;">
            <hr>
            <label><b>Roles:</b></label>

              <!-- Using value -->
            <b-button 
              pill
              variant="outline-primary" 
              v-b-toggle="'collapse-2'" 
              size="sm" 
              class="m-2 pill">Info roles
            </b-button>

            <!-- Element to collapse -->
            <b-collapse id="collapse-2">
              <b-list-group 
                v-for="rol in roles" 
                class="m-2">
                <b-list-group-item>
                  <strong>{{ rol.name }}:</strong> {{ rol.description }}
                </b-list-group-item>
              </b-list-group>
            </b-collapse>

            <b-form-group class="pl-4">
              <b-form-checkbox
                v-for="rol in roles"
                v-model="roles_user"
                :disabled="rol.id==1"
                :key="rol.id"
                :value="rol.id">
                {{ rol.name }}
              </b-form-checkbox>
            </b-form-group>

          </div>
        </div>
        <div class="pt-4">

          <b-button 
            class="mt-3 pull-left btn-danger btn-fill" 
            @click="resetModal">Cacelar
          </b-button>
          
          <b-button 
            class="mt-3 pull-right btn-info btn-fill" 
            :disabled="name=='' || lastname=='' || email!=email_confirm"
            @click="addNuevo">Agregar
          </b-button>
        
        </div>
      </b-modal>

      <!-- Edit modal -->
      <b-modal id="edit-modal" :id="editModal.id" :title="editModal.title" size="md" hide-footer>
        <div class="row">
          <div class="col-md-12">
            Apellido:
            <b-form-input 
              v-model="lastname" 
              @keydown.enter="update" 
              required 
              autocomplete="off">
            </b-form-input>
          </div>

          <div class="col-md-12" style="padding-top:1rem;">
            Nombre:
            <b-form-input 
              v-model="name" 
              @keydown.enter="update" 
              required 
              autocomplete="off">
            </b-form-input>
          </div>

          <div class="col-md-12" style="padding-top:1rem;">
            Correo electrónico:
            <b-form-input 
              type="email" 
              v-model="email" 
              @keydown.enter="update" 
              required 
              autocomplete="off">
            </b-form-input>
          </div>

          <div class="col-md-12" style="padding-top:1rem;">
            Confirmar correo electrónico:
            <b-form-input 
              :state="email==email_confirm"
              type="email" 
              v-model="email_confirm" 
              @keydown.enter="update" 
              required 
              autocomplete="off">
            </b-form-input>
          </div>
        </div>

        <div class="col-md-12" style="padding-top:1rem;">
          <hr>
          <label><b>Roles:</b></label>

            <!-- Using value -->
          <b-button 
            pill
            variant="outline-primary" 
            v-b-toggle="'collapse-2'" 
            size="sm" 
            class="m-2 pill">Info roles
          </b-button>

          <!-- Element to collapse -->
          <b-collapse id="collapse-2">
            <b-list-group 
              v-for="rol in roles" 
              class="m-2">
              <b-list-group-item>
                <strong>{{ rol.name }}:</strong> {{ rol.description }}
              </b-list-group-item>
            </b-list-group>
          </b-collapse>

          <b-form-group class="pl-4">
            <b-form-checkbox
              v-for="rol in roles"
              v-model="roles_user"
              :disabled="rol.id==1"
              :key="rol.id"
              :value="rol.id">
              {{ rol.name }}
            </b-form-checkbox>
          </b-form-group>
        </div>
        

        <div class="pt-4">
          <b-button 
            class="mt-3 pull-left btn-danger btn-fill" 
            @click="resetModal">
            Cacelar
          </b-button>
          <b-button 
            :disabled="email!=email_confirm || email_confirm==''"
            class="mt-3 pull-right btn-info btn-fill" 
            @click="update">
            Actualizar
          </b-button>
        </div>
      </b-modal>

    </b-container>
  </div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    data() {
      return {
        roles:[],
        administrador: false,
        roles_user: [1],

        usuarios: [],
        name: '',
        lastname: '',
        dni: '',
        email: '',
        email_confirm: '',
        password:'',
        editindex: null,
        deleteIndex: null,
        fields: [
          { key: 'id', label: '#', sortable: true, thStyle: {width:'7%'}},
          { key: 'user', label: 'Usuario', sortable: true, sortDirection: 'desc', thStyle: {width:'25%'}},
          { key: 'email', label: 'Correo Electrónico', sortable: true, sortDirection: 'desc', thStyle: {width:'50%'}},
          { key: 'actions', label: 'Acciones'}
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 25,
        pageOptions: [10, 25, 50],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        editModal: {
          id: 'info-modal',
          title: '',
          content: ''
        }
      }
    },

    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
    },

    mounted() {
      // Set the initial number of items
      this.totalRows = this.usuarios.length;
      this.$store.commit('setTitulo', {setTitulo: 'Configuración'});
      this.$store.commit('setSubTitulo', {setSubTitulo: 'Usuarios'});
    },

    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },

      async getUsuarios(){
        let r = await axios.get(URL_API+'api/usuarios');
        this.usuarios = r.data.usuarios;
        this.roles = r.data.roles;
        console.log(this.usuarios);
      },

      nuevo() {
        this.resetModal();
        this.$root.$emit('bv::show::modal', 'create-modal');
      },

      async addNuevo(){
        axios.post(URL_API+'api/usuarios', {
          name: this.name,
          lastname: this.lastname,
          dni: this.dni,
          email: this.email,
          password: this.password,
          roles: this.roles_user
        }).then(response => {
          this.resetModal();
          this.getUsuarios();
        }).catch(e => {
            console.log(e);
        });

        this.resetModal();
        this.$root.$emit('bv::hide::modal','create-modal')
      },

      resetModal(item, index, button) {
        this.name = '',
        this.lastname = '',
        this.dni = '',
        this.email = '',
        this.email_confirm = '',

        this.roles_user = [1],

        this.$root.$emit('bv::hide::modal','create-modal');
        this.$root.$emit('bv::hide::modal', this.editModal.id, button)
      },

      edit(item, index, button) {
        this.editindex = item.id;
        this.lastname = item.lastname;
        this.name = item.name;
        this.dni = item.dni;
        this.email = item.email;
        this.email_confirm = item.email;

        this.roles_user = [];
        item.roles.forEach(element => {
          this.roles_user.push(element.id);
        });

        this.$root.$emit('bv::show::modal', this.editModal.id, button);
      },

      async update(item, index, button){
        if(this.email== this.email_confirm){
          await axios.put(URL_API+'api/usuarios/'+this.editindex, {
              name: this.name,
              lastname: this.lastname,
              dni: this.dni,
              email: this.email,
              roles: this.roles_user
          }).then(response => {
            this.getUsuarios();
            this.resetModal();
          }).catch(e => {
              console.log(e);
              this.resetModal();
          });
        }
      },

      async deleteUser(item, index, button){
        this.deleteIndex = item.id;
        if (confirm('¿Seguro que desea eliminarlo?')) {
          axios.delete(URL_API+'api/usuarios/'+this.deleteIndex)
          .then(response => {
            this.deleteIndex = null;
            this.getUsuarios();
          }).catch(e => {
              console.log(e);
          });
        }
      }
    },

    watch: {
      lastname: function (val) {
        if(val==''){
          this.password='';
        }else{
          const acentos = {'á':'a','é':'e','í':'i','ó':'o','ú':'u','Á':'A','É':'E','Í':'I','Ó':'O','Ú':'U'};
	        val = val.split('').map( letra => acentos[letra] || letra).join('').toString();
          this.password = val.toLowerCase().replace(/ /g, "")+'1234';
        }
      },
    },

    created(){
      this.$Progress.start(),
      this.getUsuarios(),
      this.$Progress.finish()
    }
  }
</script>

<style>
.btn-close {
  background-color: red;
}

.table-usuarios {
  table-layout: fixed;
}

.table-usuarios thead th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;

}

.table-usuarios thead tr th{
  padding-top: .5rem;
  padding-bottom: .5rem;
}


@media screen and (min-width: 400px) {
  .table-usuarios tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-usuarios tbody tr td:nth-child(2) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {
  .table-usuarios tbody tr td:nth-child(1) {
    text-align: center;
  }

  .table-usuarios thead tr th:nth-child(4) {
    justify-content: center;
  }

  .table-usuarios tbody tr td:nth-child(4) {
    justify-content: center;
  }
}
</style>