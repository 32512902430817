<template>
  <div>
    <h4>Otras necesidades</h4>
    <div class="card">
      <div class="col-md-12 pt-4 pb-4">
        <div class="row">
          <div class="col-md-12">
          <!-- User Interface controls -->
          <b-row>
            <b-col sm="12" class="my-3">
              <b-button 
                class="btn btn-info btn-fill" 
                @click="nuevo()">
                <i class="fa fa-plus"></i> 
                  Agregar
              </b-button>
            </b-col>
          </b-row>
          </div>
          <!-- Main table element -->
          <div class="col-md-12 pb-4">
            <b-table
              class="table-observaciones-emprendimiento"
              hover
              stacked="md"
              head-variant="dark"
              :items="necesidad_otra_emprendimiento"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
            >
              <template v-slot:cell(actions)="row">
                <b-button 
                  size="sm" 
                  @click="editObservacion(row.item, row.index, $event.target)" 
                  class="btn btn-warning btn-fill mr-1">
                    <span class="fa fa-pencil"></span>
                </b-button>

                <b-button 
                  class="btn-danger btn-fill" 
                  size="sm" 
                  @click="deleteOtraNecesidad(row.item, row.index, $event.target)">
                    <span class="fa fa-trash"></span>
                </b-button>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

  <b-modal size="lg" id="createObservacion" hide-footer>
    <div class="row">
      <div class="col-md-8">
        <label>Otra necesidad:</label>
          <b-input
            v-model="necesidad">
          </b-input>
      </div>
      
      <div class="col-md-4">
        <label>Año:</label>
          <b-form-select 
            v-model="anio"
            :options="anios">
          </b-form-select>
      </div>   

      <div class="col-md-12 pt-3">
        <label>Descripción:</label>
          <b-form-textarea
            v-model="descripcion">
          </b-form-textarea>
      </div> 
    </div>

    <div class="pt-4">
        <b-button 
          class="mt-3 pull-left btn-danger btn-fill" 
          @click="resetOtraNecesidad">Cancelar
        </b-button>
        
        <b-button
          :disabled="necesidad==''" 
          class="mt-3 pull-right btn-info btn-fill" 
          @click="nuevaNecesidad">Agregar
        </b-button>
    </div>
  </b-modal>

  <b-modal size="lg" id="editObservacion" hide-footer>
    <div class="row">

      <div class="col-md-8">
        <label>Otra Necesidad:</label>
          <b-input
            v-model="necesidad_edit">
          </b-input>
      </div>

      <div class="col-md-4">
        <label>Año:</label>
          <b-form-select 
            v-model="anio_edit"
            :options="anios">
          </b-form-select>
      </div>   

      <div class="col-md-12 pt-3">
        <label>Descripción:</label>
          <b-form-textarea
            v-model="descripcion_edit">
          </b-form-textarea>
      </div> 
    </div>
    <div class="pt-4">
        <b-button 
          class="mt-3 pull-left btn-danger btn-fill" 
          @click="resetOtraNecesidad">
            Cacelar
        </b-button>
        
        <b-button 
          :disabled="necesidad_edit==''" 
          class="mt-3 pull-right btn-info btn-fill" 
          @click="updateOtraNecesidad(editindex)">
            Actualizar
        </b-button>
    </div>
  </b-modal>
</div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    data () {
      return {

        necesidad_otra_emprendimiento: [],
        
        necesidad:'',
        descripcion:'',
        anio: null,

        necesidad_edit:'',
        descripcion_edit:'',
        anio_edit: null,

        anios: [],

        editindex: null,
        deleteIndex: null,
        fields: [
          { key: 'necesidad', label: 'Otra Necesidad',  sortable: true, thStyle: {width:'60%'}  },
          { key: 'anio',      label: 'Año',             sortable: true, thStyle: {width:'20%'}  },
          { key: 'actions',   label: 'Acciones',        sortable: false},
        ],
        sortBy: 'anio',
        sortDesc: true,
        sortDirection: 'desc',
      }
    },

    methods:{
      getOtrasNecesidadesEmprendimientos(){
        this.necesidad_otra_emprendimiento = this.$store.state.emprendimiento.necesidad_otra_emprendimiento;
      },

      nuevo(){
        this.getAnios();
        this.$bvModal.show('createObservacion');
      },

      nuevaNecesidad(){
        var nueva_necesidad = {
          'id':           this.$store.state.emprendimiento.id_necesidad_otra_emprendimiento,
          'necesidad':    this.necesidad,
          'descripcion':  this.descripcion,
          'anio':         this.anio,
        };

        this.$store.commit('addOtrasNecesidadesEmprendimiento', nueva_necesidad);
        this.resetOtraNecesidad();
      },

      editObservacion(item, index, event){
        this.editindex = item.id; 
        this.necesidad_edit = item.necesidad,
        this.descripcion_edit = item.descripcion,
        this.anio_edit = item.anio,

        this.$bvModal.show('editObservacion');
      },

      updateOtraNecesidad(index){     
        for(var i=0; i<this.$store.state.emprendimiento.necesidad_otra_emprendimiento.length; i++){
          if(this.$store.state.emprendimiento.necesidad_otra_emprendimiento[i].id === index){
            this.$store.state.emprendimiento.necesidad_otra_emprendimiento[i].necesidad = this.necesidad_edit;
            this.$store.state.emprendimiento.necesidad_otra_emprendimiento[i].descripcion = this.descripcion_edit;
            this.$store.state.emprendimiento.necesidad_otra_emprendimiento[i].anio = this.anio_edit;
          }
        }
        this.resetOtraNecesidad();
      },

      deleteOtraNecesidad(item, index, event){
        this.deleteIndex = item.id; 
        if (confirm('¿Seguro que desea eliminarlo?')) {
          this.$store.commit('deleteOtrasNecesidadesEmprendimiento', this.deleteIndex);
        }
        this.resetOtraNecesidad();
      },

      resetOtraNecesidad() {
        var today = new Date();
        this.anio = today.getFullYear();
        this.anio_edit = today.getFullYear();
        this.necesidad = '';
        this.descripcion = '';
        this.necesidad_edit = '';
        this.descripcion_edit = '';

        this.getOtrasNecesidadesEmprendimientos();
        
        this.$bvModal.hide('createObservacion');
        this.$bvModal.hide('editObservacion');
      },

      getAnios(){
        var today = new Date();
        this.anio = today.getFullYear();

        var arr = [];
        for (var i = this.anio; i >= 2000; i--) {
          arr.push({'value': i, 'text': i });
        }
        this.anios = arr;
      },
    },

    created(){
      this.getOtrasNecesidadesEmprendimientos();
      this.getAnios();
    }
  };
</script>


<style>
.table-observaciones-emprendimiento {
  table-layout: fixed;
}

.table-observaciones-emprendimiento thead th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}


.table-observaciones-emprendimiento thead tr th{
  padding-top: .3rem;
  padding-bottom: .3rem;
}

@media screen and (min-width: 400px) {
  .table-observaciones-emprendimiento tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-observaciones-emprendimiento tbody tr td:nth-child(3) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {
  .table-observaciones-emprendimiento tbody tr td:nth-child(2) {
    text-align: center;
  }

  .table-observaciones-emprendimiento tbody tr td:nth-child(2) {
    text-align: center;
  }


  .table-observaciones-emprendimiento thead tr th:nth-child(3) {
    justify-content: center;
  }

  .table-observaciones-emprendimiento tbody tr td:nth-child(3) {
    justify-content: center;
  }
}
</style>