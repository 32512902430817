<template>
	<div>
		<h4 class="pt-3">Histórico de Plan de Negocio</h4>   
    <b-card>
      <b-table
          class="table-mdn"
          hover
          stacked="md"
          head-variant="dark"
          :items="this.registros_pdn"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
        >
          <template v-slot:cell(fecha)="row">
            {{ row.item.fecha.toString().split("-").reverse().join("/") }}
          </template>
        </b-table>
    </b-card>
	</div>
</template>

<script>
  export default {
    props: ['registros_pdn'],
    data() {
      return {
        fields: [
          { key: 'estado_pdn',  label: 'Estado',  sortable: true, thStyle: {width:'80%'} },
          { key: 'fecha',       label: 'Fecha',   sortable: true}
        ],
        sortBy: 'fecha',
        sortDesc: true,
        sortDirection: 'desc',
      }
    }
  };
</script>
<style>
.table-mdn thead tr th{
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.table-mdn thead tr th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 400px) {
  .table-mdn tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-mdn tbody tr td:nth-child(2) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {

  .table-mdn tbody tr td:nth-child(1) {
    text-align: center;
  }

  .table-mdn thead tr th:nth-child(2) {
    justify-content: center;
  }

  .table-mdn tbody tr td:nth-child(2) {
    justify-content: center;
  }

}
</style>