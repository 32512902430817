<template>
	<div class="row">
    <div class="col-12 d-md-flex justify-content-md-between justify-content-sm-center">

      <div 
        class="card indicadores"
        v-on:click="selectProgram('todos')" 
        v-bind:class="{ 'program-selected': program_selected=='todos' }">
        <div class="card-header text-right text-muted">
          Emprendimientos<br> <b>UNL</b>
        </div>

        <div class="card-body mt-0 pt-0">
          <div style="font-size:48px;" class="icon-warning">
            <i class="fas fa-university text-info"></i>
            <label class="pl-4" style="font-size:32px;">{{ this.emprendimientos_unl }}</label>
          </div>
        </div>
        <div class="card-footer p-2">
          <div style="font-size:11px;" class="text-muted text-center">
            <i class="fas fa-plus"></i> Emprendimientos Vinculados
          </div>
        </div>
      </div>

      <div 
        class="card indicadores" 
        v-on:click="selectProgram('programa_emprendedores')"
        v-bind:class="{ 'program-selected': program_selected=='programa_emprendedores' }">
        <div class="card-header text-right text-muted">
          Programa<br> <b>Emprendedores</b>
        </div>
        <div class="card-body mt-0 pt-0">
          <div style="font-size:48px;">
            <i class="fas fa-lightbulb text-info"></i>
            <label class="pl-4" style="font-size:32px;">{{ this.emprendimientos_PE }}</label>
          </div>
        </div>
        <div class="card-footer p-2">
          <div style="font-size:11px;" class="text-muted text-center">
            <i class="fas fa-plus"></i> Emprendimientos Vinculados
          </div>
        </div>
      </div>

      <div 
        class="card indicadores" 
        v-on:click="selectProgram('programa_unlbio')"
        v-bind:class="{ 'program-selected': program_selected=='programa_unlbio' }">
        <div class="card-header text-right text-muted">
          Programa<br> <b>UNLBio</b>
        </div>
        <div class="card-body mt-0 pt-0">
          <div style="font-size:48px;">
            <i class="fas fa-leaf text-info"></i>
            <label class="pl-4" style="font-size:32px;">{{ this.emprendimientos_PB }}</label>
          </div>
        </div>
        <div class="card-footer p-2">
          <div style="font-size:11px;" class="text-muted text-center">
            <i class="fas fa-plus"></i> Emprendimientos Vinculados
          </div>
        </div>
      </div>

      <div 
        class="card indicadores" 
        v-on:click="selectProgram('programa_gide')"
        v-bind:class="{ 'program-selected': program_selected=='programa_gide' }">
        <div class="card-header text-right text-muted">
          Programa<br> <b>GIDE</b>
        </div>
        <div class="card-body mt-0 pt-0">
          <div style="font-size:48px;">
            <i class="fas fa-industry text-info"></i>
            <label class="pl-4" style="font-size:32px;">{{ this.emprendimientos_PG }}</label>
          </div>
        </div>
        <div class="card-footer p-2">
          <div style="font-size:11px;" class="text-muted text-center">
            <i class="fas fa-plus"></i> Emprendimientos Vinculados
          </div>
        </div>
      </div>

      <div 
        class="card indicadores" 
        v-on:click="selectProgram('programa_unlpotencia')"
        v-bind:class="{ 'program-selected': program_selected=='programa_unlpotencia' }">
        <div class="card-header text-right text-muted">
          Programa<br> <b>UNLPotencia</b>
        </div>
        <div class="card-body mt-0 pt-0">
          <div style="font-size:48px;">
            <i class="fas fa-rocket text-info"></i>
            <label class="pl-4" style="font-size:32px;">{{ this.emprendimientos_PP }}</label>
          </div>
        </div>
        <div class="card-footer p-2">
          <div style="font-size:11px;" class="text-muted text-center">
            <i class="fas fa-plus"></i> Emprendimientos Vinculados
          </div>
        </div>
      </div>

    </div>

    <!-------Componentes de cada Programa------->
    <div class="col-xl-12 col-md-12 col-sm-12">
      <IndicadoresEmprendimientosUNL 
        v-if="program_selected=='todos'"
        :programa="this.program_selected"
        :subtitulo="this.subtitulo"
      />
    </div>

    <div class="col-12">
      <IndicadoresProgramaEmprendedores 
        v-if="program_selected=='programa_emprendedores'"
        :programa="this.program_selected"
        :subtitulo="this.subtitulo"
      />
    </div>

    <div class="col-12">
      <IndicadoresProgramaUNLBio 
        v-if="program_selected=='programa_unlbio'"
        :programa="this.program_selected"
        :subtitulo="this.subtitulo"
      />
    </div>

    <div class="col-12">
      <IndicadoresProgramaGIDE
        v-if="program_selected=='programa_gide'"
        :programa="this.program_selected"
        :subtitulo="this.subtitulo"
      />
    </div> 

    <div class="col-12">
      <IndicadoresProgramaUNLPotencia
        v-if="program_selected=='programa_unlpotencia'"
        :programa="this.program_selected"
        :subtitulo="this.subtitulo"
      />
    </div> 
    

	</div>
</template>

<script>
  import ChartCard from 'src/components/Cards/ChartCard.vue'
  import StatsCard from 'src/components/Cards/StatsCard.vue'
  import LTable from 'src/components/Table.vue'

  import IndicadoresEmprendimientosUNL from 'src/components/Indicadores/IndicadoresEmprendimientosUNL.vue'
  import IndicadoresProgramaEmprendedores from 'src/components/Indicadores/IndicadoresProgramaEmprendedores.vue'
  import IndicadoresProgramaGIDE from 'src/components/Indicadores/IndicadoresProgramaGIDE.vue'
  import IndicadoresProgramaUNLBio from 'src/components/Indicadores/IndicadoresProgramaUNLBio.vue'
  import IndicadoresProgramaUNLPotencia from 'src/components/Indicadores/IndicadoresProgramaUNLPotencia.vue'
  export default {
    components: {
      LTable,
      ChartCard,
      StatsCard,

      IndicadoresEmprendimientosUNL,
      IndicadoresProgramaEmprendedores,
      IndicadoresProgramaGIDE,
      IndicadoresProgramaUNLBio,
      IndicadoresProgramaUNLPotencia
    },
    props: ['emprendimientos_unl',
            'emprendimientos_PE', 
            'emprendimientos_PB', 
            'emprendimientos_PG', 
            'emprendimientos_PP'],
    data () {
      return {
        program_selected: 'todos',
        titulo:'Emprendimientos UNL',
        subtitulo:'Emprendimientos UNL',
      }
    },
    methods:{
      selectProgram(programa){
        switch (programa) {
          case 'todos':
            this.titulo="Emprendimientos UNL";
            this.subtitulo="Emprendimientos UNL";
            break;
          
          case 'programa_emprendedores':
            this.titulo="Emprendimientos Programa Emprendedores";
            this.subtitulo="Programa Emprendedores";
            break;
          
          case 'programa_unlbio':
            this.titulo="Emprendimientos Programa UNLBio";
            this.subtitulo="Programa UNLBio";
            break;
          
          case 'programa_gide':
            this.titulo="Emprendimientos Programa GIDE";
            this.subtitulo="Programa GIDE";
            break;
          
          case 'programa_unlpotencia':
            this.titulo="Emprendimientos UNLPotencia";
            this.subtitulo="Programa UNLPotencia";
            break;
        
          default:
            this.titulo="Emprendimientos UNL";
            this.subtitulo="Emprendimientos UNL";
            break;
        }
        this.program_selected=programa;
      }
    }
  };
</script>
<style>

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .zoom {
    width:100%;
    cursor:pointer;
    transition: transform .2s; /* Animation */
  }
  .indicadores{
    height:180px;
    cursor:pointer;
    transition: transform .2s; /* Animation */
  }
} 

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .zoom {
    cursor:pointer;
    transition: transform .2s; /* Animation */
  }  
  .indicadores{

    height:180px;
    cursor:pointer;
    transition: transform .2s; /* Animation */
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .zoom {
    cursor:pointer;
    transition: transform .2s; /* Animation */
  }
  .indicadores{
    width:19%;
    height:280px;
    cursor:pointer;
    transition: transform .2s; /* Animation */
  }
} 

.indicadores{

  height:180px;
  cursor:pointer;
  transition: transform .2s; /* Animation */
}
.indicadores:hover {
  transform: scale(1.02); /* (150% zoom)*/
}

.card .card-header {
  padding: 7px 7px 0;
}

.zoom {
  height:180px;
  cursor:pointer;
  transition: transform .2s; /* Animation */
}

.zoom:hover {
  transform: scale(1.02); /* (150% zoom)*/
}
.program-selected{
  box-shadow: 0px 0px 5px .2px rgba(63,95,252,1);
  transform: scale(1.02); /* (150% zoom)*/
}
</style>