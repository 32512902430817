<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">

          <EstadioPlataforma v-if="render" />

          <MDNEmprendimiento v-if="render" />
          <PDNEmprendimiento v-if="render" />
          <PitchEmprendimiento v-if="render" />
          
          <SolidezEquipoEmprendimiento v-if="render" />

          <ProteccionTecnologica v-if="render" />
          <FormalizacionJuridica v-if="render" />
          <FinanciamientoObtenidoEmprendimiento v-if="render" />
          <ConcursosYEventosEmprendimiento v-if="render" />
          <CapacitacionesEmprendimiento v-if="render" />
          <AsesoriasEmprendimiento v-if="render" />
          <MentoriasEmprendimiento v-if="render" />
          <ObservacionesEmprendimiento v-if="render" />
          <MercadoInternacionalEmprendimiento v-if="render" />
          <CooperacionInternacionalEmprendimiento v-if="render" />
          <RedesInternacionalesEmprendimiento v-if="render" />
          
          <div class="col-md-12 pt-4 pb-4">
            <div class="row">
              <div class="col-sm-12">

                <b-button 
                  v-if="render"
                  class="mt-3 btn btn-danger btn-fill" 
                  style="float: left;"
                  @click="volver">
                  Volver
                </b-button>

                <b-button 
                  v-if="render"
                  class="mt-3 btn btn-info btn-fill" 
                  style="float: right;"
                  @click="siguiente">
                  Siguiente
                </b-button>
              </div>
            </div>
          </div>
          
          <b-modal size="md" id="errors" hide-footer>
            <div class="row">
              <div class="col-md-12 pt-3">
                <ul>
                  <li v-for="item in errors">
                    {{ item.error }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="pt-4">
              <b-button 
                class="mt-3 pull-right btn-danger btn-fill" 
                @click="resetModalErrors">
                Aceptar
              </b-button>
            </div>
          </b-modal>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  import EstadioPlataforma from 'src/components/Emprendimiento/EstadioPlataforma.vue'
  import MDNEmprendimiento from 'src/components/Emprendimiento/MDNEmprendimiento.vue'
  import PDNEmprendimiento from 'src/components/Emprendimiento/PDNEmprendimiento.vue'
  import PitchEmprendimiento from 'src/components/Emprendimiento/PitchEmprendimiento.vue'

  import SolidezEquipoEmprendimiento from 'src/components/Emprendimiento/SolidezEquipoEmprendimiento.vue'

  import ProteccionTecnologica from 'src/components/Emprendimiento/ProteccionTecnologica.vue'
  import FormalizacionJuridica from 'src/components/Emprendimiento/FormalizacionJuridica.vue'

  import FinanciamientoObtenidoEmprendimiento from 'src/components/Emprendimiento/FinanciamientoObtenidoEmprendimiento.vue'
  import ConcursosYEventosEmprendimiento from 'src/components/Emprendimiento/ConcursosYEventosEmprendimiento.vue'
  import CapacitacionesEmprendimiento from 'src/components/Emprendimiento/CapacitacionesEmprendimiento.vue'
  import AsesoriasEmprendimiento from 'src/components/Emprendimiento/AsesoriasEmprendimiento.vue'
  import MentoriasEmprendimiento from 'src/components/Emprendimiento/MentoriasEmprendimiento.vue'
  import ObservacionesEmprendimiento from 'src/components/Emprendimiento/ObservacionesEmprendimiento.vue'
  import MercadoInternacionalEmprendimiento from 'src/components/Emprendimiento/MercadoInternacionalEmprendimiento.vue'
  import CooperacionInternacionalEmprendimiento from 'src/components/Emprendimiento/CooperacionInternacionalEmprendimiento.vue'
  import RedesInternacionalesEmprendimiento from 'src/components/Emprendimiento/RedesInternacionalesEmprendimiento.vue'

  export default {
    components: {
      EstadioPlataforma, 
      MDNEmprendimiento, 
      PDNEmprendimiento, 
      PitchEmprendimiento, 

      SolidezEquipoEmprendimiento,

      ProteccionTecnologica, 
      FormalizacionJuridica,
      FinanciamientoObtenidoEmprendimiento,
      ConcursosYEventosEmprendimiento,
      CapacitacionesEmprendimiento,
      AsesoriasEmprendimiento,
      MentoriasEmprendimiento,
      ObservacionesEmprendimiento,
      MercadoInternacionalEmprendimiento,
      CooperacionInternacionalEmprendimiento,
      RedesInternacionalesEmprendimiento
    },
    data () {
      return { 
        id:null,
        render:true,
        errors:[],
      }
    }, 

    methods:{
      currentRoute(){
        this.id = this.$route.params.id;
        this.$store.commit('setTitulo', {setTitulo: 'Programa UNLBio'})
        this.$store.commit('setSubTitulo', {setSubTitulo: 'editar 4/5'})
      },

      verificarEmprendimiento(){
        if(this.$store.state.emprendimiento_edit==false){
          this.render =false;
          this.$router.push( {name:'editBIO1', params: { id: this.id }});
        }
      },

      siguiente(){
        this.errors=[];

        if(this.$store.state.emprendimiento.formalizacion_juridica=='si'){
          if(this.$store.state.emprendimiento.fecha_estructura_juridica==''){
            this.errors.push({'error':'Seleccione la FECHA DE FORMALIZACIÓN JURIDICA del emprendimiento antes de continuar'});
          }  
        }

        if(this.errors[0]){
          this.$root.$emit('bv::show::modal','errors');
        }else{
          this.$router.push( {name:'editBIO5', params: { id: this.id }});
        }
      },

      resetModalErrors(){
        this.errors=[];
        this.$root.$emit('bv::hide::modal','errors');
      },
  
      volver(){
        this.$router.push( {name:'editBIO3', params: { id: this.id }});
      }
    },

    created(){
      this.$Progress.start();
      this.currentRoute();
      this.verificarEmprendimiento();
      window.scrollTo(0,-1);
      this.$Progress.finish();
    }
  };
</script>
<style>

</style>
