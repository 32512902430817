<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">

          <DatosRelacionadosEmprendimiento />
          <UbicacionEmprendimiento />
          <ContactoEmprendimiento />
          <RedesEmprendimiento />

          <div class="pt-4 pb-4">
            <div class="row">
              <div class="col-md-12 ">
                <b-button 
                  class="mt-3 btn-danger btn-fill" 
                  @click="cancelar">Cancelar
                </b-button>

                <b-button 
                  class="mt-3 btn btn-info btn-fill" 
                  style="float: right;"
                  @click="siguiente">
                  Siguiente
                </b-button>

              </div>
            </div>
          </div>

          <b-modal size="md" id="errors" hide-footer>
            <div class="row">
              <div class="col-md-12 pt-3">
                <ul>
                  <li v-for="item in errors">
                    {{ item.error }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="pt-4">
              <b-button class="mt-3 pull-right btn-danger btn-fill" @click="resetModal">Aceptar</b-button>
            </div>
          </b-modal>

          <b-modal 
            size="md" 
            id="name-repeat" 
            body-bg-variant="danger"
            body-text-variant="light"
            hide-header
            hide-footer>
            <div class="row">
              <div class="col-md-12 pt-4">
                  {{ this.error_name }}
              </div>

              <div class="col-md-12 pt-3">
                <ul>
                  <li v-for="item in emprendimiento_repetido">
                    {{ item.nombre }} - {{ item.created_by }}
                  </li>
                </ul>
              </div>

              <div class="col-md-12">
                Revisa que no estes cargando un emprendimiento existente. Consulta a tu coordinador/ra ó solicita que te vinculen el emprendimiento ya existente.
              </div>

            </div>
            <div class="pt-4">
              <b-button class="mt-3 pull-left btn-danger btn-fill border border-light" @click="resetModal">Cancelar</b-button>
              <b-button class="mt-3 pull-right btn-danger btn-fill border border-light" @click="continuar">Continuar de todos modos</b-button>
            </div>
          </b-modal>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;
  import DatosRelacionadosEmprendimiento from 'src/components/Emprendimiento/DatosRelacionadosEmprendimiento.vue'
  import UbicacionEmprendimiento from 'src/components/Emprendimiento/UbicacionEmprendimiento.vue'
  import ContactoEmprendimiento from 'src/components/Emprendimiento/ContactoEmprendimiento.vue'
  import RedesEmprendimiento from 'src/components/Emprendimiento/RedesEmprendimiento.vue'

  export default {
    components: {
      DatosRelacionadosEmprendimiento,
      UbicacionEmprendimiento,
      ContactoEmprendimiento,
      RedesEmprendimiento
    },
    data () {
      return {
        loading:false,
        error_name:'',
        emprendimiento_repetido:[],
        errors:[],
        rubros: [],
        perfiles:[],
        anios:[]
      }
    },        

    methods:{
      currentRoute(){
        this.$store.commit('setTitulo', {setTitulo: 'Programa Emprendedores'});
        this.$store.commit('setSubTitulo', {setSubTitulo: 'nuevo 1/5'});
      },

      verificarEmprendimiento(){
        if(this.$store.state.emprendimiento_create==false){
          this.$store.state.emprendimiento_create=true;
        }
      },

      async cheakComponent(){
        await axios.get(URL_API+'api/programas-svytt')
          .then(response =>{
            response.data.forEach(element => {
              if(element.nombre=="Emprendedores"){
                this.$store.commit('setIDPrograma', element.id);
                this.$store.commit('setIDProgramaEmprendimiento', element.id);
              }
            });
          })
      },

      siguiente(){
        this.validarNombreEmprendimiento(this.$store.state.emprendimiento.nombre)
      },

      resetModal(){
        this.loading=false;
        this.errors=[];
        this.error_name='';
        this.$root.$emit('bv::hide::modal','name-repeat');
        this.$root.$emit('bv::hide::modal','errors');
      },

      validarCampos(){
        this.errors=[];

        if(this.$store.state.emprendimiento.nombre==''){
          this.errors.push({'error':'Agregue el NOMBRE del emprendimiento antes de continuar'});
        }

        if(this.$store.state.emprendimiento.anio_inicio==null){
          this.errors.push({'error':'Seleccione el AÑO DE INICIO del emprendimiento antes de continuar'});
        }

        if(this.$store.state.emprendimiento.id_perfil==null){
          this.errors.push({'error':'Seleccione el PERFIL del emprendimiento antes de continuar'});
        }

        if(this.$store.state.emprendimiento.id_rubro_principal==null){
          this.errors.push({'error':'Seleccione el RUBRO PRIMARIO del emprendimiento antes de continuar'});
        }

        if(this.errors[0]){
          this.$root.$emit('bv::show::modal','errors');
        }else{
          this.$router.push({name:'createPE2'});
        }
      },

      async validarNombreEmprendimiento(nombre){
        this.loading=true
        await axios.get(URL_API+'api/emprendimientos/validar-nombre/'+nombre.trim())
          .then(response =>{
            this.loading=false
            if(response.data.status.status!=false){
              this.emprendimiento_repetido = response.data.emprendimientos;
              this.$root.$emit('bv::show::modal','name-repeat');
              switch (response.data.status.count) {
                case 1:
                  this.error_name='Cuidado, existe '+response.data.status.count+' emprendimiento con nombre parecido ó similar:'
                  break;
                default:
                  this.error_name='Cuidado, existen '+response.data.status.count+' emprendimientos con nombre parecidos o similares:'
                  break;
              }
            }else{
              this.validarCampos();
            }
            
          })
      },

      continuar(){
        this.validarCampos();
      },

      cancelar(){
        if (confirm('¿Seguro que desea cancelar?')) {
          this.$store.commit('clearEmprendimiento');
          this.$router.push({name:'EmprendimientosPE'});
        }
      },
    },

    created(){
      this.$Progress.start()
      window.scrollTo(0,-1);
      this.verificarEmprendimiento();
      this.cheakComponent();
      this.currentRoute();
      this.$Progress.finish()
    }

  };
</script>
<style>

</style>
