<template>
  <div>
    <h4>Mentorias</h4>
    <div class="card">
      <div class="col-md-12 pt-4 pb-4">
        <div class="row">
          <div class="col-md-12">
          <!-- User Interface controls -->
          <b-row>
            <b-col sm="12" class="my-3">
              <b-button 
                class="btn btn-info btn-fill" 
                @click="nuevo()">
                <i class="fa fa-plus"></i> 
                  Agregar
              </b-button>
            </b-col>
          </b-row>
          </div>
          <!-- Main table element -->
          <div class="col-md-12 pb-4">
            <b-table
              class="table-mentorias-emprendimiento"
              hover
              stacked="md"
              head-variant="dark"
              :items="mentorias_emprendimiento"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
            >
              <template v-slot:cell(actions)="row">
                <b-button size="sm" @click="editMentoria(row.item, row.index, $event.target)" class="btn btn-warning btn-fill mr-1">
                <span class="fa fa-pencil"></span>
                </b-button>

                <b-button class="btn-danger btn-fill" size="sm" @click="deleteMentoria(row.item, row.index, $event.target)">
                <span class="fa fa-trash"></span>
                </b-button>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

  <b-modal size="lg" id="createMentoria" hide-footer>
    <div class="row">
      <div class="col-md-8">
        <label>Mentoria:</label>
        <b-form-select 
          v-model="id_mentoria" 
          :options="mentorias">
        </b-form-select>
      </div>

      <div class="col-md-4">
        <label>Año:</label>
          <b-form-select 
            v-model="anio"
            :options="anios">
          </b-form-select>
      </div>   

      <div class="col-md-6 pt-3">
        <label>Mentor:</label>
          <b-input
            v-model="mentor">
          </b-input>
      </div>

      <div class="col-md-6 pt-3">
        <label>Encuentro (nube/carpeta de drive):</label>
          <b-input
            v-model="memo_encuentro">
          </b-input>
      </div> 
    </div>

    <div class="pt-4">
        <b-button 
          class="mt-3 pull-left btn-danger btn-fill" 
          @click="resetMentoria">Cancelar
        </b-button>
        
        <b-button
          :disabled="mentor==''" 
          class="mt-3 pull-right btn-info btn-fill" 
          @click="nuevaMentorias">Agregar
        </b-button>
    </div>
  </b-modal>

  <b-modal size="lg" id="editMentoria" hide-footer>
    <div class="row">
      <div class="col-md-8 pt-3">
        <label>Mentoria:</label>
        <b-form-select 
          v-model="id_mentoria_edit" 
          :options="mentorias">
        </b-form-select>
      </div>

      <div class="col-md-4 pt-3">
        <label>Año:</label>
          <b-form-select 
            v-model="anio_edit"
            :options="anios">
          </b-form-select>
      </div>   

      <div class="col-md-6 pt-3">
        <label>Mentor:</label>
          <b-input
            v-model="mentor_edit">
          </b-input>
      </div>

      <div class="col-md-6 pt-3">
        <label>Encuentro (nube/carpeta de drive):</label>
          <b-input
            v-model="memo_encuentro_edit">
          </b-input>
      </div> 
    </div>
    <div class="pt-4">
      <b-button 
        class="mt-3 pull-left btn-danger btn-fill" 
        @click="resetMentoria">
          Cacelar
      </b-button>
      
      <b-button 
        :disabled="mentor_edit==''" 
        class="mt-3 pull-right btn-info btn-fill" 
        @click="updateMentoria(editindex)">
          Actualizar
      </b-button>
    </div>
  </b-modal>
</div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    data () {
      return {
        first_mentoria: null,
        id_mentoria: null,
        mentorias: [],

        fecha_estado_mdn: '',

        mentorias_emprendimiento: [],

        anio: null,
        anios: [],

        id_mentoria_edit:null,
        anio_edit: null,
        mentor_edit: '',
        mentor: '',

        memo_encuentro: '',
        memo_encuentro_edit: '',

        editindex: null,
        deleteIndex: null,
        fields: [
          { key: 'mentoria',          label: 'Mentoria',      sortable: true, thStyle: {width:'40%'}  },
          { key: 'mentor',            label: 'Mentor',        sortable: true, thStyle: {width:'25%'}  },
          { key: 'anio',              label: 'Año',           sortable: true, thStyle: {width:'15%'}  },
          { key: 'actions',           label: 'Acciones',      sortable: false},
        ],
        sortBy: 'anio',
        sortDesc: true,
        sortDirection: 'desc',
      }
    },

    methods:{
      async getMentoriasUNL(){
        let r = await axios.get(URL_API+'api/mentorias');

        var arr = [];
        var first = true;
        var aux;

        r.data.forEach(function(element, index){
          if(first==true){
            aux = element.id;
            first = false;
          }
          arr.push({'value': element.id, 'text': element.tematica });
        });
        
        this.first_mentoria = aux;
        this.id_mentoria = aux;
        this.mentorias = arr;
      },

      getMentoriasEmprendimientos(){
        this.mentorias_emprendimiento = this.$store.state.emprendimiento.mentorias_emprendimiento;
      },

      nuevo(){
        this.getMentoriasUNL();
        this.resetMentoria();
        this.$bvModal.show('createMentoria');
      },

      nuevaMentorias(){
        var id = this.id_mentoria;
        var mentoria = this.mentorias.find(x => x.value == id).text;

        var nueva_mentoria = {
          'id':             this.$store.state.emprendimiento.id_mentoria_emprendimiento,
          'id_mentoria':    this.id_mentoria,
          'mentoria':       mentoria,
          'mentor':         this.mentor,
          'memo_encuentro': this.memo_encuentro,
          'anio':  this.anio,
        };

        this.$store.commit('addMentoriaEmprendimiento', nueva_mentoria);
        this.resetMentoria();
      },

      editMentoria(item, index, event){
        this.editindex = item.id; 
        this.id_mentoria_edit = item.id_mentoria,
        this.anio_edit = item.anio,
        this.mentor_edit = item.mentor,
        this.memo_encuentro_edit = item.memo_encuentro,
        this.getMentoriasUNL();
        this.$root.$emit('bv::show::modal','editMentoria');
      },

      updateMentoria(index){
        var id = this.id_mentoria_edit;
        var mentoria_edit = this.mentorias.find(x => x.value == id).text;
         
        for(var i=0; i<this.$store.state.emprendimiento.mentorias_emprendimiento.length; i++){
          if(this.$store.state.emprendimiento.mentorias_emprendimiento[i].id === index){
            this.$store.state.emprendimiento.mentorias_emprendimiento[i].id_mentoria = this.id_mentoria_edit;
            this.$store.state.emprendimiento.mentorias_emprendimiento[i].mentoria = mentoria_edit;
            this.$store.state.emprendimiento.mentorias_emprendimiento[i].mentor = this.mentor_edit;
            this.$store.state.emprendimiento.mentorias_emprendimiento[i].memo_encuentro = this.memo_encuentro_edit;
            this.$store.state.emprendimiento.mentorias_emprendimiento[i].anio = this.anio_edit;
          }
        }
        this.resetMentoria();
      },

      deleteMentoria(item, index, event){
        this.deleteIndex = item.id; 
        if (confirm('¿Seguro que desea eliminarlo?')) {
          this.$store.commit('deleteMentoriaEmprendimiento', this.deleteIndex);
        }
        this.resetMentoria();
      },

      resetMentoria() {
        this.id_mentoria = this.first_mentoria;
        var today = new Date();
        this.anio = today.getFullYear();

        this.id_mentoria = this.first_mentoria;
        this.mentor = '';
        this.memo_encuentro = '';

        this.getMentoriasEmprendimientos();
        
        this.$bvModal.hide('createMentoria')
        this.$bvModal.hide('editMentoria')
      },

      getAnios(){
        var today = new Date();
        this.anio = today.getFullYear();

        var arr = [];
        for (var i = this.anio; i >= 2000; i--) {
          arr.push({'value': i, 'text': i });
        }
        this.anios = arr;
      },
    },

    created(){
      this.getMentoriasUNL();
      this.getMentoriasEmprendimientos();
      this.getAnios();
    }
  };
</script>


<style>
.table-mentorias-emprendimiento {
  table-layout: fixed;
}

.table-mentorias-emprendimiento thead th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}


.table-mentorias-emprendimiento thead tr th{
  padding-top: .3rem;
  padding-bottom: .3rem;
}

@media screen and (min-width: 400px) {
  .table-mentorias-emprendimiento tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-mentorias-emprendimiento tbody tr td:nth-child(3) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {

  .table-mentorias-emprendimiento thead tr th:nth-child(3) {
    justify-content: center;
  }

  .table-mentorias-emprendimiento tbody tr td:nth-child(3) {
    text-align: center;
  }

  .table-mentorias-emprendimiento thead tr th:nth-child(4) {
    justify-content: center;
  }

  .table-mentorias-emprendimiento tbody tr td:nth-child(4) {
    justify-content: center;
  }
}
</style>