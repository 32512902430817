<template>
	<div>
		<h4 class="pt-3">Estudio de Mercados Internacionales</h4>   
    <b-card>
      <b-table
          class="table-estudio-mercados"
          hover
          stacked="md"
          head-variant="dark"
          :items="this.estudios_mercados_emprendimiento"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
        >
        </b-table>
    </b-card>
	</div>
</template>

<script>
  export default {
    props: ['estudios_mercados_emprendimiento'],
    data() {
      return {
        fields: [
          { key: 'estado_estudio_mercado',  label: 'Estado',  sortable: true, thStyle: {width:'80%'} },
          { key: 'anio',                    label: 'Año',   sortable: true}
        ],
        sortBy: 'anio',
        sortDesc: true,
        sortDirection: 'desc',
      }
    }
  };
</script>
<style>
.table-estudio-mercados thead tr th{
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.table-estudio-mercados thead tr th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 400px) {
  .table-estudio-mercados tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-estudio-mercados tbody tr td:nth-child(2) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {

  .table-estudio-mercados tbody tr td:nth-child(1) {
    text-align: center;
  }

  .table-estudio-mercados thead tr th:nth-child(2) {
    justify-content: center;
  }

  .table-estudio-mercados tbody tr td:nth-child(2) {
    justify-content: center;
  }

}
</style>