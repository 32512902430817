<template>
	<div>
		<h4 class="pt-3">Financiamiento Obtenido <small>ANR/crédico/inversión</small></h4>   
    <b-card>
      <b-table
          class="tab-financiamiento-emprendimiento"
          hover
          stacked="md"
          head-variant="dark"
          :items="this.financiamiento_obtenido_emprendimiento"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
        >
          <template v-slot:cell(fecha_financiamiento)="row">
            {{ row.item.fecha_financiamiento.toString().split("-").reverse().join("/") }}
          </template>

          <template v-slot:cell(modalidad_financiamiento)="row">
            {{ ((row.item.modalidad_financiamiento!='otro')? row.item.modalidad_financiamiento:row.item.otra_modalidad_financiamiento) }}
          </template>

          <template v-slot:cell(actions)="row">
            <b-button size="sm" @click="view(row.item, row.index, $event.target)" class="btn btn-info btn-fill mr-1">
              <span class="fa fa-eye"></span>
            </b-button>
          </template>
        </b-table>
    </b-card>

    <b-modal id="financiamiento_emprendimiento" size="lg" title="Financiamiento" hide-footer>
      <b-col cols="12" class="pt-1">
        <b-row>
          <b-col class="titulo" md="4" xs="12">
            <b>Linea financiamiento:</b>
          </b-col>
          <b-col md="8" xs="12">
            {{ this.financiamiento_obtenido.nombre_linea }}
          </b-col>
        </b-row>

        <b-row class="pt-1">
          <b-col class="titulo" md="4" xs="12">
            <b>Fecha:</b>
          </b-col>
          <b-col md="8" xs="12">
            {{ this.financiamiento_obtenido.fecha_financiamiento }}
          </b-col>
        </b-row>

        <b-row class="pt-1">
          <b-col class="titulo" md="4" xs="12">
            <b>Modalidad:</b>
          </b-col>
          <b-col md="8" xs="12">
             {{ ((this.financiamiento_obtenido.modalidad_financiamiento != 'otro') ? this.financiamiento_obtenido.modalidad_financiamiento : this.financiamiento_obtenido.otra_modalidad_financiamiento ) }}
          </b-col>
        </b-row>

        <b-row class="pt-1">
          <b-col class="titulo" md="4" xs="12">
            <b>Acompañamiento:</b>
          </b-col>
          <b-col md="8" xs="12">
            {{ this.financiamiento_obtenido.recibio_acompanamiento }}
          </b-col>
        </b-row>

        <template v-if="this.financiamiento_obtenido.recibio_acompanamiento === 'Si'">
          <b-row class="pt-1">
            <b-col class="titulo" md="4" xs="12">
              <b>Plataforma:</b>
            </b-col>
            <b-col md="8" xs="12">
              {{ this.financiamiento_obtenido.plataforma_innovacion }}
            </b-col>
          </b-row>
        </template>

        <hr>

        <b-row class="pt-1">
          <b-col class="titulo" md="4" xs="12">
            <b>Adjudicación:</b>
          </b-col>
          <b-col md="8" xs="12">
            {{ this.financiamiento_obtenido.adjudicacion_financiamiento }}
          </b-col>
        </b-row>

        <template v-if="this.financiamiento_obtenido.adjudicacion_financiamiento === 'Si'">
          
          <b-row class="pt-1">
            <b-col class="titulo" md="4" xs="12">
              <b>Monto adjudicado:</b>
            </b-col>
            <b-col md="8" xs="12">
              {{ "$ "+this.financiamiento_obtenido.monto_financiamiento }}
            </b-col>
          </b-row>
          
          <template v-if="this.financiamiento_obtenido.contraparte_financiamiento !== ''">
            <b-row class="pt-1">
              <b-col class="titulo" md="4" xs="12">
                <b>Contraparte:</b>
              </b-col>
              <b-col md="8" xs="12">
                {{ "$ "+this.financiamiento_obtenido.contraparte_financiamiento }}
              </b-col>
            </b-row>
          </template>

          <template v-if="this.financiamiento_obtenido.fecha_adjudicacion_financiamiento !== null">
            <b-row class="pt-1">
              <b-col class="titulo" md="4" xs="12">
                <b>Fecha adjudicación:</b>
              </b-col>
              <b-col md="8" xs="12">
                {{ this.financiamiento_obtenido.fecha_adjudicacion_financiamiento }}
              </b-col>
            </b-row>
          </template>

          <template v-if="this.financiamiento_obtenido.fecha_devolucion_financiamiento !== null">
            <b-row class="pt-1">
              <b-col class="titulo" md="4" xs="12">
                <b>Fecha adjudicación:</b>
              </b-col>
              <b-col md="8" xs="12">
                {{ this.financiamiento_obtenido.fecha_devolucion_financiamiento }}
              </b-col>
            </b-row>
          </template>

          <b-row class="pt-1">
            <b-col class="titulo" md="4" xs="12">
              <b>Duración de la ejecución:</b>
            </b-col>
            <b-col md="8" xs="12">
              {{ ((this.financiamiento_obtenido.duracion_ejecucion == '1') ? this.financiamiento_obtenido.duracion_ejecucion+' año' : this.financiamiento_obtenido.duracion_ejecucion+' años' ) }}
            </b-col>
          </b-row>

        </template>

        <b-row class="pt-1">
          <b-col class="titulo" md="4" xs="12">
            <b>Fin del capítal:</b>
          </b-col>
          <b-col md="8" xs="12">
            {{ this.financiamiento_obtenido.fin_capital }}
          </b-col>
        </b-row>

        <template v-if="this.financiamiento_obtenido.observacion !== ''">
          <b-row class="pt-1">
            <b-col class="titulo" md="4" xs="12">
              <b>Observaciones:</b>
            </b-col>
            <b-col md="8" xs="12">
              {{ this.financiamiento_obtenido.observacion }}
            </b-col>
          </b-row>
        </template>
      </b-col>

      <b-col class="pt-4">
        <b-button class="mt-3 pull-left btn-danger btn-fill" @click="closeModal">Cerrar</b-button>
      </b-col>
    </b-modal>
	</div>
</template>

<script>
  export default {
    props: ['financiamiento_obtenido_emprendimiento'],
    data() {
      return {
        financiamiento_obtenido:{
          nombre_linea:null,
          fecha_financiamiento: null,
          modalidad_financiamiento: null,
          otra_modalidad_financiamiento: null,
          recibio_acompanamiento: null,
          plataforma_innovacion: null,
          adjudicacion_financiamiento:null,
          monto_financiamiento: null,
          contraparte_financiamiento: null,
          fecha_adjudicacion_financiamiento: null,
          fecha_devolucion_financiamiento: null,
          duracion_ejecucion: null,
          fin_capital: null,
          observacion: null
        },
        viewindex: null,
        fields: [
          { key: 'nombre_linea',              label: 'Nombre de la Linea',            sortable: true, thStyle: {width:'40%'} },
          { key: 'modalidad_financiamiento',  label: 'Modalidad del Financiamiento',  sortable: true},
          { key: 'fecha_financiamiento',      label: 'Fecha',                         sortable: true,   sortDirection: 'desc'},
          { key: 'actions',                   label: 'Vér' }
        ],
        sortBy: 'fecha_financiamiento',
        sortDesc: true,
        sortDirection: 'desc',
        financiamiento_emprendimiento: {
          id: 'financiamiento_emprendimiento',
          title: '',
          content: ''
        }
      }
    },

    methods: {
      view(item, index, button) {
        this.financiamiento_obtenido.nombre_linea = item.nombre_linea;
        this.financiamiento_obtenido.fecha_financiamiento = item.fecha_financiamiento.toString().split("-").reverse().join("/");
        this.financiamiento_obtenido.modalidad_financiamiento = item.modalidad_financiamiento;
        this.financiamiento_obtenido.otra_modalidad_financiamiento = item.otra_modalidad_financiamiento;
        this.financiamiento_obtenido.recibio_acompanamiento = item.recibio_acompanamiento.charAt(0).toUpperCase() + item.recibio_acompanamiento.slice(1);
        this.financiamiento_obtenido.plataforma_innovacion = item.plataforma_innovacion;
        this.financiamiento_obtenido.adjudicacion_financiamiento = item.adjudicacion_financiamiento.charAt(0).toUpperCase() + item.adjudicacion_financiamiento.slice(1);
        this.financiamiento_obtenido.monto_financiamiento = item.monto_financiamiento;
        this.financiamiento_obtenido.contraparte_financiamiento = item.contraparte_financiamiento;
        this.financiamiento_obtenido.fecha_adjudicacion_financiamiento = (item.fecha_adjudicacion_financiamiento!=null) ? item.fecha_adjudicacion_financiamiento.toString().split("-").reverse().join("/"):null;
        this.financiamiento_obtenido.fecha_devolucion_financiamiento = (item.fecha_devolucion_financiamiento!=null) ? item.fecha_devolucion_financiamiento.toString().split("-").reverse().join("/"):null;
        this.financiamiento_obtenido.duracion_ejecucion = item.duracion_ejecucion;
        this.financiamiento_obtenido.fin_capital = item.fin_capital;
        this.financiamiento_obtenido.observacion = item.observacion;

        this.$root.$emit('bv::show::modal', this.financiamiento_emprendimiento.id, button);
      },

      closeModal(item, index, button){
        this.$root.$emit('bv::hide::modal', this.financiamiento_emprendimiento.id, button)
      }
    }
  };
</script>
<style>
.tab-financiamiento-emprendimiento thead tr th{
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.tab-financiamiento-emprendimiento thead th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 400px) {
  .tab-financiamiento-emprendimiento tbody tr td:nth-child(1) {
    text-align: left;
  }
  .tab-financiamiento-emprendimiento tbody tr td:nth-child(3) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {
  .tab-financiamiento-emprendimiento tbody tr td:nth-child(2) {
    text-align: center;
  }

  .tab-financiamiento-emprendimiento tbody tr td:nth-child(2) {
    text-align: center;
  }

  .tab-financiamiento-emprendimiento tbody tr td:nth-child(3) {
    text-align: center;
  }

  .tab-financiamiento-emprendimiento tbody tr td:nth-child(3) {
    text-align: center;
  }


  .tab-financiamiento-emprendimiento thead tr th:nth-child(4) {
    justify-content: center;
  }

  .tab-financiamiento-emprendimiento tbody tr td:nth-child(4) {
    justify-content: center;
  }
}
</style>