<template>
  <div>
  <h4>Cooperación Internacional</h4>
  <div class="card">
    <div class="col-md-12 pt-4 pb-4">
      <div class="row">
        <div class="col-md-12">
        <!-- User Interface controls -->
        <b-row>
          <b-col sm="12" class="my-3">
            <b-button 
              v-b-modal.createCooperacion
              class="btn btn-info btn-fill" 
              @click="resetCooperacionInternacional"><i class="fa fa-plus"></i> 
                Agregar
            </b-button>
          </b-col>
        </b-row>
        </div>
        <!-- Main table element -->
        <div class="col-md-12 pb-4">
          <b-table
            class="table-cooperacion-internacionaal-emprendimiento"
            hover
            stacked="md"
            head-variant="dark"
            :items="cooperaciones_internacionales"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
          >
            <template v-slot:cell(actions)="row">
              <b-button 
                size="sm" 
                @click="editCooperacion(row.item, row.index, $event.target)" 
                class="btn btn-warning btn-fill mr-1">
                  <span class="fa fa-pencil"></span>
              </b-button>

              <b-button 
                class="btn-danger btn-fill" 
                size="sm" 
                @click="deleteEstudio(row.item, row.index, $event.target)">
                  <span class="fa fa-trash"></span>
              </b-button>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>

  <b-modal size="lg" id="createCooperacion" hide-footer>
    <div class="row">
       <div class="col-md-10 pt-3">
        <label>Nombre:</label>
          <b-input 
            v-model="nombre">
          </b-input>
      </div>
      <div class="col-md-2 pt-3">
        <label>Año:</label>
          <b-form-select 
            v-model="anio"
            :options="anios">
          </b-form-select>
      </div>  
      <div class="col-md-12 pt-3">
        <label>Institución:</label>
          <b-input 
            v-model="institucion">
          </b-input>
      </div>

      <div class="col-md-12 pt-3">
        <label>Descripción:</label>
          <b-form-textarea
            v-model="descripcion">
          </b-form-textarea>
      </div>
    </div>

    <div class="pt-4">
        <b-button 
          class="mt-3 pull-left btn-danger btn-fill" 
          @click="resetCooperacionInternacional">Cancelar
        </b-button>
        
        <b-button
          :disabled="nombre==''" 
          class="mt-3 pull-right btn-info btn-fill" 
          @click="nuevaCooperacionInternacional">Agregar
        </b-button>
    </div>
  </b-modal>

  <b-modal size="lg" id="editCooperacion" hide-footer>
    <div class="row">
      <div class="col-md-10 pt-3">
        <label>Nombre:</label>
          <b-input 
            v-model="nombre_edit">
          </b-input>
      </div>
      <div class="col-md-2 pt-3">
        <label>Año:</label>
          <b-form-select 
            v-model="anio_edit"
            :options="anios">
          </b-form-select>
      </div>   

      <div class="col-md-12 pt-3">
        <label>Institución:</label>
          <b-input 
            v-model="institucion_edit">
          </b-input>
      </div>

      <div class="col-md-12 pt-3">
        <label>Descripción:</label>
          <b-form-textarea
            v-model="descripcion_edit">
          </b-form-textarea>
      </div>
    </div>
    <div class="pt-4">
        <b-button 
          class="mt-3 pull-left btn-danger btn-fill" 
          @click="resetCooperacionInternacional">
            Cacelar
        </b-button>
        
        <b-button 
          :disabled="nombre_edit==''" 
          class="mt-3 pull-right btn-info btn-fill" 
          @click="updateCooperacionInternacion(editindex)">
            Actualizar
        </b-button>
    </div>
  </b-modal>
</div>
</template>

<script>
  export default {
    data () {
      return {
        anio: null,
        nombre: '',
        institucion: '',
        descripcion: '',

        nombre_edit: '',
        institucion_edit: '',
        descripcion_edit: '',
        anio_edit: null,

        anios: [],

        cooperaciones_internacionales: [],

        editindex: null,
        deleteIndex: null,
        fields: [
          { key: 'nombre',            label: 'Nombre Cooperación',  sortable: true, thStyle: {width:'60%'} },
          { key: 'anio',              label: 'Año',                 sortable: true, thStyle: {width:'20%'} },
          { key: 'actions',           label: 'Acciones',            sortable: false},
        ],
        sortBy: 'anio',
        sortDesc: true,
        sortDirection: 'desc',
      }
    },

    methods:{
      getEstudiosMercadosEmprendimientos(){
        this.cooperaciones_internacionales = this.$store.state.emprendimiento.cooperaciones_internacionales_emprendimiento;
      },

      nuevaCooperacionInternacional(){
        var nueva_cooperacion = {
          'id':           this.$store.state.emprendimiento.id_cooperacion_internacional_emprendimiento,
          'nombre':       this.nombre,
          'institucion':  this.institucion,
          'descripcion':  this.descripcion,
          'anio':         this.anio
        };

        this.$store.commit('addCooperacionesInternacionalesEmprendimiento', nueva_cooperacion);
        this.resetCooperacionInternacional();
      },

      editCooperacion(item, index, event){
        this.editindex = item.id; 

        this.nombre_edit = item.nombre,
        this.institucion_edit = item.institucion,
        this.descripcion_edit = item.descripcion,
        this.anio_edit = item.anio,

        this.$bvModal.show('editCooperacion');
      },

      updateCooperacionInternacion(index){
        for(var i=0; i<this.$store.state.emprendimiento.cooperaciones_internacionales_emprendimiento.length; i++){
          if(this.$store.state.emprendimiento.cooperaciones_internacionales_emprendimiento[i].id === index){
            this.$store.state.emprendimiento.cooperaciones_internacionales_emprendimiento[i].nombre = this.nombre_edit;
            this.$store.state.emprendimiento.cooperaciones_internacionales_emprendimiento[i].institucion = this.institucion_edit;
            this.$store.state.emprendimiento.cooperaciones_internacionales_emprendimiento[i].descripcion = this.descripcion_edit;
            this.$store.state.emprendimiento.cooperaciones_internacionales_emprendimiento[i].anio= this.anio_edit;
          }
        }
        this.resetCooperacionInternacional();
      },

      deleteEstudio(item, index, event){
        this.deleteIndex = item.id; 
        if (confirm('¿Seguro que desea eliminarlo?')) {
          this.$store.commit('deleteCooperacionesInternacionalesEmprendimiento', this.deleteIndex);
        }
        this.resetCooperacionInternacional();
      },

      resetCooperacionInternacional() {
        var today = new Date();
        this.anio = today.getFullYear();
        this.anio_edit = today.getFullYear();

        this.nombre = '';
        this.nombre_edit = '';

        this.institucion = '';
        this.institucion_edit = '';

        this.descripcion = '';
        this.descripcion_edit = '';

        this.getEstudiosMercadosEmprendimientos();
        
        this.$bvModal.hide('createCooperacion');
        this.$bvModal.hide('editCooperacion');
      },

      getAnios(){
        var today = new Date();
        this.anio = today.getFullYear();

        var arr = [];
        for (var i = this.anio; i >= 2000; i--) {
          arr.push({'value': i, 'text': i });
        }
        this.anios = arr;
      },
    },

    created(){
      this.getEstudiosMercadosEmprendimientos();
      this.getAnios();
    }
  };
</script>


<style>
.table-cooperacion-internacionaal-emprendimiento {
  table-layout: fixed;
}

.table-cooperacion-internacionaal-emprendimiento thead th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}


.table-cooperacion-internacionaal-emprendimiento thead tr th{
  padding-top: .3rem;
  padding-bottom: .3rem;
}

@media screen and (min-width: 400px) {
  .table-cooperacion-internacionaal-emprendimiento tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-cooperacion-internacionaal-emprendimiento tbody tr td:nth-child(3) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {
  .table-cooperacion-internacionaal-emprendimiento tbody tr td:nth-child(2) {
    text-align: center;
  }

  .table-cooperacion-internacionaal-emprendimiento tbody tr td:nth-child(2) {
    text-align: center;
  }


  .table-cooperacion-internacionaal-emprendimiento thead tr th:nth-child(3) {
    justify-content: center;
  }

  .table-cooperacion-internacionaal-emprendimiento tbody tr td:nth-child(3) {
    justify-content: center;
  }
}
</style>