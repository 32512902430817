<template>
	<div>
		<h4 class="pt-3">Observaciones</h4>   
    <b-card>
      <b-table
          class="table-observaciones-emprendimiento2"
          hover
          stacked="md"
          head-variant="dark"
          :items="this.observaciones_emprendimiento"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
        >
          <template v-slot:cell(anio)="row">
            {{ row.item.anio.toString() }}
          </template>
        </b-table>
    </b-card>
	</div>
</template>

<script>
  export default {
    props: ['observaciones_emprendimiento'],
    data() {
      return {
        fields: [
          { key: 'impacto_interno', label: 'Impacto Interno', sortable: true, thStyle: {width:'30%'} },
          { key: 'descripcion',     label: 'Descripción',     sortable: true, thStyle: {width:'50%'} },
          { key: 'anio',            label: 'Año',             sortable: true}
        ],
        sortBy: 'anio',
        sortDesc: true,
        sortDirection: 'desc',
      }
    }
  };
</script>
<style>
.table-observaciones-emprendimiento2 thead tr th{
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.table-observaciones-emprendimiento2 thead tr th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 400px) {
  .table-observaciones-emprendimiento2 tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-observaciones-emprendimiento2 tbody tr td:nth-child(2) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {
  .table-observaciones-emprendimiento2 thead tr th:nth-child(3) {
    justify-content: center;
  }

  .table-observaciones-emprendimiento2 tbody tr td:nth-child(3) {
    display: flex;
    justify-content: center;
    vertical-align: middle;
  }
}
</style>