<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <DatosEmprendedor
            :nombre="this.emprendedor.nombre"
            :apellido="this.emprendedor.apellido"
            :dni="this.emprendedor.dni"
            :cuit_cuil="this.emprendedor.cuit_cuil"
            :correo_electronico="this.emprendedor.correo_electronico"
            :telefono="this.emprendedor.telefono"
            :celular="this.emprendedor.celular"
            :genero="this.emprendedor.genero"
            :provincia="this.emprendedor.provincia"
            :departamento="this.emprendedor.departamento"
            :nivel_formacion="this.emprendedor.nivel_formacion"
            :condicion_formacion="this.emprendedor.condicion_formacion"
            :universidad="this.emprendedor.universidad"
            :unidad_academica="this.emprendedor.unidad_academica"
            :carrera="this.emprendedor.carrera"
            :otros_datos="this.emprendedor.otros_datos"
            :id_emprendimiento="this.emprendedor.id_emprendimiento"
            :emprendimientos="this.emprendedor.emprendimientos"
          /> 

          <div class="pt-4 pb-4">
            <div class="row">
              <div class="col-md-12" align="center">
                <router-link class="btn btn-info" 
                    :to="{ name: 'EmprendedoresUNL' }"
                  >Volver
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import DatosEmprendedor from 'src/components/Emprendedor/DatosEmprendedor.vue'

  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    components: {
      DatosEmprendedor
    },
    props: ['id'],
    data () {
      return {
        show: true,
        emprendedor: [],
      }
    },

    methods:{
      currentRoute(){
        if(screen.width<=768){
          this.$store.commit('setTitulo', {setTitulo: 'Emprendedores UNL'})
          this.$store.commit('setSubTitulo', {setSubTitulo: ''})
        }else{
          this.$store.commit('setTitulo', {setTitulo: 'Emprendedores UNL'})
          this.$store.commit('setSubTitulo', {setSubTitulo: 'ver'})
        }
      },

      getEmprendedor(){
        this.$Progress.start();
        axios.get(URL_API+'api/emprendedores/'+this.id)
          .then(response => {
            this.emprendedor = response.data.emprendedor;
            this.show = true;
            this.$Progress.finish()
          }).catch(error => {
            this.$Progress.finish();
            alert(error.response.data.message);
            console.log(error);
          })
      },
    },

    created(){
      this.currentRoute();
      this.getEmprendedor();
    }
  };
</script>
