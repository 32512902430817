<template>
  <div>
    <h4>Pitch del Emprendimiento</h4>
    <div class="card">
      <div class="col-md-12 pt-4 pb-4">
        <div class="row">
          <div class="col-md-12">
            
	          <!-- User Interface controls -->
	          <b-row>
	            <b-col sm="12" class="my-3">
                <label for="pitch">URL del PITCH:</label>
                <b-form-input type="text" autocomplete="off" v-model="pitchEmprendimiento"></b-form-input>
	            </b-col>
	          </b-row>
          </div>

        </div>
      </div>
    </div>

</div>
</template>

<script>

  export default {
    name: 'PitchEmprendimiento',
    computed: {
      pitchEmprendimiento:{
        get(){
          return this.$store.state.emprendimiento.url_pitch
        },
        set(value){
          this.$store.commit('setPitchEmprendimiento', {pitchEmprendimiento: value})
        }
      },
    },
  };
</script>
