<template>
  <div>
    <h4>Histórico de Plan de Negocio</h4>
    <div class="card">
      <div class="col-md-12 pt-4 pb-4">
        <div class="row">
          <div class="col-md-12">
            
          <!-- User Interface controls -->
          <b-row>
            <b-col sm="12" class="my-3">
              <b-button 
                class="btn btn-info btn-fill" 
                @click="nuevo()">
                <i class="fa fa-plus"></i> 
                  Agregar
              </b-button>
            </b-col>
          </b-row>
          </div>
          <!-- Main table element -->
          <div class="col-md-12 pb-4">
            <b-table
              class="table-registro-pdn"
              hover
              stacked="md"
              head-variant="dark"
              :items="registros_pdn"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
            >
              <template v-slot:cell(fecha)="row">
                {{ row.item.fecha.toString().split("-").reverse().join("/") }}
              </template>

              <template v-slot:cell(actions)="row">
                <b-button size="sm" @click="editarPDN(row.item, row.index, $event.target)" class="btn btn-warning btn-fill mr-1">
                <span class="fa fa-pencil"></span>
                </b-button>

                <b-button class="btn-danger btn-fill" size="sm" @click="deletePDN(row.item, row.index, $event.target)">
                <span class="fa fa-trash"></span>
                </b-button>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <b-modal size="lg" id="createPDN" hide-footer>
      <div class="row">
        <div class="col-md-8">
          Estado PDN:
          <b-form-select 
            v-model="id_estado_pdn" 
            :options="estados_pdn">
          </b-form-select>
        </div>

        <div class="col-md-4">
          Fecha:
          <b-form-input 
              v-model="fecha"   
              type="date"
              placeholder="dd/mm/aaaa"
              autocomplete="off">
          </b-form-input>
        </div>      
      </div>

      <div class="pt-4">
          <b-button 
            class="mt-3 pull-left btn-danger btn-fill" 
            @click="resetModalPDN">Cancelar
          </b-button>
          
          <b-button 
            :disabled="fecha===''"
            class="mt-3 pull-right btn-info btn-fill" 
            @click="nuevoEstadoPDN">Agregar
          </b-button>
      </div>
    </b-modal>

    <b-modal size="lg" id="editPDN" title="Editar PDN" hide-footer>
      <div class="row">
        <div class="col-md-8 pt-3">
          Estado MDN:
          <b-form-select 
            v-model="id_estado_pdn" 
            :options="estados_pdn">
          </b-form-select>
        </div>

        <div class="col-md-4 pt-3">
          Fecha:
          <b-form-input 
              v-model="fecha"   
              type="date"
              placeholder="dd/mm/aaaa"
              autocomplete="off">
          </b-form-input>
        </div>
      </div>  
      <div class="pt-4">
          <b-button class="mt-3 pull-left btn-danger btn-fill" @click="resetModalPDN">Cacelar</b-button>
          <b-button class="mt-3 pull-right btn-info btn-fill" @click="updatePDN(editindex)">Actualizar</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import axios from "axios";
  const URL_API = process.env.VUE_APP_URL_API;

  export default {
    data () {
      return {
        first_estado_pdn: null,
        id_estado_pdn: null,
        estados_pdn: [],

        fecha: '',
        registros_pdn: [],

        editindex: null,
        deleteIndex: null,
        fields: [
          { key: 'estado_pdn',      label: 'Estado',    sortable: true, thStyle: {width:'60%'} },
          { key: 'fecha',label: 'Fecha',     sortable: true },
          { key: 'actions',         label: 'Acciones',  sortable: false},
        ],
        sortBy: null,
        sortDesc: true,
        sortDirection: 'desc',
      }
    },

    methods:{
      async getEstadosPDN(){
        let r = await axios.get(URL_API+'api/estados-mdn');

        var arr = [];
        var first = true;
        var aux;

        r.data.forEach(function(element, index){
          if(first==true){
            aux = element.id;
            first = false;
          }
          arr.push({'value': element.id, 'text': element.nombre });
        });
        
        this.first_estado_pdn = aux;
        this.id_estado_pdn = aux;
        this.estados_pdn = arr;
      },

      getRegistrosPDN(){
        this.registros_pdn = this.$store.state.emprendimiento.registros_pdn;
      },

      nuevo(){
        this.resetModalPDN();
        this.getEstadosPDN();
        this.$bvModal.show('createPDN');
      },

      nuevoEstadoPDN(){
        var id = this.id_estado_pdn;
        var estado_pdn = this.estados_pdn.find(x => x.value == id).text;

        var nuevo_registro_pdn = {
          'id':               this.$store.state.emprendimiento.id_registros_pdn,
          'id_estado_pdn':    this.id_estado_pdn,
          'estado_pdn':       estado_pdn,
          'fecha': this.fecha
        };

        this.$store.commit('addRegistroPDN', nuevo_registro_pdn);
        this.resetModalPDN();
      },

      editarPDN(item, index, event){
        this.editindex = item.id; 
        this.id_estado_pdn = item.id_estado_pdn,
        this.fecha = item.fecha,
        this.$bvModal.show('editPDN');
      },

      updatePDN(index){
        var id = this.id_estado_pdn;
        var estado_pdn = this.estados_pdn.find(x => x.value == id).text;

        for(var i=0; i<this.$store.state.emprendimiento.registros_pdn.length; i++){
          if(this.$store.state.emprendimiento.registros_pdn[i].id === index){

            this.$store.state.emprendimiento.registros_pdn[i].id_estado_pdn = this.id_estado_pdn;
            this.$store.state.emprendimiento.registros_pdn[i].estado_pdn = estado_pdn;
            this.$store.state.emprendimiento.registros_pdn[i].fecha = this.fecha;
          }
        }
        this.resetModalPDN();
      },

      deletePDN(item, index, event){
        this.deleteIndex = item.id;
        if (confirm('¿Seguro que desea eliminarlo?')) {
          this.$store.commit('deleteRegistroPDN', this.deleteIndex);
        }
        this.resetModalPDN();
      },

      resetModalPDN() {
        this.id_estado_pdn = this.first_estado_pdn;
        this.fecha = '';
        this.getRegistrosPDN();
        this.$bvModal.hide('createPDN');
        this.$bvModal.hide('editPDN');
      },
    },

    created(){
      this.getEstadosPDN();
      this.getRegistrosPDN();
    }
  };
</script>
<style>
.table-registro-pdn {
  table-layout: fixed;
}

.table-registro-pdn thead th div{
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}


.table-registro-pdn thead tr th{
  padding-top: .3rem;
  padding-bottom: .3rem;
}

@media screen and (min-width: 400px) {
  .table-registro-pdn tbody tr td:nth-child(1) {
    text-align: left;
  }
  .table-registro-pdn tbody tr td:nth-child(3) {
    text-align: left;
  }
}

@media screen and (min-width: 800px) {
  .table-registro-pdn tbody tr td:nth-child(1) {
    text-align: center;
  }

  .table-registro-pdn tbody tr td:nth-child(1) {
    text-align: center;
  }

  .table-registro-pdn thead tr th:nth-child(2) {
    justify-content: center;
  }

  .table-registro-pdn tbody tr td:nth-child(2) {
    text-align: center;
  }


  .table-registro-pdn thead tr th:nth-child(3) {
    justify-content: center;
  }

  .table-registro-pdn tbody tr td:nth-child(3) {
    justify-content: center;
  }
}
</style>