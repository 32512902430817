<template>
	<div>
		<h4 class="pt-3">Programa Principal</h4>   
    <b-card>
      <b-container>
        <b-row>

          <b-col md="6" xs="12" class="pt-2">
            <b-row sm>
              <b-col class="titulo" md="6" xs="12">
                <label>Programa:</label>
              </b-col>
              <b-col md="6" xs="12">
                {{ this.nombre_programa }}
              </b-col>
            </b-row>
          </b-col>

          <b-col md="6" xs="12" class="pt-2">
            <b-row sm>
              <b-col class="titulo" md="6" xs="12">
                <label>Gabinete / Incubadora:</label>
              </b-col>
              <b-col md="6" xs="12">
                {{ this.incubado_en }}
              </b-col>
            </b-row>
          </b-col>

        </b-row>
      </b-container>
    </b-card>
	</div>
</template>

<script>
  export default {
    props: ['nombre_programa','incubado_en']
  };
</script>
<style>
@media (min-width: 768.98px) { 
  .titulo{
    text-align: right;
  }
}
</style>